import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[uPreventDrag]'
})
export class UPreventDragDirective {

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  /// Name:    u-prevent-drag
  /// Purpose: Directive to disable drag & drop on the element. This will prevent users droping files
  ///          and forcing the browser to exit ansible and navigate to the respective file(s).
  /// Usage:   <div u-prevent-drag></div>
  ///////////////////////////////////////////////////////////////////////////////////////////////////

  @HostListener('dragover', ['$event']) private dragover(event: any) {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = 'none';
  }
}
