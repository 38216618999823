import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

declare let Circuit: any;

@Component({
  selector: 'app-ice-settings',
  templateUrl: './ice-settings.component.html',
  styleUrls: ['./ice-settings.component.scss']
})
export class IceSettingsComponent implements OnInit {
  private RtcSessionController: any;
  private LogSvc: any;
  currentSettings: any;

  constructor(private mdDialogRef: MatDialogRef<IceSettingsComponent>) {
    this.RtcSessionController = Circuit.RtcSessionController;
    this.LogSvc = Circuit.serviceInstances.logSvc;
  }

  ngOnInit(): void {
    this.currentSettings = {
      candidatesTimeout: this.RtcSessionController.candidatesTimeout,
      trickleIceTimeout: this.RtcSessionController.trickleIceTimeout,
      allowAllIceCandidatesScreenShare: this.RtcSessionController.allowAllIceCandidatesScreenShare,
      disableTrickleIce: this.RtcSessionController.disableTrickleIce,
      allowOnlyRelayCandidates: this.RtcSessionController.allowOnlyRelayCandidates,
      customTurnServers: this.RtcSessionController.customTurnServers.join('\n')
    };
  }

  close() {
    this.mdDialogRef.close();
  }

  confirm() {
    this.LogSvc.debug('[IceSettingsComponent]: Setting ICE debug data to ', this.currentSettings);
    this.RtcSessionController.candidatesTimeout = this.currentSettings.candidatesTimeout || this.RtcSessionController.DEFAULT_CANDIDATES_TIMEOUT;
    this.RtcSessionController.trickleIceTimeout = this.currentSettings.trickleIceTimeout || this.RtcSessionController.DEFAULT_TRICKLE_ICE_TIMEOUT;
    this.RtcSessionController.allowAllIceCandidatesScreenShare = this.currentSettings.allowAllIceCandidatesScreenShare;
    this.RtcSessionController.disableTrickleIce = this.currentSettings.disableTrickleIce;
    this.RtcSessionController.allowOnlyRelayCandidates = this.currentSettings.allowOnlyRelayCandidates;
    this.RtcSessionController.customTurnServers = this.currentSettings.customTurnServers.split('\n').filter(function (t: any) { return !!t; });

    this.close();
  }

}
