import { EventEmitter, Component, OnInit, Output, ViewChild, OnDestroy, Input } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { DialogService } from '../../sub-modules/dialog/dialog.service';
import { ContactsComponent } from '../contacts/contacts.component';

declare let Circuit: any;

@Component({
  selector: 'app-new-call',
  templateUrl: './new-call.component.html',
  styleUrls: ['./new-call.component.scss']
})
export class NewCallComponent implements OnInit, OnDestroy {
  @ViewChild(ContactsComponent) private contactsComponent!: ContactsComponent;
  private LogSvc: any;
  private PhoneCallSvc: any;

  value = '';
  waitingDialResponse = false;
  isDialable = false;
  isValueSearchable = false;
  root: any;

  @Output('parentToggleNewCall') parentToggleNewCall: EventEmitter<any> = new EventEmitter();
  @Input() call: any;

  constructor(private commonBlService: CommonBLService, private dialogService: DialogService) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PhoneCallSvc = Circuit.serviceInstances.phoneCallSvc;
    this.root = this.commonBlService.getRootScopeData();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.PhoneCallSvc.cancelConsultation(this.call?.callId);
  }

  dialFromDevice = (deviceName?: any, number?: string) => {
    this.LogSvc.buttonPressed('[NewCallComponent]: Make audio call using ', deviceName);
    this.waitingDialResponse = true;
    this.PhoneCallSvc.checkDisclaimerAndDialFromDevice(deviceName, number, null, null)
    .then(() => {
      this.waitingDialResponse = false;
      this.value = '';
      this.goBack();
    })
    .catch((err: any, warn: any) => {
      this.waitingDialResponse = false;
      this.LogSvc.debug('[NewCallComponent]: User has rejected the PopUp');
      this.dialogService.handleCallError(err, warn);
    });
  };

  goBack() {
    this.parentToggleNewCall.emit('NewCall');
  }

  setValue(value: any) {
    this.value = value;
    if (this.value.match(Circuit.Utils.PHONE_DIAL_PATTERN)) {
      this.isDialable = true;
    } else {
      this.isDialable = false;
    }
  }

  isSearchable(isSearchable: any) {
    this.isValueSearchable = isSearchable;
  }

  searchContact(event: any) {
    this.contactsComponent.handleKeydown(event);
  }
}
