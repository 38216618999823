import { Component, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';

declare let Circuit: any;
interface IDeviceCategory {
  deviceList: string;
  soundType: {
    category: string;
    mobile: boolean;
    playMode: string;
    repeat: boolean;
    soundFile: string;
    soundName: string;
    vibrate: boolean;
  };
}

interface IDeviceCategoryIndex {
  [index: string]: IDeviceCategory;
}

const DeviceCategory: IDeviceCategoryIndex = {
  AUDIO_OUTPUT: {
    soundType: Circuit.SoundType.OUTGOING_CALL_RINGBACK_TONE,
    deviceList: 'playbackDevices'
  },
  RINGING_OUTPUT: {
    soundType: Circuit.SoundType.INCOMING_CALL_RINGING,
    deviceList: 'ringingDevices'
  }
};

@Component({
  selector: 'app-test-audio-device',
  templateUrl: './test-audio-device.component.html',
  styleUrls: ['./test-audio-device.component.scss']
})
export class TestAudioDeviceComponent implements OnInit, OnDestroy {
  private LogSvc: any;
  private PopupSvc: any;
  private SoundSvc: any;

  uri: string | undefined;
  category: any;
  soundElement: any;
  ignoreAudioOutputSelectionSupportedAttr: any;
  plays = false;
  matIconEl: any;
  root: any;

  @Input() deviceCategory!: any;
  @Input() ignoreOutputSelectionNotSupported: any | undefined;
  @Input() ringtone: any | undefined;
  @ViewChild('audioElement') audioElementRef: any;

  constructor(private commonBlService: CommonBLService) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PopupSvc = Circuit.serviceInstances.popupSvc;
    this.SoundSvc = Circuit.serviceInstances.soundSvc;
    this.root = this.commonBlService.getRootScopeData();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void {}

  ngAfterViewInit() {
    this.category = DeviceCategory[this.deviceCategory];
    this.soundElement = this.audioElementRef.nativeElement.firstChild;
    this.ignoreAudioOutputSelectionSupportedAttr = this.ignoreOutputSelectionNotSupported !== undefined;
    this.soundElement.addEventListener('ended', this.onPlayEnded);
    // If this attr is present, this directive will be displayed even if this client
    // doesn't support audio output selection, in which case the default device will always be used
    if ((!this.ignoreAudioOutputSelectionSupportedAttr && !Circuit.WebRTCAdapter.audioOutputSelectionSupported) ||
    (!this.category && !this.uri)) {
      this.ngOnDestroy();
      this.audioElementRef.nativeElement.remove();
    }
  }

  onPlayEnded = () => {
    this.plays = false;
    this.LogSvc.debug('[TestAudioDeviceComponent]: Ended playing sound file. URL: ', this.soundElement?.src);
  };

  stop() {
    if (!this.soundElement?.ended && !this.soundElement?.paused) {
      this.soundElement.load(); // This puts the element in paused state
      this.onPlayEnded(); // load() discards all event handlers, so force onPlayEnded() here
      return true;
    }
    return false;
  }

  playOnDevice = () => {
    this.plays = true;
    this.soundElement.play().catch((error: string) => {
      this.LogSvc.error('[TestAudioDeviceComponent]: Error playing sound file.: ', error);
    });
    this.LogSvc.debug('[TestAudioDeviceComponent]: Playing sound file. URL: ', this.soundElement.src);
  };

  play() {
    this.soundElement.src = this.uri || (this.category && this.SoundSvc.buildSoundFileURL(this.category.soundType)) || '';
    this.LogSvc.debug('[TestAudioDeviceComponent]: Testing output device for: ', this.deviceCategory);
    if (this.category && Circuit.WebRTCAdapter.audioOutputSelectionSupported) {
      Circuit.WebRTCAdapter.attachSinkIdToAudioElement(this.soundElement, Circuit.RtcSessionController[this.category.deviceList], (err: any) => {
        if (err) {
          this.LogSvc.error('[TestAudioDeviceComponent]: Device test failed. SetSinkId error: ', err);
          this.PopupSvc.error({message: 'res_TestAudioDeviceError'});
          return;
        }
        this.playOnDevice();
      });
    } else {
      this.playOnDevice();
    }
  }

  testSound() {
    if (!this.stop()) {
      this.play();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ringtone) {
      // First time ringtone is set it won't play it
      if (changes.ringtone?.previousValue && changes.ringtone?.currentValue !== changes.ringtone?.previousValue) {
        // Stop old and play new ringtone
        this.stop();
        this.play();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.soundElement) {
      this.soundElement.src = '';
      this.soundElement.removeEventListener('ended', this.onPlayEnded);
    }
    this.soundElement = null;
  }
}
