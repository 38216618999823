import { Component, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { CallActionService } from '../../services/call-action.service';
import { DialogService } from '../../sub-modules/dialog/dialog.service';

declare let Circuit: any;

@Component({
  selector: 'app-back-to-call',
  templateUrl: './back-to-call.component.html',
  styleUrls: ['./back-to-call.component.scss']
})
export class BackToCallComponent implements OnInit {
  private LogSvc: any;
  private PubSubSvc: any;
  private PhoneCallSvc: any;

  root: any;
  call: any;
  secondCall: any;
  phoneCalls: any;
  defaultAnswerDevice: any;

  constructor(private commonBlService: CommonBLService, private callAction: CallActionService, private dialogService: DialogService) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PhoneCallSvc = Circuit.serviceInstances.phoneCallSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
  }

  ngOnInit(): void {
    this.LogSvc.info('[BackToCallComponent]: ngOnInit');
    this.root = this.commonBlService.getRootScopeData();
    this.PubSubSvc.subscribe('/call/ended', this.onEndCall);
    this.PubSubSvc.subscribe('/conversation/update', this.onConversationUpdate);
    this.initPhoneCalls(true);
    this.defaultAnswerDevice = this.callAction.defaultAnswerDevice;
  }

  ngOnDestroy(): void {
    this.LogSvc.info('[BackToCallComponent]: ngOnDestroy');
    this.PubSubSvc.unsubscribe('/call/ended', this.onEndCall);
    this.PubSubSvc.unsubscribe('/conversation/update', this.onConversationUpdate);
  }

  private onConversationUpdate = (conversation: any) => {
    this.LogSvc.debug('[BackToCallComponent]: Received /conversation/update event. Conversation:', conversation);
    this.initPhoneCalls();
  };

  private onEndCall = (call: any) => {
    if (call && !call.isHandoverInProgress) {
      this.LogSvc.debug('[BackToCallComponent]: Received /call/ended event');
      const transferFailedCause = call.transferCallFailedCause;
      if (transferFailedCause) {
        this.dialogService.handleCallError(transferFailedCause.ui);
      }
      this.initPhoneCalls();
    }
  };

  private initPhoneCalls = (init?: any) => {
    this.phoneCalls = this.PhoneCallSvc.getPhoneCalls();
    if (this.phoneCalls.length > 0) {
      this.call = this.phoneCalls[0];
      this.secondCall = this.call && this.phoneCalls[1];

      if (init && this.call && this.secondCall && this.call.isHolding() && !this.secondCall.isHolding()) {
        //We need to show held call as sencondCall
        this.call = this.phoneCalls[1];
        this.secondCall = this.phoneCalls[0];
      }
    }
  };

  canAnswerCall = (callId: any) => this.PhoneCallSvc.canAnswerCall(callId);
  canPickupCall = (callId: any) => this.PhoneCallSvc.canPickupCall(callId);
  canPullRemoteCall = (callId: any) => this.PhoneCallSvc.canPullRemoteCall(callId);
  isHangupCallAvailable = (callId: any) => this.PhoneCallSvc.isHangupCallAvailable(callId);

  showCallView() {
    this.root.inFullCallView = true;
    this.root.inMiniCallView = false;
    this.dialogService.closeActiveDialog();
  }

  changeMute = (call: any) => {
    this.LogSvc.buttonPressed('[BackToCallComponent]:Toggle mute');
    this.callAction.toggleMute(call);
  };

  pullRemoteCall = (callId: string): void => {
    this.LogSvc.buttonPressed('[CallComponent]: Pull remote call');
    this.callAction.pullRemoteCall(callId);
  };

  endCall = (call: any) => {
    this.LogSvc.info('[CallComponent]: endCall');
    this.callAction.endCall(call);
  };

  answerCall = (callId: string, deviceName?: any): void => {
    this.LogSvc.buttonPressed('[CallComponent]: Answer call from: ' + deviceName);
    this.callAction.answerCall(callId, deviceName);
  };

  getCallTitleUiStr(call: any) {
    return this.callAction.getCallTitleUiStr(call);
  }

  ignoreCall = (call: any) => this.PhoneCallSvc.ignoreCall(call.callId)
    .catch((err: any) => {
      this.LogSvc.info('[BackToCallComponent]: IgnoreCall error:', err);
    });

  getRedirectingUserUiStr = (redirectingUser: any) => this.callAction.getRedirectingUserUiStr(redirectingUser);
}
