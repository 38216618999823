import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogService } from './dialog.service';
import { AngularMaterialModule } from 'projects/main-app/src/app/material.module';
import { CommonNgModule } from 'common-ng/common-ng.module';

@NgModule({
  declarations: [
    AlertDialogComponent,
    ConfirmDialogComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    CommonNgModule
  ],
  providers: [
    { provide: MatDialogRef, useValue: {hasBackdrop: true}},
    DialogService
  ]
})
export class DialogModule { }
