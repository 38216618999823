import { Injectable } from '@angular/core';
import { DialogService } from '../sub-modules/dialog/dialog.service';

declare let Circuit: any;

const INTERVAL_MS = 30000;

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  private LogSvc: any;
  private PubSubSvc: any;
  private HttpSvc: any;

  private preventUnloadPrompt = false;

  intervalId: any;
  WINDOW_OPTIONS: any = {
    DEFAULT_WIDTH: 400,
    DEFAULT_HEIGHT: 300
  };
  WINDOW_CLOSED_INTERVAL = 10000;
  openedWindows: any = {};
  winClosedInterval = 0;

  deferredPrompt: any;

  constructor(private dialogService: DialogService) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this.HttpSvc = Circuit.serviceInstances.httpSvc;
  }

  createInterval = () => {
    this.intervalId = setInterval(() => {
      this.publishEvent();
    }, INTERVAL_MS);
  };

  publishEvent = () => {
    this.PubSubSvc.publish('/interval/update');
  };

  destroyInterval = () => {
    this.LogSvc.debug('[UtilService]: Destroying /interval/update event: ', this.intervalId);
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  };

  isEmptyObject = (obj: any) => Object.keys(obj).length === 0;

  /**
    * Used to open new window.
    * @param options {Object} Options to configure the opened window.
    * - newWindowUrl (required) url of new window
    * - emailWindow (default = false) if true, window is created and closed immediately
    * - data (optional) position: width, height, left, top
    * @return {Object|undefined} window, if it is created
    */
  openNewWindow = (options: any) => {
    const newWindowUrl = options && options.newWindowUrl;
    if (!newWindowUrl) {
      return null;
    }

    if (options.emailWindow) {
      // ANS-26113: We cannot use window.location.href to open the email window because that
      // disconnects the logFileWorker and
      // ANS-25827: We cannot use window.open(path, '_self') for FF to open download dialog due to
      // reconnecting of application
      const newWindow = window.open(newWindowUrl);
      if (newWindow) {
        window.setTimeout(() => {
          newWindow.close();
        }, 100);
      }
    } else {
      const data = options.data || {};
      const width = data.width || this.WINDOW_OPTIONS.DEFAULT_WIDTH;
      const height = data.height || this.WINDOW_OPTIONS.DEFAULT_HEIGHT;
      const left = data.left === undefined ? (screen.width - width) / 2 : data.left;
      const top = data.top === undefined ? (screen.height - height) / 2 : data.top;

      let childWin = this.openedWindows[newWindowUrl];
      // If window already exists, do not create another one.
      if (childWin && !childWin.closed) {
        // win.focus() method works only in IE. For other browsers reopen the window.
        // Chrome: https://bugs.chromium.org/p/chromium/issues/detail?id=622739
        // FF: https://bugzilla.mozilla.org/show_bug.cgi?id=1341711
        childWin.close();
      }

      childWin = window.open(newWindowUrl, '', 'width=' + width + ', height=' + height +
        ', left=' + left + ', top=' + top + ', resizable=yes, scrollbars=yes');
      this.openedWindows[newWindowUrl] = childWin;

      // Check when child windows are closed and clear references (use interval, cause there is no appropriate event)
      this.winClosedInterval = this.winClosedInterval || window.setInterval(() => {
        for (const winUrl in this.openedWindows) {
          if (this.openedWindows.hasOwnProperty(winUrl) && this.openedWindows[winUrl].closed) {
            delete this.openedWindows[winUrl];
          }
        }
        // If there is no opened windows, clear the interval
        if (this.isEmptyObject(this.openedWindows)) {
          window.clearInterval(this.winClosedInterval);
          this.winClosedInterval = 0;
        }
      }, this.WINDOW_CLOSED_INTERVAL);

      return childWin;
    }
    return null;
  };

  downloadAllLogFiles = () => {
    this.LogSvc.getLogFilesForDownload((logFiles: any[]) => {
      if (!logFiles || logFiles.length === 0) {
        return;
      }

      let pendingCount = logFiles.length;
      let failedCount = 0;
      let failedFile: any = null;

      logFiles.forEach(f => {
        this.HttpSvc.downloadFile(f, (err: any) => {
          pendingCount--;
          if (err) {
            failedCount++;
            failedFile = f;
          }
          if (pendingCount === 0) {
            if (failedCount > 0) {
              if (failedCount === 1) {
                this.dialogService.error({ message: 'res_DownloadFailed', messageParams: [failedFile.fileName], title: 'res_Download' });
              } else {
                this.dialogService.error({ message: 'res_DownloadFailedMultiFiles', messageParams: [failedCount], title: 'res_Download' });
              }
            }
          }
        });
      });
    });
  };

  conditionallyToggleClassOnElements = (elements: any[], condition: boolean, className: string):void => {
    if (condition) {
      elements.forEach((elem: any) => {
        elem?.classList.add(className);
      });
    } else {
      elements.forEach((elem: any) => {
        elem?.classList.remove(className);
      });
    }
  };

  isWebHIDSupported = (): boolean => {
    if ('hid' in navigator) {
      // The WebHID API is supported.
      return true;
    }
    return false;
  };

  isInstalledApp = (): boolean => window.matchMedia('(display-mode: standalone)').matches;

  shouldPreventUnloadPrompt = (): boolean => this.preventUnloadPrompt;

  setPreventUnloadPrompt = (value: boolean) => {
    this.preventUnloadPrompt = value;
  };

  redirectToPath(path:any, timeout = 500) {
    this.LogSvc.info('[UtilService]: Redirect client to ', path);
    const host = window.location.host;
    const port = window.location.port ? `:${window.location.port}` : '';
    setTimeout(() => {
      window.location.href = 'https://' + host + port + '/' + path;
    }, timeout);
  }

  showPrompt = () => {
    if (!this.deferredPrompt) {
      return;
    }
    this.deferredPrompt.prompt();
    // Wait for user to respond to the prompt
    this.deferredPrompt?.userChoice.then((/* choice: any */) => {
      this.resetPrompt();
    });
  };

  resetPrompt = () => {
    this.deferredPrompt = null;
  };

  getAvatarImageFormat(image: string) {
    const regex = /[\w]+:[\w]+\/png/;
    return regex.exec(image) ? 'png' : 'jpeg';
  }
}
