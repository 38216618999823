import { Injectable } from '@angular/core';
import { LoginType, Providers, ProviderState } from '@microsoft/mgt-element';
import { Msal2Provider } from '@microsoft/mgt-msal2-provider';
import { DialogService } from './sub-modules/dialog/dialog.service';

declare let Circuit: any;
declare let RegistrationState: any;

const COMMON_CHAR_REGEX = /[-()\s]/g;
const DIGIT_LENGTH_REGEX = /^\+?[\d#*]{3,}\b/g;
const PREFIX_CHAR_PATTERN = '[\\+\\b]?[\\*#]*';
const PREFIX_PATTERN = '[\\+\\b]?[\\d\\*#]*';
const POSTFIX_PATTERN = '[\\d\\*#]*\\b';
const PERSONAL_CONTACTS_SYNC_MIN_INTERVAL = 60 * 60 * 1000; // Sync Exchange private contacts cache every hour
const DEFAULT_NUM_CONTACTS = 5;
const MAX_CONTACTS_SIZE = 1000;
const USER_CANCELLED = 'user_cancelled';

class Deferred {
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
    this.promise.finally(() => {
      this.fulfilled = true;
    });
  }
  resolve: any;
  reject: any;
  promise: Promise<any>;
  fulfilled = false;
}

type GenObject = {
  [key: string]: any;
}

@Injectable({
  providedIn: 'root'
})
export class ExchangeOnlineService {
  private LogSvc: any;
  private UserProfileSvc: any;
  private PubSubSvc: any;
  private LocalStoreSvc: any;
  private _provider: any;
  private _exchangeConfigData: any;
  private _hostname: string;
  private _connected = false;
  private _lastConnectionError: string | null;
  private _lastPersonalContactsSync: any = 0;
  private _personalContactsPhoneMap:Map<any, any> = new Map<any, any>();
  private _personalContacts: Map<any, any> = new Map<any, any>();
  private _phoneLookup = '';
  private _phoneNumberLookupSearchId = 0;
  private _exchangeServerSearchId = 0;
  private _cacheInitialized: Deferred = new Deferred();
  private _phoneTypesMap: GenObject = {
    home: Circuit.Constants.PhoneNumberType.HOME,
    business: Circuit.Constants.PhoneNumberType.WORK,
    mobile: Circuit.Constants.PhoneNumberType.MOBILE
  };

  constructor(private dialogService: DialogService) {
    const port = window.location.port ? `:${window.location.port}` : '';
    this._hostname = `https://${window.location.host}${port}`;
    this._lastConnectionError = null;
  }

  // Needed for injection in MailBoxConnSvc
  name = 'ExchangeOnlineSvc';

  private regExpEscape = (str: any) => {
    if (!str) {
      return '';
    }
    return str.replace(/[.*+?|()[\]{}\\$^]/g, '\\$&');
  };

  private processPhoneNumber = (p: any) => {
    if (!p || !p.number) {
      return false;
    }
    if (!Circuit.Utils.PHONE_DIAL_PATTERN.test(p.number)) {
      const match = p.number.match(Circuit.Utils.PHONE_WITH_EXTENSION_PATTERN);
      if (match) {
        // Phone numbers in Exchange might contain extension numbers as well
        // so strip the extension out
        p.number = match[1] || '';
      } else {
        return false;
      }
    }
    // Remove any non-digit character from the phone number (except +)
    p.number = p.number.replace(/[^\d+*#]/g, '');
    if (!p.number) {
      return false;
    }
    return true;
  };

  private processContact = (c: any, skipAddToLookupStr = false) => {
    const phones = c.phoneNumbers;
    if (phones) {
      phones.forEach((p: any) => {
        if (!this.processPhoneNumber(p)) {
          return;
        }
        this._personalContactsPhoneMap.set(p.number, c.id);
        if (!skipAddToLookupStr) {
          this._phoneLookup += '\t' + p.number;
        }
      });
    }
    this._personalContacts.set(c.id, c);
  };

  private setGlobalProvider = (config: any) => {
    this.LogSvc.info('[ExchangeOnlineService]: Initializing Exchange Provider.');
    Providers.globalProvider = new Msal2Provider({
      clientId: config.clientId,
      scopes: [
        'Contacts.Read',
        'Contacts.Read.Shared',
        'Directory.Read.All',
        'User.Read'
      ],
      redirectUri: `${this._hostname}${config.redirectUri}`,
      loginType: LoginType.Popup
    });

    if (this._provider) {
      Providers.removeProviderUpdatedListener(this.onProviderStateChanged);
    }
    this._provider = Providers.globalProvider;
    // Register a callback for when the state changes
    Providers.onProviderUpdated(this.onProviderStateChanged);
  };

  private mergePhoneNumbers = (total: any, phoneNumbers: any, type: any) => {
    if (phoneNumbers) {
      const phones = phoneNumbers.map((phoneNumber: any) => ({
        number: phoneNumber,
        type: type
      }));
      total.push(...phones);
    }
  };

  private parseContacts = (contacts: any, addToCache = false): Array<any> => {
    if (!contacts) {
      return [];
    }
    if (!(contacts instanceof Array)) {
      contacts = [contacts];
    }
    return contacts.map((contact: any) => {
      contact.firstName = contact.firstName || contact.givenName;
      contact.lastName = contact.lastName || contact.surname;
      if (!contact.firstName || !contact.lastName) {
        const tokens = contact.displayName.split(' ');
        contact.firstName = contact.firstName || tokens?.[0];
        contact.lastName = contact.lastName || (tokens?.length > 1 ? tokens[tokens.length - 1] : undefined);
      }

      const phoneNumbers = contact.phones?.reduce((pns: any, pn: any) => {
        if (pn.number) {
          const type = this._phoneTypesMap[pn.type] || Circuit.Constants.PhoneNumberType.OTHER;
          pns.push({ number: pn.number, type });
        }
        return pns;
      }, []);
      if (phoneNumbers?.length > 0) {
        contact.phoneNumbers = phoneNumbers;
      }
      if (!contact.phoneNumbers) {
        contact.phoneNumbers = [];
        const mobilePhoneNumbers = contact.mobilePhone && [contact.mobilePhone];
        this.mergePhoneNumbers(contact.phoneNumbers, contact.businessPhones, Circuit.Constants.PhoneNumberType.WORK);
        this.mergePhoneNumbers(contact.phoneNumbers, contact.homePhones, Circuit.Constants.PhoneNumberType.HOME);
        this.mergePhoneNumbers(contact.phoneNumbers, mobilePhoneNumbers, Circuit.Constants.PhoneNumberType.MOBILE);
      }
      contact.isExchangeOnline = true;
      addToCache && this.processContact(contact);
      return contact;
    });
  };

  private onProviderStateChanged = () => {
    this.checkConnected();
  };

  private populateContactsFromLocalStore = async () => {
    try {
      return this.LocalStoreSvc.getAllExchangeOnlineContacts()
      .then((contacts: any) => {
        if (contacts instanceof Array) {
          this.LogSvc.info(`[ExchangeOnlineService]: Retrieved ${contacts.length} contacts from local store`);
          contacts.forEach((contact: any) => {
            this.processContact(contact);
          });
        }
      });
    } catch (error) {
      this.LogSvc.error('[ExchangeOnlineService]: Could not retrieve contacts from local store: ', error);
      return false;
    }
  };

  private startContactsSync = async () => {
    if (!this.LocalStoreSvc.isCachingDisabled() || !this._connected) {
      await this.populateContactsFromLocalStore();
      this.LogSvc.debug('[ExchangeOnlineService]: Start syncing personal contacts.');
      this.syncAllPersonalContacts();
    }
  };

  private clearCachedContacts = async () => {
    try {
      this._personalContactsPhoneMap.clear();
      this._personalContacts.clear();
      this._phoneLookup = '';
      this._phoneNumberLookupSearchId = 0;
      this._exchangeServerSearchId = 0;
      this._lastPersonalContactsSync = 0;
      await this.LocalStoreSvc.removeAllExchangeOnlineContacts();
    } catch (error: any) {
      this.LogSvc.error(`[ExchangeOnlineService]: Error while clearing contacts cache: ${error}`);
    }
  };

  private checkConnected = (skipEvent = false) => {
    this._lastConnectionError = null;
    if (this._provider?.state !== ProviderState.SignedIn) {
      const wasConnected = this._connected;
      this._connected = false;
      if (!skipEvent && wasConnected && this._provider?.state === ProviderState.SignedOut) {
        this.PubSubSvc.publish('/exchangeOnline/disconnected');
      }
    } else {
      this._connected = true;
      if (!skipEvent) {
        this.PubSubSvc.publish('/exchangeOnline/connected');
        this.startContactsSync();
      }
    }

    return this._connected;
  };

  private onInitEvent = async (state: any) => {
    if (state !== RegistrationState.Registered) {
      return;
    }
    await this.initProvider();
  };

  private onRegistrationState = async (state: any) => {
    this.LogSvc.debug('[ExchangeOnlineService]: Received /registration/state event');
    await this.onInitEvent(state);
  };

  private async searchPrivateContacts(searchQuery: string, resCount: bigint) {
    try {
      return await this._provider?.graph.client
      .api('me/contacts')
      .top(resCount)
      .search(searchQuery)
      .get();
    } catch (error: any) {
      this.LogSvc.error(`[ExchangeOnlineService]: Could not search for private Exchange contacts: ${error}`);
      return null;
    }
  }

  private async searchGlobalContacts(searchQuery: string, resCount: bigint) {
    let res = null;
    try {
      res = await this._provider?.graph.client?.
      api('contacts')
      .header('ConsistencyLevel', 'eventual')
      .top(resCount)
      .search(searchQuery)
      .get();
    } catch (error: any) {
      this.LogSvc.error(`[ExchangeOnlineService]: Could not search for global Exchange contacts: ${error}`);
    }
    return res;
  }

  getConnected = (): boolean => this._connected;

  getLastConnectionError = (): string | null => this._lastConnectionError;

  resetConnectionError = () => {
    this._lastConnectionError = null;
  };

  getExchangeConfiguration = async () => {
    const localUser = await this.UserProfileSvc.getLocalUserPromise();
    this._exchangeConfigData = {
      clientId: localUser.exchangeOnlineClientId,
      redirectUri: localUser.exchangeOnlineRedirectUri
    };
    return Promise.resolve(this._exchangeConfigData);
  };

  initProvider = async () => {
    const config = await this.getExchangeConfiguration();
    if (!config?.clientId) {
      this.LogSvc.error('[ExchangeOnlineService]: Missing Exchange configuration');
      return;
    }
    this.setGlobalProvider(config);
  };

  connect = async () => {
    if (!this._provider) {
      this.LogSvc.warn('[ExchangeOnlineService]: Exchange Provider is not initialized yet.');
      const options = {
        message: 'res_MicrosoftExchangeError'
      };
      this.dialogService.error(options).result.catch((/* err: any */) => {
        // Handle reject to prevent 'Possibly unhandled rejection' error
      });
      return;
    }

    if (this.checkConnected(true)) {
      return;
    }
    if (this._provider?.login) {
      try {
        await this._provider.login();
      } catch (error: any) {
        this.LogSvc.error(`[ExchangeOnlineService]: Could not connect to Exchange Online: ${error}`);
        if (typeof error === 'object' && error?.errorCode.toLowerCase() === USER_CANCELLED) {
          this.LogSvc.warn(`[ExchangeOnlineService]: User cancelled the proccess: ${error}`);
          this._lastConnectionError = null;
          return;
        }
        this._lastConnectionError = error;
      }
    }
  };

  disconnect = async () => {
    if (this._provider?.logout) {
      try {
        await this._provider.logout();
        await this.clearCachedContacts();
      } catch (error: any) {
        this.LogSvc.error(`[ExchangeOnlineService]: Could not disconnect from Exchange Online: ${error}`);
      }
    }
  };

  searchContactsWithConstraints = async (searchStr: any, constraints: any, resCount: any, cb: any) => {
    if (!searchStr || typeof searchStr !== 'string') {
      cb && cb(null, []);
      return null;
    }
    const isNumberLookup = constraints?.phoneNumberLookup || constraints?.reversePhoneNumberLookup;
    if (!isNumberLookup && (this._provider?.state !== ProviderState.SignedIn)) {
      // If it's not a number lookup and we are not signed in, return;
      this.LogSvc.warn('[ExchangeOnlineService]: Can not search for contacts while signed out');
      cb && cb();
      return null;
    }
    const contacts: Array<any> = [];

    if (isNumberLookup) {
      // First remove commonly used phone number formatting characters: (, ), - and white spaces. In case the user is copy/pasting the phone number
      // We can't simply remove all non-digit chars, because that would cause search strings like "conferenceroom123" to trigger a phone number lookup
      let numberLookupStr = searchStr.replace(COMMON_CHAR_REGEX, '');
      // 1. If it's number lookup (numberLookupStr begins with 3 or more digits), search for it in the cached private contacts
      if (this._phoneLookup.length > 0 && numberLookupStr.match(DIGIT_LENGTH_REGEX)) {
        this.LogSvc.debug('[ExchangeOnlineService]: Searching number in Personal Contacts cache. Number of phone numbers in cache: ', this._phoneLookup.length);
        let singleMatch = numberLookupStr[0] === '+'; // If numberLookupStr is in E164 format, there can be only one match
        // Make sure that special characters in the numberLookupStr are escaped
        numberLookupStr = this.regExpEscape(numberLookupStr);
        let regex;
        if (constraints.reversePhoneNumberLookup) {
          // Full match
          singleMatch = true;
          regex = new RegExp(PREFIX_CHAR_PATTERN + numberLookupStr + '\\b', 'g');
        } else {
          // Partial match
          regex = new RegExp(PREFIX_PATTERN + numberLookupStr + POSTFIX_PATTERN, 'g');
        }
        const matches = this._phoneLookup.match(regex) || [];
        const matchesLength = matches.length;
        if (singleMatch && matchesLength > 1) {
          this.LogSvc.warn(`[ExchangeOnlineService]: searchContacts. ${matchesLength} private contacts found for ${numberLookupStr}. No private contacts returned`);
        } else {
          this.LogSvc.debug('[ExchangeOnlineService]: Number of matches in local cache:', matchesLength);
          // Copy all matches to contacts
          for (let i = 0; i < matchesLength; i++) {
            const phone = matches[i];
            const id = this._personalContactsPhoneMap.get(phone);
            const c = this._personalContacts.get(id);
            if (c) {
              contacts.push({
                firstName: c.firstName,
                lastName: c.lastName,
                phoneNumbers: [{
                  type: c.phoneType,
                  number: phone
                }],
                emailAddresses: c.emailAddresses,
                department: c.department,
                sourceIntegrationRes: 'res_ExchangeContact',
                isExchangeOnline: true,
                isExchangeContact: true
              });
            }
          }
        }

        cb(null, contacts);
        this._phoneNumberLookupSearchId++;
        return this._phoneNumberLookupSearchId;
      }
    }
    if (this._provider?.state !== ProviderState.SignedIn) {
      // If it's not a number lookup and we are not signed in, return;
      this.LogSvc.warn('[ExchangeOnlineService]: Can not search for contacts while signed out');
      cb && cb();
      return null;
    }
    try {
      resCount = resCount || DEFAULT_NUM_CONTACTS;
      // 2. Search in Exchange only if it's not a number lookup
      const searchQuery = `"givenName:${searchStr} OR surname:${searchStr}"`;
      const searchQueryGlobal = `"givenName:${searchStr}" OR "surname:${searchStr}" OR "displayName:${searchStr}"`;
      const privateContacts = await this.searchPrivateContacts(searchQuery, resCount);
      const globalContacts = await this.searchGlobalContacts(searchQueryGlobal, resCount);
      cb && cb(null, [...contacts, ...this.parseContacts([...globalContacts?.value, ...privateContacts?.value])]);
      this._exchangeServerSearchId++;
      return this._exchangeServerSearchId;
    } catch (error: any) {
      this.LogSvc.error(`[ExchangeOnlineService]: Could not search for Exchange contacts: ${error}`);
      cb && cb(error);
    }
    return null;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  cancelSearchContacts = (reqId: any) => {};

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  getContact = (exchangeEmail: any, cb: any) => {};

  getAllPersonalContacts = async () => {
    if (!this._cacheInitialized || this._cacheInitialized.fulfilled) {
      this._cacheInitialized = new Deferred();
    }
    if (this._provider?.state !== ProviderState.SignedIn) {
      this.LogSvc.warn('[ExchangeOnlineService]: Can not fetch all personal contacts while signed out');
      this._cacheInitialized.resolve(Array.from(this._personalContacts.values()));
      return this._cacheInitialized.promise;
    }

    try {
      const contacts = await this._provider?.graph.client
        .api('/me/contacts')
        .top(MAX_CONTACTS_SIZE)
        .get();
      const contactFolders = await this._provider?.graph.client
        .api('/me/contactFolders')
        .get();
      await this.clearCachedContacts();
      this.parseContacts(contacts?.value, true);
      if (contactFolders?.value instanceof Array) {
        // Create get folder contacts promises
        const folderPromises = contactFolders?.value.reduce((folderPrs: any, folder: any) => {
          folderPrs.push(
            new Promise<void>(resolve => {
              this._provider?.graph.client
                  .api(`/me/contactFolders/${folder.id}/contacts`)
                  .top(MAX_CONTACTS_SIZE)
                  .get()
                  .then(resolve)
                  .catch((err: any) => {
                    this.LogSvc.error(`Could not fetch contacts for folder: ${folder?.displayName} `, err);
                    resolve();
                  });
            })
          );
          return folderPrs;
        }, []);
        // Execute all promises and gather all contacts
        const folderResults = await Promise.all(folderPromises);
        const fContacts = folderResults.reduce((totalContacts: any, res: any) => {
          res?.value && totalContacts.push(...res?.value);
          return totalContacts;
        }, []);
        this.parseContacts(fContacts, true);
      }
    } catch (error: any) {
      this.LogSvc.error(`[ExchangeOnlineService]: Could not retrieve all personal Exchange contacts: ${error}`);
    }
    this._cacheInitialized.resolve(Array.from(this._personalContacts.values()));
    return this._cacheInitialized.promise;
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  disableAutoConnect = () => {};

  syncAllPersonalContacts = () => {
    if (this.LocalStoreSvc.isCachingDisabled() || !this._connected) {
      return;
    }
    const now = Date.now();
    if (now - this._lastPersonalContactsSync > PERSONAL_CONTACTS_SYNC_MIN_INTERVAL) {
      this._lastPersonalContactsSync = now;
      this.LogSvc.debug('[ExchangeOnlineService]: syncAllPersonalContacts. Next sync after ' +
          new Date(this._lastPersonalContactsSync +
              PERSONAL_CONTACTS_SYNC_MIN_INTERVAL).toLocaleTimeString()
      );
      this.getAllPersonalContacts()
      .then((contacts: any) => {
        this.LocalStoreSvc.putExchangeOnlineContacts(contacts || [])
        .catch((err: any) => {
          this.LogSvc.error('[ExchangeOnlineService]: Could not update personal contacts in local store: ', err);
        });
      })
      .catch((err: any) => {
        this.LogSvc.error('[ExchangeOnlineService]: Could not fetch all personal contacts: ', err);
      });
    }
  };

  updateUserWithLocalContactName = (user: any) => {
    this._cacheInitialized.promise
      .then(() => {
        if (this._phoneLookup.length === 0 || !user || !(user.phoneNumber || user.fullyQualifiedNumber)) {
          return false;
        }
        const number = user.fullyQualifiedNumber || user.phoneNumber;
        // First remove commonly used phone number formatting characters: (, ), - and white spaces. In case the user is copy/pasting the phone number
        // We can't simply remove all non-digit chars, because that would cause search strings like "conferenceroom123" to trigger a phone number lookup
        let numberLookupStr = number.replace(COMMON_CHAR_REGEX, '');
        // Search for the number lookup in the cached private contacts
        if (numberLookupStr.match(DIGIT_LENGTH_REGEX)) {
          this.LogSvc.debug('[ExchangeOnlineService]: Searching number in Personal Contacts cache');
          // Make sure that special characters in the numberLookupStr are escaped
          numberLookupStr = this.regExpEscape(numberLookupStr);
          const regex = new RegExp(PREFIX_CHAR_PATTERN + numberLookupStr + '\\b', 'g');
          const matches = this._phoneLookup.match(regex) || [];
          const l = matches.length;
          this.LogSvc.debug(`[ExchangeOnlineService]: Found ${l} entries for ${number} in Personal Contacts cache`);
          if (l === 1) {
            const phone = matches[0];
            const id = this._personalContactsPhoneMap.get(phone);
            const c = this._personalContacts.get(id);
            if (c) {
              user.displayName = ((c.firstName || '') + ' ' + (c.lastName || '')).trim();
              user.isExchangeContact = true;
              user.isExchangeOnline = true;
              return true;
            }
          }
        }
        return false;
      });
  };

  init = () => {
    Circuit.serviceInstances.pubSubSvc.subscribe('/registration/state', this.onRegistrationState);
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this.UserProfileSvc = Circuit.serviceInstances.userProfileSvc;
    this.LocalStoreSvc = Circuit.serviceInstances.localStoreSvc;
  };
}
