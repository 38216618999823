import { Component, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';

declare let RegistrationState: any;

@Component({
  selector: 'app-state-banner',
  templateUrl: './state-banner.component.html',
  styleUrls: ['./state-banner.component.scss']
})
export class StateBannerComponent implements OnInit {
  root: any;
  i18n: any;

  constructor(private commonBlService: CommonBLService) {
    this.root = this.commonBlService.getRootScopeData();
    this.i18n = this.root.i18n;
  }

  ngOnInit(): void { }

  isReconnecting = () => {
    return this.root.registrationState === RegistrationState.Reconnecting;
  }
}
