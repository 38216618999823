import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { LocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SettingsOverlayComponent } from '../../components/settings-overlay/settings-overlay.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { CallFwdOverlayComponent } from '../../components/call-fwd-overlay/call-fwd-overlay.component';
import {EditLogLevelComponent} from '../../components/edit-log-level/edit-log-level.component';
import { AboutOverlayComponent } from '../../components/about-overlay/about-overlay.component';
import { HelpOverlayComponent } from '../../components/help-overlay/help-overlay.component';
import { IceSettingsComponent } from '../../components/ice-settings/ice-settings.component';
import { RtpDebugAudioComponent } from '../../components/rtp-debug-audio/rtp-debug-audio.component';
import { AgentStatusIndicationComponent } from '../../components/agent-status-indication/agent-status-indication.component';
import { WhatsNewComponent } from '../../components/whats-new/whats-new.component';
import { EditProfilePictureComponent } from '../../components/edit-profile-picture/edit-profile-picture.component';
import { ExchangeConnectComponent } from '../../components/exchange-connect/exchange-connect.component';
import { CallQualityComponent } from '../../components/call-quality/call-quality.component';

declare let Circuit: any;

@Injectable({ providedIn: 'root' })
export class DialogService {

  // eslint-disable-next-line no-useless-constructor
  constructor(
    private location:LocationStrategy,
    readonly dialog: MatDialog
  ) { }


  dialogRef!: MatDialogRef<any>;

  private isString = (value: any) => typeof value === 'string' || value instanceof String;

  // eslint-disable-next-line arrow-body-style
  open = (options: any): Promise<any> => {
    return new Promise<void>((resolve, reject) => {
      this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
        scrollStrategy: new NoopScrollStrategy(), //this is added to disable vertical background scrolling
        data: options,
        minWidth: '30rem',
        maxWidth: options.maxWidth || '50%'
      });
      this.confirmed().then(confirmed => {
        if (confirmed) {
          resolve();
          return;
        }
        reject();
      });
    });
  };

  confirmed = (): Promise<any> => this.dialogRef.afterClosed().toPromise();

  // eslint-disable-next-line arrow-body-style
  openSettingsView = (options: any): Promise<any> => {
    return new Promise<void>((resolve, reject) => {
      this.dialogRef = this.dialog.open(SettingsOverlayComponent, {
        scrollStrategy: new NoopScrollStrategy(), //this is added to disable vertical background scrolling
        data: options,
        width: '100%',
        maxWidth: '100%',
        panelClass: ['settings-overlay'],
        hasBackdrop: false,
        disableClose: false, // true prevents ESC from closing the dialog
        ariaLabel: 'Settings'
      });
      this.confirmed().then(confirmed => {
        if (confirmed) {
          resolve();
          return;
        }
        reject();
      });
    });
  };

  // eslint-disable-next-line arrow-body-style
  openAboutView = (options: any): Promise<any> => {
    return new Promise<void>((resolve, reject) => {
      this.dialogRef = this.dialog.open(AboutOverlayComponent, {
        scrollStrategy: new NoopScrollStrategy(), //this is added to disable vertical background scrolling
        data: options,
        width: '100%',
        maxWidth: '100%',
        panelClass: ['settings-overlay'],
        hasBackdrop: false,
        disableClose: false, // true prevents ESC from closing the dialog
        ariaLabel: 'About'
      });
      this.confirmed().then(confirmed => {
        if (confirmed) {
          resolve();
          return;
        }
        reject();
      });
    });
  };

  // eslint-disable-next-line arrow-body-style
  openHelpView = (options: any): Promise<any> => {
    return new Promise<void>((resolve, reject) => {
      this.dialogRef = this.dialog.open(HelpOverlayComponent, {
        scrollStrategy: new NoopScrollStrategy(), //this is added to disable vertical background scrolling
        data: options,
        width: '100%',
        maxWidth: '100%',
        panelClass: ['settings-overlay'],
        hasBackdrop: false,
        disableClose: false, // true prevents ESC from closing the dialog
        ariaLabel: 'Help'
      });
      this.confirmed().then(confirmed => {
        if (confirmed) {
          resolve();
          return;
        }
        reject();
      });
    });
  };


  // eslint-disable-next-line arrow-body-style
  openCallFwdView = (options: any): Promise<any> => {
    return new Promise<void>((resolve, reject) => {
      this.dialogRef = this.dialog.open(CallFwdOverlayComponent, {
        scrollStrategy: new NoopScrollStrategy(), //this is added to disable vertical background scrolling
        data: options,
        width: '100%',
        maxWidth: '28rem',
        minHeight: '17.3rem',
        panelClass: ['callFwd-overlay'],
        hasBackdrop: true,
        position: {top: '4.1rem', right: '0'}
      });
      this.confirmed().then(confirmed => {
        if (confirmed) {
          resolve();
          return;
        }
        reject();
      });
    });
  };

  // eslint-disable-next-line arrow-body-style
  openExchangeSettingsView = (options?: any): Promise<any> => {
    return new Promise<void>((resolve, reject) => {
      this.dialogRef = this.dialog.open(ExchangeConnectComponent, {
        scrollStrategy: new NoopScrollStrategy(), //this is added to disable vertical background scrolling
        width: '100%',
        maxWidth: '44.8rem',
        data: options,
        panelClass: ['log-level-modal'],
        hasBackdrop: true
      });
      this.confirmed().then(confirmed => {
        if (confirmed) {
          resolve();
          return;
        }
        reject();
      });
    });
  };

  // eslint-disable-next-line arrow-body-style
  openEditLogLevelComponent = (options: any): Promise<any> => {
    return new Promise<void>((resolve, reject) => {
      this.dialogRef = this.dialog.open(EditLogLevelComponent, {
        scrollStrategy: new NoopScrollStrategy(), //this is added to disable vertical background scrolling
        data: options,
        panelClass: ['log-level-modal'],
        hasBackdrop: true
      });
      this.confirmed().then(confirmed => {
        if (confirmed) {
          resolve();
          return;
        }
        reject();
      });
    });
  };

  // eslint-disable-next-line arrow-body-style
  openICESettingsComponent = (options: any): Promise<any> => {
    return new Promise<void>(() => {
      this.dialogRef = this.dialog.open(IceSettingsComponent, {
        scrollStrategy: new NoopScrollStrategy(), //this is added to disable vertical background scrolling
        data: options,
        panelClass: ['ice-settings-modal'],
        hasBackdrop: true
      });
    });
  };

  // eslint-disable-next-line arrow-body-style
  openRtpDebugAudioSettingsComponent = (options: any): Promise<any> => {
    return new Promise<void>(() => {
      this.dialogRef = this.dialog.open(RtpDebugAudioComponent, {
        scrollStrategy: new NoopScrollStrategy(), //this is added to disable vertical background scrolling
        data: options,
        panelClass: ['ice-settings-modal'],
        hasBackdrop: true
      });
    });
  };

  openAgentStatusIndicationComponent = (options: any): Promise<any> =>
    new Promise<void>((resolve, reject) => {
      this.dialogRef = this.dialog.open(AgentStatusIndicationComponent, {
        scrollStrategy: new NoopScrollStrategy(), //this is added to disable vertical background scrolling
        data: options,
        panelClass: ['agent-status-indication-modal'],
        maxWidth: '29rem',
        hasBackdrop: true,
        position: {top: '4.1rem', right: '2rem'}
      });
      this.confirmed().then(confirmed => {
        if (confirmed) {
          resolve();
          return;
        }
        reject();
      });
    });

  openWhatsNewComponent = (options: any): Promise<any> =>
    new Promise<void>((resolve, reject) => {
      this.dialogRef = this.dialog.open(WhatsNewComponent, {
        scrollStrategy: new NoopScrollStrategy(), //this is added to disable vertical background scrolling
        data: options,
        hasBackdrop: true,
        maxWidth: '100rem',
        width: '80vw'
      });
      this.confirmed().then(confirmed => {
        if (confirmed) {
          resolve();
          return;
        }
        reject();
      });
    });

  openEditProfilePicture = (options: any): Promise<any> =>
    new Promise<void>((resolve, reject) => {
      this.dialogRef = this.dialog.open(EditProfilePictureComponent, {
        scrollStrategy: new NoopScrollStrategy(), //this is added to disable vertical background scrolling
        data: options,
        hasBackdrop: true,
        maxWidth: '48rem',
        width: '80vw'
      });
      this.confirmed().then(confirmed => {
        if (confirmed) {
          resolve();
          return;
        }
        reject();
      });
    });

  // eslint-disable-next-line arrow-body-style
  openCallQualityView = (options: any): Promise<any> => {
    return new Promise<void>(() => {
      this.dialogRef = this.dialog.open(CallQualityComponent, {
        scrollStrategy: new NoopScrollStrategy(), //this is added to disable vertical background scrolling
        data: options,
        width: '100%',
        maxWidth: '28rem',
        hasBackdrop: true
      });
    });
  };

  /**
   * Used to display custom modals.
   * @param options {object} Options to configure the modal. Same API as $dialog directly
   * @example: PopupSvc.openCustomModal({
   *     backdropClick: true,
   *     templateUrl: 'views/modals/releaseNotes.html',
   *     controller: 'ReleaseNotesCtrl',
   *     resolve = {
   *       releaseNotes: function () {
   *         return releaseNotes;
   *       }
   *     }
   *  });
   */
  openCustomModal = (options: any) => {
    if (!options.templateUrl && !options.component) {
      return null;
    }
    Circuit.serviceInstances.logSvc.debug('[DialogService] openCustomModal: ' + options.templateUrl);
    return this.open(options);
  };

  /**
   * Used to display generic modals.
   * @param options {object} Options to configure the modal.
   * @options.message {String} The message to be displayed in the modal body. This may also be a resource name.
   * @options.title {String} The title to be displayed in the modal header.
   * @options.messageParams {Array} Message-specific localization parameters.
   * @options.titleParams {Array} Title-specific localization parameters.
   * @options.size {String} The modal's size. Can be large, medium, small, mini.
   * @example: this.dialogService.openGenericModal({
   *     title: 'res_TermsOfService',
   *     message: LegalTexts[$rootScope.localUser.locale].terms
   *  });
   */
  openGenericModal = (options: any) => {
    if (!options.message) {
      return null;
    }
    const genericOptions = {
      title: options.title,
      titleParams: options.titleParams,
      message: options.message,
      messageParams: options.messageParams,
      type: 'generic',
      action: options.action || false, // indicates that the modal should display yes/no buttons
      yesLabel: options.yesLabel || undefined,
      noLabel: options.noLabel || undefined,
      messageClickAction: options.messageClickAction,
      resolve: {options: function () { return genericOptions; }}
    };
    Circuit.serviceInstances.logSvc.debug('[DialogService] openGenericModal: ' + genericOptions.message);
    return this.open(genericOptions);
  };

  /**
   * Used to display alert modals (modals with ok button).
   * @param options {object} Options to configure the modal.
   * @options.message {String} The error message to be displayed in the modal body.
   * @options.title {String} The error title to be displayed in the modal header.
   * @options.messageParams {Array} Message-specific localization parameters.
   * @options.titleParams {Array} Title-specific localization parameters.
   * @options.size {String} The modal's size.
   * @options.btnok {Boolean} Controls the display of the ok button.
   * @options.okLabel {String} Text (label) for the 'ok' button. Defaults to 'Ok'.
   * @example PopupSvc.alert({
          title: 'res_SystemIssue',
          message: 'res_BackendThrottleError'
      });
  */
  alert = (options: any) => {
    if (!options.message) {
      return null;
    }
    const alertOptions = {
      title: options.title,
      titleParams: options.titleParams,
      message: options.message,
      messageParams: options.messageParams,
      type: 'generic',
      size: options.size || 'small',
      alert: true, // indicates that the modal should display ok button
      okLabel: options.okLabel || 'res_OK',
      resolve: {options: function () { return alertOptions; }}
    };
    Circuit.serviceInstances.logSvc.debug('[DialogService] alert: ', alertOptions.message);
    return this.open(alertOptions);
  };

  /**
 * Used to display confirm modals (modals with confrim/cancel buttons).
 * @param options {object} Options to configure the modal.
 * @options.message {String} The error message to be displayed in the modal body.
 * @options.title {String} The error title to be displayed in the modal header.
 * @options.messageParams {Array} Message-specific localization parameters.
 * @options.titleParams {Array} Title-specific localization parameters.
 * @options.size {String} The modal's size.
 * @options.yesLabel {String} Text (label) for the 'yes' button. Defaults to 'Yes'.
 * @options.noLabel {String} Text (label) for the 'no' button. Defaults to 'No'.
 * @example PopupSvc.confirm({
    message: 'res_UnloadActiveCall',
        yesLabel: 'res_Yes',
        noLabel: 'res_No',
    });
  */
  confirm = (options: any) => {
    if (!options.message) {
      return {
        result: Promise.reject()
      };
    }
    const confirmOptions = {
      title: options.title,
      titleParams: options.titleParams,
      message: options.message,
      messageParams: options.messageParams,
      type: 'confirm',
      action: true, // indicates that the modal should display yes/no buttons
      size: options.size || 'medium',
      maxWidth: options.maxWidth,
      yesLabel: options.yesLabel || 'res_Yes',
      noLabel: options.noLabel || (options.noLabel === undefined ? 'res_No' : null),
      keyboard: options.keyboard || true,
      timeOut: options.timeOut,
      resolve: {options: function () { return confirmOptions; }}
    };
    Circuit.serviceInstances.logSvc.debug('PopupSvc.confirm: ', confirmOptions.message);
    return {
      result: this.open(confirmOptions)
    };
  };


  /**
   * Used to display info messages.
   * @param options {object} Options to configure the modal.
   * @options.id {String} Unique ID that identifies this message in case it needs to be closed
   * @options.message {String} The info message to be displayed in the modal body.
   * @options.messageParams {Array} Message-specific localization parameters.
   * @options.manual {Boolean} If true, then a dialog object will be returned.
   * @options.yesLabel {String} Text of additional button.
   * @options.noLabel {String} Text of additional button.
   * @options.infoYesClickAction {Function} Action performed on yesLabel click.
   * @options.infoNoClickAction {Function} Action performed on noLabel click.
   * @options.messageClickAction {Function} Action performed on link click inside popup.
   * @options.onManualClose {Function} Action performed when user closes popup.
   * @options.className {string} unique class name of the popup
   * @example PopupSvc.info({message: 'res_PoorConnectionQuality', manual: true});
  */
  info = (options: any) => {
    if (options.message) {
      Circuit.serviceInstances.logSvc.debug('[DialogService] info: ', options.message);
      Circuit.serviceInstances.pubSubSvc.publish('/infoMessage/show', [options]);
    }
  };
  closeInfo = (messageId: any) => {
    Circuit.serviceInstances.logSvc.debug('[DialogService] closeInfo: ', messageId);
    Circuit.serviceInstances.pubSubSvc.publish('/infoMessage/close', [messageId]);
  };

  /**
   * Used to display error messages (may be with confirm/cancel buttons).
   * @param options {object} Options to configure the modal.
   * @options.message {String} The error message to be displayed in the modal body.
   * @options.messageParams {Array} Message-specific localization parameters.
   * @options.title {String} The error title to be displayed in the modal header.
   * @options.titleParams {Array} Title-specific localization parameters.
   * @options.action {Boolean} Indicates that the modal should display yes/no buttons. Defaults to 'false'.
   * @options.yesLabel {String} Text (label) for the 'yes' button. Defaults to 'Yes'.
   * @options.noLabel {String} Text (label) for the 'no' button. Defaults to 'No'.
   * @options.size {String} The modal's size.
   * @example PopupSvc.error({message: 'res_InvalidFileType', title: 'Invalid File Type Error', size:'medium'});
   */
  error = (options: any) => {
    if (!options.message) {
      return {
        result: Promise.reject()
      };
    }
    const errorOptions = {
      title: options.title || 'res_ErrorTitle', // remove check when all title headers are implemented
      titleParams: options.titleParams,
      message: options.message,
      messageParams: options.messageParams,
      type: 'error',
      action: options.action || false, // indicates that the modal should display yes/no buttons
      yesLabel: options.yesLabel || 'res_Yes',
      noLabel: options.noLabel || (options.noLabel === undefined ? 'res_No' : null),
      size: options.size || 'small',
      messageClickAction: options.messageClickAction,
      resolve: {options: function () { return errorOptions; }}
    };
    Circuit.serviceInstances.logSvc.error('[DialogService] error: ' + errorOptions.message);
    return {
      result: this.open(errorOptions)
    };
  };

  /**
   * Used as callback to most call control function to handle error and warning messages as needed.
  */
  handleCallError = (error: any, warn?: any) => {
    if (!error) {
      if (warn) {
        this.info({message: warn, timeOut: 5000});
      }
      return;
    }
    if (!this.isString(error)) {
      Circuit.serviceInstances.logSvc.debug(`[DialogService]: Expected string error but got ${error}`);
      return;
    }
    if (error === 'CHOOSE_DESKTOP_MEDIA_CANCELLED') {
      // This is an internal error in case the user cancels the screen share media selection. Just ignore it.
      return;
    }
    if (error.startsWith('res_')) {
      const options: any = {message: error};
      if (error === 'res_RestartBrowser') {
        // eslint-disable-next-line no-extra-parens
        options.messageParams = [(<any> this.location)._platformLocation.location.href];
      } else if (error.startsWith('res_TransferCallFailedCause')) {
        options.title = 'res_TransferCallFailed';
      } else if (error.startsWith('res_PickUpCallFailedCause')) {
        options.title = 'res_PickUpCallFailed';
      }

      this.error(options).result
        .catch(() => {
          Circuit.serviceInstances.logSvc.error('[DialogService]: handleCallError error');
        });
    } else {
      Circuit.serviceInstances.logSvc.error('[DialogService]: Internal call error: ', error);
    }
  };

  // eslint-disable-next-line arrow-body-style
  confirmDeletion = (message: any, messageParams?: any) => {
    return this.confirm({
      message: message,
      messageParams: messageParams,
      title: 'res_ConfirmDeletion',
      yesLabel: 'res_Yes',
      noLabel: 'res_Cancel'
    })
    .result;
  };

  closeActiveDialog = () => {
    if (this.dialogRef) {
      Circuit.serviceInstances.logSvc.debug('[DialogService]: Closing active dialog');
      this.dialogRef.close();
    }
  };
}
