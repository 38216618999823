<mat-toolbar-row class="header-container">
  <div class="title-container mat-headline">
    <mat-icon class="settings-icon md-35">settings</mat-icon>
    <h1 uLocalize="res_Settings"></h1>
  </div>
  <button mat-button #closebtn
    align="end"
    mat-dialog-close
    class="close-btn">
    <span uAriaLabel=res_AccessibilityCloseModal role="button"></span>
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar-row>
