import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonBLService } from 'common-ng/services/common-bl.service';
declare let Circuit: any;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit, OnDestroy {
  private LogSvc: any;

  i18n: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private mdDialogRef: MatDialogRef<ConfirmDialogComponent>,
              private commonBlService: CommonBLService) { }

  ngOnInit(): void {
    const serviceInstances = Circuit.serviceInstances;
    this.LogSvc = serviceInstances.logSvc;
    this.LogSvc.info('[ConfirmDialogComponent]: ngOnInit');

    this.i18n = this.commonBlService.getRootScopeData().i18n;
  }

  ngOnDestroy(): void {
    this.LogSvc.info('[ConfirmDialogComponent]: ngOnDestroy');
  }

  cancel() {
    this.close(false);
  }

  close(value: boolean) {
    this.mdDialogRef.close(value);
  }

  confirm() {
    this.close(true);
  }

  messageClick($event: any) {
    const target = $event && $event.target && $event.target.tagName;
    if (target === 'A' && this.data.messageClickAction) {
      // User clicked on an anchor, run the supplied action
      $event.stopPropagation();
      this.data.messageClickAction($event);
      this.close(false);
    }
  }

}
