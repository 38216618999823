<div class="state-mic-wrapper" *ngIf="microphoneDisabled && showMessage" role="alert" aria-live="assertive" aria-relevant="additions removals">
  <div class="state-mic">
    <mat-icon class="md-15">{{iconMessage}}</mat-icon>
    <p mat-caption class="message">
      <span [innerHTML]="i18n.localize(infoMessage)"></span>
    </p>
  </div>
  <button mat-icon-button class="close-btn" uAriaLabel="res_AccessibilityCloseModal">
      <mat-icon class="close-icon md-15" (click)="closeMessage()">close</mat-icon>
  </button>
</div>
