import { Injectable, OnDestroy } from '@angular/core';

declare const Circuit:any;

const IDLE_TIMEOUT = 2400000;
const IDLE_INTERVAL_CHECK = 30000;

@Injectable({
  providedIn: 'root'
})
export class UiService implements OnDestroy {

  private LogSvc: any;
  private PubSubSvc: any;
  private UserProfileSvc: any;

  _checkActivityInterval: any;
  _mouseMoved = false;
  _lastActivityDetected: any;
  _isIdle = false;

  constructor() {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this.UserProfileSvc = Circuit.serviceInstances.userProfileSvc;
  }

  init = async () => {
    this.PubSubSvc.subscribe('/localUser/presence/checkIdle', this.presenceChangeHandler);
    this.startActivityChecking();
  };

  presenceChangeHandler = (userPresenceState: any) => {
    this.LogSvc.debug('[uiService]: Received /localUser/presence/checkIdle event', userPresenceState);
    if (userPresenceState.state === Circuit.Constants.PresenceState.AWAY && userPresenceState?.isSystemDefined) {
      this._isIdle = true;
      this.stopInterval();
    } else {
      this._isIdle = false;
      this.startInterval();
    }
  };

  stopInterval = () => {
    if (this._checkActivityInterval) {
      window.clearInterval(this._checkActivityInterval);
      this._checkActivityInterval = null;
    }
  };

  startInterval = () => {
    if (this._checkActivityInterval) {
      return;
    }
    this._checkActivityInterval = window.setInterval(() => {
      if (this._mouseMoved) {
        this._mouseMoved = false;
        this._lastActivityDetected = Date.now();
        return;
      }

      if (!this._isIdle && (Date.now() - this._lastActivityDetected > IDLE_TIMEOUT)) {
        this.UserProfileSvc.setIdleState(Circuit.Constants.IdleState.Idle);
      }
    }, IDLE_INTERVAL_CHECK);
  };

  // Detecting idle functionality. Publish /idle/state events on idle state change.
  activityDetected = () => {
    this._mouseMoved = true;
    if (this._isIdle) {
      this.UserProfileSvc.setIdleState(Circuit.Constants.IdleState.Active);
    }
  };

  // Initialize idle detection.
  startActivityChecking = () => {
    this._lastActivityDetected = Date.now();
    this.startInterval();
  };

  ngOnDestroy() {
    this.LogSvc.debug('[uiService]: Destroy uiService');
    this.stopInterval();
    this.PubSubSvc.unsubscribe('/localUser/presence/checkIdle', this.presenceChangeHandler);
  }
}
