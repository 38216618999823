import { Injectable } from '@angular/core';
import { DialogService } from '../sub-modules/dialog/dialog.service';
import { ExchangeService, ExchangeVersion, WebCredentials, Uri, WellKnownFolderName, ItemView,
  EwsLogging, ResolveNameSearchLocation, SearchFilter, FolderView, FolderTraversal, FolderSchema, PropertySet, ContactSchema, BasePropertySet } from 'ews-js-api-browser';


declare let Circuit: any;
declare let RegistrationState: any;

const EMAIL_PATTERN = /[_a-z0-9-+]+?(\.[_a-z0-9-+]+?)*?@[a-z0-9-]+?(\.[a-z0-9-]+?)*(\.[a-z]{2,4})/gim;
const COMMON_CHAR_REGEX = /[-()\s]/g;
const DIGIT_LENGTH_REGEX = /^\+?[\d#*]{3,}\b/g;
const PREFIX_CHAR_PATTERN = '[\\+\\b]?[\\*#]*';
const PREFIX_PATTERN = '[\\+\\b]?[\\d\\*#]*';
const POSTFIX_PATTERN = '[\\d\\*#]*\\b';
const PERSONAL_CONTACTS_SYNC_MIN_INTERVAL = 60 * 60 * 1000; // Sync Exchange private contacts cache every hour
const DEFAULT_NUM_CONTACTS = 5;
const MAX_CONTACTS_SIZE = 1000;
const MAX_FOLDERS_SIZE = 1000;
const DEBUG_ENABLED = false;
const NO_SETTINGS_ERROR = 'NO_SETTINGS';

class Deferred {
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
    this.promise.finally(() => {
      this.fulfilled = true;
    });
  }
  resolve: any;
  reject: any;
  promise: Promise<any>;
  fulfilled = false;
}

type GenObject = {
  [key: string]: any;
}
@Injectable({
  providedIn: 'root'
})
export class ExchangeOnPremiseService {
  private LogSvc: any;
  private UserProfileSvc: any;
  private PubSubSvc: any;
  private LocalStoreSvc: any;
  private _exchangeConfigData: any;
  private _connected = false;
  private _lastConnectionError: string | null;
  private _lastPersonalContactsSync: any = 0;
  private _ewsSvc: any;
  private _personalContactsPhoneMap:Map<any, any> = new Map<any, any>();
  private _personalContacts: Map<any, any> = new Map<any, any>();
  private _phoneLookup = '';
  private _phoneNumberLookupSearchId = 0;
  private _exchangeServerSearchId = 0;
  private _cacheInitialized: Deferred = new Deferred();
  private _autoConnectEnabled = false;

  constructor(private dialogService: DialogService) {
    this._lastConnectionError = null;
  }

  // Needed for injection in MailBoxConnSvc
  name = 'ExchangeOnPremiseSvc';

  private regExpEscape = (str: any) => {
    if (!str) {
      return '';
    }
    return str.replace(/[.*+?|()[\]{}\\$^]/g, '\\$&');
  };

  private checkConnected = () => this._connected;

  private onInitEvent = async (state: any) => {
    if (state !== RegistrationState.Registered) {
      return;
    }
    await this.getConnectionSettings(true);
    const connector = this.LocalStoreSvc.getObjectSync(this.LocalStoreSvc.keys.EXCHANGE_CONNECTOR);
    if (connector?.enabled) {
      this.initConnection();
    }
  };

  private onRegistrationState = async (state: any) => {
    this.LogSvc.debug('[ExchangeOnPremiseService]: Received /registration/state event');
    await this.onInitEvent(state);
  };

  private saveAutoConnect(enabled: boolean) {
    this.LocalStoreSvc.setObjectSync(this.LocalStoreSvc.keys.EXCHANGE_CONNECTOR, {
      enabled
    });
    this._autoConnectEnabled = enabled;
  }

  private async testConnection() {
    this.LogSvc.debug('[ExchangeOnPremiseService]: testConnection');
    if (!this._exchangeConfigData) {
      return false;
    }
    try {
      await this._ewsSvc.ResolveName('test', ResolveNameSearchLocation.ContactsOnly);
    } catch (err: any) {
      this.LogSvc.debug('[ExchangeOnPremiseService]: Connection testing failed: ', err);
      this._lastConnectionError = err;
      return false;
    }
    return true;
  }

  private async initConnection() {
    this.LogSvc.debug('[ExchangeOnPremiseService]: initConnection');
    if (!this._exchangeConfigData) {
      this._lastConnectionError = NO_SETTINGS_ERROR;
      return false;
    }
    const { username, password, server } = this._exchangeConfigData;
    this._ewsSvc = new ExchangeService(ExchangeVersion.Exchange2016);
    this._ewsSvc.AcceptGzipEncoding = false;
    EwsLogging.DebugLogEnabled = DEBUG_ENABLED;
    this._ewsSvc.Credentials = new WebCredentials(username, password);
    const serverUrl = new URL(server);
    const prot = serverUrl.protocol?.replace(':', '');
    const strippedUrl = serverUrl.host + serverUrl.pathname;
    this._ewsSvc.Url = new Uri(`${window.location.origin}/corsproxy/ews/${strippedUrl}?prot=${prot}`);
    const connectionOK = await this.testConnection();
    if (!connectionOK) {
      return false;
    }
    this._lastConnectionError = null;
    this._connected = true;
    this.saveAutoConnect(true);
    this.PubSubSvc.publish('/exchangeOnPremise/connected');
    this.startContactsSync();
    return true;
  }

  private splitTypeEmail(email: any) {
    const parts = [];

    if (email.indexOf(':') >= 0) {
      parts[0] = email.substring(0, email.indexOf(':'));
      parts[1] = email.substring(email.indexOf(':') + 1);
    }

    return parts;
  }

  private processPhoneNumber = (p: any) => {
    if (!p || !p.number) {
      return false;
    }
    if (!Circuit.Utils.PHONE_DIAL_PATTERN.test(p.number)) {
      const match = p.number.match(Circuit.Utils.PHONE_WITH_EXTENSION_PATTERN);
      if (match) {
        // Phone numbers in Exchange might contain extension numbers as well
        // so strip the extension out
        p.number = match[1] || '';
      } else {
        return false;
      }
    }
    // Remove any non-digit character from the phone number (except +)
    p.number = p.number.replace(/[^\d+*#]/g, '');
    if (!p.number) {
      return false;
    }
    return true;
  };

  private processContact = (c: any, skipAddToLookupStr = false) => {
    const phones = c.phoneNumbers;
    if (phones) {
      phones.forEach((p: any) => {
        if (!this.processPhoneNumber(p)) {
          return;
        }
        this._personalContactsPhoneMap.set(p.number, c.id);
        if (!skipAddToLookupStr) {
          this._phoneLookup += '\t' + p.number;
        }
      });
    }
    this._personalContacts.set(c.id, c);
  };

  private parseContact(contact: any) {
    if (!contact) {
      return null;
    }
    const result: GenObject = {};
    const textFields = [
      {exchange: 'Department', map: 'department'},
      {exchange: 'OfficeLocation', map: 'officeLocation'},
      {exchange: 'JobTitle', map: 'title'},
      {exchange: 'CompanyName', map: 'companyName'}
    ];

    const phoneFields: GenObject = {
      BusinessPhone: 'WORK',
      MobilePhone: 'MOBILE',
      HomePhone: 'HOME',
      BusinessFax: 'FAX'
    };

    if (contact.CompleteName) {
      const name = contact.CompleteName;
      result.firstName = name.FirstName || name.GivenName;
      result.lastName = name.LastName || name.Surname;
      result.displayName = name.FullName;
    } else {
      Array.prototype.push.apply(textFields, [
        {exchange: 'GivenName', map: 'firstName'},
        {exchange: 'Surname', map: 'lastName'},
        {exchange: 'DisplayName', map: 'displayName'}
      ]);
    }

    result.id = contact?.Id?.UniqueId;
    result.parentId = contact.ParentFolderId?.UniqueId;

    textFields.forEach(field => {
      if (contact[field.exchange]) {
        result[field.map] = contact[field.exchange];
      }
    });

    result.emailAddresses = [];
    if (contact?.EmailAddresses?.Entry || contact?.EmailAddresses?.Entries) {
      const entryData = contact?.EmailAddresses?.Entries || contact?.EmailAddresses?.Entry;
      const emailAddresses = Array.isArray(entryData) ? entryData : [entryData];
      // Get only the primary email(SMTP) and secondary email(smtp) addresses.
      emailAddresses.forEach((entryItem: any) => {
        let email: any;
        if (typeof entryItem === 'string') {
          email = entryItem;
        } else if (Array.isArray(entryItem?.Values)) {
          email = entryItem.Values[0]?.EmailAddress?.address;
        }
        const parts = this.splitTypeEmail(email || '');
        if (parts.length === 2) {
          if (parts[0] === 'SMTP' || parts[0] === 'smtp') {
            const addressType = parts[0] === 'SMTP' ? 'SMTP' : 'OTHER';
            const smtpEmailList = parts[1].match(EMAIL_PATTERN);
            if (smtpEmailList) {
              result.emailAddresses.push({ type: addressType, address: smtpEmailList[0]});
            }
          }
        }
        if (!result.emailAddresses.some((emailAddress: any) => emailAddress.address === email)) {
          result.emailAddresses.unshift({ type: 'OTHER', address: email});
        }
      });
    }

    if (contact.mainEmailAddress) {
      if (!result.emailAddresses.some((emailAddress: any) => emailAddress.address === contact.mainEmailAddress)) {
        result.emailAddresses.unshift({ type: 'OTHER', address: contact.mainEmailAddress});
      }
    }

    if (contact.PhoneNumbers?.Entries?.Items) {
      const phoneNumbers = Array.isArray(contact.PhoneNumbers.Entries.Items) ?
        contact.PhoneNumbers.Entries.Items : [contact.PhoneNumbers.Entries.Items];

      result.phoneNumbers = [];
      phoneNumbers.forEach((entryItem: any) => {
        if (entryItem?.value) {
          const { value: entry } = entryItem;
          const key: string = entry.keyType[entry.key];
          const type = phoneFields[key] || key;
          let { phoneNumber } = entry;
          if (phoneNumber) {
            // Remove any non-printable characters (sometimes users copy/paste numbers and
            // non-printable characters are introduced, which will make our PHONE_PATTERN not match)
            phoneNumber = phoneNumber.replace(/[^\x20-\x7E]+/g, '');
          }
          if (phoneNumber) {
            result.phoneNumbers.push({ type, number: phoneNumber });
          }
        }
      });
    }
    return result;
  }

  private async syncExchangeConfig(data?: any) {
    if (!data) {
      return;
    }
    const config = {
      username: data.username,
      password: data.password,
      server: data.server,
      version: data.version
    };
    try {
      this._exchangeConfigData = await this.UserProfileSvc.saveExchangeSettings(config);
    } catch (error) {
      this.LogSvc.error('[ExchangeOnPremiseService]: Could not save Exchange settings: ', error);
    }
  }

  private mergePhoneNumbers = (total: any, phoneNumbers: any, type: any) => {
    if (phoneNumbers) {
      const phones = phoneNumbers.map((phoneNumber: any) => ({
        number: phoneNumber,
        type: type
      }));
      total.push(...phones);
    }
  };

  private populateContact = (contact: any, addToCache: boolean) => {
    if (!contact) {
      return null;
    }
    // Contact data found, proceed with parsing
    contact = this.parseContact(contact);
    contact.firstName = contact.firstName || contact.givenName;
    contact.lastName = contact.lastName || contact.surname;
    if ((!contact.firstName || !contact.lastName) && contact.displayName) {
      const tokens = contact.displayName.split(' ');
      contact.firstName = contact.firstName || tokens?.[0];
      contact.lastName = contact.lastName || (tokens?.length > 1 ? tokens[tokens.length - 1] : undefined);
    }

    if (!contact.phoneNumbers) {
      contact.phoneNumbers = [];
      const mobilePhoneNumbers = contact.mobilePhone && [contact.mobilePhone];
      this.mergePhoneNumbers(contact.phoneNumbers, contact.businessPhones, Circuit.Constants.PhoneNumberType.WORK);
      this.mergePhoneNumbers(contact.phoneNumbers, contact.homePhones, Circuit.Constants.PhoneNumberType.HOME);
      this.mergePhoneNumbers(contact.phoneNumbers, mobilePhoneNumbers, Circuit.Constants.PhoneNumberType.MOBILE);
    }
    if (contact.phoneNumbers.length > 0) {
      contact.isExchangeOnPremise = true;
      addToCache && this.processContact(contact);
      return contact;
    }
    return null;
  };

  private retrieveContactsByIds = async (itemIds: any, addToCache: boolean) => {
    const resolvedContacts: any = [];
    try {
      const pSet = new PropertySet(BasePropertySet.IdOnly, ContactSchema.ParentFolderId, ContactSchema.DisplayName, ContactSchema.CompleteName,
        ContactSchema.GivenName, ContactSchema.Surname, ContactSchema.BusinessPhone, ContactSchema.BusinessPhone2,
        ContactSchema.HomePhone, ContactSchema.HomePhone2, ContactSchema.MobilePhone, ContactSchema.BusinessFax, ContactSchema.HomeFax,
        ContactSchema.OtherFax, ContactSchema.Department, ContactSchema.OfficeLocation, ContactSchema.JobTitle,
        ContactSchema.CompanyName, ContactSchema.EmailAddresses, ContactSchema.EmailAddress1, ContactSchema.EmailAddress2,
        ContactSchema.EmailAddress3);

      const foundItems = await this._ewsSvc.BindToItems(itemIds, pSet);
      resolvedContacts.push(...foundItems?.responses?.reduce((contacts: any, response: any) => {
        const contact = this.populateContact(response.item, addToCache);
        contact && contacts.push(contact);
        return contacts;
      }, []));
    } catch (err: any) {
      this.LogSvc.error('[ExchangeOnPremiseService]: Could not retrieve contacts by ids: ', err);
    }
    return resolvedContacts;
  };

  private parseContacts = async (contacts: any, addToCache = false): Promise<Array<any>> => {
    if (!contacts) {
      return [];
    }
    if (!(contacts instanceof Array)) {
      contacts = [contacts];
    }
    const incompleteContactIds: any = [];
    const parsedContacts = contacts.reduce((completeContacts: any, ewsContact: any) => {
      // Check if array contains actual contact or ewsContact (object with .contact and .mailbox properties).
      let contact: any = ewsContact?.contact || ewsContact;
      // If obect contains mailbox it means that no .contact was found so we can not parse anything.
      // In that case check personal contacts cache for given contact Id.
      const contactId = contact?.mailbox?.Id?.UniqueId;
      if (contactId) {
        const cachedContact = this._personalContacts.get(contactId);
        if (!cachedContact) {
          incompleteContactIds.push(contact?.mailbox?.Id);
        } else {
          completeContacts.push(cachedContact);
        }
        return completeContacts;
      }
      contact = this.populateContact(contact, addToCache);
      contact && completeContacts.push(contact);
      return completeContacts;
    }, []);
    const individuallySearchedContacts: any = [];
    if (incompleteContactIds.length > 0) {
      individuallySearchedContacts.push(...await this.retrieveContactsByIds(incompleteContactIds, addToCache));
    }
    return [...parsedContacts, ...individuallySearchedContacts];
  };

  private populateContactsFromLocalStore = async () => {
    try {
      return this.LocalStoreSvc.getAllExchangeOnPremiseContacts()
      .then((contacts: any) => {
        if (contacts instanceof Array) {
          this.LogSvc.info(`[ExchangeOnPremiseService]: Retrieved ${contacts.length} contacts from local store`);
          contacts.forEach((contact: any) => {
            this.processContact(contact);
          });
        }
      });
    } catch (error) {
      this.LogSvc.error('[ExchangeOnPremiseService]: Could not retrieve contacts from local store: ', error);
      return false;
    }
  };

  private startContactsSync = async () => {
    if (!this.LocalStoreSvc.isCachingDisabled() || !this._connected) {
      await this.populateContactsFromLocalStore();
      this.LogSvc.debug('[ExchangeOnPremiseService]: Start syncing personal contacts.');
      this.syncAllPersonalContacts();
    }
  };

  private clearCachedContacts = async () => {
    try {
      this._personalContactsPhoneMap.clear();
      this._personalContacts.clear();
      this._phoneLookup = '';
      this._phoneNumberLookupSearchId = 0;
      this._exchangeServerSearchId = 0;
      this._lastPersonalContactsSync = 0;
      await this.LocalStoreSvc.removeAllExchangeOnPremiseContacts();
    } catch (error: any) {
      this.LogSvc.error(`[ExchangeOnPremiseService]: Error while clearing contacts cache: ${error}`);
    }
  };

  editConnectionSettings = async () => {
    try {
      await this.dialogService.openExchangeSettingsView();
    } catch (err) {
      this.LogSvc.debug('[ExchangeOnPremiseService]: editConnectionSettings - Popup canceled');
    }
  };

  getConnected = (): boolean => this._connected;

  getLastConnectionError = (): string | null => this._lastConnectionError;

  resetConnectionError = () => {
    this._lastConnectionError = null;
  };

  getConnectionSettings = async (init?: boolean) => {
    this.LogSvc.debug('[ExchangeOnPremiseService]: getConnectionSettings');
    if (init) {
      try {
        this._exchangeConfigData = await this.UserProfileSvc.getExchangeSettings();
      } catch (error) {
        this.LogSvc.error('[ExchangeOnPremiseService]: getConnectionSettings: Failed to get setttings: ', error);
      }
    }
    return this._exchangeConfigData || {};
  };

  connect = async (popupData?: any) => {
    this.LogSvc.debug('[ExchangeOnPremiseService]: connect');
    if (popupData) {
      await this.syncExchangeConfig(popupData);
    } else if (!this._exchangeConfigData) {
      this.editConnectionSettings();
      return;
    }
    if (!this._exchangeConfigData) {
      this.LogSvc.error('[ExchangeOnPremiseService]: Could not connect, no settings configured: ');
      this._lastConnectionError = NO_SETTINGS_ERROR;
      return;
    }
    await this.initConnection();
  };

  disconnect = async () => {
    this.LogSvc.debug('[ExchangeOnPremiseService]: disconnect');
    this._connected = false;
    this._lastConnectionError = null;
    this._ewsSvc = null;
    this.saveAutoConnect(false);
    this.PubSubSvc.publish('/exchangeOnPremise/disconnected');
    await this.clearCachedContacts();
  };

  searchContactsWithConstraints = async (searchStr: any, constraints: any, resCount: any, cb: any) => {
    this.LogSvc.debug('[ExchangeOnPremiseService]: searchContactsWithConstraints');

    if (!searchStr || typeof searchStr !== 'string') {
      cb && cb(null, []);
      return null;
    }
    const isNumberLookup = constraints?.phoneNumberLookup || constraints?.reversePhoneNumberLookup;
    if (!isNumberLookup && !this._connected) {
      // If it's not a number lookup and we are not connected, return;
      this.LogSvc.warn('[ExchangeOnPremiseService]: Can not search for contacts while not connected');
      cb && cb();
      return null;
    }
    const contacts: Array<any> = [];

    if (isNumberLookup) {
      // First remove commonly used phone number formatting characters: (, ), - and white spaces. In case the user is copy/pasting the phone number
      // We can't simply remove all non-digit chars, because that would cause search strings like "conferenceroom123" to trigger a phone number lookup
      let numberLookupStr = searchStr.replace(COMMON_CHAR_REGEX, '');
      // 1. If it's number lookup (numberLookupStr begins with 3 or more digits), search for it in the cached private contacts
      if (this._phoneLookup.length > 0 && numberLookupStr.match(DIGIT_LENGTH_REGEX)) {
        this.LogSvc.debug('[ExchangeOnPremiseService]: Searching number in Personal Contacts cache. Number of phone numbers in cache: ', this._phoneLookup.length);
        let singleMatch = numberLookupStr[0] === '+'; // If numberLookupStr is in E164 format, there can be only one match
        // Make sure that special characters in the numberLookupStr are escaped
        numberLookupStr = this.regExpEscape(numberLookupStr);
        let regex;
        if (constraints.reversePhoneNumberLookup) {
          // Full match
          singleMatch = true;
          regex = new RegExp(PREFIX_CHAR_PATTERN + numberLookupStr + '\\b', 'g');
        } else {
          // Partial match
          regex = new RegExp(PREFIX_PATTERN + numberLookupStr + POSTFIX_PATTERN, 'g');
        }
        const matches = this._phoneLookup.match(regex) || [];
        const mathcesLength = matches.length;
        if (singleMatch && mathcesLength > 1) {
          this.LogSvc.warn(`[ExchangeOnPremiseService]: searchContacts. ${mathcesLength} private contacts found for ${numberLookupStr}. No private contacts returned`);
        } else {
          this.LogSvc.debug('[ExchangeOnPremiseService]: Number of matches in local cache:', mathcesLength);
          // Copy all matches to contacts
          for (let i = 0; i < mathcesLength; i++) {
            const phone = matches[i];
            const id = this._personalContactsPhoneMap.get(phone);
            const c = this._personalContacts.get(id);
            if (c) {
              contacts.push({
                firstName: c.firstName,
                lastName: c.lastName,
                phoneNumbers: [{
                  type: c.phoneType,
                  number: phone
                }],
                emailAddresses: c.emailAddresses,
                department: c.department,
                sourceIntegrationRes: 'res_ExchangeContact',
                isExchangeOnPremise: true,
                isExchangeContact: true
              });
            }
          }
        }

        cb(null, contacts);
        this._phoneNumberLookupSearchId++;
        return this._phoneNumberLookupSearchId;
      }
    }

    if (!this._ewsSvc) {
      this.LogSvc.warn('[ExchangeOnPremiseService]: Can not search for contacts while not connected');
      cb && cb();
      return null;
    }
    try {
      resCount = resCount || DEFAULT_NUM_CONTACTS;
      // 2. Search in Exchange only if it's not a number lookup
      const resolvedNames = await this._ewsSvc.ResolveName(searchStr, ResolveNameSearchLocation.ContactsThenDirectory, true);
      const itemsToParse = resolvedNames?.items?.slice(0, resCount - 1);
      const serverResults = await this.parseContacts(itemsToParse);
      cb && cb(null, [...contacts, ...serverResults]);
      this._exchangeServerSearchId++;
      return this._exchangeServerSearchId;
    } catch (error) {
      this.LogSvc.error(`[ExchangeOnPremiseService]: Could not search for Exchange OnPremise contacts: ${error}`);
      cb && cb(error);
    }
    return null;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  cancelSearchContacts = (reqId: any) => {
    this.LogSvc.debug('[ExchangeOnPremiseService]: cancelSearchContacts');
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  getContact = (exchangeEmail: any, cb: any) => {
    this.LogSvc.debug('[ExchangeOnPremiseService]: getContact');
  };

  getAllPersonalContacts = async () => {
    if (!this._cacheInitialized || this._cacheInitialized.fulfilled) {
      this._cacheInitialized = new Deferred();
    }
    if (!this._connected) {
      this.LogSvc.warn('[ExchangeOnPremiseService]: Can not fetch all personal contacts while disconnected');
      this._cacheInitialized.resolve(Array.from(this._personalContacts.values()));
      return this._cacheInitialized.promise;
    }

    try {
      const totalPersonalContacts = [];
      const fView = new FolderView(MAX_FOLDERS_SIZE);
      fView.Traversal = FolderTraversal.Deep;
      const sFilter = new SearchFilter.IsEqualTo(FolderSchema.FolderClass, 'IPF.Contact');
      const contactFolders = await this._ewsSvc.FindFolders(WellKnownFolderName.Root, sFilter, fView);

      if (contactFolders?.folders instanceof Array) {
        // Gather folder ids
        let containsDefault = false;
        const folderIds = contactFolders?.folders.reduce((ids: any, folder: any) => {
          if (folder?.DisplayName === 'Contacts') {
            containsDefault = true;
          }
          folder?.Id && ids.push(folder?.Id);
          return ids;
        }, []);
        if (!containsDefault) {
          const contacts = await this._ewsSvc.FindItems(WellKnownFolderName.Contacts, new ItemView(MAX_CONTACTS_SIZE));
          totalPersonalContacts.push(...contacts?.items);
        }
        // Execute findItems and gather all contacts
        const folderResults = await this._ewsSvc.FindItems(folderIds, new ItemView(MAX_CONTACTS_SIZE));
        totalPersonalContacts.push(...folderResults?.items);
        await this.clearCachedContacts();
        this.parseContacts(totalPersonalContacts, true);
      }
    } catch (error: any) {
      this.LogSvc.error(`[ExchangeOnPremiseService]: Could not retrieve all personal Exchange contacts: ${error}`);
    }
    this._cacheInitialized.resolve(Array.from(this._personalContacts.values()));
    return this._cacheInitialized.promise;
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  disableAutoConnect = () => {
    this.LogSvc.debug('[ExchangeOnPremiseService]: disableAutoConnect');
  };

  syncAllPersonalContacts = () => {
    if (this.LocalStoreSvc.isCachingDisabled() || !this._connected) {
      return;
    }
    const now = Date.now();
    if (now - this._lastPersonalContactsSync > PERSONAL_CONTACTS_SYNC_MIN_INTERVAL) {
      this._lastPersonalContactsSync = now;
      this.LogSvc.debug('[ExchangeOnPremiseService]: syncAllPersonalContacts. Next sync after ' +
          new Date(this._lastPersonalContactsSync +
              PERSONAL_CONTACTS_SYNC_MIN_INTERVAL).toLocaleTimeString()
      );
      this.getAllPersonalContacts()
      .then((contacts: any) => {
        this.LocalStoreSvc.putExchangeOnPremiseContacts(contacts || [])
        .catch((err: any) => {
          this.LogSvc.error('[ExchangeOnPremiseService]: Could not update personal contacts in local store: ', err);
        });
      })
      .catch((err: any) => {
        this.LogSvc.error('[ExchangeOnPremiseService]: Could not fetch all personal contacts: ', err);
      });
    }
  };

  updateUserWithLocalContactName = (user: any) => {
    this._cacheInitialized.promise
      .then(() => {
        if (this._phoneLookup.length === 0 || !user || !(user.phoneNumber || user.fullyQualifiedNumber)) {
          return false;
        }
        const number = user.fullyQualifiedNumber || user.phoneNumber;
        // First remove commonly used phone number formatting characters: (, ), - and white spaces. In case the user is copy/pasting the phone number
        // We can't simply remove all non-digit chars, because that would cause search strings like "conferenceroom123" to trigger a phone number lookup
        let numberLookupStr = number.replace(COMMON_CHAR_REGEX, '');
        // Search for the number lookup in the cached private contacts
        if (numberLookupStr.match(DIGIT_LENGTH_REGEX)) {
          this.LogSvc.debug('[ExchangeOnPremiseService]: Searching number in Personal Contacts cache');
          // Make sure that special characters in the numberLookupStr are escaped
          numberLookupStr = this.regExpEscape(numberLookupStr);
          const regex = new RegExp(PREFIX_CHAR_PATTERN + numberLookupStr + '\\b', 'g');
          const matches = this._phoneLookup.match(regex) || [];
          const mathcesLength = matches.length;
          this.LogSvc.debug(`[ExchangeOnPremiseService]: Found ${mathcesLength} entries for ${number} in Personal Contacts cache`);
          if (mathcesLength === 1) {
            const phone = matches[0];
            const id = this._personalContactsPhoneMap.get(phone);
            const c = this._personalContacts.get(id);
            if (c) {
              user.displayName = ((c.firstName || '') + ' ' + (c.lastName || '')).trim();
              user.isExchangeContact = true;
              user.isExchangeOnPremise = true;
              return true;
            }
          }
        }
        return false;
      });
  };

  init = () => {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this.PubSubSvc.subscribe('/registration/state', this.onRegistrationState);
    this.UserProfileSvc = Circuit.serviceInstances.userProfileSvc;
    this.LocalStoreSvc = Circuit.serviceInstances.localStoreSvc;
  };
}
