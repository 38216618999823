<div class="device-icon playback" #audioElement>
    <audio></audio>
    <button mat-icon-button (click)="testSound()"
    [attr.aria-label]="plays ? root.i18n.map.res_TestAudioEnd : root.i18n.map.res_TestAudio">
        <mat-icon
         class="material-icons-outlined sound-icon"
         [ngClass]="{'test-in-progress': plays}"
         >volume_up</mat-icon>
    </button>
</div>
