<div class="dialog-container agent-status-selection-dialog">
  <h1 class="title" mat-dialog-title uLocalize="res_AgentStatus"></h1>
  <mat-dialog-content>
    <p class="text" uLocalize="res_AgentStatusDescription"></p>
    <div class="status-list" *ngIf="data?.huntGroupList?.length > 1 || !data.isSetAgentStatePerGroupSupported">
      <div class="status">
        <div class="status-item"
          [ngClass]="{'available': uIAgentState?.name === 'available', 'partially-unavailable': uIAgentState?.name === 'partiallyUnavailable', 'unavailable': uIAgentState?.name === 'unavailable'}">
          <mat-slide-toggle [(ngModel)]="globalAgentStateReady" (change)="setAgentState($event.checked)"
            uAriaLabel="res_AgentStatus"
            [ngSwitch]="uIAgentState?.name">
            <b *ngSwitchCase="'available'" uLocalize="res_AgentStatusAvailable"></b>
            <b *ngSwitchCase="'unavailable'" uLocalize="res_AgentStatusUnavailable"></b>
            <b *ngSwitchCase="'partiallyUnavailable'" uLocalize="res_AgentStatusPartiallyUnavailable"></b>
          </mat-slide-toggle>
        </div>
      </div>
    </div>

    <div class="status-list">
      <div class="status" *ngFor="let huntGroup of data.huntGroupList"
        [ngSwitch]="data.isSetAgentStatePerGroupSupported">
        <div class="status-item-wrap" *ngSwitchCase="false">
          <div class="status-name" matTooltip="{{ huntGroup.name || huntGroup.pilotDn }}" matTooltipPosition="above">{{huntGroup.name || huntGroup.pilotDn}}</div>
        </div>
        <div class="status-item-wrap" *ngSwitchCase="true">
          <div class="status-item" [id]="huntGroup.name || huntGroup.pilotDn" matTooltip="{{ huntGroup.name || huntGroup.pilotDn }}" matTooltipPosition="above">{{huntGroup.name || huntGroup.pilotDn}}</div>
          <mat-slide-toggle class="toggle-switch" [ngModel]="huntGroup.available"
            [aria-labelledby]="huntGroup.name || huntGroup.pilotDn"
            (change)="setAgentState($event.checked, huntGroup.pilotDn)">
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
