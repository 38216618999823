import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ExchangeOnlineService } from '../../exchange-online.service';
import { CommonBLService } from 'common-ng/services/common-bl.service';

declare let Circuit: any;

interface PeerUser {
  avatar: string,
  avatarColor: string,
  avatarLarge: string,
  displayNameEscaped: string,
  hasAvatarPicture: boolean,
  hasBotRole: boolean
  hasSpecialRole: boolean,
  hasSupportRole: boolean,
  hasTelephonyRole: boolean,
  initials:string,
  isActiveState: boolean
  isDeleted: boolean,
  isDialable: boolean,
  isExtended: boolean,
  isExternal: boolean,
  isGuest: boolean,
  isInactive: boolean,
  isPurged: boolean,
  isSuspended: boolean,
  phoneNumber: string,
  shortName: string
}

@Component({
  selector: 'app-avatar-single',
  templateUrl: './avatar-single.component.html',
  styleUrls: ['./avatar-single.component.scss']
})
export class AvatarSingleComponent implements OnInit, OnChanges {
  private LogSvc: any;
  initials: string | undefined;
  color: string | undefined;
  hasOneInitial = false;
  hasImageError = false;
  rootScope: any;

  @Input() size: string | undefined;
  @Input() resizable = false;
  @Input() user!: any;
  @Input() isGroupCall = false;
  @Input() presence: string | undefined;
  @Input() isOnHold: boolean | undefined;
  @Input() editProfilePic: boolean | undefined;
  @Input() withBorder = false;

  constructor(private commonBlService: CommonBLService, private exchangeOnlineService: ExchangeOnlineService) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.rootScope = this.commonBlService.getRootScopeData();
  }

  ngOnInit() {
    // Get the original size attribute ranges from 'xxsmall' through 'medium' to 'xxxxlarge' and 'xhuge' (default: large)
    this.size = this.size || 'large';
    if (this.size?.includes('xsmall')) {
      // In case of xxsmall or xsmall only show first letter
      this.hasOneInitial = true;
    }

    this.setAvatarInfo();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.user) {
      if (changes.user.previousValue?.phoneNumber != null && changes.user.currentValue?.phoneNumber !== changes.user.previousValue?.phoneNumber) {
        this.LogSvc.info('[AvatarSingleComponent] Phone number changed, updating user info');
        this.getUpdatedExchangeContact();
      }

      if ((changes.user.currentValue?.hasAvatarPicture !== changes.user.previousValue?.hasAvatarPicture) ||
        (changes.user.currentValue?.initials !== changes.user.previousValue?.initials)) {
        this.setAvatarInfo();
      }
    }
  }

  private setAvatarInfo() {
    this.color = this.user?.avatarColor || 'blue';

    if ((this.user.hasAvatarPicture || this.user?.initials) && !this.hasImageError) {
      return;
    }

    if (this.user?.firstName && this.user?.lastName) {
      this.createInitials();
    }
  }

  createInitials() {
    this.initials = (this.user.firstName[0] + this.user.lastName[0]).toUpperCase();
  }

  getUpdatedExchangeContact = () => {
    this?.user && this.exchangeOnlineService.updateUserWithLocalContactName(this.user);
  };

  isUnknownUser = () => !(this.user.hasAvatarPicture || this.user?.initials);

  getInitials = () => {
    let initials = this.user?.initials || this.initials;
    if (this.size?.includes('xsmall')) {
      // In case of xxsmall or xsmall only show first letter
      initials = initials.substring(0, 1);
    }
    return initials;
  };

  getPresenceIcon = () => {
    switch (this.presence) {
    case 'AVAILABLE':
      return 'check_circle';
    case 'AWAY':
      return 'watch_later';
    case 'DND':
      return 'do_not_disturb_on';
    case 'INVISIBLE':
    case 'OFFLINE':
      return 'cancel';
    default:
      return 'circle';
    }
  };

  onAvatarPictureError = () => {
    this.hasImageError = true;
    this.setAvatarInfo();
  };

  localizedPrecenceStates(state: any): string {
    let statusText = '';
    switch (state) {
    case Circuit.Constants.PresenceState.AVAILABLE:{
      statusText = this.rootScope.i18n.map.res_Available;
      break;
    }
    case Circuit.Constants.PresenceState.BUSY:{
      statusText = this.rootScope.i18n.map.res_Busy;
      break;
    }
    case Circuit.Constants.PresenceState.BUSY_IN_A_CALL:{
      statusText = this.rootScope.i18n.map.res_BusyCall;
      break;
    }
    case Circuit.Constants.PresenceState.BUSY_IN_AN_EXTERNAL_CALL:{
      statusText = this.rootScope.i18n.map.res_BusyCall_Teams;
      break;
    }
    case Circuit.Constants.PresenceState.AWAY:{
      statusText = this.rootScope.i18n.map.res_Away;
      break;
    }
    case Circuit.Constants.PresenceState.DND:{
      statusText = this.rootScope.i18n.map.res_DoNotDisturb;
      break;
    }
    case Circuit.Constants.PresenceState.INVISIBLE:{
      statusText = this.rootScope.i18n.map.res_Invisible;
      break;
    }
    case Circuit.Constants.PresenceState.OFFLINE:{
      statusText = this.rootScope.i18n.map.res_Offline;
      break;
    }
    }
    return statusText;
  }
}
