<div *ngIf="data.title" mat-dialog-title>
  <div *ngIf="data.type === 'error'" class="error-icon"></div>
  <h1 class="dialog-title mat-h3" [innerHTML]="root.i18n.localize(data.title, data.titleParams)"></h1>
</div>
<section>
  <div uLocalize="res_Video" class="call-quality-label" *ngIf="showValues('video')"></div>
  <span *ngFor="let item of callQualityVideoValues">
    <p *ngIf="!!item.value" class="call-quality-text">
      <span [uLocalizeOnce]="item.name" data-autotest="videoQuality"></span>
      <span class="call-quality">
        <span>{{item.value}}</span>
        <mat-icon *ngIf="item.lowQuality" class="warning-icon md-15" color="warn">warning</mat-icon>
      </span>
    </p>
  </span>
</section>
<section>
  <div uLocalize="res_Audio" class="call-quality-label" class="call-quality-label" *ngIf="showValues('audio')"></div>
  <span *ngFor="let item of callQualityAudioValues">
    <p *ngIf="!!item.value" class="call-quality-text">
    <span [uLocalizeOnce]="item.name" data-autotest="audioQuality"></span>
    <span class="call-quality">
      <span>{{item.value}}</span>
      <mat-icon *ngIf="item.lowQuality" class="warning-icon md-15" color="warn">warning</mat-icon>
    </span>
    </p>
  </span>
</section>
