import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonBLService } from 'common-ng/services/common-bl.service';

declare let Circuit: any;

@Component({
  selector: 'app-call-fwd-overlay',
  templateUrl: './call-fwd-overlay.component.html',
  styleUrls: ['./call-fwd-overlay.component.scss']
})
export class CallFwdOverlayComponent implements OnInit {

  isAnyCallForwardingEnabled = false;
  callForwardingType = '';
  callForwardingData: any = {};

  private LogSvc: any;
  private TelephonySettingsSvc: any;

  i18n: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mdDialogRef: MatDialogRef<CallFwdOverlayComponent>,
    private commonBlService: CommonBLService
  ) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.TelephonySettingsSvc = Circuit.serviceInstances.telephonySettingsSvc;
  }

  ngOnInit(): void {
    this.LogSvc.info('[CallFwdOverlayComponent]: ngOnInit');
    const serviceInstances = Circuit.serviceInstances;
    this.LogSvc = serviceInstances.logSvc;
    this.i18n = this.commonBlService.getRootScopeData().i18n;
  }

  cancel() {
    this.close(false);
  }

  close(value: boolean) {
    this.mdDialogRef.close(value);
  }

  confirm() {
    this.close(true);
    this.disableCallForwarding();
  }

  disableCallForwarding = () => {
    this.isAnyCallForwardingEnabled = this.TelephonySettingsSvc.isAnyCallForwardingEnabled();
    this.callForwardingData = this.TelephonySettingsSvc.getCallForwardingData();

    if (this.callForwardingData?.Immediate && this.callForwardingData?.Immediate.cfwEnabled) {
      this.callForwardingType = Circuit.Enums.CallForwardingTypes.Immediate.name;
    } else if (this.callForwardingData?.VM && this.callForwardingData?.VM.cfwEnabled) {
      this.callForwardingType = Circuit.Enums.CallForwardingTypes.VM.name;
    }
    if (this.isAnyCallForwardingEnabled) {
      this.TelephonySettingsSvc.setCallForwarding(false, Circuit.Enums.CallForwardingTypes[this.callForwardingType].name, '')
      .then(() => {
        this.callForwardingType = '';
      })
      .catch((error: any) => {
        this.LogSvc.error('[CallFwdOverlayComponent]: disableCallForwarding error', error);
      });
    }
  };

  ngOnDestroy(): void {
    this.LogSvc.info('[CallFwdOverlayComponent]: ngOnDestroy');
  }

}
