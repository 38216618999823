import { Pipe, PipeTransform } from '@angular/core';

declare let Circuit: any;

///////////////////////////////////////////////////////////////////////////////////////////////////
/// Name:    scopedHighlighter
/// Purpose: Add highlighting html to the text. The matched string will be highlighted using
//           css class "ui-match" - in green.
/// Params:  text - The text to add highlighting.
//           search - The words to be highlighted. it can be a single string or a search object which
//                    contains a list a scope-words mappings.
//           caseSensitive - Match is case sensitive or not.
/// Usage:   <span [innerHTML]="text | scopedHighlighter:search:false"></span>
//  Note:    The whole logic of scopedHighlighter was copied but we only use the highlighter
//           filter logic. If we need more we have to refactor this.
///////////////////////////////////////////////////////////////////////////////////////////////////
@Pipe({
  name: 'scopedHighlighter'
})
export class ScopedHighlighterPipe implements PipeTransform {

  /*
    * Enum for Highlighting places
  */
  HighlightingAttributes = Object.freeze({
    TOPIC: 'TOPIC',
    MESSAGE: 'MESSAGE',
    FILENAME: 'FILENAME',
    PEOPLE: 'PEOPLE',
    MEMBERS: 'MEMBERS',
    CREATOR: 'CREATOR',
    TAGS: 'TAGS'
  });

  private Constants: any;
  private Utils: any;

  constructor() {
    this.Constants = Circuit.Constants;
    this.Utils = Circuit.Utils;
  }

  transform(text: any, search: any, caseSensitive: boolean): unknown {
    return this.scopedHighlighter(text, search, '', false, caseSensitive);
  }

  escapeRegex(string: any) {
    return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  }

  isMatchedStringOnTag(index: any, offset: any, tag: any, str: any) {
    return !!(index >= 0 && str.slice(offset - index, offset + (tag.length - index)) === tag);
  }

  // html tags will be ignored
  highlight = (p1: any, p2: any, offset: any, str: any) => {
    if (p2) {
      const openingSpan = '<span class="ui-match">';
      const closingSpan = '</span>';
      if (str.indexOf(openingSpan) < 0) {
        // There were no highlighting before. Add first one.
        return openingSpan + p1 + closingSpan;
      }

      // Index of matched search string in opening match tag
      const indexInOpeningSpan = openingSpan.indexOf(str[offset]);
      // Index of matched search string in closing match tag
      const indexInClosingSpan = closingSpan.indexOf(str[offset]);
      if (this.isMatchedStringOnTag(indexInOpeningSpan, offset, openingSpan, str) ||
            this.isMatchedStringOnTag(indexInClosingSpan, offset, closingSpan, str)) {
        // Search string is on the span tags themselves
        return p1;
      }

      const substr = str.slice(0, offset);
      const lastOpeningIndex = substr.lastIndexOf(openingSpan);
      const lastClosingIndex = substr.lastIndexOf(closingSpan);
      if (lastClosingIndex < lastOpeningIndex || lastClosingIndex < 0) {
        // We are between spans for highlighting.
        // This case not combined with the previous return for readability
        return p1;
      }

      return openingSpan + p1 + closingSpan;
    }

    return p1;
  };

  scopedHighlighter(text: any, search: any, attr: any, notMatchedItem: any, caseSensitive: any) {
    // No highlighting if there is no text, no search, or if it's not a matched item
    if (notMatchedItem || !text || !search || search.length === 0) {
      return text;
    }

    // Remove styling
    text = text.toString().replace(/<[/]?span[^>]*>/gi, '');

    const caseFlag = caseSensitive ? 'g' : 'gi';

    // Allow matching through regular text, bold, italics, highlighted, links, emotes, and multiple item messages.
    // Match from beginning of the word when highlight people names or messages.
    const matchFlag = attr === this.HighlightingAttributes.PEOPLE || attr === this.HighlightingAttributes.MEMBERS || attr === this.HighlightingAttributes.CREATOR ?
      '<[^>]+>|\\b(' : '(?:<a href[^>]*|<img[^>]*|<span[^>]*>)*((?:<[\\/]?span[^>]*>)?';

    const extraFlag = '(?:<[\\/]?span[^>]*>|<img[^>]*>|\\s)*'; // match between html font tags and emoticons
    const endFlag = '(?:<[\\/]?span[^>]*>|<hr>)*)*'; // match closing tags

    if (typeof search === 'string' || typeof search === 'number') {
      search = search.toString();
      text = text.replace(new RegExp(matchFlag + this.escapeRegex(search) + ')', caseFlag), this.highlight);
    } else if (Array.isArray(search)) {
      // Search is an array with search pairs
      search.forEach(searchPair => {
        let highlighting;

        switch (searchPair.scope) {
        case this.Constants.SearchScope.ALL:
          highlighting = attr !== this.HighlightingAttributes.TAGS;
          break;
        case this.Constants.SearchScope.MESSAGES:
          highlighting = !attr || attr === this.HighlightingAttributes.MESSAGE;
          break;
        case this.Constants.SearchScope.CONVERSATIONS:
        case this.Constants.SpaceSearchScope.SPACES:
          highlighting = !attr || attr === this.HighlightingAttributes.TOPIC;
          break;
        case this.Constants.SearchScope.FILES:
          highlighting = !attr || attr === this.HighlightingAttributes.FILENAME;
          break;
        case this.Constants.SearchScope.PEOPLE:
          highlighting = !attr || attr === this.HighlightingAttributes.PEOPLE;
          break;
        case this.Constants.SearchScope.MEMBERS:
          highlighting = !attr || attr === this.HighlightingAttributes.MEMBERS;
          break;
        case this.Constants.SearchScope.SENT_BY:
          highlighting = !attr || attr === this.HighlightingAttributes.CREATOR;
          break;
        case this.Constants.SpaceSearchScope.TAGS:
          highlighting = !attr || attr === this.HighlightingAttributes.TAGS || attr === this.HighlightingAttributes.MESSAGE;
          break;
        default:
          highlighting = false;
          break;
        }

        const getVerifiedText = (inputText: any, query: any) => {
          query = this.Utils.textToHtmlEscaped(query);
          // Add # in front of query string for content tag search
          if (searchPair.scope === this.Constants.SpaceSearchScope.TAGS && attr === this.HighlightingAttributes.MESSAGE) {
            query = '#' + query;
          }
          // Need to replace special characters(",') because conversation title is content editable and need to be escaped
          return inputText
                .replace(/&quot;/g, '"').replace(/&#39;/g, '\'')
                .replace(new RegExp(matchFlag + this.escapeRegex(query).replace(/ /g, extraFlag) + endFlag, caseFlag), this.highlight);
        };

        if (highlighting) {
          const space = ' ';
          let query = searchPair.searchTerm.trim();
          if (/(^".*"$)|(^'.*'$)/.test(query)) {
            // Check for full string search: e.g. "need to replace"
            // As result only full word combination should be highlighted
            text = text.replace(/<[/]?b>|<[/]?i>/gi, '');
            query = query.substring(1, query.length - 1);
            text = getVerifiedText(text, query);
          } else if (query.includes(space)) {
            // If query consists of several words, verify for every word
            query.split(space).forEach(function (q: any) {
              text = getVerifiedText(text, q);
            });
          } else {
            text = getVerifiedText(text, query);
          }
        }
      });
    }

    return text;
  }

}
