import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appScrollableItem]'
})
export class ScrollableItemDirective {

  @Input('appScrollableItem')
  value!: any;
  constructor(private el: ElementRef) {}

  scrollIntoView() {
    this.el.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end'});
  }

  scrollIntoViewIfNeeded() {
    this.el.nativeElement.scrollIntoViewIfNeeded({ behavior: 'smooth', block: 'start'});
  }

}
