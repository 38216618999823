import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { CallActionService } from '../../services/call-action.service';
import { DialogService } from '../../sub-modules/dialog/dialog.service';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { ScrollableItemDirective } from '../../directives/scrollable-item.directive';
declare let Circuit: any;

const INSUFFICIENT_DIGIT_LENGTH_REGEX = /^\+?[\d#*]{1,2}\b/g;
const DEBOUNCE_TIMEOUT = 1000;
interface ContactNumber {
  userId?: string;
  displayName?: string;
  firstName?: string;
  lastName?: string;
  initials?: string;
  phoneNumber: string;
  numberType?: string;
  last: boolean;
  first: boolean;
  isExchangeContact: boolean;
  isExchangeOnPremise: boolean;
  isExchangeOnline: boolean;
  isOsbizContact?: boolean;
  avatar?: string;
  avatarLarge?: string;
  hasAvatarPicture?: boolean;
  avatarColor?: string;
  userPresenceState?: string;
}

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() searchTerm = '';
  @Input() rightClickSupport = false;
  @Output() contactSelected = new EventEmitter<any>();
  @Output() closeView = new EventEmitter<any>();
  @Output() onlyContact = new EventEmitter<any>();

  @ViewChild('contactList') contactList!: MatSelectionList;
  @ViewChild('opt') option!: MatListOption;
  @ViewChild(MatMenuTrigger) matMenuTrigger!: MatMenuTrigger;
  @ViewChildren(ScrollableItemDirective) scrollableItems!: QueryList<ScrollableItemDirective>;

  private LogSvc: any;
  private UserSvc: any;
  private PhoneCallSvc: any;

  private _debounceSearch: any;
  private _usersEnhanced: ContactNumber[] = [];
  private _searchedUser: ContactNumber[] = [];
  private _lastSearchTerm = '';
  private _activeIndex: number | undefined;
  private _selectedContact: ContactNumber | undefined;
  private _hasEmitted = false;
  noResults = false;
  searchInProgress = false;
  showDialNumber = true;
  usersToDisplay: ContactNumber[] = [];
  menuTopLeftPosition = {x: '0', y: '0'};
  callDevices: any[] = [];
  isActive = false;
  i18n: any;
  root: any;

  constructor(private commonBlService: CommonBLService,
              private callAction: CallActionService,
              private dialogService: DialogService) {
    const serviceInstances = Circuit.serviceInstances;
    this.UserSvc = serviceInstances.userSvc;
    this.root = this.commonBlService.getRootScopeData();
    this.i18n = this.root.i18n;
  }

  ngOnInit(): void {
    const serviceInstances = Circuit.serviceInstances;
    this.LogSvc = serviceInstances.logSvc;
    this.PhoneCallSvc = serviceInstances.phoneCallSvc;
    this.callDevices = this.callAction.callDevices;
    this.LogSvc.info('[ContactsComponent]: ngOnInit');
  }

  ngOnChanges(): void {
    if (this.searchTerm === this._lastSearchTerm) { return; }

    // check if we have more than 2 digits
    if (this.searchTerm.match(INSUFFICIENT_DIGIT_LENGTH_REGEX) || !this.searchTerm) {
      this.clearSearch();
      return;
    }

    // check if there is a character in the searchTerm
    if (!this.searchTerm.match(Circuit.Utils.PHONE_DIAL_PATTERN)) {
      this.showDialNumber = false;
      this._searchedUser.length = 0;
      this.usersToDisplay = this._usersEnhanced;
    } else {
      // Show the typed number immediately as a contact, before searching.
      this.showDialNumber = true;

      this._searchedUser = [{
        displayName: '',
        phoneNumber: this.searchTerm,
        last: true,
        first: true,
        isExchangeContact: false,
        isExchangeOnPremise: false,
        isExchangeOnline: false
      }];

      this.updateUsersToDisplay(this._searchedUser);
    }

    // Start searching.
    if (this._debounceSearch) { clearTimeout(this._debounceSearch); }

    this._debounceSearch = setTimeout(() => {
      if (this.searchTerm === this._lastSearchTerm) { return; }

      this.search();
    }, DEBOUNCE_TIMEOUT);
  }

  ngOnDestroy(): void {
    this.LogSvc.info('[ContactsComponent]: ngOnDestroy');
    this.clearSearch();
  }

  private updateUsersToDisplay(users: ContactNumber[]) {
    this.usersToDisplay = users;

    // Wait for the next cycle.
    setTimeout(() => {
      this.selectFirstElement();
    }, 0);
  }

  private selectFirstElement = () => {
    if (!this.contactList || this.contactList.options.length === 0) { return; }

    const firstOption = this.contactList.options.first;

    if (!firstOption.selected) {
      firstOption.toggle(); //select first
    }
    this.option = firstOption; //set respective option to selected
  };

  private selectLastElement = () => {
    this.contactList.options.last.toggle(); //select last
    this.option = this.contactList.options.last; //set respective option to selected
  };

  private findSelectedElement = () => {
    this.contactList.options.forEach((item: MatListOption, i: number) => {
      // set the isActive `true` for the appropriate list item and `false` for the rest
      if (this.option.value === item.value) {
        this._activeIndex = i;
        return this._activeIndex;
      }
      // eslint-disable-next-line no-useless-return, consistent-return
      return;
    });
  };

  private selectElement = (contact: ContactNumber) => {
    this.contactList.options.some((item: MatListOption) => {
      if (item.value === contact) {
        item.selected = true;
        this._selectedContact = contact;
        return true;
      }
      return false;
    });
  };

  private scroll = () => {
    if (this.root.browser.chrome) {
      this.scrollableItems.find(x => x.value === this.option.value)?.scrollIntoViewIfNeeded();
    } else {
      this.scrollableItems.find(x => x.value === this.option.value)?.scrollIntoView();
    }
  };

  private localizedNumberTypes(number: any): string {
    let numberType = '';
    switch (number) {
    case Circuit.Constants.PhoneNumberType.WORK:{
      numberType = this.root.i18n.map.res_Work;
      break;
    }
    case Circuit.Constants.PhoneNumberType.MOBILE:{
      numberType = this.root.i18n.map.res_MobilePhone;
      break;
    }
    case Circuit.Constants.PhoneNumberType.HOME:{
      numberType = this.root.i18n.map.res_Home;
      break;
    }
    case Circuit.Constants.PhoneNumberType.OTHER:{
      numberType = this.root.i18n.map.res_Other;
      break;
    }
    default:{
      numberType = number;
      break;
    }
    }
    return numberType;
  }

  clearSearch(): void {
    this.searchInProgress = false;
    this.usersToDisplay.length = 0;
    this._searchedUser.length = 0;
    this.noResults = false;
    this._lastSearchTerm = '';

    if (this._debounceSearch) {
      clearTimeout(this._debounceSearch);
      this._debounceSearch = null;
    }
  }

  onContactSelected(): void {
    const selectedContact = this.contactList.selectedOptions.selected[0]?.value;
    this.contactSelected.emit(selectedContact);
    this._hasEmitted = true;
  }

  handleKeydown = (event: any) => {
    switch (event.key) {
    case 'Enter':
      if (this.usersToDisplay.length === 0) {
        return;
      }

      this.contactSelected.emit(this.option.value);
      break;

    case 'ArrowUp':
      if (this.usersToDisplay.length === 0) {
        return;
      }
      //find selected item _activeIndex only if there is selected item
      if (this.option.selected) {
        this.findSelectedElement();
      }
      //In ArrowUp we need to move to the previous element this._activeIndex - 1
      if (this._activeIndex != null && (this._activeIndex - 1) >= 0) {
        this.contactList.options.get(this._activeIndex)?.toggle();
        this.contactList.options.get(this._activeIndex - 1)?.toggle();
        this.option = this.contactList.selectedOptions.selected[0];
      } else {
        //no item selected or
        //focus last element again
        this.contactList.options.first.toggle(); //unselect element
        this.selectLastElement();
      }
      this.scroll();
      event.preventDefault();
      event.stopPropagation();
      break;

    case 'ArrowDown':
      if (this.usersToDisplay.length === 0) {
        return;
      }

      //find selected item _activeIndex only if there is selected item
      if (this.option.selected) {
        this.findSelectedElement();
      }
      //In ArrowDown we need to move to the next element this._activeIndex + 1
      if (this._activeIndex != null && this.contactList.options.length > (this._activeIndex + 1)) {
        this.contactList.options.get(this._activeIndex)?.toggle();
        this.contactList.options.get(this._activeIndex + 1)?.toggle();
        this.option = this.contactList.selectedOptions.selected[0];
      } else {
        //no item selected or
        //focus first element again
        this.contactList.options.last.toggle(); //unselect element
        this.selectFirstElement();
      }
      this.scroll();
      event.preventDefault();
      event.stopPropagation();
      break;
    }
  };

  sortBySystemNumber = (numbers: any) => numbers.sort((x: any) => (!x?.type ? -1 : 1));

  search = () => {
    this.searchInProgress = true;
    this.noResults = false;
    this._lastSearchTerm = this.searchTerm;
    this._usersEnhanced.length = 0;
    this._debounceSearch = null;
    this.updateUsersToDisplay(this._searchedUser);

    this.UserSvc.startPhoneCallSearch(this._lastSearchTerm, () => {
      if (this._lastSearchTerm && this._lastSearchTerm === this.searchTerm) {
        this.searchInProgress = false;
        const users = this.UserSvc.getUserSearchList().slice(0);

        if (users.length > 1) {
          // Reset onlyContact
          this.onlyContact.emit(null);
        }

        if (users.length === 0) {
          this.noResults = true;
        } else {
          users.forEach((user: any) => {
            const phoneNumbersLength = user.phoneNumbers.length;
            // system numbers don't have type - set as first obj in array
            const sortedPhoneNumbers = this.sortBySystemNumber(user.phoneNumbers);

            sortedPhoneNumbers?.forEach((number: any, index: any) => {
              const contactNumber: ContactNumber = {
                phoneNumber: number.phoneNumber,
                numberType: this.localizedNumberTypes(number?.type),
                last: index === phoneNumbersLength - 1,
                first: index === 0,
                isExchangeContact: !!user.isExchangeContact,
                isExchangeOnline: !!user.isExchangeOnline,
                isExchangeOnPremise: !!user.isExchangeOnPremise,
                isOsbizContact: !!user.isOsbizContact
              };

              contactNumber.userPresenceState = user.userPresenceState;
              contactNumber.displayName = user.displayNameEscaped || this.root.i18n.map.res_Unknown;
              contactNumber.userId = user?.userId;
              contactNumber.avatar = user?.avatar;
              contactNumber.avatarLarge = user?.avatarLarge;
              contactNumber.hasAvatarPicture = user?.hasAvatarPicture;
              contactNumber.avatarColor = user?.avatarColor;
              if (user?.initials) {
                contactNumber.initials = user.initials;
              } else {
                contactNumber.firstName = user?.firstName;
                contactNumber.lastName = user?.lastName;
              }
              this._usersEnhanced.push(contactNumber);
            });
            if (users.length === 1) {
              user.phoneNumbers[0].phoneNumber = Circuit.Utils.cleanPhoneNumber(user.phoneNumbers[0].phoneNumber);
              if (this.searchTerm === user.phoneNumbers[0].phoneNumber) {
                this.onlyContact.emit(user);
              }
            }
          });
        }
        this.updateUsersToDisplay([...this._searchedUser, ...this._usersEnhanced]);
      }
    });
  };

  onRightClick(e: any, contact: ContactNumber) {
    if (!this.rightClickSupport) { return; }

    e.preventDefault();

    this.selectElement(contact);

    this.menuTopLeftPosition.x = e.clientX + 'px';
    this.menuTopLeftPosition.y = e.clientY + 'px';
    this.matMenuTrigger.openMenu();
  }

  // It is needed because if a contact is selected with right click or keyboard, selectionChange event will not fire.
  onClick(contact: ContactNumber) {
    // Prevents from emitting again if onContactSelected has already emitted
    if (this._hasEmitted) { return; }

    this.contactSelected.emit(contact);
  }

  onDeviceSelect(deviceId: string) {
    this.LogSvc.buttonPressed('[ContactsComponent]: Right click: Make telephony audio call using ', deviceId);
    this.PhoneCallSvc.checkDisclaimerAndDialFromDevice(deviceId, this._selectedContact?.phoneNumber, this._selectedContact?.displayName)
    .then(() => {
      this.closeView.emit();
    })
    .catch((err: any, warn: any) => {
      this.LogSvc.debug('[ContactsComponent]: User has rejected the PopUp');
      this.dialogService.handleCallError(err, warn);
    });
  }

  onCopySelect() {
    navigator.clipboard.writeText(this._selectedContact?.phoneNumber || '');
  }
}
