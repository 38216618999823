import { Injectable, NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterModule, RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { CommonBLService } from 'common-ng/services/common-bl.service';

import { routes } from './routes';
declare let Circuit: any;

@Injectable()
export class TemplatePageTitleStrategy extends TitleStrategy {
  private LogSvc: any;
  private PubSubSvc: any;
  private rootScope: any;
  private _totalUnreadCounter: any;
  private _routerStateSnapshot!: RouterStateSnapshot;

  constructor(private commonBLService: CommonBLService, private readonly title: Title) {
    super();
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this.PubSubSvc.subscribe('/conversations/totalUnreadCountChanged', this.onTotalUnreadCountChanged);
    this.PubSubSvc.subscribe('/language/update', this.onLanguageUpdate);
    this.rootScope = this.commonBLService.getRootScopeData();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    this._routerStateSnapshot = routerState;
    const routerTitle = this.buildTitle(routerState);
    this.title.setTitle(this.titleOptions(routerTitle));
  }

  private titleOptions(routerTitle: any) {
    let title = `${Circuit.productName}`;
    if (this._totalUnreadCounter > 0) {
      title = `(${this._totalUnreadCounter}) ${title}`;
    }

    if (routerTitle !== undefined) {
      title = `${title} - ${this.rootScope.i18n.localize(routerTitle)}`;
    }

    return title;
  }

  private onTotalUnreadCountChanged = (newTotalUnreadCount: any) => {
    this.LogSvc.debug('[RouterModule]: Received /conversations/totalUnreadCountChanged event');
    this._totalUnreadCounter = newTotalUnreadCount > 0 ? newTotalUnreadCount : '';
    this.updateTitle(this._routerStateSnapshot);
    // eslint-disable-next-line no-extra-parens
    (navigator as any).setAppBadge && (navigator as any).setAppBadge(this._totalUnreadCounter);
  };

  private onLanguageUpdate = () => {
    this.LogSvc.debug('[RouterModule]: Received /language/update event');
    this.updateTitle(this._routerStateSnapshot);
  };
}

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: TemplatePageTitleStrategy
    }
  ]
})
export class AppRoutingModule { }
