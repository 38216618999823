import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CallActionService } from '../../../services/call-action.service';
import { DialogService } from '../../../sub-modules/dialog/dialog.service';

declare let Circuit: any;

@Component({
  selector: 'app-push-call',
  templateUrl: './push-call.component.html',
  styleUrls: ['./push-call.component.scss']
})
export class PushCallComponent implements OnInit, OnDestroy {
  @Output() dial = new EventEmitter();
  private LogSvc: any;
  private PhoneCallSvc: any;
  private PubSubSvc: any;
  callDevices: any;

  constructor(private callActionService: CallActionService, private dialogService: DialogService) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this.PhoneCallSvc = Circuit.serviceInstances.phoneCallSvc;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////
  // Internal functions
  ///////////////////////////////////////////////////////////////////////////////////////////////
  private dialFromDevice = (deviceName: any) => {
    this.LogSvc.buttonPressed('[PushCallComponent]: Make telephony audio call using ', deviceName);

    this.PhoneCallSvc.checkDisclaimerAndDialFromDevice(deviceName)
    .catch((err: any) => {
      this.dialogService.handleCallError(err, null);
    });
  };


  ///////////////////////////////////////////////////////////////////////////////////////////////
  // PubSubSvc listeners
  // (You MUST unsubscribe to these events on the ngOnDestroy function below)
  ///////////////////////////////////////////////////////////////////////////////////////////////
  private onTelephonyAvailability = (available: any) => {
    this.LogSvc.debug('[PushCallComponent]: Received /telephony/availability event');
    this.callDevices = this.callActionService.callDevices;
  };

  private onDeviceChangeEvent = () => {
    this.LogSvc.debug('[PushCallComponent]: Received /telephony/callDevices/changed');
    this.callDevices = this.callActionService.callDevices;
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////
  // Public functions
  ///////////////////////////////////////////////////////////////////////////////////////////////
  callSplitButtonAction = (deviceName: any, isDefaultItem: any) => {
    this.LogSvc.info('[PushCallComponent]: callSplitButtonAction');
    if (isDefaultItem) {
      this.dial.emit();
    } else {
      this.dialFromDevice(deviceName);
    }
  };

  getNumOfAvailableCallDevices = () => this.callActionService.getNumOfAvailableCallDevices();
  isTelephonyAvailable = () => this.callActionService.isTelephonyAvailable();

  ngOnDestroy(): void {
    this.PubSubSvc.unsubscribe('/telephony/availability', this.onTelephonyAvailability);
    this.PubSubSvc.unsubscribe('/telephony/callDevices/changed', this.onDeviceChangeEvent);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////
  // Initializations
  // Keep this at the end of the controller.
  ///////////////////////////////////////////////////////////////////////////////////////////////
  ngOnInit(): void {
    this.callDevices = this.callActionService.callDevices;
    this.PubSubSvc.subscribe('/telephony/availability', this.onTelephonyAvailability);
    this.PubSubSvc.subscribe('/telephony/callDevices/changed', this.onDeviceChangeEvent);
  }
}
