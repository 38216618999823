<div class="dialog-container">
    <button mat-button #closebtn
        align="end"
        mat-dialog-close
        class="close-btn">
        <span uAriaLabel="res_AccessibilityCloseModal" role="button"></span>
        <mat-icon>close</mat-icon>
    </button>
  <div mat-dialog-title class="title-container">
    <mat-icon class="mat-15 icon-whats-new-icon"></mat-icon>
    <h1 class="dialog-title headline" *ngIf="pages[currentPageId]?.categoryName" [innerHTML]="!!pages[currentPageId] && i18n.localize(pages[currentPageId].categoryName)"></h1>
  </div>
  <mat-dialog-content class="central-pane">
    <h2 class="feature-title" *ngIf="pages[currentPageId]?.title" [innerHTML]="!!pages[currentPageId] && i18n.localize(pages[currentPageId].title)"></h2>
    <div class="text" *ngIf="pages[currentPageId]?.text" [innerHTML]="!!pages[currentPageId] && i18n.localize(pages[currentPageId].text)"></div>
    <div class="bullets-wrapper" *ngIf="checkIsArray(pages[currentPageId]?.bullets)">
        <div class="bullet" *ngFor="let bullet of pages[currentPageId]?.bullets">
            <mat-icon class="icon mat-15">lens</mat-icon>
            <div class="text">{{bullet}}</div>
        </div>
    </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button class="left-pane" (click)="previousPage()" [ngClass]="{'hide-icon': pages.length <= 1 }" uAriaLabel="res_AccessibilityShowPrevious">
            <span uLocalize="res_Previous"></span>
        </button>
        <div class="dots" role="radiogroup" *ngIf="pages.length > 1">
            <button type="button" class="dot" role="radio" *ngFor="let page of pages" [attr.aria-label]="page.title" [attr.aria-checked]="page.id == currentPageId" [ngClass]="{'selected': page.id == currentPageId}" (click)="gotoPage(page.id)"></button>
        </div>
        <button mat-button class="right-pane" (click)="nextPage()" [ngClass]="{'hide-icon': pages.length <= 1 }" uAriaLabel="res_AccessibilityShowNext">
            <span uLocalize="res_Next"></span>
        </button>
</mat-dialog-actions>
</div>

