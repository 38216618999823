import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';

declare let Circuit: any;

@Component({
  selector: 'app-call-summary-duration',
  templateUrl: './call-summary-duration.component.html',
  styleUrls: ['./call-summary-duration.component.scss']
})
export class CallSummaryDurationComponent implements OnInit, OnDestroy {
  private LogSvc: any;
  private PubSubSvc: any;

  i18n: any;
  formattedDuration: any;

  @Input() item: any;

  constructor(private commonBlService: CommonBLService) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this.i18n = this.commonBlService.getRootScopeData().i18n;
  }

  ngOnInit(): void {
    // we set the duration format once in the beginning
    this.refreshDuration();
    this.PubSubSvc.subscribe('/language/update', this.refreshDuration);
  }

  callDurationDays = (days: number, hours: number) => {
    hours = hours % 24;
    return hours ?
      this.formattedDuration = this.i18n.localize('res_CallDurationDaysHours', [days, hours]) : // e.g. 1d 12h
      this.formattedDuration = this.i18n.localize('res_CallDurationDays', [days]); // e.g. 1d
  };

  callDurationHours = (hours: number, minutes: number) => {
    return minutes ?
      this.formattedDuration = this.i18n.localize('res_CallDurationHoursMin', [hours, minutes]) : // 5h 3min
      this.formattedDuration = this.i18n.localize('res_CallDurationHours', [hours]); // e.g. 5h
  };

  callDurationMinutes = (minutes: number, seconds: number) => {
    return seconds ?
      this.formattedDuration = this.i18n.localize('res_CallDurationMinSec', [minutes, seconds]) : // e.g. 3min 34sec
      this.formattedDuration = this.i18n.localize('res_CallDurationMin', [minutes]); // e.g. 5min
  };

  refreshDuration = () => {
    if (!this.item) {
      return this.ngOnDestroy();
    }
    if (!this.item.duration || this.item.duration === 0) {
      return this.formattedDuration = '-';
    }
    let totalSeconds = this.item.duration;

    const hours = Math.floor(totalSeconds / 3600);
    const days = Math.floor(hours / 24);

    // Format for days or dys & hours
    if (days) {
      return this.callDurationDays(days, hours);
    }

    totalSeconds = totalSeconds % 3600;
    const minutes = Math.floor(totalSeconds / 60);

    // Format for hours or hours & min
    if (hours) {
      return this.callDurationHours(hours, minutes);
    }

    totalSeconds = totalSeconds % 60;
    const seconds = Math.floor(totalSeconds);
    // Format for min or min & secs
    if (minutes) {
      return this.callDurationMinutes(minutes, seconds);
    }
    // Format only for seconds
    return this.formattedDuration = this.i18n.localize('res_CallDurationSeconds', [seconds]); // e.g. 30sec
  };

  ngOnDestroy() {
    this.PubSubSvc.unsubscribe('/language/update', this.refreshDuration);
  }

}
