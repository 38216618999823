<div class="dialpad">
  <div class="dial-input-wrapper">
    <input class="dial-input mat-headline" #input skip-nav-target type="text" [(ngModel)]="value"
           uPlaceholder="res_SearchNameNumber"
           uAriaLabel="res_DialInput"
           (keydown.enter)="selectionChange($event)"
           (keydown.arrowup)="selectionChange($event)"
           (keydown.arrowdown)="selectionChange($event)"
           (ngModelChange)="checkInputValue()">
    <button mat-mini-fab color="grey" class="dial-input-button" uAriaLabel="res_Search" *ngIf="!value" (click)="input.focus()">
      <mat-icon>search</mat-icon>
    </button>
    <button mat-mini-fab color="grey" class="dial-input-button clear" uAriaLabel="res_Clear" *ngIf="value" (click)="clearInput()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <app-contacts *ngIf="value && !isDialpadPressed && isValueSearchable" [searchTerm]="value.trim()"
                [rightClickSupport]="canDial()" (contactSelected)="dial($event.phoneNumber, $event.displayName)" (onlyContact)="onlyContact($event)" (closeView)="clearInput()"></app-contacts>

  <div class="dialpad-wrapper" *ngIf="!value || isDialpadPressed || !isValueSearchable">
    <button mat-raised-button class="dialpad-btn" (click)="type('1')">
      <span class="mat-title dialpad-number">1</span>
      <div class="mat-caption dialpad-letter">-</div>
    </button>
    <button mat-raised-button class="dialpad-btn" (click)="type('2')">
      <span class="mat-title dialpad-number">2</span>
      <div class="mat-caption dialpad-letter">abc</div>
    </button>
    <button mat-raised-button class="dialpad-btn" (click)="type('3')">
      <span class="mat-title dialpad-number">3</span>
      <div class="mat-caption dialpad-letter">def</div>
    </button>
    <button mat-raised-button class="dialpad-btn" (click)="type('4')">
      <span class="mat-title dialpad-number">4</span>
      <div class="mat-caption dialpad-letter">ghi</div>
    </button>
    <button mat-raised-button class="dialpad-btn" (click)="type('5')">
      <span class="mat-title dialpad-number">5</span>
      <div class="mat-caption dialpad-letter">jkl</div>
    </button>
    <button mat-raised-button class="dialpad-btn" (click)="type('6')">
      <span class="mat-title dialpad-number">6</span>
      <div class="mat-caption dialpad-letter">mno</div>
    </button>
    <button mat-raised-button class="dialpad-btn" (click)="type('7')">
      <span class="mat-title dialpad-number">7</span>
      <div class="mat-caption dialpad-letter">pqrs</div>
    </button>
    <button mat-raised-button class="dialpad-btn" (click)="type('8')">
      <span class="mat-title dialpad-number">8</span>
      <div class="mat-caption dialpad-letter">tuv</div>
    </button>
    <button mat-raised-button class="dialpad-btn" (click)="type('9')">
      <span class="mat-title dialpad-number">9</span>
      <div class="mat-caption dialpad-letter">wxyz</div>
    </button>
    <button mat-raised-button class="dialpad-btn" (click)="type('*')">
      <span class="mat-title dialpad-number">*</span>
    </button>
    <button mat-raised-button class="dialpad-btn"
      appDialPress (shortPress)="type('0')" (longPress)="type('+')">
      <span class="mat-title dialpad-number">0</span>
      <div class="mat-caption dialpad-letter">+</div>
    </button>
    <button mat-raised-button class="dialpad-btn" (click)="type('#')">
      <span class="mat-title dialpad-number">#</span>
    </button>
    <app-push-call (dial)="dial($event)"></app-push-call>
    <div matTooltip="{{ canDial() ? '' : root.i18n.map.res_CannotDial }}" matTooltipShowDelay="500">
      <button
        mat-fab color="green"
        class="dialpad-btn call-btn"
        uAriaLabel="res_Call"
        [disabled]="!canDial()"
        (click)="dial(value, '', '')">
        <mat-icon *ngIf="defaultCallDevice.value.icon" [class]="defaultCallDevice.value.icon">{{defaultCallDevice.value.icon !== 'icon-deskphone-icon' ? defaultCallDevice.value.icon : ''}}</mat-icon>
        <mat-icon *ngIf="!defaultCallDevice.value.icon">phone</mat-icon>
      </button>
    </div>
    <button mat-icon-button class="dialpad-btn" uAriaLabel="res_Delete" (click)="backspace()">
      <mat-icon>backspace</mat-icon>
    </button>
  </div>
</div>
