<button mat-button
        class="header-section voicemail-button"
        (click)="listenVoiceMails()"
        [disabled]="!canDial()"
        *ngIf="root?.localUser?.vmNumber"
        uAriaLabel="res_Voicemail"
        matTooltip='{{root.i18n.localize("res_Voicemail")}}'
        [matTooltipShowDelay]="500">
  <span class="cdk-visually-hidden"
        role="status"
        aria-live="polite">{{root.localUser.msgWaitingIndicator? root.i18n.localize('res_NewVoicemailMessages') : ''}}</span>
  <div *ngIf="root.localUser.msgWaitingIndicator" class="badge mat-caption"></div>
  <mat-icon>voicemail</mat-icon>
</button>
