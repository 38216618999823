<button mat-button
        #userMenuTrigger
        [matMenuTriggerFor]="userMenu"
        class="selection-btn"
        *ngIf="isTelephonyAvailable() && getNumOfAvailableCallDevices() > 1">
  <span uLocalize="res_Call"></span>
  <mat-icon class="material-icons-outlined arrow">arrow_drop_down</mat-icon>
</button>

<mat-menu #userMenu="matMenu"
          yPosition="below"
          [overlapTrigger]="false">
  <button mat-menu-item
          *ngFor="let device of callDevices"
          (click)="callSplitButtonAction(device.id, device.isDefault)">
    <mat-icon [class]="device.icon">{{device.icon !== 'icon-deskphone-icon' ? device.icon : ''}}</mat-icon>
    <span [uLocalizeOnce]="device.text"></span>
  </button>
</mat-menu>
