import { Directive, ElementRef, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { constants } from 'os';

declare let Circuit: any;

@Directive({
  selector: '[uPlaceholder]'
})
export class UPlaceholderDirective implements OnInit {

  constructor(private el: ElementRef, commonBlService: CommonBLService) {
    this.rootScope = commonBlService.getRootScopeData();
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this.localizeValue = el.nativeElement.getAttribute('uPlaceholder');
  }

  private params: string[] = [];
  private LogSvc;
  private PubSubSvc;
  private rootScope: any;
  private localizeValue: any;

  private updateText = () => {
    const text = this.params.length ? this.rootScope.localize(this.localizeValue, this.params) : this.rootScope.i18n.map[this.localizeValue];
    this.el.nativeElement.setAttribute('placeholder', text);
  };

  ngOnInit(): void {
    // Initialization
    this.PubSubSvc.subscribe('/language/update', this.updateText);

    const localizeParams = this.el.nativeElement.getAttribute('localizeParams');
    if (typeof localizeParams === 'string') {
      this.params = localizeParams.split('|');
    }
    this.updateText();
  }

  ngOnDestroy(): void {
    this.PubSubSvc.unsubscribe('/language/update', this.updateText);
  }
}
