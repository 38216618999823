import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgentUIState } from '../../shared/constants';

declare let Circuit: any;

@Component({
  selector: 'app-agent-status-indication',
  templateUrl: './agent-status-indication.component.html',
  styleUrls: ['./agent-status-indication.component.scss']
})
export class AgentStatusIndicationComponent implements OnInit, OnDestroy {
  private CstaSvc: any;
  private LogSvc: any;
  private PubSubSvc: any;
  globalAgentStateReady: boolean;
  uIAgentState: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.globalAgentStateReady = this.data.globalAgentStateReady;
    this.uIAgentState = this.data.uIAgentState;
  }

  ngOnInit(): void {
    this.CstaSvc = Circuit.serviceInstances.cstaSvc;
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;

    this.PubSubSvc.subscribe('/agent/state/ready', this.onAgentStateReady);
  }

  ngOnDestroy(): void {
    this.LogSvc.info('[AgentStatusIndicationComponent]: ngOnDestroy');

    this.PubSubSvc.unsubscribe('/agent/state/ready', this.onAgentStateReady);
  }

  private onAgentStateReady = (agentStateReady: boolean) => {
    this.LogSvc.debug('[AgentStatusIndicationComponent]: Received /agent/state/ready event');
    this.globalAgentStateReady = agentStateReady;

    switch (this.CstaSvc.getUIAgentState()) {
    case 'available':
      this.uIAgentState = AgentUIState.AVAILABLE;
      break;
    case 'partiallyUnavailable':
      this.uIAgentState = AgentUIState.PARTIAL;
      break;
    case 'unavailable':
      this.uIAgentState = AgentUIState.UNAVAILABLE;
      break;
    }
  };

  setAgentState = (ready: boolean, group?: any) => {
    this.LogSvc.buttonPressed('Set agent state - ready = ', ready);
    this.CstaSvc.setAgentState(ready, group);
  };
}
