import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { CommonBLService } from 'common-ng/services/common-bl.service';

@Component({
  selector: 'app-notifications-snackbar',
  templateUrl: './notifications-snackbar.component.html',
  styleUrls: ['./notifications-snackbar.component.scss']
})
export class NotificationsSnackbarComponent {

  root: any;

  constructor(
    private commonBlService: CommonBLService,
    public snackBarRef: MatSnackBarRef<NotificationsSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {
    this.root = this.commonBlService.getRootScopeData();
  }
}
