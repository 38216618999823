<mat-tab-group mat-align-tabs="center" class="tabs-container" animationDuration="0ms" (selectedTabChange)="onTabSelected($event)">
  <mat-tab *ngFor="let tab of tabs">
    <ng-template mat-tab-label><h3 [uLocalizeOnce]="tab.uLocalize"></h3></ng-template>
    <div *ngIf="tab.name === selectedTab">
      <a href skip-nav-target tabindex="-1" [uLocalizeOnce]="tab.uA11yLocalize" class="accessibility-element"></a>
      <app-empty-screen-item
        *ngIf="!loadInProgress && (callList.length === 0)"
        imageName="{{atosSign}}"
        imageType="svg"
        imageTitle="atos-sign"
        pageTitle="res_CallHistoryEmptyTitle"
        subtitle="res_CallHistoryEmptySubtitle"
      ></app-empty-screen-item>
      <div *ngIf="callList.length > 0">
        <div class="delete-all-wrapper">
          <button mat-stroked-button
            class="delete-btn"
            matTooltip='{{i18n.localize("res_DeleteCallsTooltip_"+selectedTab)}}'
            [attr.aria-label]='i18n.localize("res_DeleteCallsTooltip_"+selectedTab)'
            (click)="deleteAll()">
            <mat-icon class="dlt-btn">delete_forever</mat-icon>
          </button>
        </div>
        <span class="spinner">
          <mat-spinner *ngIf="deleteInProgress" [diameter]="30" uAriaLabel="res_AccessibilityLoading"></mat-spinner>
        </span>
        <mat-list class="call-log content" uAriaLabel="res_AccessibilityCallHistory"
          role="listbox"
          tabindex="0"
          [ngClass]="{'loading': loadInProgress, 'banner-is-displayed': showBanner}"
          infiniteScroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="50"
          [scrollWindow]="false"
          (scrolled)="onScrollToBottom()">
              <app-call-log-list-item
                  *ngFor="let item of callList"
                  role="option"
                  tabindex="-1"
                  [item]="item"
                  [unreadAfter]="unreadAfter"
                  [dialable]="canDial()">
              </app-call-log-list-item>
        </mat-list>
        <div class="loading-indicator">
          <mat-spinner class="loading" *ngIf="loadInProgress" [diameter]="30" uAriaLabel="res_AccessibilityLoading"></mat-spinner>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
