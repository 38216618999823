import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AngularMaterialModule } from './material.module';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonNgModule } from 'common-ng/common-ng.module';
import { A11yModule } from '@angular/cdk/a11y';

import { AppComponent } from './app.component';
import { TabSelectorComponent } from './components/tab-selector/tab-selector.component';
import { DialpadComponent } from './components/dialpad/dialpad.component';
import { TabViewComponent } from './components/tab-view/tab-view.component';
import { CallHistoryComponent } from './components/call-history/call-history.component';
import { VoicemailComponent } from './components/voicemail/voicemail.component';
import { HeaderComponent } from './components/header/header.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { TabContainerComponent } from './components/tab-container/tab-container.component';
import { CallComponent } from './components/call/call.component';
import { SecondCallComponent } from './components/second-call/second-call.component';
import { DialogModule } from './sub-modules/dialog/dialog.module';
import { BackToCallComponent } from './components/back-to-call/back-to-call.component';
import { DtmfComponent } from './components/dtmf/dtmf.component';
import { DtmfListenersDirective } from './components/dtmf/dtmf-listeners.directive';
import { DialpadButtonComponent } from './components/dialpad-button/dialpad-button.component';
import { SettingsOverlayComponent } from './components/settings-overlay/settings-overlay.component';
import { SettingsHeaderComponent } from './components/settings-header/settings-header.component';
import { SettingsTabsComponent } from './components/user-settings-tab-selector/settings-tabs.component';
import { GeneralTabComponent } from './components/user-settings-tab-selector/general-tab/general-tab.component';
import { CallAudioStreamComponent } from './components/call-audio-stream/call-audio-stream.component';
import { CallLogListItemComponent } from './components/call-log-list-item/call-log-list-item.component';
import { AudioTabComponent } from './components/user-settings-tab-selector/audio-tab/audio-tab.component';
import { TelephonyTabComponent } from './components/user-settings-tab-selector/telephony-tab/telephony-tab.component';
import { TestAudioDeviceComponent } from './components/user-settings-tab-selector/test-audio-device/test-audio-device.component';
import { MicrophoneActivityComponent } from './components/user-settings-tab-selector/microphone-activity/microphone-activity.component';
import { CallFwdOverlayComponent } from './components/call-fwd-overlay/call-fwd-overlay.component';
import { UElapsedTimeDirective } from './directives/u-elapsed-time.directive';
import { CallTransferComponent } from './components/call-transfer/call-transfer.component';
import { ItemDateFormatComponent } from './components/call-log-list-item/item-date-format/item-date-format.component';
import { CallSummaryDurationComponent } from './components/call-log-list-item/call-summary-duration/call-summary-duration.component';
import { EmptyScreenItemComponent } from './components/empty-screen-item/empty-screen-item.component';
import { NewCallComponent } from './components/new-call/new-call.component';
import { PushCallComponent } from './components/dialpad/push-call/push-call.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AvatarSingleComponent } from './components/avatar-single/avatar-single.component';
import { PushIncomingCallComponent } from './components/push-incoming-call/push-incoming-call.component';
import { EditLogLevelComponent } from './components/edit-log-level/edit-log-level.component';
import { PlantronicsIntegration } from './components/plantronics-integration/plantronics-integration.component';
import { ContactComponent } from './components/contacts/contact/contact.component';
import { ScopedHighlighterPipe } from './pipes/scoped-highlighter.pipe';
import { AboutOverlayComponent } from './components/about-overlay/about-overlay.component';
import { ScrollableItemDirective } from './directives/scrollable-item.directive';
import { ExternalLinkButton } from './components/external-link-button/external-link-button.component';
import { DialPressDirective } from './directives/dial-press.directive';
import { StateBannerComponent } from './components/state-banner/state-banner.component';
import { JabraIntegration } from './components/jabra-integration/jabra-integration.component';
import { USkipNavDirective } from './directives/u-skip-nav.directive';
import { LabsTabComponent } from './components/user-settings-tab-selector/labs-tab/labs-tab.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ExchangeExtensionComponent } from './components/exchange-extension/exchange-extension.component';
import { ManualMessageComponent } from './components/manual-message/manual-message.component';
import { NotificationsSnackbarComponent } from './components/notifications-snackbar/notifications-snackbar.component';
import { SearchUsersComponent } from './components/search-users/search-users.component';
import { IceSettingsComponent } from './components/ice-settings/ice-settings.component';
import { RtpDebugAudioComponent } from './components/rtp-debug-audio/rtp-debug-audio.component';
import { CallListComponent } from './components/call-list/call-list.component';
import { CallViewsComponent } from './components/call-views/call-views.component';
import { CallPrimaryComponent } from './components/call-primary/call-primary.component';
import { AgentStatusIndicationComponent } from './components/agent-status-indication/agent-status-indication.component';
import { WhatsNewComponent } from './components/whats-new/whats-new.component';
import { HelpOverlayComponent } from './components/help-overlay/help-overlay.component';
import { AccountTabComponent } from './components/user-settings-tab-selector/account-tab/account-tab.component';
import { EditProfilePictureComponent } from './components/edit-profile-picture/edit-profile-picture.component';
import { PhotoBoothComponent } from './components/photo-booth/photo-booth.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { UPreventDragDirective } from './directives/u-prevent-drag.directive';
import { UAllowDragDirective } from './directives/u-allow-drag.directive';
import { ExchangeOnpremiseExtensionComponent } from './components/exchange-onpremise-extension/exchange-onpremise-extension.component';
import { ExchangeConnectComponent } from './components/exchange-connect/exchange-connect.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RingtonesComponent } from './components/user-settings-tab-selector/ringtones/ringtones.component';
import { CallQualityComponent } from './components/call-quality/call-quality.component';

@NgModule({
  declarations: [
    AppComponent,
    TabSelectorComponent,
    DialpadComponent,
    TabViewComponent,
    CallHistoryComponent,
    VoicemailComponent,
    HeaderComponent,
    ContactsComponent,
    TabContainerComponent,
    CallComponent,
    BackToCallComponent,
    DtmfComponent,
    DtmfListenersDirective,
    DialpadButtonComponent,
    SettingsOverlayComponent,
    SettingsHeaderComponent,
    SettingsTabsComponent,
    GeneralTabComponent,
    CallAudioStreamComponent,
    CallLogListItemComponent,
    AudioTabComponent,
    TelephonyTabComponent,
    TestAudioDeviceComponent,
    MicrophoneActivityComponent,
    CallFwdOverlayComponent,
    UElapsedTimeDirective,
    CallTransferComponent,
    ItemDateFormatComponent,
    CallSummaryDurationComponent,
    EmptyScreenItemComponent,
    NewCallComponent,
    PushCallComponent,
    SecondCallComponent,
    AvatarSingleComponent,
    PushIncomingCallComponent,
    EditLogLevelComponent,
    PlantronicsIntegration,
    ContactComponent,
    ScopedHighlighterPipe,
    ScrollableItemDirective,
    AboutOverlayComponent,
    ExternalLinkButton,
    DialPressDirective,
    StateBannerComponent,
    JabraIntegration,
    USkipNavDirective,
    LabsTabComponent,
    ExchangeExtensionComponent,
    ManualMessageComponent,
    NotificationsSnackbarComponent,
    SearchUsersComponent,
    IceSettingsComponent,
    RtpDebugAudioComponent,
    CallListComponent,
    CallViewsComponent,
    CallPrimaryComponent,
    AgentStatusIndicationComponent,
    HelpOverlayComponent,
    WhatsNewComponent,
    AccountTabComponent,
    EditProfilePictureComponent,
    PhotoBoothComponent,
    UPreventDragDirective,
    UAllowDragDirective,
    ExchangeOnpremiseExtensionComponent,
    ExchangeConnectComponent,
    RingtonesComponent,
    CallQualityComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularMaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonNgModule,
    DialogModule,
    InfiniteScrollModule,
    ImageCropperModule,
    A11yModule,
    MatCheckboxModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true, //environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
