import { ListKeyManager } from '@angular/cdk/a11y';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { Subscription } from 'rxjs';
import { CallActionService } from '../../services/call-action.service';
import { DialogService } from '../../sub-modules/dialog/dialog.service';
import { ContactsComponent } from '../contacts/contacts.component';

const INSUFFICIENT_DIGIT_LENGTH_REGEX = /^\+?[\d#*]{1,2}\b/g;
const PENDING_CALL_TIMEOUT = 1000;

declare let Circuit: any;
@Component({
  selector: 'app-dialpad',
  templateUrl: './dialpad.component.html',
  styleUrls: ['./dialpad.component.scss']
})
export class DialpadComponent implements OnInit, OnDestroy {
  private LogSvc: any;
  private PhoneCallSvc: any;
  private PubSubSvc: any;
  keyboardEventsManager!: ListKeyManager<any>;

  @ViewChild('input') private input: any;
  @ViewChild(ContactsComponent) private contactsComponent!: ContactsComponent;

  value = '';
  root: any;
  defaultCallDevice: any;
  isDialpadPressed = false;
  isValueSearchable = false;
  dialable = true;
  private routerSub!: Subscription;
  private pendingCallFromUrl: any = null;
  private singleContact: any;
  private pendingCallTimeout: any;

  constructor(
    private callActionService: CallActionService,
    private commonBlService: CommonBLService,
    private dialogService: DialogService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    const serviceInstances = Circuit.serviceInstances;
    this.LogSvc = serviceInstances.logSvc;
    this.LogSvc.info('[DialpadComponent]: ngOnInit');
    this.PhoneCallSvc = serviceInstances.phoneCallSvc;
    this.PubSubSvc = serviceInstances.pubSubSvc;
    this.root = this.commonBlService.getRootScopeData();
    this.defaultCallDevice = this.callActionService.defaultCallDevice;
    this.PubSubSvc.subscribe('/telephony/availability', this.onTelephonyAvailability);
    this.subscribeToActivatedRoute();
  }

  ngOnDestroy(): void {
    this.LogSvc.info('[DialpadComponent]: ngOnDestroy');
    this.PubSubSvc.unsubscribe('/telephony/availability', this.onTelephonyAvailability);
    this.routerSub.unsubscribe();
  }

  private onTelephonyAvailability = (available: any) => {
    this.LogSvc.debug('[DialpadComponent]: Received /telephony/availability event');
    if (available) {
      // NGTC-7922: Sometimes, due to a racing issue, getActiveSession drops the call if initiated directly after
      // telephony availability
      this.pendingCallTimeout && clearTimeout(this.pendingCallTimeout);
      this.pendingCallTimeout = setTimeout(() => {
        this.pendingCallTimeout = null;
        this.checkPendingAndCall();
      }, PENDING_CALL_TIMEOUT);
    }
  };

  private checkPendingAndCall = () => {
    if (this.pendingCallFromUrl && this.pendingCallFromUrl.number === this.value) {
      const device = this.pendingCallFromUrl.device;
      this.pendingCallFromUrl = null;
      if (device) {
        this.dialFromDevice(device, this.value);
      } else {
        this.dial(this.value);
      }
    }
  };

  private subscribeToActivatedRoute = () => {
    this.routerSub = this.activatedRoute?.queryParams.subscribe(params => {
      let number = params.number;
      const device = params.device;

      if (number) {
        this.LogSvc.debug('[DialpadComponent]: Dial from url with number: ', number);

        if (number.startsWith('tel:')) {
          number = number.substring(4);
        }
        if (number.startsWith(' ')) {
          // '+' is encoded as blank (%20), so revert to a '+' so its shown as such in the UI
          number = '+' + number.substring(1);
        }
        number = decodeURIComponent(number);

        this.value = number;
        //Null or undefined
        if (this.root.inFullCallView == null) {
          if (this.canInitiatePhoneCall()) {
            this.LogSvc.debug('[DialpadComponent]: No call, trying to dial');
            if (device) {
              this.dialFromDevice(device, number);
            } else {
              this.dial(number);
            }
          } else {
            this.LogSvc.debug('[DialpadComponent]: Cannot dial at the moment. Will try when telephony is available');
            this.pendingCallFromUrl = { number, device };
          }
        }
        this.router.navigateByUrl(this.router.url.replace('number', ''));
      }

    });
  };

  private dialFromDevice = (deviceName: any, number: string, name?: string, userId?: string) => {
    this.LogSvc.buttonPressed('[DialpadComponent]: Make telephony audio call using ', deviceName);

    this.PhoneCallSvc.checkDisclaimerAndDialFromDevice(deviceName, number, name, userId)
    .then(() => {
      this.root.inFullCallView = true;
      if (this.root.inMiniCallView) {
        this.root.inMiniCallView = false;
      }
    })
    .catch((err: any) => {
      this.dialogService.handleCallError(err, null);
    });

    this.clearInput();
  };

  canInitiatePhoneCall = () => this.PhoneCallSvc.canInitiatePhoneCall();

  type(value: string) {
    this.input.nativeElement.focus();
    this.value += value;
    // keep dialpad view if user writes with dialpad first
    this.isDialpadPressed = true;
  }

  selectionChange(event: any) {
    if (event.key === 'Enter') {
      if (!this.value.trim()) {
        return;
      }

      if (this.isDialpadPressed || this.value.match(Circuit.Utils.PHONE_DIAL_PATTERN)) {
        //If dialpad buttons are pressed or value is a valid phone on enter call the number
        this.dial(this.value);
        return;
      }
    }
    if (this.value.trim() && !this.isDialpadPressed && this.isValueSearchable) {
      this.contactsComponent.handleKeydown(event);
    }
  }

  backspace() {
    if (this.value && this.value !== '') {
      this.input.nativeElement.focus();
      this.value = this.value.substring(0, this.value.length - 1);

      if (!this.value) {
        this.clearInput();
      }
    }
  }

  dial = (number: string, name = '', userId = '') => {
    if (!number) {
      this.LogSvc.warn('[DialpadComponent]: Cannot initiate a call wthout a number');
      return;
    }

    if (!this.canInitiatePhoneCall() || !this.canDial()) {
      this.LogSvc.warn('[DialpadComponent]: Phone calls are not possible at the moment');
      this.dialogService.handleCallError('res_CannotDial', null);
      return;
    }

    if (this.singleContact?.number === number) {
      name = this.singleContact.name;
    }
    this.LogSvc.buttonPressed('[DialpadComponent]: Make telephony audio call to: ', {
      number: number,
      userId: userId
    });

    this.PhoneCallSvc.checkDisclaimerAndDial(number, name, userId)
      .then(() => {
        this.root.inFullCallView = true;
        if (this.root.inMiniCallView) {
          this.root.inMiniCallView = false;
        }
      })
      .catch((err: any, warn: any) => {
        this.LogSvc.debug('[DialpadComponent]: User has rejected the PopUp');
        this.dialogService.handleCallError(err, warn);
      });

    this.clearInput();
  };

  clearInput() {
    this.value = '';
    this.isDialpadPressed = false;
    this.isValueSearchable = false;
  }

  checkInputValue() {
    // Prevent dialpad from disappearing when value is not searchable
    this.isValueSearchable = !this.value.match(INSUFFICIENT_DIGIT_LENGTH_REGEX) && !!this.value.trim();

    // Reset when the input string is deleted
    if (!this.value) {
      this.clearInput();
    }
  }

  canDial = () => {
    this.dialable = this.callActionService.canDial();
    return this.dialable;
  };

  onlyContact = (event: any) => {
    if (!event) {
      this.singleContact = null;
      return;
    }
    this.singleContact = {
      number: event?.phoneNumbers[0]?.phoneNumber,
      name: event?.displayName
    };
  };
}
