import { Component, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { UtilsService } from '../../../services/utils.service';
import { DialogService } from '../../../sub-modules/dialog/dialog.service';

declare let Circuit: any;

interface Language {
  locale: string
  name: string
  nativeName: string
  resourceName: string
  shortValue: string
  value: string
}

@Component({
  selector: 'app-general-tab',
  templateUrl: './general-tab.component.html',
  styleUrls: ['./general-tab.component.scss']
})
export class GeneralTabComponent implements OnInit {
  private LogSvc: any;
  private PubSubSvc: any;
  private LocalizeSvc: any;
  private LocalStoreSvc: any;
  private UserProfileSvc: any;

  root: any;
  languages: any = [];
  selectedLanguage: any = '';
  showInstallSection = false;
  isEdge = false;

  constructor(private commonBlService: CommonBLService, private dialogService: DialogService, private utilsService: UtilsService) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this.LocalizeSvc = Circuit.serviceInstances.localizeSvc;
    this.LocalStoreSvc = Circuit.serviceInstances.localStoreSvc;
    this.UserProfileSvc = Circuit.serviceInstances.userProfileSvc;
  }

  ngOnInit(): void {
    this.LogSvc.info('[GeneralTabComponent]: ngOnInit');
    this.root = this.commonBlService.getRootScopeData();
    this.isEdge = this.root.browser.subType === 'edge';
    const localizeSvcLanguageValue = this.LocalizeSvc.getLanguageObject()?.value;
    this.languages = this.root.i18n.languages;
    if (localizeSvcLanguageValue && this.languages?.length > 0) {
      const selectedLanguage = this.languages.find((lang: Language) => lang.value === localizeSvcLanguageValue);
      this.selectedLanguage = selectedLanguage.value;
    }

    // subscribe to events on language changes eg between 2 clients (web & mobile)
    this.PubSubSvc.subscribe('/language/update', this.onLanguageUpdate);
  }

  ngOnDestroy(): void {
    this.LogSvc.info('[GeneralTabComponent]: ngOnDestroy');
    this.PubSubSvc.unsubscribe('/language/update', this.onLanguageUpdate);
  }

  toggleAccessibilityStatus = () => {
    this.root.accessibilityEnabled = !this.root.accessibilityEnabled;
    this.LocalStoreSvc.setObjectSync(this.LocalStoreSvc.keys.ACCESSIBILITY_ENABLED, this.root.accessibilityEnabled);
    this.PubSubSvc.publish('/accessibility/status', [this.root.accessibilityEnabled]);
  };

  onSelectChange(event: any) {
    const selection = this.languages.filter((lang: Language) => lang.value === event.value);
    if (selection?.length > 0 && selection[0]?.value && selection[0]?.nativeName) {
      this.UserProfileSvc.setLanguage(selection[0].value, (err: any) => {
        if (err) {
          this.LogSvc.error('[GeneralTabComponent]: Could not update language', err);
          this.dialogService.handleCallError('res_ErrorSettingLanguage');
        }
      });
    }
  }

  onLanguageUpdate = (lang: Language) => {
    this.LogSvc.debug('[GeneralTabComponent]: Received /language/update event. Language = ', lang);
    this.selectedLanguage = this.LocalizeSvc.getLanguageObject().value;
  };

  installPwa = () => {
    this.utilsService.showPrompt();
  };

  canInstallPWA = () => !this.utilsService.isInstalledApp() && Boolean(this.utilsService.deferredPrompt);

  installExtension = () => {
    const edgeLink = 'https://microsoftedge.microsoft.com/addons/detail/gjnihknefcenglogbkahojihhgmceonl';
    const chromeLink = 'https://chrome.google.com/webstore/detail/unify-phone/kmecfbfkkbgoadanmacdkpfpljfocehm';
    const extURL = this.isEdge ? edgeLink : chromeLink;
    window.open(extURL, '_newtab');
  };

  canInstallExtension = () => !this.root.isBrowserExtRunning && this.root.browser.chrome;

}
