<mat-toolbar color="primary"
             class="main-header"
             [ngClass]="{'header-section-with-call': phoneConv?.call && !rootScope.inFullCallView }"
             (click)="toggleShowDebugMenu($event)">
  <a href uSkipNav class="skip-link stacked-link" uLocalize="res_AccessibilitySkipToMainContent"></a>
  <h1 class="accessibility-element" uLocalize="res_ProductName"></h1>
  <div class="left-corner-menus header-section mat-caption" *ngIf="rootScope?.localUser">
    <!--- RC and Standalone with presence status  --->
    <button class="user-wrapper"
            mat-button #userMenuTrigger
            [matMenuTriggerFor]="userMenu">
      <span class="accessibility-element"
            role="status"
            [innerHTML]="rootScope.i18n.localize('res_AccessibilityPresenceChanged', [i18nPrecenceStateRes])"></span>
      <app-avatar-single [user]="rootScope.localUser" [presence]="rootScope.localUser?.userPresenceState.state" size="small2" [withBorder]="true"></app-avatar-single>
      <span class="display-name" [textContent]="displayName"></span>
      <span class="phone-number" *ngIf="phoneNumber"> | {{ phoneNumber }}</span>
      <mat-icon class="material-icons-outlined arrow">arrow_drop_down</mat-icon>
    </button>

    <mat-menu class="logout-menu" #userMenu="matMenu" yPosition="below" [overlapTrigger]="false" [ngSwitch]="rootScope.localUser.isStandalone">
      <button
        class="profile-info"
        *ngIf="rootScope.localUser.isStandalone"
        mat-menu-item
        (click)="openOverlay(editProfilePictureOptions)"
        (mouseenter) ="editProfilePic = true"
        (mouseleave) ="editProfilePic = false"
        (focus) ="editProfilePic = true"
        (focusout) ="editProfilePic = false"
        uAriaLabel="res_EditPicture">
          <app-avatar-single
            [user]="rootScope.localUser"
            size="medium"
            [withBorder]="true"
            [editProfilePic]="editProfilePic">
          </app-avatar-single>
          <div class="info-container">
            <div class="user-name" [textContent]="displayName"></div>
            <div class="user-number" *ngIf="phoneNumber">{{ phoneNumber }}</div>
          </div>
      </button>
      <mat-divider *ngIf="rootScope.localUser.isStandalone"></mat-divider>
      <button mat-menu-item class="presence-item AVAILABLE" (click)="setPresence('AVAILABLE')">
        <span class="icon-container">
          <mat-icon class="material-icons md-15">check_circle</mat-icon>
        </span>
        <span uLocalize="res_Available"></span>
      </button>
      <button *ngSwitchCase="true" mat-menu-item class="presence-item BUSY" (click)="setPresence('BUSY')">
        <span class="icon-container">
          <mat-icon class="material-icons md-15">circle</mat-icon>
        </span>
        <span uLocalize="res_Busy"></span>
      </button>
      <button mat-menu-item class="presence-item DND" (click)="setPresence('DND')">
        <span class="icon-container">
          <mat-icon class="material-icons md-15">do_not_disturb_on</mat-icon>
        </span>
        <span uLocalize="res_DoNotDisturb"></span>
      </button>
      <button *ngSwitchCase="true" mat-menu-item class="presence-item AWAY" (click)="setPresence('AWAY')">
        <span class="icon-container">
          <mat-icon class="material-icons md-15">watch_later</mat-icon>
        </span>
        <span uLocalize="res_Away"></span>
      </button>
      <button *ngSwitchCase="false" mat-menu-item class="presence-item INVISIBLE" (click)="setPresence('INVISIBLE')">
        <span class="icon-container">
          <mat-icon class="material-icons-outlined md-15">cancel</mat-icon>
        </span>
        <span uLocalize="res_Invisible"></span>
      </button>
      <button *ngSwitchCase="true" mat-menu-item class="presence-item INVISIBLE" (click)="setPresence('OFFLINE')">
        <span class="icon-container">
          <mat-icon class="material-icons-outlined md-15">cancel</mat-icon>
        </span>
        <span uLocalize="res_AppearOffline"></span>
      </button>
    </mat-menu>
  </div>

  <!-- Minimized call view -->
  <app-back-to-call class="back-to-call-bar"
    *ngIf="phoneConv?.call && !!rootScope.inMiniCallView">
  </app-back-to-call>


  <div class="right-corner-menus">
    <!-- Notification start -->
    <ng-template [ngIf]="!notificationPermissionSet && showNotificationHeader">
      <button mat-button class="header-section mat-caption notifications-btn" (click)="enableNotifications()" uAriaLabel="res_EnableNotifications">
        <mat-icon>notifications_off</mat-icon>
        <span class="btn-text" uLocalize="res_EnableNotifications"></span>
      </button>
      <button mat-button class="header-section mat-caption notifications-btn-icon">
        <mat-icon matTooltip="{{rootScope.i18n.map.res_NotificationsBlockedTooltip}}">notifications_off</mat-icon>
      </button>
    </ng-template>
    <ng-template [ngIf]="notificationPermissionSet && isNotificationBlocked">
      <button mat-button class="header-section mat-caption notifications-btn" [disabled]="true" uAriaLabel="res_NotificationsBlocked">
        <mat-icon>notifications_off</mat-icon>
        <span class="btn-text" uLocalize="res_NotificationsBlocked"></span>
      </button>
      <button mat-button class="header-section mat-caption notifications-btn-icon" [disabled]="true">
        <mat-icon matTooltip="{{rootScope.i18n.map.res_NotificationsBlockedTooltip}}">notifications_off</mat-icon>
      </button>
    </ng-template>
    <!-- Notification end -->
    <ng-template [ngIf]="showDebugMenu">
      <div class="header-section mat-caption">
        <button mat-button #debugMenuTrigger [matMenuTriggerFor]="debugMenu">
          <span uLocalize="res_DebugOptions"></span>
          <mat-icon class="material-icons-outlined">
            arrow_drop_down
          </mat-icon>
        </button>

        <mat-menu class="logout-menu" #debugMenu="matMenu" yPosition="below" xPosition="before"
          [overlapTrigger]="false">
          <button mat-menu-item (click)="openOverlay(editLogLevelOptions)">
            <span uLocalize="res_LogManagement"></span>
          </button>
          <button mat-menu-item (click)="toggleLabFeatures()">
            <span>
              {{ rootScope.showAlphaFeatures ? rootScope.i18n.map.res_DisableAlphaFeatures : rootScope.i18n.map.res_EnableAlphaFeatures }}
            </span>
          </button>
          <ng-template [ngIf]="!popUpLogEnabled">
            <button mat-menu-item (click)="enablePopUpLog()">
              <span uLocalize="res_PopupLogEnable"></span>
            </button>
          </ng-template>
          <ng-template [ngIf]="popUpLogEnabled">
            <button mat-menu-item (click)="disablePopUpLog()">
              <span uLocalize="res_PopupLogDisable"></span>
            </button>
          </ng-template>
          <ng-template [ngIf]="popUpLogEnabled">
            <button mat-menu-item (click)="showPopUpLogWindow()">
              <span uLocalize="res_ShowLog"></span>
            </button>
          </ng-template>
          <button mat-menu-item (click)="hideDebugMenu()">
            <span uLocalize="res_HideMenu"></span>
          </button>
          <button mat-menu-item (click)="setRtcDebugAudioParams()">
            <span uLocalize="res_RTCDebugAudio"></span>
          </button>
          <button mat-menu-item (click)="setRtcDebugIceParams()">
            <span uLocalize="res_RTCDebugIce"></span>
          </button>
        </mat-menu>
      </div>
    </ng-template>
    <button mat-button class="header-section mat-caption hunt-group-agent" (click)="openOverlay(agentOptions)"
      matTooltip="{{rootScope.i18n.map.res_AccessibilityAgentStatus}}" *ngIf="rootScope.localUser?.isAgent && telephonyAvailable" uAriaLabel="res_AccessibilityAgentStatus">
      <mat-icon>support_agent</mat-icon>
      <span class="status" [ngClass]="uIAgentState?.name"></span>
      <span class="accessibility-element"
            role="status"
            aria-live="polite">{{rootScope.i18n.localize(uIAgentState?.ally)}}</span>
    </button>
    <button mat-button class="header-section mat-caption call-fwd-button" (click)="openOverlay(callFwdOptions)"
      matTooltip="{{rootScope.i18n.map.res_CallForwardingIsEnabled}}" *ngIf="!!cfwEnabled" uAriaLabel="res_CallForwardingIsEnabled">
      <mat-icon class="call-fwd-icon">phone_forwarded</mat-icon>
    </button>
    <app-voicemail></app-voicemail>
    <button mat-button class="header-section" #settingsMenuTrigger [matMenuTriggerFor]="settingsMenu" uAriaLabel="res_AccessibilityOptionsMenu">
      <mat-icon class="settings-icon">more_horiz</mat-icon>
    </button>
    <mat-menu class="logout-menu" #settingsMenu="matMenu" yPosition="below" [overlapTrigger]="false">
      <button mat-menu-item *ngIf="rootScope.localUser" (click)="openOverlay(settingsOptions)">
        <mat-icon class="settings-icon">settings</mat-icon>
        <span uLocalize="res_Settings" ></span>
      </button>
      <button *ngIf="!rootScope.localUser?.isStandalone" mat-menu-item (click)="clickUnifyOfficeChild()" class="custom-link-button">
        <app-external-link-button text="Unify Video" uri="unifyoffice://app.unifyoffice.com" image="unify-video.svg" #unifyOfficeChild></app-external-link-button>
      </button>
      <button mat-menu-item (click)="openOverlay(whatsNewOptions)">
        <mat-icon class="settings-icon icon-whats-new-icon"></mat-icon>
        <span uLocalize="res_WhatsNew"></span>
      </button>
      <button mat-menu-item (click)="openOverlay(aboutOptions)">
        <mat-icon class="settings-icon">info</mat-icon>
        <span uLocalize="res_About" ></span>
      </button>
      <button mat-menu-item (click)="openOverlay(helpOptions)">
        <mat-icon class="settings-icon">help</mat-icon>
        <span uLocalize="res_Help"></span>
      </button>
      <button mat-menu-item (click)="emailLogs()">
        <mat-icon class="settings-icon">report_problem</mat-icon>
        <span uLocalize="res_IssueReport" ></span>
      </button>
      <button mat-menu-item class="no-underline" (click)="logout()">
        <mat-icon class="settings-icon">exit_to_app</mat-icon>
        <span uLocalize="res_SignOut" ></span>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
<call-audio-stream></call-audio-stream>
