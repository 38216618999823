import { Directive, HostListener } from '@angular/core';

import { DtmfComponent } from './dtmf.component';

const VALID_DTMF_DIGITS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '*', '#', ','];
const TAB_KEY = 'Tab';

@Directive({
  selector: '[appDtmfListenersDirective]'
})
export class DtmfListenersDirective {

  constructor(private dtmfComponent: DtmfComponent) {}

  @HostListener('paste', ['$event'])
  @HostListener('keydown', ['$event'])
  @HostListener('focus', ['$event'])
  @HostListener('mousedown', ['$event'])
  onEvent(event: any) {
    if (event instanceof KeyboardEvent) { this.onKeydown(event); }
    if (event.type === 'paste') { this.sendClipboardText(event); }
    if (event.type === 'focus' || event.type === 'mousedown') { this.dtmfComponent.moveCaret(null); }
  }

  mapKeypadCodeToDtmf(event: KeyboardEvent) {
    let digit = event.key;

    if (!/^[A-Za-z0-9#*]$/.exec(digit)) {
      return null; // If a modifier key (e.g.: Shift) if pressed, null is returned
    }

    if (!/[0-9#*]/.exec(digit)) {
      // Convert the letter to the equivalent DTMF character
      if (/[A-Ca-c]/.exec(digit)) {
        digit = '2';
      } else if (/[D-Fd-f]/.exec(digit)) {
        digit = '3';
      } else if (/[G-Ig-i]/.exec(digit)) {
        digit = '4';
      } else if (/[J-Lj-l]/.exec(digit)) {
        digit = '5';
      } else if (/[M-Om-o]/.exec(digit)) {
        digit = '6';
      } else if (/[P-Sp-s]/.exec(digit)) {
        digit = '7';
      } else if (/[T-Vt-v]/.exec(digit)) {
        digit = '8';
      } else if (/[W-Zw-z]/.exec(digit)) {
        digit = '9';
      }
    }
    return digit;
  }

  onKeydown(event: KeyboardEvent) {

    if (event.metaKey ||
      event.ctrlKey ||
      event.altKey ||
      event.key === TAB_KEY) {
      return;
    }

    const char = this.mapKeypadCodeToDtmf(event) || '';
    if (!VALID_DTMF_DIGITS.includes(char)) {
      event.preventDefault();
      return;
    }

    this.dtmfComponent.onDtmfDigits(char);
  }

  getClipboardText(event: ClipboardEvent) {

    let plainTextData;
    if (event.clipboardData && event.clipboardData.getData) {
      plainTextData = event.clipboardData.getData('text');
    }
    return plainTextData;
  }

  sendClipboardText(event: ClipboardEvent) {
    let digits = this.getClipboardText(event);
    if (digits) {
      // Remove white spaces
      digits = digits.replace(/\s/g, '');
      this.dtmfComponent.onDtmfDigits(digits);
    }
  }

}
