import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilsService } from '../../services/utils.service';
import { DialogService } from '../../sub-modules/dialog/dialog.service';

declare let Circuit: any;

@Component({
  selector: 'app-edit-log-level',
  templateUrl: './edit-log-level.component.html',
  styleUrls: ['./edit-log-level.component.scss']
})
export class EditLogLevelComponent implements OnInit {
  private LogSvc;
  private bytesToStr;
  form: any; // Forms must be initialized inside a separate object
  logLevels: any[] = [];
  fileList: any[] = [];
  LogLevel: any;
  log: any;
  selectedLogLevel = '';
  showErrors = false;
  downloadStarted = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private mdDialogRef: MatDialogRef<EditLogLevelComponent>,
    private sanitizer: DomSanitizer, private dialogService: DialogService,
    private utilsService: UtilsService) {
    this.LogLevel = Circuit.LogLevel;
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.bytesToStr = Circuit.Utils.bytesToSize;
  }

  ngOnInit(): void {
    this.logLevels = Object.values(this.LogLevel);
    this.log = {
      logLevel: this.LogSvc.getLevel()
    };
    this.selectedLogLevel = this.log.logLevel.name;
    this.LogSvc.getLogFilesForDownload((logFiles: any[]) => {
      this.fileList = logFiles ? logFiles.slice(0) : [];
    }, true);
  }

  close(value: boolean) {
    this.mdDialogRef.close(value);
  }

  saveLogLevel = () => {
    this.logLevels.some((lvl: any) => {
      if (this.selectedLogLevel && this.selectedLogLevel === lvl.name) {
        this.log.logLevel = lvl;
        return true;
      }
      return false;
    });
    this.LogSvc.setLevel(this.log.logLevel);
    this.close(true);
  };

  removeLogFiles = () => {
    this.LogSvc.removeLogFiles((logFiles: any[]) => {
      if (logFiles) {
        this.fileList = logFiles.slice(0);
      }
    });
    this.LogSvc.info('EditLogLevelCtrl: removed all log files');
    this.fileList = [];
    this.downloadStarted = false;
  };

  downloadAll = () => {
    this.utilsService.downloadAllLogFiles();
  };

  bytesToString = (bytes: number) => this.bytesToStr(bytes);

  sanitizeUrl = (url: string) => this.sanitizer.bypassSecurityTrustUrl(url);
}
