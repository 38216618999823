import { Component, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CommonBLService } from 'common-ng/services/common-bl.service';

declare let Circuit: any;

interface FeatureItem {
  detailsRes: string,
  featureName: string,
  isAlpha: boolean,
  isClosedBeta: boolean,
  isDemo: boolean,
  titleRes: string,
  typeRes: string
}

@Component({
  selector: 'app-labs-tab',
  templateUrl: './labs-tab.component.html',
  styleUrls: ['./labs-tab.component.scss']
})
export class LabsTabComponent implements OnInit {
  private LogSvc: any;
  private PopupSvc: any;
  private CircuitLabSvc: any;

  i18n: any;
  supportedFeatureList: any;
  selectedFeatures: any;
  feature = false;

  constructor(private commonBlService: CommonBLService) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PopupSvc = Circuit.serviceInstances.popupSvc;
    this.CircuitLabSvc = Circuit.serviceInstances.circuitLabSvc;
    this.selectedFeatures = {};
    this.supportedFeatureList = [];
  }

  ngOnInit(): void {
    this.LogSvc.info('[LabsTabComponent]: ngOnInit');
    const rootScope = this.commonBlService.getRootScopeData();
    this.i18n = rootScope.i18n;
    rootScope.showAlphaFeatures && this.initializeSupportedList();
  }

  initializeSupportedList = () => {
    this.supportedFeatureList = this.CircuitLabSvc.getSupportedFeatureList(true);
    // get if each selected feature was already enabled
    this.supportedFeatureList.forEach((item: FeatureItem) => {
      this.selectedFeatures[item.featureName] = {
        enabled: this.CircuitLabSvc.isFeatureEnabled(item.featureName)
      };
    });
  };

  showCircuitLabsFeature = (featureName: string) => this.CircuitLabSvc.showCircuitLabsFeature(featureName);

  setCircuitLabsFeature = (feature: FeatureItem, enabled: MatSlideToggleChange) => {
    const { featureName } = feature;
    const { checked } = enabled;
    const isReloadRequired = this.CircuitLabSvc.isReloadRequired(featureName);

    if (!isReloadRequired) {
      this.selectedFeatures[featureName] = {
        enabled: checked
      };
      this.CircuitLabSvc.setFeature(featureName, checked);
      return;
    }
    this.PopupSvc.confirm({
      message: 'res_LabsConfirmReloadMessage',
      title: 'res_LabsConfirmReloadTitle',
      size: 'small',
      noLabel: 'res_Cancel'
    })
      .result
      .then(() => {
        this.selectedFeatures[featureName] = { enabled: checked };
        this.CircuitLabSvc.setFeature(featureName, checked);
      })
      .catch(() => {
        this.LogSvc.info('[LabsTabComponent]: User declined feature change: ', featureName);
        this.selectedFeatures[featureName] = { enabled: !checked };
      });
  };
}
