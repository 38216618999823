<div class="header-transfer-container">
    <button mat-icon-button class="back-btn" uAriaLabel="res_Back" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <h2 class="mat-display-1 transfer-title" uLocalize="res_TransferCallTo"></h2>
</div>

<div class="transfer-wrapper">
    <app-search-users class="dial-input-wrapper"
                        [ariaLabel]="root.i18n.map.res_AccessibilityTransferCallInput"
                        [value]="value"
                        (searchContact)="searchContact($event)"
                        (inputValue)="setValue($event)"
                        (isSearchable)="isSearchable($event)"></app-search-users>

    <div class="contact-button-wrapper">
        <app-contacts *ngIf="value && isValueSearchable" [searchTerm]="value.trim()"
                      (contactSelected)="transferCall($event.phoneNumber)"></app-contacts>

        <div class="transfer-action" [ngSwitch]="waitingTransferResponse">
            <span class="spinner">
                <mat-spinner *ngSwitchCase="true" [diameter]="24" uAriaLabel="res_AccessibilityLoading"></mat-spinner>
            </span>
            <button class="transfer-btn" mat-raised-button color="accent" (click)="transferCall(value)"
                [disabled]="!isDialable || waitingTransferResponse || !value.trim()">
                <mat-icon class="material-icons-outlined md-20 icon-transfer-icon"></mat-icon>
                <span uLocalize="res_Transfer"></span>
            </button>
        </div>
    </div>
</div>
