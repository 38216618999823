import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';

declare let Circuit: any;

@Component({
  selector: 'app-photo-booth',
  templateUrl: './photo-booth.component.html',
  styleUrls: ['./photo-booth.component.scss']
})
export class PhotoBoothComponent implements OnInit, OnDestroy {

  CUT_OUT_SIZES = Object.freeze({
    s: 80,
    b: 250
  });

  /////////////////////////////////////////////////////////////////////////
  // Initialization
  /////////////////////////////////////////////////////////////////////////
  private LogSvc;
  private PictureEditSvc;
  private _streaming: boolean | undefined;
  private _width!: any;
  private _height: any;
  private _videoRatio: any;
  private _pictureIndex: any;
  private _pictureTimer: any;

  root: any;
  images: Array<any> = [];
  selectedImage!: any;
  videoError = false;
  stream: any = null;
  previewImage: any = null;
  countDown: any;

  @ViewChild('video') video!: ElementRef;
  @ViewChild('canvas') canvas!: ElementRef;
  @Output('images') parentSelectedImage: EventEmitter<any> = new EventEmitter();

  constructor(private commonBlService: CommonBLService, private cdr: ChangeDetectorRef) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PictureEditSvc = Circuit.serviceInstances.pictureEditSvc;
  }

  ngOnInit(): void {
    this.LogSvc.info('[PhotoBoothComponent]: ngOnInit');
    this.root = this.commonBlService.getRootScopeData();
    this._streaming = false;
    this._pictureIndex = -1;
  }

  ngOnDestroy(): void {
    this.stopStream();
    this.removeElements();
  }


  ngAfterViewInit() {
    /////////////////////////////////////////////////////////////////////////
    // Request Data
    /////////////////////////////////////////////////////////////////////////
    Circuit.WebRTCAdapter.getUserMedia({video: true, audio: false}, (stream: null) => {
      this.stream = stream;
      this.video.nativeElement.srcObject = stream;
      this.video.nativeElement.play();
    }, () => {
      this.videoError = true;
    });
    this.cdr.detectChanges();
  }

  private stopStream = async () => {
    if (this.stream) {
      try {
        this.LogSvc.debug('[PhotoBoothComponent]: Stopping old media stream');
        await Circuit.WebRTCAdapter.stopMediaStream(this.stream);
        this.stream = null;
      } catch (error) {
        this.LogSvc.error('[PhotoBoothComponent]: Error stopping media stream. ', error);
      }
      return true;
    }
    return false;
  };

  private removeElements() {
    this.canvas?.nativeElement?.remove();
    this.video.nativeElement = null;
    this.canvas.nativeElement = null;
  }

  takePicture = () => {
    this.LogSvc.info('[PhotoBoothComponent]: takePicture');
    if (!this.stream) {
      this.LogSvc.info('[PhotoBoothComponent]: takePicture - no stream provided');
      return;
    }

    if (this.previewImage) {
      this.previewImage = null;
      return;
    }

    if (this._pictureTimer) {
      clearTimeout(this._pictureTimer);
      this._pictureTimer = null;
    }

    const countDown = () => {
      if (this.countDown > 1) {
        this.countDown--;
        setTimeout(countDown, 1000);
      } else if (this.countDown === 1) {
        this.flash();
        setTimeout(this.takePictureInternal, 50);
        this.countDown = null;
        this._pictureTimer = null;
      }
    };
    this.countDown = 4;
    countDown();

  };

  setSelectedImage = (img: any) => {
    this.previewImage = img.b;
    this.selectedImage = img;
    this.parentSelectedImage.emit(img);
  };

  private flash = () => {
    this.LogSvc.info('[PhotoBoothComponent]: flash');
    let backdrop = document.querySelector('.cdk-overlay-backdrop');
    this.css(backdrop, {
      'animation': 'flash 0.5s',
      'z-index': 9999
    });

    setTimeout(() => {
      this.css(backdrop, {animation: ''});
      backdrop = null;
    }, 600);

    setTimeout(() => {
      this.css(backdrop, {'z-index': ''});
    }, 100);
  };

  private css = (element: any, style: any) => {
    // eslint-disable-next-line guard-for-in
    for (const property in style) {
      element.style[property] = style[property];
    }
  };

  private takePictureInternal = () => {
    this.canvas.nativeElement.width = this.canvas.nativeElement.height = this.CUT_OUT_SIZES.b;
    const context = this.canvas.nativeElement.getContext('2d', { willReadFrequently: true });
    context.scale(1, 1);
    context.drawImage(this.video.nativeElement, Math.round((this.canvas.nativeElement.width - this._width) / 2), 0, this._width, this._height);
    this._pictureIndex = (this._pictureIndex + 1) % 4;
    this.images[this._pictureIndex] = {
      b: this.canvas.nativeElement.toDataURL('image/jpeg'),
      s: this.PictureEditSvc.resizeImage(this.canvas.nativeElement, this.CUT_OUT_SIZES.s)
    };
    this.setSelectedImage(this.images[this._pictureIndex]);
  };

  handleCanPlay = () => {
    if (!this._streaming) {
      // This code assumes a wide aspect ratio
      this._height = this.CUT_OUT_SIZES.b;
      this._videoRatio = this.video.nativeElement.videoHeight / this._height;
      this._width = this.video.nativeElement.videoWidth / this._videoRatio;

      this.video.nativeElement.setAttribute('width', this._width);
      this.video.nativeElement.setAttribute('height', this._height);
      this.canvas.nativeElement.setAttribute('width', this._width);
      this.canvas.nativeElement.setAttribute('height', this._height);
      this._streaming = true;
    }
  };

}
