import { Component, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { Tabs } from '../../shared/tabs';

declare let Circuit: any;

@Component({
  selector: 'app-tab-container',
  templateUrl: './tab-container.component.html',
  styleUrls: ['./tab-container.component.scss']
})
export class TabContainerComponent implements OnInit {
  root: any;
  Tabs: Tabs;
  tabs: any[];

  constructor(private commonBlService: CommonBLService) {
    this.Tabs = new Tabs();
    this.tabs = this.Tabs.getTabsArray();
  }

  ngOnInit(): void {
    this.root = this.commonBlService.getRootScopeData();
  }
}
