import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';

declare let Circuit: any;

const INITIAL_DELAY = 1000;

const PLANTRONIC_STATE = {
  NO_DEVICE_FOUND: 0,
  PLANTRONICS_CONNECTION_ERROR: 1,
  CONNECTED_DEVICE: 2,
  AUTO_CONNECT_RETRY: 3,
}

@Component({
  selector: 'app-plantronics-integration',
  templateUrl: './plantronics-integration.component.html',
  styleUrls: ['./plantronics-integration.component.scss']
})
export class PlantronicsIntegration implements OnInit, OnDestroy {
  private LogSvc: any;
  private PubSubSvc: any;
  private PlantronicsControlSvc: any;

  i18n: any;
  _activeDevice: any = null;
  _connecting = false;
  deviceText: string = '';
  // plantronics
  plantronics: boolean = false;
  plantronicsState: number;
  plantronicsLink = ['https://www.poly.com/us/en/support/downloads-apps/hub-desktop'];

  constructor(private commonBlService: CommonBLService) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this.PlantronicsControlSvc = Circuit.serviceInstances.plantronicsControlSvc;
    this.plantronicsState = 0; // 0 (default) no device found
  }

  ngOnInit(): void {
    this.LogSvc.info('[PlantronicsIntegration]: ngOnInit');
    this.i18n = this.commonBlService.getRootScopeData().i18n;
    this.plantronics = this.isPlantronicsEnabled();
    this.PubSubSvc.subscribe('/language/update', this.onLanguageUpdate);
    this.PubSubSvc.subscribe('/headset/plantronics/connectSucceeded', this.init);
    this.init();
  }

  init() {
    if (!this.isPlantronicsEnabled()) {
        return;
    }

    if (this.isConnectionHubError()) {
      // error connecting with hub!!!!
      // status 3: diable integration
      this.plantronicsState = 3;
      this.getDeviceText();
      return;
    }
    // Wait a little before checking state and querying for active device
    this._connecting = true;
    setTimeout(() => {
        if (this.PlantronicsControlSvc.connected) {
            this.LogSvc.debug('[PlantronicsIntegration]: Client is connected to Plantronics Hub. Get active device.');
            this.plantronics = true;
            this.getPlantronicDevice();
        }
    }, INITIAL_DELAY);
  }

  onLanguageUpdate = () => {
    this.getDeviceText();
  };

  connect = () => {
    if (this.PlantronicsControlSvc.connected || this._connecting) {
        return;
    }

    this._connecting = true;
    this._activeDevice = null;
    return this.PlantronicsControlSvc.connect().then(() => {
      this.plantronics = true;
      this.getPlantronicDevice();
    }).catch((err: any)=> {
      this.LogSvc.warn('[PlantronicsIntegration]: Failed to connect to Plantronics Hub. ', err);
      // plantronics hub is not installed show error message
      this.plantronics = false;
      this.plantronicsState = 1;
      return this.getDeviceText();
    });
  };

  getDeviceText = () => {
    this._connecting = false;
    // status 0 (no value) no device found
    // status 1 (error): failed to connect (hub not installed)
    // status 2 (success): device found
    // status 3 (error): connection error with auto retry (keeps connecting with ability to disable integration)
    switch (this.plantronicsState) {
      case PLANTRONIC_STATE.NO_DEVICE_FOUND: {
        this.deviceText = this.i18n.localize('res_PlantronicsNoDeviceFound');
          break;
      }
      case PLANTRONIC_STATE.PLANTRONICS_CONNECTION_ERROR: {
        this.deviceText = this.i18n.localize('res_PlantronicsHubConnectionError');
          break;
      }
      case PLANTRONIC_STATE.CONNECTED_DEVICE: {
        this.deviceText = this._activeDevice?.ProductName ?
        this.i18n.localize('res_ConnectedTo', [this._activeDevice.ProductName]) : this.i18n.map.res_Connected;
          break;
      }
      case PLANTRONIC_STATE.AUTO_CONNECT_RETRY: {
        this.deviceText = this.i18n.localize('res_AutoConnectExtensionsLabel');
          break;
      }
    }
  }

  getPlantronicDevice = async () => {
    try {
      const activeDevice = await this.PlantronicsControlSvc.getActiveDevice();
      this.LogSvc.info('[PlantronicsIntegration]: Successfully connected to Plantronics Hub. Active device = ', activeDevice);
      if (activeDevice == null) {
        // no devices detected
        this.plantronicsState = 0;
      }
      if (activeDevice) {
        // found device
        this.plantronicsState = 2;
        this._activeDevice = activeDevice;
      }
      return this.getDeviceText();

    } catch(error: any) {
      this.LogSvc.error('[PlantronicsIntegration]: Failed to retrieve active Plantronics device.', error);
      // error show no devices detected
      this.plantronicsState = 0;
      return this.getDeviceText();
    }
  }

  togglePlantronics = () => {
    this.plantronicsState = 0;
    if (!this.isPlantronicsEnabled()) {
      this.connect();
    } else {
      if (!this.isConnectionHubError()) {
        this.disconnect();
      } else {
        // connectionError user can disable integration
        return this.disableIntegration();
      }
    }
  }

  isPlantronicsEnabled = () => this.PlantronicsControlSvc.isIntegrationEnabled();

  isConnectionHubError = () => this.PlantronicsControlSvc.connectionError && this.isPlantronicsEnabled();

  disconnect = () => {
    this.plantronics = false;
    this._activeDevice = null;
    this.PlantronicsControlSvc.disconnect();
  };

  disableIntegration = () => {
    this.plantronics = false;
    this.PlantronicsControlSvc.disableIntegration();
  };

  ngOnDestroy() {
    this.LogSvc.info('[PlantronicsIntegration]: ngOnDestroy');
    this.PubSubSvc.unsubscribe('/language/update', this.onLanguageUpdate);
    this.PubSubSvc.unsubscribe('/headset/plantronics/connectSucceeded', this.init);
  }
}
