<div class="item">
    <div class="audio-header">
      <h3 class="title" [uLocalizeOnce]="'res_Ringtone'"></h3>
      <app-test-audio-device [deviceCategory]="category" [ringtone]="selectedRingtone" ignoreOutputSelectionNotSupported="'not-supported'"></app-test-audio-device>
    </div>
    <p [uLocalizeOnce]="'res_RingtonesDescription'"></p>
    <mat-form-field appearance="outline" class="select">
      <mat-label class="accessibility-element" [uLocalizeOnce]="'res_Ringtone'"></mat-label>
      <mat-select
        [(ngModel)]="selectedRingtone"
        name="ringtone"
        data-autotest="dropdown['ringtone']"
        [disabled]="ringtoneList.length === 0"
      >
        <mat-select-trigger>
          <span *ngIf="ringtoneList.length > 0">
            {{root.i18n.localize('res_'+selectedRingtone)}}
          </span>
        </mat-select-trigger>
      <ng-container>
      </ng-container>
      <ng-container *ngIf="ringtoneList.length > 0">
        <mat-option
          *ngFor="let ringtone of ringtoneList"
          [value]="ringtone"
          (click)="onSelectRingtone(ringtone)"
          (onSelectionChange)="onSelectRingtone(ringtone)"
          >
          {{root.i18n.localize('res_'+ringtone)}}
        </mat-option>
      </ng-container>
      </mat-select>
    </mat-form-field>
    <mat-divider class="divider"></mat-divider>
  </div>
