<div *ngIf="!hasViewOpen" class="other-person-details">
  <div class="call-state">
    <span class="mat-body-2" [ngClass]="{'call-state-warn': (call?.state?.css === 'declined' || call?.state?.css === 'failed' || call?.state?.css === 'busy'),
                'call-state-accent' : call?.state?.css === 'active-call-remote'}">
      {{ showCallStateUi() ? root.i18n.map[call.state.ui]: '' }}
    </span>
    <div class="loader" *ngIf="call?.state.css === 'outgoing'">
      <div class="ringing-animation medium light-dark-background">
        <div class="one"></div>
        <div class="two"></div>
        <div class="three"></div>
      </div>
    </div>
    <span *ngIf="call?.state?.ui &&
                (call?.state.css==='answering' ||
                call?.state.css==='waiting' ||
                call?.state.css==='holding-call'||
                call?.state.css==='active-call') &&
                call.establishedTime"> &nbsp; - &nbsp; </span>
    <span class="mat-body-2 elapsed-time" [uElapsedTime]="call.establishedTime"
          *ngIf="call?.state.css==='answering' ||
                call?.state.css==='waiting' ||
                call?.state.css==='active-call' ||
                call?.state.css==='holding-call'">
    </span>
    <button mat-button *ngIf="!call.isRemote && call.state.name == 'Active' && !hasAnyCallInTransientState()"
      uAriaLabel="res_CallMetrics" matTooltip='{{root.i18n.localize("res_CallMetrics")}}'
      [matTooltipShowDelay]="500" data-autotest="callQuality" (click)="openDialog()" class="call-quality-btn circle-button small">
      <span class="call-quality-icon" [ngClass]="call.networkQuality ? call.networkQuality.levelName : 'stats-loading'"></span>
    </button>
  </div>
  <div *ngIf="!!call.redirectingUser && (call?.state.css === 'incoming' || call?.isRemote )" class='call-forwarded'>
    <mat-icon *ngIf="call?.forwarded" class="material-icons-outlined md-15">phone_forwarded</mat-icon>
    <mat-icon *ngIf="call.pickupNotification || call.pickedUp" class="material-icons-outlined md-15 icon-pickup-group-icon"></mat-icon>
    <span class="mat-caption fwd-name">
      {{root.i18n.localize(call.redirectingUser?.redirectionType?.ui, [getRedirectingUserUiStr(call.redirectingUser)])}}
    </span>
  </div>
  <div class="mat-display-1 other-person-number">
    <mat-icon class="material-icons md-20"
              *ngIf="call?.forwarded && !(call?.state.css === 'incoming' || call?.isRemote)"
              matTooltip="{{root.i18n.localize(call.redirectingUser?.redirectionType?.ui, [getRedirectingUserUiStr(call.redirectingUser)])}}"
              >phone_forwarded</mat-icon>
    <mat-icon class="material-icons-outlined md-20 icon-pickup-group-icon"
              *ngIf="call.pickedUp && !(call?.state.css === 'incoming' || call?.isRemote)"
              matTooltip="{{root.i18n.localize(call.redirectingUser?.redirectionType?.ui, [getRedirectingUserUiStr(call.redirectingUser)])}}">
    </mat-icon>
    <span *ngIf="call?.participants?.length <= 1 && !call?.isDirectUpgradedToConf">
      {{call?.peerUser.phoneNumber}}
    </span>
    <div *ngIf="call?.participants?.length > 1 || call?.isDirectUpgradedToConf" class="call-title">
      <mat-icon class="material-icons-outlined md-25">call_merge</mat-icon>
      <span>{{ getCallTitleUiStr(call) }}</span>
    </div>
  </div>
  <div class="mat-body-2 call-participants">
    <span matTooltip="{{getCallStateUiStr(call)}}">{{getCallStateUiStr(call)}}</span>
  </div>
</div>

<div *ngIf="!hasViewOpen" class="secondary-call-buttons-wrapper">
  <!-- Call Actions Start Local Call ------------------------------------------------------->
  <div class="secondary-call-buttons" *ngIf="isLocalCallActive(call.callId)">
    <div class="first-row">
      <div class="center">
        <button mat-button class="circle-button" [ngClass]="{'active': call?.isMuted()}"
          [attr.aria-label]="call?.isMuted() ? root.i18n.map.res_AccessibilityUnmute : root.i18n.map.res_AccessibilityMute"
          (click)="toggleMute(call)">
          <mat-icon class="material-icons md-26">mic_off</mat-icon>
        </button>
        <span class="mat-caption" uLocalize="res_Mute"></span>
      </div>
      <div class="center">
        <button mat-button [disabled]="!canInitiatePhoneCall() || dtmfDisabled"
          class="circle-button option-grey"
          uAriaLabel="res_Dtmf"
          (click)="toggleView(callView.DTMF)"
          [ngClass]="{'active': hasDtmfView}">
          <mat-icon class="material-icons-outlined md-26">dialpad</mat-icon>
        </button>
        <span class="mat-caption"
              [ngClass]="{'disabled': !canInitiatePhoneCall() || dtmfDisabled}"
              [attr.aria-disabled]="!canInitiatePhoneCall() || dtmfDisabled"
              uLocalize="res_Dtmf">
        </span>
      </div>
      <!-- Hold Call -->
      <div class="center" *ngIf="canHoldCall(call?.callId)" [ngSwitch]="hasAnyCallInTransientState()">
        <button mat-button
                [disabled]="call?.state?.css !== 'active-call'"
                *ngSwitchCase="false"
                class="circle-button hold option-grey"
                (click)="holdCall(call)"
                uAriaLabel="res_HoldCall"
                data-autotest="holdCall">
          <mat-icon class="material-icons-outlined md-26">pause</mat-icon>
        </button>
        <div *ngSwitchCase="true" class="circle-button hold" uAriaLabel="res_AccessibilityLoading">
          <div class="loading-wrapper">
            <mat-spinner [diameter]="24"></mat-spinner>
          </div>
        </div>
        <span class="mat-caption"
              [ngClass]="{'disabled': call?.state?.css !== 'active-call'}"
              [attr.aria-disabled]="call?.state?.css !== 'active-call'"
              uLocalize="res_HoldCall">
        </span>
      </div>
      <!-- Retrieve Hold Only  -->
      <div class="center" *ngIf="canRetrieveCall(call?.callId)" [ngSwitch]="hasAnyCallInTransientState()">
        <button mat-button *ngSwitchCase="false"
                class="circle-button hold-only retrieve active"
                uAriaLabel="res_RetrieveCall"
                (click)="retrieveCall(call)"
                data-autotest="retrieveCall">
          <mat-icon class="material-icons-outlined md-26">pause</mat-icon>
        </button>
        <div *ngSwitchCase="true" class="circle-button hold-only" uAriaLabel="res_AccessibilityLoading">
          <div class="loading-wrapper">
            <mat-spinner [diameter]="24"></mat-spinner>
          </div>
        </div>
        <span class="mat-caption" uLocalize="res_HoldCall"></span>
      </div>
    </div>
    <div class="second-row">
      <!-- Call Transfer  -->
      <div [ngSwitch]="hasMultipleLocalCalls(true)" *ngIf="consultationAvailable">
        <div class="center" *ngSwitchCase="false">
          <button mat-button
                  class="circle-button transfer-btn"
                  (click)="toggleView(callView.TRANSFER)"
                  [disabled]="!canTransferCall(call.callId)"
                  uAriaLabel="res_Transfer">
            <mat-icon class="material-icons-outlined icon-transfer-icon"></mat-icon>
          </button>
          <span class="mat-caption"
                [ngClass]="{'disabled': !canTransferCall(call.callId)}"
                [attr.aria-disabled]="!canTransferCall(call.callId)"
                uLocalize="res_Transfer">
          </span>
        </div>
        <div class="center" *ngSwitchCase="true">
          <button mat-button
                  class="circle-button transfer-btn"
                  (click)="completeTransfer()"
                  [disabled]="!canConsultTransfer(call.callId)"
                  uAriaLabel="res_Transfer"
                  matTooltip="{{root.i18n.map.res_CallControlToolTipCompleteTransfer}}"
                  [matTooltipShowDelay]="1000">
            <mat-icon class="material-icons-outlined icon-transfer-icon"></mat-icon>
          </button>
          <span class="mat-caption"
                [ngClass]="{'disabled': !canConsultTransfer(call.callId)}"
                [attr.aria-disabled]="!canConsultTransfer(call.callId)"
                uLocalize="res_Transfer">
          </span>
        </div>
      </div>
      <!-- New call -->
      <div class="center" *ngIf="consultationAvailable && canInitiateConsultationLocalCall()">
        <button mat-button
                class="circle-button consultation"
                (click)="toggleView(callView.NEWCALL)"
                uAriaLabel="res_NewCall"
                [disabled]="!canInitiateConsultationLocalCall()"
                data-autotest="makeConsultation">
          <mat-icon class="md-26">add_ic_call</mat-icon>
        </button>
        <span class="mat-caption"
              [ngClass]="{'disabled': !canInitiateConsultationLocalCall()}"
              [attr.aria-disabled]="!canInitiateConsultationLocalCall()"
              uLocalize="res_NewCall">
        </span>
      </div>
      <!-- Merge -->
      <div class="center">
        <button mat-button
              *ngIf="mergeAvailable"
              class="circle-button merge"
              [disabled]="!canMergeCall(call?.callId)"
              (click)="mergeCall(call)"
              uAriaLabel="res_CallControlToolTipMerge">
          <mat-icon class="material-icons-outlined md-26">call_merge</mat-icon>
        </button>
        <span class="mat-caption"
              [ngClass]="{'disabled': !canMergeCall(call?.callId)}"
              [attr.aria-disabled]="!canMergeCall(call?.callId)"
              uLocalize="res_MergeCall">
        </span>
      </div>
    </div>
  </div>
  <!-- Call Actions End Local Call--------------------------------------------------------->
  <!-- Call Actions Start Remote Call ------------------------------------------------------->
  <div class="secondary-call-buttons" *ngIf="isRemoteCallActive(call.callId)">
    <div class="first-row">
      <div class="center">
        <!-- Place holder to keep space on the left -->
      </div>
      <div class="center">
        <button mat-button [disabled]="!canInitiatePhoneCall() || dtmfDisabled"
                class="circle-button option-grey"
                uAriaLabel="res_Dtmf"
                (click)="toggleView(callView.DTMF)"
                [ngClass]="{'active': hasDtmfView}">
          <mat-icon class="material-icons-outlined md-26">dialpad</mat-icon>
        </button>
        <span class="mat-caption"
              [ngClass]="{'disabled': !canInitiatePhoneCall() || dtmfDisabled}"
              [attr.aria-disabled]="!canInitiatePhoneCall() || dtmfDisabled"
              uLocalize="res_Dtmf">
        </span>
      </div>
      <!-- Hold Call -->
      <div class="center" *ngIf="canHoldCall(call?.callId)" [ngSwitch]="hasAnyCallInTransientState()">
        <button mat-button *ngSwitchCase="false"
                class="circle-button hold option-grey"
                (click)="holdCall(call)"
                uAriaLabel="res_HoldCall"
                data-autotest="holdCall">
          <mat-icon class="material-icons-outlined md-26">pause</mat-icon>
        </button>
        <div *ngSwitchCase="true" class="circle-button hold" uAriaLabel="res_AccessibilityLoading">
          <div class="loading-wrapper">
            <mat-spinner [diameter]="24"></mat-spinner>
          </div>
        </div>
        <span class="mat-caption" uLocalize="res_HoldCall"></span>
      </div>
      <!-- Retrieve Hold Only  -->
      <div class="center" *ngIf="canRetrieveCall(call?.callId)" [ngSwitch]="hasAnyCallInTransientState()">
        <button mat-button *ngSwitchCase="false"
                class="circle-button hold-only retrieve active"
                uAriaLabel="res_RetrieveCall"
                (click)="retrieveCall(call)"
                data-autotest="retrieveCall">
          <mat-icon class="material-icons-outlined md-26">pause</mat-icon>
        </button>
        <div *ngSwitchCase="true" class="circle-button hold-only" uAriaLabel="res_AccessibilityLoading">
          <div class="loading-wrapper">
            <mat-spinner [diameter]="24"></mat-spinner>
          </div>
        </div>
        <span class="mat-caption" uLocalize="res_HoldCall"></span>
      </div>
    </div>
    <div class="second-row">
      <!-- Call Transfer  -->
      <div [ngSwitch]="hasMultipleRemoteCalls()" *ngIf="consultationAvailable">
        <div class="center" *ngSwitchCase="false">
          <button mat-button
                  class="circle-button transfer-btn"
                  (click)="toggleView(callView.TRANSFER)"
                  [disabled]="!canTransferCall(call.callId)"
                  uAriaLabel="res_Transfer">
            <mat-icon class="material-icons-outlined icon-transfer-icon"></mat-icon>
          </button>
          <span class="mat-caption"
                [ngClass]="{'disabled': !canTransferCall(call.callId)}"
                [attr.aria-disabled]="!canTransferCall(call.callId)"
                uLocalize="res_Transfer">
          </span>
        </div>
        <div class="center" *ngSwitchCase="true">
          <button mat-button
                  class="circle-button transfer-btn"
                  (click)="completeTransfer()"
                  [disabled]="!canConsultTransfer(call.callId)"
                  uAriaLabel="res_Transfer"
                  matTooltip="{{root.i18n.map.res_CallControlToolTipCompleteTransfer}}"
                  [matTooltipShowDelay]="1000">
            <mat-icon class="material-icons-outlined icon-transfer-icon"></mat-icon>
          </button>
          <span class="mat-caption"
                [ngClass]="{'disabled': !canConsultTransfer(call.callId)}"
                [attr.aria-disabled]="!canConsultTransfer(call.callId)"
                uLocalize="res_Transfer">
          </span>
        </div>
      </div>
      <!-- New call -->
      <div class="center" *ngIf="consultationAvailable && canInitiateConsultationRemoteCall()">
        <button mat-button
                class="circle-button consultation"
                (click)="toggleView(callView.NEWCALL)"
                [disabled]="!canInitiateConsultationRemoteCall()"
                uAriaLabel="res_NewCall"
                data-autotest="makeConsultation">
          <mat-icon class="md-26">add_ic_call</mat-icon>
        </button>
        <span class="mat-caption"
              [ngClass]="{'disabled': !canInitiateConsultationRemoteCall()}"
              [attr.aria-disabled]="!canInitiateConsultationRemoteCall()"
              uLocalize="res_NewCall">
        </span>
      </div>
      <!-- Merge -->
      <div class="center">
        <button mat-button
              *ngIf="mergeAvailable"
              class="circle-button merge"
              [disabled]="!canMergeCall(call?.callId)"
              (click)="mergeCall(call)"
              uAriaLabel="res_CallControlToolTipMerge">
          <mat-icon class="material-icons-outlined md-26">call_merge</mat-icon>
        </button>
        <span class="mat-caption"
              [ngClass]="{'disabled': !canMergeCall(call?.callId)}"
              [attr.aria-disabled]="!canMergeCall(call?.callId)"
              uLocalize="res_MergeCall">
        </span>
      </div>
    </div>
  </div>
  <!-- Call Actions End Remote Call--------------------------------------------------------->
</div>

<div class="primary-call-buttons" *ngIf="!hasTransferView && !hasNewCallView" [ngClass]="{'remote': call?.isRemote}">
  <!-- hangup or decline button -->
  <div *ngIf="isHangupCallAvailable(call?.callId) || (canAnswerCall(call?.callId) && declineDevices.length > 0)" matTooltip="{{ !!call && canHangupCall(call?.callId) ? '' : root.i18n.map.res_OSBizCannotEndCall}}" class="btn-wrapper">
    <button mat-fab color="warn"
            class="end-call-btn"
            [disabled]="!canHangupCall(call?.callId)"
            *ngIf="!!call && isHangupCallAvailable(call?.callId)"
            matTooltip="{{canHangupCall(call?.callId) ? '' : root.i18n.map.res_OSBizCannotEndCall}}"
            [attr.aria-label]="call?.state?.css !=='incoming' ? root.i18n.map.res_AccessibilityHangup : root.i18n.map.res_AccessibilityDecline"
            (click)="endCall(call)">
      <mat-icon>call_end</mat-icon>
    </button>
    <!-- We only want to display decline to voicemail when receiving a call -->
    <app-push-incoming-call *ngIf="canAnswerCall(call?.callId) && declineDevices.length > 0 && !canPickupCall(call.callId)"
                            class="left"
                            [call]="call"
                            [devices]="declineDevices"
                            [color]="'warn'"
                            [title]="'res_SendTo'"
                            [btnLabel]=""
                            [ariaLabel]="'res_Decline'"
                            [tooltipText]="'res_Decline'">
      </app-push-incoming-call>
  </div>

  <!-- Ignore pickup call button -->
  <button mat-fab color="ignore"
          class="ignore-call-btn"
          matTooltip="{{root.i18n.map.res_IgnoreCall}}"
          *ngIf="canPickupCall(call.callId)"
          (click)="ignoreCall(call)"
          uAriaLabel="res_IgnoreCall"
          data-autotest="ignorePickupNotification"
          [matTooltipShowDelay]="1000">
    <mat-icon class="md-26">close</mat-icon>
  </button>

  <!-- Pull Remote Call -->
  <button mat-fab color="green" class="pull-remote-call" uAriaLabel="res_AccessibilityPullCall"
    *ngIf="canPullRemoteCall(call?.callId)" (click)="pullRemoteCall(call?.callId)"
    matTooltip="{{root.i18n.map.res_PullCallTooltip}}" matTooltipPosition="above">
    <mat-icon class="icon-pull-icon"></mat-icon>
  </button>

  <!-- Push Call -->
  <div *ngIf="canPushLocalCall(call?.callId)" class="btn-wrapper">
    <button mat-fab color="green" class="end-call-btn"
            [attr.aria-label]="root.i18n.localize('res_AccessibilityPushCall', [root.i18n.map[defaultPushDevice.value.ui]])"
            (click)="pushLocalCall(call.callId, defaultPushDevice.value.id)">
      <mat-icon [class]="defaultPushDevice.value.icon">{{defaultPushDevice.value.icon !== 'icon-deskphone-icon' ? defaultPushDevice.value.icon : ''}}</mat-icon>
    </button>
    <!-- pushDevices should be > 1 -->
    <app-push-incoming-call *ngIf="pushDevices && pushDevices.length > 1"
                            class="right"
                            [action]="'pushLocalCall'"
                            [call]="call"
                            [devices]="pushDevices"
                            [color]="'green'"
                            [title]="'res_PushCallDropdownLabel'"
                            [btnLabel]=""
                            [ariaLabel]="'res_PushCall'"
                            [tooltipText]="'res_PushCall'">
    </app-push-incoming-call>
  </div>

  <!-- Incoming Calls (Voice) -->
  <div *ngIf="canAnswerCall(call?.callId) && defaultAnswerDevice.value.id !== 'VM'" class="btn-wrapper">
    <button mat-fab color="green"
            class="end-call-btn"
            uAriaLabel="res_AccessibilityAnswerCall"
            (click)="answerCall(call?.callId)"
            data-autotest="answerVoice">
      <mat-icon [class]="defaultAnswerDevice.value.icon">{{defaultAnswerDevice.value.icon !== 'icon-deskphone-icon' ? defaultAnswerDevice.value.icon : ''}}</mat-icon>
    </button>
    <app-push-incoming-call *ngIf="answerDevices.length > 0"
                            class="right"
                            [action]="'answerCall'"
                            [call]="call"
                            [devices]="answerDevices"
                            [color]="'green'"
                            [title]="'res_AnswerWith'"
                            [btnLabel]=""
                            [ariaLabel]="'res_Answer'"
                            [tooltipText]="'res_Answer'">
      </app-push-incoming-call>
  </div>
</div>
