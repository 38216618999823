import { Component, Input, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';

@Component({
  selector: 'app-empty-screen-item',
  templateUrl: './empty-screen-item.component.html',
  styleUrls: ['./empty-screen-item.component.scss']
})
export class EmptyScreenItemComponent implements OnInit {
  @Input() imageName!: string;
  @Input() imageTitle!:string;
  @Input() imageType!:string;
  @Input() pageTitle!:string;
  @Input() subtitle!:string;

  constructor() { }

  ngOnInit(): void {}

}
