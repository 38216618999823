import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { DialogService } from '../../sub-modules/dialog/dialog.service';
import { Tabs } from '../../shared/tabs';

declare let Circuit: any;

@Component({
  selector: 'app-tab-view',
  templateUrl: './tab-view.component.html',
  styleUrls: ['./tab-view.component.scss']
})
export class TabViewComponent implements OnInit, OnDestroy {
  private LogSvc: any;
  private PubSubSvc: any;
  private PhoneCallSvc: any;
  root: any;
  Tabs: Tabs;
  tabs: any[];

  phoneCalls: any;
  phoneCallsInitialized = false;

  constructor(private commonBlService: CommonBLService, private dialogService: DialogService) {
    this.Tabs = new Tabs();
    this.tabs = this.Tabs.getTabsArray();
    this.PhoneCallSvc = Circuit.serviceInstances.phoneCallSvc;
  }

  ngOnInit(): void {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.LogSvc.info('[TabViewComponent]: ngOnInit');
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this.root = this.commonBlService.getRootScopeData();

    this.PubSubSvc.subscribe('/call/ended', this.onEndCall);
    this.PubSubSvc.subscribe('/conversation/update', this.onConversationUpdate);
    this.PubSubSvc.subscribe('/telephony/phoneCalls/init', this.onPhoneCallsInit);
  }

  ngOnDestroy(): void {
    this.LogSvc.info('[TabViewComponent]: ngOnDestroy');
    this.PubSubSvc.unsubscribe('/call/ended', this.onEndCall);
    this.PubSubSvc.unsubscribe('/conversation/update', this.onConversationUpdate);
    this.PubSubSvc.unsubscribe('/telephony/phoneCalls/init', this.onPhoneCallsInit);
  }

  private onEndCall = () => {
    this.LogSvc.debug('[TabViewComponent]: Received /call/ended event');
    this.phoneCalls = this.PhoneCallSvc.getPhoneCalls();
    //we need to unset inFullCallView when there is no call.
    if (this.phoneCalls.length === 0) {
      this.root.inFullCallView = null;
    }
  };

  private onConversationUpdate = (c: any) => {
    this.LogSvc.debug('[TabViewComponent]: Received /conversation/update event');
    // Always show incoming call in full view
    if (c.call && c.call.state.css === 'incoming') {
      this.root.inFullCallView = true;
      if (this.root.inMiniCallView) {
        this.root.inMiniCallView = false;
      }
      this.dialogService.closeActiveDialog();
      return;
    }
    // When call is not incoming and back to call bar is visible, keep showing back to call bar
    if (c.call && this.root.inMiniCallView) {
      this.root.inFullCallView = false;
      return;
    }

    // In all other cases, show full call view and hide back to call bar
    if (c.call) {
      this.root.inFullCallView = true;
      this.root.inMiniCallView = false;
      this.dialogService.closeActiveDialog();
    }
  };

  private onPhoneCallsInit = () => {
    this.LogSvc.debug('[TabViewComponent]: Received /telephony/phoneCalls/init event');
    this.phoneCallsInitialized = true;
  };
}
