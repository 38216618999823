import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { DialogService } from '../../sub-modules/dialog/dialog.service';

declare let Circuit: any;

const MASKED_DTMF_DIGIT = '*';
const MASK_DIGIT_DELAY = 100;

@Component({
  selector: 'app-dtmf',
  templateUrl: './dtmf.component.html',
  styleUrls: ['./dtmf.component.scss']
})
export class DtmfComponent implements OnInit, OnDestroy {
  private LogSvc: any;
  private PhoneCallSvc: any;

  @Output('parentToggleDtmf') parentToggleDtmf: EventEmitter<any> = new EventEmitter();
  @Input() call!: any;
  @ViewChild('dtmfInput') private inputRef: any;

  value: any = '';
  root: any;
  showDtmfDigits = false;

  private _dialedDtmfDigits = ''; // Here we save the actual digits, so we can show them if needed

  constructor(
    private commonBlService: CommonBLService,
    private dialogService: DialogService) {
    this.call = null;
  }

  ngOnInit(): void {
    const serviceInstances = Circuit.serviceInstances;
    this.LogSvc = serviceInstances.logSvc;
    this.LogSvc.info('[DTMFComponent]: ngOnInit');
    this.PhoneCallSvc = serviceInstances.phoneCallSvc;
    this.root = this.commonBlService.getRootScopeData();
  }

  goBack() {
    this.parentToggleDtmf.emit('Dtmf');
    this.value = this.getMaskedDigits();
    this.showDtmfDigits = false;
  }

  change(event: any) {
    // ngModel doesn't detect instantly changes so
    // we need to trigger moveCaret whenever we detect change on input
    this.moveCaret(event.target.value);
  }

  toggleMaskedDigits() {
    this.showDtmfDigits = !this.showDtmfDigits;
    this.value = this.showDtmfDigits ? this._dialedDtmfDigits : this.getMaskedDigits();
  }

  type(value: string) {
    this.inputRef.nativeElement.focus();
    this.value += value;
    this.moveCaret(this.value);
    this.onDtmfDigits(value);
  }

  moveCaret(targetValue: any) {
    const input = this.inputRef.nativeElement;
    let valueLength = this.value.length;
    if (targetValue?.length) {
      valueLength = targetValue?.length;
    }
    setTimeout(function () {
      // Move caret to end of text
      input.setSelectionRange(valueLength + 1, valueLength + 1);
      input.scrollLeft = input.scrollWidth;
    });
  }

  onDtmfDigits(digits: any) {
    if (this.call?.callId && digits && digits !== '') {
      this._dialedDtmfDigits += digits;
      if (!this.showDtmfDigits) {
        this.showMaskedDigitsDelayed();
      }
      this.PhoneCallSvc.sendDigits(this.call.callId, digits)
        .catch((err: any) => {
          this.LogSvc.error('[DTMFComponent]: Failed to send DTMF digits. ', err);
        });
    }
  }

  showMaskedDigitsDelayed = () => {
    setTimeout(() => {
      this.value = this.getMaskedDigits();
    }, MASK_DIGIT_DELAY);
  };

  getMaskedDigits() {
    return MASKED_DTMF_DIGIT.repeat(this._dialedDtmfDigits.length);
  }

  ngOnDestroy(): void {
    this.LogSvc.info('[DTMFComponent]: onDestroy');
  }
}
