import { Injectable } from '@angular/core';
import {
  init,
  webHidPairing,
  RequestedBrowserTransport,
  EasyCallControlFactory,
  ISingleCallControl,
  MuteState,
  IApi
} from '@gnaudio/jabra-js';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { CallActionService } from './call-action.service';
import { UtilsService } from './utils.service';

declare const Circuit:any;

@Injectable({
  providedIn: 'root'
})
export class JabraControlService {

  private HEADSET_TYPE = 'jabra';

  private LogSvc: any;
  private PubSubSvc: any;
  private CallControlSvc: any;
  private LocalStoreSvc: any;
  private rootScope: any;
  private CstaCallState: any;
  private Constants: any;

  //Call termination triggered by jabra controls
  private _jabraCallTermination = true;
  private _integrationEnabled = false;
  private _connectionError = false;
  private _activeCall: any;
  private _alertingCall: any;

  private jabra?: IApi | void;
  private deviceListSub: any = null;
  private callActiveSub: any = null;
  private muteSub: any = null;
  private onDisconnectSub : any = null;
  private currentCallControl?: ISingleCallControl;

  private startCallTimeout: any;
  private signalCallTimeout: any;

  constructor(private commonBlService: CommonBLService, private utilsService: UtilsService, private callActionSvc: CallActionService) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this.CallControlSvc = Circuit.serviceInstances.callControlSvc;
    this.LocalStoreSvc = Circuit.serviceInstances.localStoreSvc;
    this.rootScope = this.commonBlService.getRootScopeData();

    this.CstaCallState = Circuit.Enums.CstaCallState;
    this.Constants = Circuit.Constants;

    this.PubSubSvc.subscribeOnce('/localUser/init', () => {
      this.LogSvc.debug('[JabraControlService]: Received /localUser/init event');
      this.init();
    });
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  // Private Functions
  ///////////////////////////////////////////////////////////////////////////////////////

  // Actions that affect the device

  private muteMicrophone() {
    this.LogSvc.info('[JabraControlService]: Sending mute microphone to device');
    this.currentCallControl?.mute();
  }

  private unMuteMicrophone() {
    this.LogSvc.info('[JabraControlService]: Sending unmute microphone to device');
    this.currentCallControl?.unmute();
  }

  private startCall = async (mute?: boolean) => {
    this.LogSvc.info('[JabraControlService]: Sending start call to device');
    try {
      await this.currentCallControl?.startCall();
      // Device always starts unmuted
      // A timeout is needed to avoid loop
      mute && setTimeout(() => { this.muteMicrophone(); }, 500);
    } catch (err) {
      this.LogSvc.error('[JabraControlService]: Failed to start call', err);
    }
  };

  private endCall = async () => {
    this.LogSvc.info('[JabraControlService]: Sending end call to device');
    try {
      this._jabraCallTermination = false;
      await this.currentCallControl?.endCall();
      this.checkActiveAndStartCall();
    } catch (err) {
      this.LogSvc.error('[JabraControlService]: Failed to end call', err);
    }
  };

  private answerIncomingCall = async (/* call: any */) => {
    this.LogSvc.info('[JabraControlService]: Sending answer incoming call to device');
    this.clearAlertingCallId();
    try {
      await this.currentCallControl?.acceptIncomingCall();
    } catch (err) {
      this.LogSvc.error('[JabraControlService]: Failed to answer call', err);
    }
  };

  private rejectIncomingCall = () => {
    this.LogSvc.info('[JabraControlService]: Sending reject incoming call to device');
    try {
      this.currentCallControl?.rejectIncomingCall();
    } catch (err) {
      this.LogSvc.error('[JabraControlService]: Failed to reject call', err);
    }
  };

  private signalIncomingCall = async (call: any, forceRing = false) => {
    if ((this.rootScope.localUser.userPresenceState.state !== this.Constants.PresenceState.DND || forceRing) && !call.doNotRingOnHeadset) {
      this.LogSvc.info('[JabraControlService]: Sending signal incoming call to device');
      try {
        await this.currentCallControl?.signalIncomingCall();
      } catch (err) {
        this.LogSvc.error('[JabraControlService]: Failed to singal incoming call', err);
      }
    } else {
      this.LogSvc.debug('[JabraControlService]: Do not ring this call. Not sending incoming call notification to device');
    }
  };

  private onCallIncoming = async (call: any) => {
    this.LogSvc.info('[JabraControlService]: Received /call/incoming event');
    this.checkAndSignalIncomingCall(call, false);
  };

  private onCallEnded = async (endedCall: any, replaced: any) => {
    if (!endedCall) {
      return;
    }
    if (!this._integrationEnabled || endedCall.isRemote) {
      return;
    }

    this.LogSvc.info('[JabraControlService]: Received /call/ended event. Replaced:', !!replaced);

    if (this._alertingCall && endedCall.callId === this._alertingCall.callId) {
      this.LogSvc.debug('[JabraControlService]: Alerting call has been canceled');
      this.rejectIncomingCall();
      this.clearAlertingCallId();
    } else if (endedCall.sameAs(this._activeCall) && !replaced) {
      await this.endCall();
      this.clearActiveCallId();

      const call = this.CallControlSvc.getActiveCall();
      if (call && (call.isEstablished() || call.isOutgoingState())) {
        this.checkActiveAndStartCall();
      } else {
        // There is a racing issue where user is still on DND so we have to
        // force the ring on device
        this.checkAndSignalIncomingCallDelayed(true);
      }
    } else {
      await this.endCall();
    }
  };

  private onCallLocalUserMuted = (callId: any, remotelyMuted: any, locallyMuted: any) => {
    if (this._integrationEnabled && this._activeCall &&
      (this._activeCall.callId === callId ||
        (this.rootScope.localUser.isOsBizCTIEnabled && this.callActionSvc.getElectedCall()?.callId) === callId)) {
      const muted = !!(remotelyMuted || locallyMuted);
      this.LogSvc.debug('[JabraControlService]: Local user has been ' + (muted ? 'muted' : 'unmuted') + '. Send notification to device.');
      muted ? this.muteMicrophone() : this.unMuteMicrophone();
    }
  };

  private onCallLocalUserMutedSelf = (callId: any, muted: any) => {
    if (this._integrationEnabled && this._activeCall &&
        (this._activeCall.callId === callId ||
          (this.rootScope.localUser.isOsBizCTIEnabled && this.callActionSvc.getElectedCall()?.callId) === callId)) {
      muted = !!muted;
      this.LogSvc.debug('[JabraControlService]: Local user has ' + (muted ? 'muted' : 'unmuted') + ' self. Send notification to device.');
      muted ? this.muteMicrophone() : this.unMuteMicrophone();
    }
  };

  private onCallState = async (call: any) => {
    if (!call) {
      return;
    }

    if (call.isRemote && call.checkCstaState([this.CstaCallState.Ringing, this.CstaCallState.ExtendedRinging])) {
      this.LogSvc.debug('[JabraControlService]: New ATC remote call ringing');
      this.onCallIncoming(call);
      return;
    }

    if (!this._integrationEnabled || call.isRemote || !(call.isOutgoingState() || call.isEstablished())) {
      return;
    }

    this.LogSvc.debug('[JabraControlService]: Received /call/state event for established call');

    if (!call.sameAs(this._activeCall) && call.sameAs(this.CallControlSvc.getActiveCall())) {
      this.setActiveCall(call);

      if (this._alertingCall && call.callId === this._alertingCall.callId) {
        this.LogSvc.debug('[JabraControlService]: Alerting call has been answered');
        this.answerIncomingCall(/* call */);
      } else {
        this.clearStartCallTimeout();
        await this.startCall();
      }
    } else if (this._alertingCall && call.callId === this._alertingCall.callId) {
      // This would be an error condition where _activeCall and _alertingCall
      // are pointing to the same call. Just clear the alerting call.
      this.clearAlertingCallId();
    }
  };

  private onAtcRemoteCallInfo = (atcCall: any) => {
    if (this._alertingCall && this._alertingCall.callId === atcCall.callId) {
      if (atcCall.isEstablished() || atcCall.checkCstaState([this.CstaCallState.Idle])) {
        this.LogSvc.debug('[JabraControlService]: Remote call was answered or terminated. Stop ringing');
        this.rejectIncomingCall();
        this.clearAlertingCallId();
      }
    }
  };

  private onAtcCallReplace = () => {
    this.LogSvc.debug('[JabraControlService]: Received /atccall/replace event');
    if (this._alertingCall && this._alertingCall.isHandoverInProgress) {
      this.LogSvc.debug('[JabraControlService]: Remote call was answered locally. Stop ringing');
      this.rejectIncomingCall();
      this.clearAlertingCallId();
    }
  };

  // Actions from device that affect the call

  private initDeviceSubscriptions = () => {
    // Stop any leftover subscripitons
    this.stopDeviceSubscriptions();

    // Disconnected device subscription
    this.onDisconnectSub = this.currentCallControl?.onDisconnect.subscribe(this.onDeviceDisconnected);

    // Call Active subscription - True if the user accepts a call, false if declined or rejected
    this.callActiveSub = this.currentCallControl?.callActive.subscribe(this.onCallActive);

    // Mute subscription
    this.muteSub = this.currentCallControl?.muteState.subscribe(this.onMuteState);
  };

  private onDeviceDisconnected = () => {
    this.stopDeviceSubscriptions();
    this.currentCallControl = undefined;
    this.PubSubSvc.publish('/headset/jabra/deviceListUpdated');
  };

  private onCallActive = (callActive: boolean) => {
    if (callActive) {
      if (this._alertingCall) {
        this.CallControlSvc.answerCall(this._alertingCall.callId, { audio: true });
      }
    } else if (this._jabraCallTermination && (this._activeCall || this._alertingCall)) {
      let callId = this._activeCall ? this._activeCall.callId : this._alertingCall.callId;

      // For OsBiz the callId that this.CallControlSvc.getActiveCall() returns is the original one
      // with the hastag, so we will depend on the elected call to get the id
      if (this.rootScope.localUser.isOsBizCTIEnabled) {
        callId = this.callActionSvc.getElectedCall()?.callId || callId;
      }

      try {
        this.CallControlSvc.endCall(callId);
        this.checkActiveAndStartCall();
      } catch { }
    }
    this._jabraCallTermination = true;
  };

  private onMuteState = (muteState: MuteState) => {
    const call = this.CallControlSvc.getActiveCall() || this.CallControlSvc.getActiveRemoteCall()[0] || this.CallControlSvc.getIncomingCall();
    if (call) {
      if (muteState === MuteState.MUTED) {
        this.CallControlSvc.mute(call.callId);
      } else if (muteState === MuteState.UNMUTED) {
        this.CallControlSvc.unmute(call.callId);
      }
    }
  };

  private stopDeviceSubscriptions(all?: boolean) {
    this.onDisconnectSub && this.onDisconnectSub.unsubscribe();
    this.callActiveSub && this.callActiveSub.unsubscribe();
    this.muteSub && this.muteSub.unsubscribe();
    if (all) {
      this.deviceListSub && this.deviceListSub.unsubscribe();
    }
  }

  private checkActiveAndStartCall = async (delay = 1000) => {
    this.clearStartCallTimeout();

    this.startCallTimeout = setTimeout(async () => {
      this.startCallTimeout = null;
      const call = this.CallControlSvc.getActiveCall();
      if (call && (call.isEstablished() || call.isOutgoingState())) {
        this._jabraCallTermination = true;
        this.setActiveCall(call);
        await this.startCall(call.isMuted());
      }
    }, delay);
  };

  private checkAndSignalIncomingCall = async (call: any, forceRing = false) => {
    if (!call) {
      return;
    }
    if (!this._integrationEnabled || (this._alertingCall && call.callId === this._alertingCall.callId)) {
      return;
    }
    if (this._activeCall) {
      this.LogSvc.debug('[JabraControlService]: Ignore incoming call since there\'s an active call.');
      return;
    }

    this.LogSvc.info('[JabraControlService]: Signal incoming call');
    this.setAlertingCall(call);
    await this.signalIncomingCall(call, forceRing);
  };

  private checkAndSignalIncomingCallDelayed = async (forceRing = false, delay = 1250) => {
    this.clearSignalCallTimeout();
    this.signalCallTimeout = setTimeout(async () => {
      this.signalCallTimeout = null;
      const call = this.CallControlSvc.getIncomingCall();
      if (call) {
        this.checkAndSignalIncomingCall(call, forceRing);
      }
    }, delay);
  };

  private clearStartCallTimeout = () => {
    this.startCallTimeout && clearTimeout(this.startCallTimeout);
    this.startCallTimeout = null;
  };

  private clearSignalCallTimeout = () => {
    this.signalCallTimeout && clearTimeout(this.signalCallTimeout);
    this.signalCallTimeout = null;
  };

  private saveIntegration = (enabled: boolean) => {
    const types = this.getEnabledIntegrationTypes();
    const index = types.indexOf(this.HEADSET_TYPE);
    if (index >= 0) {
      if (!enabled) {
        types.splice(index, 1);
      }
    } else if (enabled) {
      types.push(this.HEADSET_TYPE);
    } else {
      return;
    }
    this.LocalStoreSvc.setObjectSync(this.LocalStoreSvc.keys.HEADSET_TYPES_ENABLED, types);
    this._integrationEnabled = enabled;
  };

  private getEnabledIntegrationTypes = () => this.LocalStoreSvc.getObjectSync(this.LocalStoreSvc.keys.HEADSET_TYPES_ENABLED) || [];

  private setActiveCall = (call: any) => {
    this.LogSvc.debug('[JabraControlService]: Set active call to ', call && call.callId);
    this._activeCall = call;
  };

  private setAlertingCall = (call: any) => {
    this.clearAlertingCallId();
    this._alertingCall = call;
  };

  private clearAlertingCallId = () => {
    if (this._alertingCall) {
      this._alertingCall = null;
    }
  };

  private clearActiveCallId = () => {
    if (this._activeCall) {
      this._activeCall = null;
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////////
  // Public Functions
  ///////////////////////////////////////////////////////////////////////////////////////

  connect = async () => {
    this.LogSvc.debug('[JabraControlService]: Connect integration');

    if (!this.rootScope.localUser.jabraEnabled) {
      this.LogSvc.warn('[JabraControlService]: Jabra device integration is disabled. Do not connect.');
      return Promise.reject('Jabra integration disabled by administrator');
    }

    try {
      this._connectionError = false;
      if (!this.jabra) {
        this.jabra = await init({
          transport: RequestedBrowserTransport.WEB_HID,
          partnerKey: '81ec-dcc18ba3-83b2-492d-979e-a9eef7220e20',
          appId: 'unify-phone-client-web',
          appName: 'Unify Phone'
        }).catch(err => {
          this.LogSvc.error('[JabraControlService]: Error while initializing: ', err);
          this._connectionError = true;
          return Promise.reject();
        });
      }

      if (!this.jabra) {
        this.LogSvc.error('[JabraControlService]: The Jabra SDK failed to initialize. See error above for more details.');
        this._connectionError = true;
        return Promise.reject();
      }

      const eccFactory = new EasyCallControlFactory(this.jabra);

      /**
       * Subscribe to device attach/detach events
       */
      this.deviceListSub = this.jabra.deviceList.subscribe({
        next: devices => {
          if (devices.length >= 1) {
            // Find the first available device which supports call control
            let indexToUse = 0;
            while (indexToUse < devices.length &&
              !eccFactory.supportsEasyCallControl(devices[indexToUse])) {
              indexToUse++;
            }

            // Create call control with device
            eccFactory.createSingleCallControl(devices[indexToUse])
              .then(callControl => {
                this.currentCallControl = callControl;
                this.initDeviceSubscriptions();
                this.PubSubSvc.publish('/headset/jabra/deviceListUpdated');
                this._connectionError = false;
              })
              .catch(err => {
                this.LogSvc.error('[JabraControlService]: Error creating call control: ', err);
                this._connectionError = true;
              });
          } else {
            this.PubSubSvc.publish('/headset/jabra/deviceListUpdated');
          }

          // Log a warning if page has consent to more than 1 device
          if (devices.length >= 2) {
            this.LogSvc.warn('[JabraControlService]: More than one device is connected. The first will be used for call control');
          }
        },
        error: err => {
          this.LogSvc.error('[JabraControlService]: Error while retrieving device list: ', err);
          this._connectionError = true;
        }
      });

      // Save the connected state so it will be automatically reconnected in the next login
      this.saveIntegration(true);
      this.PubSubSvc.publish('/headset/jabra/connectSucceeded');

      this.PubSubSvc.subscribe('/call/ended', this.onCallEnded);
      this.PubSubSvc.subscribe('/call/incoming', this.onCallIncoming);
      this.PubSubSvc.subscribe('/call/localUser/muted', this.onCallLocalUserMuted);
      this.PubSubSvc.subscribe('/call/localUser/mutedSelf', this.onCallLocalUserMutedSelf);
      this.PubSubSvc.subscribe('/call/state', this.onCallState);
      this.PubSubSvc.subscribe('/atccall/info', this.onAtcRemoteCallInfo);
      this.PubSubSvc.subscribe('/atccall/replace', this.onAtcCallReplace);

      return Promise.resolve();
    } catch (err) {
      this.LogSvc.error('[JabraControlService]: Failed to connect. ', err);
      this._connectionError = true;
      return Promise.reject();
    }
  };

  disconnect = () => {
    this.LogSvc.debug('[JabraControlService]: Disconnect integration');

    this._connectionError = false;

    this.release();
    // Save the disconnected state so it won't be automatically reconnected in the next login
    this.saveIntegration(false);

    this.PubSubSvc.unsubscribe('/call/ended', this.onCallEnded);
    this.PubSubSvc.unsubscribe('/call/incoming', this.onCallIncoming);
    this.PubSubSvc.unsubscribe('/call/localUser/muted', this.onCallLocalUserMuted);
    this.PubSubSvc.unsubscribe('/call/localUser/mutedSelf', this.onCallLocalUserMutedSelf);
    this.PubSubSvc.unsubscribe('/call/state', this.onCallState);
    this.PubSubSvc.unsubscribe('/atccall/info', this.onAtcRemoteCallInfo);
    this.PubSubSvc.unsubscribe('/atccall/replace', this.onAtcCallReplace);
  };

  release = () => {
    if (!this._integrationEnabled) {
      return;
    }
    this.stopDeviceSubscriptions(true);
    try {
      if (this._activeCall) {
        this.currentCallControl?.endCall();
      } else if (this._alertingCall) {
        this.currentCallControl?.rejectIncomingCall();
      }
    } finally {
      this.currentCallControl = undefined;
    }
  };

  async triggerWebhidConsent() {
    try {
      await webHidPairing();
    } catch (err) {
      this.LogSvc.error('[JabraControlService]: WebHidPairing failed: ', err);
    }
  }

  isIntegrationEnabled = () => this._integrationEnabled;

  getActiveDevice = () => this.currentCallControl?.device;

  getHasConnectionError = () => this._connectionError;

  ///////////////////////////////////////////////////////////////////////////////////////
  // Initialization
  ///////////////////////////////////////////////////////////////////////////////////////

  private async init() {
    this._integrationEnabled = this.getEnabledIntegrationTypes().indexOf(this.HEADSET_TYPE) >= 0;
    if (this.utilsService.isWebHIDSupported() && this._integrationEnabled) {
      await this.connect();
    }
  }

}
