import { Component, EventEmitter, Input, Output } from '@angular/core';

const INSUFFICIENT_DIGIT_LENGTH_REGEX = /^\+?[\d#*]{1,2}\b/g;

@Component({
  selector: 'app-search-users',
  templateUrl: './search-users.component.html',
  styleUrls: ['./search-users.component.scss']
})
export class SearchUsersComponent {
  isValueSearchable = false;
  @Input() ariaLabel: any;
  @Input() value = '';

  @Output('searchContact') searchContact:EventEmitter<string> = new EventEmitter<string>();
  @Output('isSearchable') isSearchable:EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output('inputValue') inputValue: EventEmitter<string> = new EventEmitter<string>();

  checkInputValue() {
    this.isValueSearchable = !this.value.match(INSUFFICIENT_DIGIT_LENGTH_REGEX) && !!this.value.trim();
    this.isSearchable.emit(this.isValueSearchable);
    this.inputValue.emit(this.value);
  }

  selectionChange(event: any) {
    if (event.key === 'Enter' && !this.value.trim()) { return; }

    if (this.isValueSearchable) {
      this.searchContact.emit(event);
    }
  }

}
