import { DOCUMENT } from '@angular/common';
import { Directive, Inject, Injectable, OnInit, OnDestroy } from '@angular/core';

declare let Circuit: any;

///////////////////////////////////////////////////////////////////////////////////////////////////
/// Name:    uSetLangAttribute
/// Purpose: Use this directive to set the lang attribute in the html element dynamically
///
/// Used in: Add this directive on the first element that is on the Angular scope, inside app.component.html,
//           since adding it on the <html> is impossible (Angular app does not have direct access there)
/// Usage:   <app-header uSetLangAttribute></app-header>
///////////////////////////////////////////////////////////////////////////////////////////////////

@Directive({
  selector: '[uSetLangAttribute]'
})
@Injectable()
export class USetLangAttributeDirective implements OnInit, OnDestroy {
  private LogSvc: any;
  private PubSubSvc;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
  }

  private onLanguageUpdate = (language: string) => {
    this.LogSvc.info('[USetLangAttributeDirective]: Received /language/update event with language: ', language);
    this.document.documentElement.lang = language;
  };

  ngOnInit(): void {
    // Initialization
    this.PubSubSvc.subscribe('/language/update', this.onLanguageUpdate);
  }

  ngOnDestroy(): void {
    this.PubSubSvc.unsubscribe('/language/update', this.onLanguageUpdate);
  }
}
