<button mat-button
        [color]="color"
        [attr.aria-label]="root.i18n.localize(ariaLabel)"
        #userMenuTrigger
        [matMenuTriggerFor]="userMenu"
        [matTooltipShowDelay]="1000"
        matTooltip="{{ btnLabel ? '' : root.i18n.localize(tooltipText)}}"
        drop-down-data-autotest="dropdownAnswer"
        [ngClass]="['selection-btn', size]">
  <span *ngIf="!!btnLabel" [uLocalizeOnce]="btnLabel"></span>
  <mat-icon class="material-icons-outlined arrow">arrow_drop_down</mat-icon>
</button>

<mat-menu #userMenu="matMenu"
          yPosition="below"
          [overlapTrigger]="false"
          list-data-autotest="answerDevice">
  <p class="menu-title" [class]="color" [uLocalizeOnce]="title"></p>
  <button mat-menu-item
          *ngFor="let device of devices"
          (click)="splitButtonAction(device.id, call.callId)">
    <mat-icon [class]="device.icon">{{device.icon !== 'icon-deskphone-icon' ? device.icon : ''}}</mat-icon>
    <span [uLocalizeOnce]="device.text"></span>
  </button>
</mat-menu>
