import { Component, OnInit } from '@angular/core';

declare let Circuit: any;

@Component({
  selector: 'app-settings-header',
  templateUrl: './settings-header.component.html',
  styleUrls: ['./settings-header.component.scss']
})
export class SettingsHeaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    Circuit.serviceInstances.logSvc.debug('[SettingsHeaderComponent]: ngOnInit');
  }

}
