import { Directive, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';

declare let Circuit: any;

@Directive({
  selector: '[uAriaLabel]'
})
export class UAriaLabelDirective implements OnInit, OnDestroy {

  constructor(private el: ElementRef, commonBlService: CommonBLService) {
    this.rootScope = commonBlService.getRootScopeData();
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this.localizeValue = el.nativeElement.getAttribute('uAriaLabel');
  }

  private params: string[] = [];
  private PubSubSvc;
  private rootScope: any;
  private localizeValue: any;

  private updateText = () => {
    const text = this.params.length ? this.rootScope.localize(this.localizeValue, this.params) : this.rootScope.i18n.map[this.localizeValue];
    this.el.nativeElement.setAttribute('aria-label', text);
  };

  ngOnInit(): void {
    // Initialization
    this.PubSubSvc.subscribe('/language/update', this.updateText);

    const localizeParams = this.el.nativeElement.getAttribute('localizeParams');
    if (typeof localizeParams === 'string') {
      this.params = localizeParams.split('|');
    }
    this.updateText();
  }

  ngOnDestroy(): void {
    this.PubSubSvc.unsubscribe('/language/update', this.updateText);
  }
}
