import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { CallActionService } from '../../services/call-action.service';
import { DialogService } from '../../sub-modules/dialog/dialog.service';

declare let Circuit: any;

@Component({
  selector: 'app-call',
  templateUrl: './call.component.html',
  styleUrls: ['./call.component.scss']
})
export class CallComponent implements OnInit, OnDestroy {

  /*
    * Enum for Call Views
  */
  View = Object.freeze({
    TRANSFER: 'Transfer',
    NEWCALL: 'NewCall',
    DTMF: 'Dtmf'
  });

  private LogSvc: any;
  private PhoneCallSvc: any;
  private PubSubSvc: any;

  hasDtmfView: boolean;
  hasNewCallView: boolean;
  hasTransferView: boolean;
  hasViewOpen: boolean;
  root: any;
  call: any;
  remoteCall: any;
  secondCall: any;
  mergeAvailable: any;
  consultationAvailable: any;
  answerDevices: any;
  declineDevices: any;
  phoneCalls: any;
  defaultPushDevice: any;
  defaultAnswerDevice: any;

  constructor(private commonBlService: CommonBLService, private callAction: CallActionService, private dialogService: DialogService, private _snackBar: MatSnackBar) {
    this.hasDtmfView = false;
    this.hasNewCallView = false;
    this.hasTransferView = false;
    this.hasViewOpen = false;
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this.root = this.commonBlService.getRootScopeData();
    this.PhoneCallSvc = Circuit.serviceInstances.phoneCallSvc;
  }

  ngOnDestroy(): void {
    this.LogSvc.info('[CallComponent]: ngOnDestroy');
    this.PubSubSvc.unsubscribe('/conversation/update', this.onConversationUpdate);
    this.PubSubSvc.unsubscribe('/call/ended', this.onEndCall);
    this.PubSubSvc.unsubscribe('/call/state', this.onCallState);
  }

  ngOnInit(): void {
    this.LogSvc.info('[CallComponent]: ngOnInit');
    this.PubSubSvc.subscribe('/conversation/update', this.onConversationUpdate);
    this.PubSubSvc.subscribe('/call/ended', this.onEndCall);
    this.PubSubSvc.subscribe('/call/state', this.onCallState);
    this.initPhoneCalls(true);
    this.initDevices();
    this.initAvailableFeatures();
  }

  // private bindSvcFunctions = () => {
  //   [
  //     'canAnswerOnDevice',
  //     'canInitiateConsultation',
  //     'canInitiateConsultationRemoteCall',
  //     'canPickupCall',
  //     'canDialFromDevice',
  //     'isLocalCallActive',
  //     'isRemoteCallActive',
  //     'isCallBusy',
  //     'hasMultipleRemoteCalls',
  //     'stopRingingTone'
  //   ].forEach((name) => {
  //       (this as any)[name] = this.PhoneCallSvc[name];
  //   });
  // }

  private onConversationUpdate = (/* conversation: any */) => {
    this.LogSvc.debug('[CallComponent]: Received /conversation/update event');
    this.initPhoneCalls();
  };

  private onEndCall = (call: any) => {
    if (call && !call.isHandoverInProgress) {
      this.LogSvc.debug('[CallComponent]: Received /call/ended event');
      const transferFailedCause = call.transferCallFailedCause;
      if (transferFailedCause) {
        this.dialogService.handleCallError(transferFailedCause.ui);
      }
      this.initPhoneCalls();
    }
  };

  private onCallState = (call: any) => {
    //in 2 calls scenario we want to display holding-call as second call
    if (this.phoneCalls.length > 1) {
      this.LogSvc.debug('[CallComponent]: OnCallState. state:', call.state.css);
      if (this.call && this.call.state.css === 'holding-call' && call.state.css !== 'holding-call') {
        if (this.call.callId !== call.callId) {
          this.call = this.phoneCalls[1];
          this.secondCall = this.phoneCalls[0];
        }
      }
    }
  };

  private initDevices = () => {
    this.answerDevices = this.callAction.answerDevices;
    this.declineDevices = this.callAction.declineDevices;
    this.defaultPushDevice = this.callAction.defaultPushDevice;
    this.defaultAnswerDevice = this.callAction.defaultAnswerDevice;
  };

  private initAvailableFeatures = () => {
    const featureList = this.PhoneCallSvc.getAvailableFeatureList();

    this.mergeAvailable = featureList.includes(Circuit.Constants.TcAvailableFeatures.MERGE);
    this.consultationAvailable = featureList.includes(Circuit.Constants.TcAvailableFeatures.CONSULTATION);
  };

  private initPhoneCalls = (init?: any) => {
    this.phoneCalls = this.PhoneCallSvc.getPhoneCalls();
    if (this.phoneCalls.length > 0) {
      this.call = this.phoneCalls[0];
      this.secondCall = this.call && this.phoneCalls[1];

      if (init && this.call && this.secondCall && this.call.isHolding() && !this.secondCall.isHolding()) {
        //We need to show held call as sencondCall
        this.call = this.phoneCalls[1];
        this.secondCall = this.phoneCalls[0];
      }
    }
  };

  hasAnyCallInTransientState = () => this.PhoneCallSvc.hasAnyCallInTransientState();

  hasMultipleLocalCalls = (multipleLocalCalls: boolean) => this.PhoneCallSvc.hasMultipleLocalCalls(multipleLocalCalls);

  canAnswerCall = (callId: any) => this.PhoneCallSvc.canAnswerCall(callId);

  canConsultTransfer = (callId: any) => this.PhoneCallSvc.canConsultTransfer(callId);

  canRetrieveCall = (callId: any) => this.PhoneCallSvc.canRetrieveCall(callId);

  canHangupCall = (callId: any) => this.PhoneCallSvc.canHangupCall(callId);

  canHoldCall = (callId: any) => this.PhoneCallSvc.canHoldCall(callId);

  canInitiatePhoneCall = () => this.PhoneCallSvc.canInitiatePhoneCall();

  canInitiateConsultationLocalCall = () => this.PhoneCallSvc.canInitiateConsultationLocalCall();

  canSwapCall = (callId?: any) => this.PhoneCallSvc.canSwapCall(callId);

  canMergeCall = (callId: any) => this.PhoneCallSvc.canMergeCall(callId);

  canPushLocalCall = (callId: any) => this.PhoneCallSvc.canPushLocalCall(callId);

  canPullRemoteCall = (callId: any) => this.PhoneCallSvc.canPullRemoteCall(callId);

  canTransferCall = (callId: any) => this.PhoneCallSvc.canTransferCall(callId);

  isHangupCallAvailable = (callId: any) => this.PhoneCallSvc.isHangupCallAvailable(callId);

  getCallTitleUiStr = (call: any) => this.callAction.getCallTitleUiStr(call);

  getCallStateUiStr = (call: any) => this.callAction.getCallStateUiStr(call);

  swapCall = async (call: any) => {
    this.LogSvc.buttonPressed('[CallComponent]: Swap calls');
    try {
      await this.PhoneCallSvc.swapCall(call.callId);
    } catch (err: any) {
      //possibly show the error in the dialog here
      this.dialogService.handleCallError(err, null);
      this.LogSvc.error('[CallComponent]: swapCall() error: ', err);
    }
  };

  mergeCall = (call: any) => {
    this.LogSvc.buttonPressed('[CallComponent]: Merge calls');
    this.PhoneCallSvc.mergeCall(call.callId)
      .catch((err: any) => {
        //possibly show the error in the dialog here
        //this.dialogService.handleCallError(err, null);
        this.LogSvc.error('[CallComponent]: mergeCall() error: ', err);
      });
  };

  pushLocalCall = (callId: any, deviceName: any) => {
    this.LogSvc.buttonPressed('[CallComponent]: Push local call to ', deviceName);
    this.PhoneCallSvc.pushLocalCall(callId, deviceName)
      .catch((err: any) => {
        //possibly show the error in the dialog here
        //this.dialogService.handleCallError(err, null);
        this.LogSvc.error('[CallComponent]: pushLocalCall() error: ', err);
      });
  };

  holdCall = (call: any) => {
    this.LogSvc.buttonPressed('[CallComponent]: Hold call');
    this.PhoneCallSvc.holdCall(call.callId)
      .catch((err: any) => {
        //possibly show the error in the dialog here
        //this.dialogService.handleCallError(err, null);
        this.LogSvc.error('[CallComponent]: holdCall() error: ', err);
      });
  };

  retrieveCall = (call: any) => {
    this.LogSvc.buttonPressed('[CallComponent]: Retrieve call');
    this.PhoneCallSvc.retrieveCall(call.callId)
      .catch((err: any) => {
        //possibly show the error in the dialog here
        //this.dialogService.handleCallError(err, null);
        this.LogSvc.error('[CallComponent]: retrieveCall() error: ', err);
      });
  };

  completeTransfer = () => {
    this.LogSvc.buttonPressed('[CallComponent]: Complete transfer');
    return this.PhoneCallSvc.transferCall(this.call.callId)
      .then(() => {
        this.openSnackBar(this.root.i18n.map.res_CallTransferred, '');
      })
      .catch((error: any) => {
        this.LogSvc.error('[CallComponent]: completeTransfer() error', error);
        this.dialogService.handleCallError(error, null);
      });
  };

  private openSnackBar = (message: string, action: string) => {
    this._snackBar.open(message, action, {
      duration: 3000
    });
  };

  toggleView = (view: any) => {
    this.hasViewOpen = !this.hasViewOpen;
    switch (view) {
    case this.View.DTMF:
      this.hasDtmfView = !this.hasDtmfView;
      break;
    case this.View.NEWCALL:
      this.hasNewCallView = !this.hasNewCallView;
      break;
    case this.View.TRANSFER:
      this.hasTransferView = !this.hasTransferView;
      break;
    }

  };

  toggleMute = (call: any) => {
    this.LogSvc.buttonPressed('[CallComponent]:Toggle mute');
    this.callAction.toggleMute(call);
  };

  endCall = (call: any) => {
    this.LogSvc.info('[CallComponent]: endCall');
    this.PhoneCallSvc.endCall(call.callId)
      .then(() => {
        //NGTC-1886: we need to unset inFullCallView when there is no call.
        if (this.phoneCalls.length === 0) {
          this.root.inFullCallView = null;
        }
      })
      .catch((err: any) => {
        //possibly show the error in the dialog here
        this.LogSvc.error('[CallComponent]: endCall() error', err);
      });
  };

  answerCall = (callId: string, deviceName?: any): void => {
    this.LogSvc.buttonPressed('[CallComponent]: Answer call from: ' + deviceName);
    this.callAction.answerCall(callId, deviceName);
  };

  pullRemoteCall = (callId: string): void => {
    this.LogSvc.buttonPressed('[CallComponent]: Pull remote call');
    this.callAction.pullRemoteCall(callId);
  };

  get callView() { return this.View; }

}
