/*
  * Enum for AgentUIState
*/
export const AgentUIState = {
  AVAILABLE: {
    name: 'available',
    ally: 'res_AccessibilityAgentStatusAvailable',
    ui: 'res_AgentStatusAvailable'
  },
  PARTIAL: {
    name: 'partiallyUnavailable',
    ally: 'res_AccessibilityAgentStatusPartiallyUnavailable',
    ui: 'res_AgentStatusPartiallyUnavailable'
  },
  UNAVAILABLE: {
    name: 'unavailable',
    ally: 'res_AccessibilityAgentStatusUnavailable',
    ui: 'res_AgentStatusUnavailable'
  }
};
