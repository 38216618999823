import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { JabraControlService } from './services/jabra-control.service';
import { UtilsService } from './services/utils.service';
import { UiService } from './services/ui.service';

declare let Circuit: any;
declare let RegistrationState: any;

const BANNER_TIMEOUT = 1500;
const PWA_INITIAL_WIDTH = 500;
const PWA_INITIAL_HEIGHT = 700;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
      private commonBLService: CommonBLService,
      private jabraControlService: JabraControlService,
      private overlayContainer: OverlayContainer,
      private utilsService: UtilsService,
      private uiService: UiService) {
    this.LocalStoreSvc = Circuit.serviceInstances.localStoreSvc;
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this.SoundSvc = Circuit.serviceInstances.soundSvc;
    this.CircuitLabSvc = Circuit.serviceInstances.circuitLabSvc;
    this.RegistrationSvc = Circuit.serviceInstances.registrationSvc;
    this.PlantronicsControlSvc = Circuit.serviceInstances.plantronicsControlSvc;
    this.CallControlSvc = Circuit.serviceInstances.callControlSvc;
    this.PhoneCallSvc = Circuit.serviceInstances.phoneCallSvc;
    this.Utils = Circuit.Utils;
    this.serverName = Circuit.__serverName;
    this.rootScope = this.commonBLService.getRootScopeData();
  }

  title = 'main-app';
  private registrationPromise: any | undefined;
  private LocalStoreSvc: any;
  private LogSvc: any;
  private PubSubSvc: any;
  private SoundSvc: any;
  private CircuitLabSvc: any;
  private RegistrationSvc: any;
  private PlantronicsControlSvc: any;
  private CallControlSvc: any;
  private Utils: any;
  private PhoneCallSvc: any;
  private serverName: any;
  private _bannerTimeout: any = null;
  rootScope: any;

  private onTelephonyAvailability = (available: boolean) => {
    this.LogSvc.debug('[AppComponent]: Received /telephony/availability event. Available:', available);
  };

  private onRegistrationState = (state: string) => {
    this.LogSvc.debug('[AppComponent]: Received /registration/state event');
    this.rootScope.registrationState = state;
    this.updateShowBanner();
  };

  private onTelephonyAvailable = () => {
    this.updateShowBanner();
  };

  private updateShowBanner = () => {
    const isReconnecting = this.rootScope.registrationState === RegistrationState.Reconnecting;
    const isTelephonyDown = !this.PhoneCallSvc.isTelephonyAvailable() && this.rootScope.registrationState === RegistrationState.Registered;

    if (this._bannerTimeout) {
      clearTimeout(this._bannerTimeout);
      this._bannerTimeout = null;
    }

    if (isReconnecting) {
      this.rootScope.showBanner = true;
      this.PubSubSvc.publish('/show/banner', true);
      return;
    }

    if (isTelephonyDown) {
      // When client is first connected the condition (telephonyDown) is met
      // so wait before showing the banner to avoid flash at startup
      this._bannerTimeout = setTimeout(() => {
        this.rootScope.showBanner = true;
        this.PubSubSvc.publish('/show/banner', true);

      }, BANNER_TIMEOUT);
    }

    this.rootScope.showBanner = false;
    this.PubSubSvc.publish('/show/banner', false);
  };

  private shouldEnableCache() {
    const keys = this.LocalStoreSvc.keys;
    // Initialize this.rootScope.ssoLogin
    this.rootScope.ssoLogin = !!this.LocalStoreSvc.getObjectSync(keys.SSOLOGIN);

    // If the user logged in using username/password check the rememberMe flag in local storage.
    // Otherwise check the cacheEnabled flag.

    if (!this.rootScope.ssoLogin) {
      const rememberMe = !!this.LocalStoreSvc.getObjectSync(keys.REMEMBER_ME);
      this.LogSvc.info('[AppComponent]: User has logged in using username/password. rememberMe = ', rememberMe);
      return Promise.resolve(rememberMe);
    }

    const cacheEnabled = this.LocalStoreSvc.getObjectSync(keys.CACHE_ENABLED);
    if (typeof cacheEnabled === 'boolean') {
      this.LogSvc.info('[AppComponent]: User has logged in using SSO. cacheEnabled = ', cacheEnabled);
      return Promise.resolve(cacheEnabled);
    }

    // Disable caching, for now
    return Promise.resolve(false);
  }

  private redirectToLogout(reason?:any) {
    const querystring = this.Utils.toQS({
      reason: reason || undefined,
      return_to: !reason && window.location.href !== '/' ? '/#' + window.location.href : undefined
    });
    const logoutPath = 'logout' + (querystring ? '?' + querystring : '');
    this.utilsService.setPreventUnloadPrompt(true);
    this.utilsService.redirectToPath(logoutPath);
  }

  private redirectToLogin(reason?:any) {
    let loginPath = 'login';
    if (reason) {
      loginPath += '?reason=' + reason;
    }
    this.utilsService.setPreventUnloadPrompt(true);
    this.utilsService.redirectToPath(loginPath);
  }

  private onSessionLogout = (reason: any) => {
    this.LogSvc.info('[AppComponent]: Received /session/logout event. reason = ', reason);
    this.redirectToLogout(reason);
  };

  private onSessionSuspended = () => {
    this.LogSvc.info('[AppComponent]: Received /session/suspended event.');
    this.redirectToLogout('accountSuspended');
  };

  private onNewConnectionDetected = () => {
    this.LogSvc.info('[AppComponent]: Received /session/newConnectionDetected event.');
    this.redirectToLogout('newConnectionDetected');
  };

  private onSetupConnections() {
    this.LogSvc.info('[AppComponent]: Successfully set up connections with server');
  }

  private onSetupError(err: any) {
    this.LogSvc.error('[AppComponent]: Failed to setup connections with server ', err);
    if (err === 'res_auth_InvalidCredentials') {
      // Redirect to /logout without any reason so the client automatically tries
      // to re-login in case SSO is enabled.
      this.redirectToLogout();
    } else if (err === 'res_auth_SessionExpiring') {
      // Redirect to /logout with reason so login page will in the end present reason to user
      this.redirectToLogout('sessionExpiring');
    } else {
      this.LogSvc.error('[AppComponent]: Skip retry modal');
      this.redirectToLogout();
      //TODO: Implement retry modal
    }
  }

  private terminateActiveCall = () => {
    if (this.rootScope.localUser) {
      const call = this.CallControlSvc.getActiveCall();
      if (call) {
        call.setDisconnectCause(Circuit.Constants.DisconnectCause.HANGUP, Circuit.Constants.DisconnectReason.PAGE_UNLOADED);
        this.CallControlSvc.endCallWithCauseCode(call.callId, Circuit.Enums.CallClientTerminatedReason.PAGE_UNLOADED);
        return true;
      }
    }
    return false;
  };

  private initPWA = () => {
    // Check if PWA is installed
    if (!this.utilsService.isInstalledApp()) {
      window.addEventListener('appinstalled', () => {
        this.LogSvc.info('[AppComponent]: PWA was installed');
        setTimeout(() => { window.resizeTo(PWA_INITIAL_WIDTH, PWA_INITIAL_HEIGHT); }, 500);
      });
    }
  };

  reloadAfterTimeout = () => {
    setTimeout(() => {
      window.location.reload();
    }, 500, false);
  };

  private onApplicationReload = (/* message: any */) => {
    this.LogSvc.info('[AppCtrl]: Received /application/reload event');
    if (this.rootScope.localUser) {
      this.reloadAfterTimeout();
    }
  };

  @HostListener('window:beforeunload', ['$event'])
  private onWindowBeforeUnload = (event: any) => {
      const shouldPrompt = !this.utilsService.shouldPreventUnloadPrompt() && this.utilsService.isInstalledApp();
      this.LogSvc.info('[AppComponent]: onWindowBeforeUnload - should ask before closing =', shouldPrompt);
      if (shouldPrompt) {
        event.preventDefault();
        event.returnValue = 1;
        return event;
      }
      return null;
    };

  @HostListener('window:unload')
  private onWindowUnload = (/*event*/) => {
      this.LogSvc.info('[AppComponent]: onWindowUnload - Release resources');
      if (this.registrationPromise) {
        clearTimeout(this.registrationPromise);
        this.registrationPromise = null;
        return;
      }
      this.PlantronicsControlSvc.release();
      this.jabraControlService.release();
      this.terminateActiveCall();
    };

  // Listen for the beforeinstallprompt event for PWA installation
  // https://web.dev/customize-install/
  @HostListener('window:beforeinstallprompt', ['$event'])
  private onBeforeInstallPromptEvent = (event: any) => {
      this.LogSvc.info('[AppComponent]: beforeinstallprompt event listener');
      // Prevent the mini-infobar from appearing on mobile.
      event.preventDefault();
      // Stash the event so it can be triggered later.
      this.utilsService.deferredPrompt = event;
    };

  @HostListener('document:keydown.f5')
  @HostListener('document:keydown.control.f5')
  @HostListener('document:keydown.control.r')
  @HostListener('document:keydown.meta.shift.r')
  @HostListener('document:keydown.shift.meta.r')
  private onKeydownHandler = (/* event: KeyboardEvent */) => {
      this.utilsService.setPreventUnloadPrompt(true);
    };

  @HostListener('document:mousemove')
  private onMouseMoveHandler = (/* event: MouseEvent */) => {
      this.uiService.activityDetected();
    };

  private init() {
    // Check if the app is running in PWA
    this.initPWA();

    // Initialize the SoundSvc
    this.SoundSvc.init();

    // If user is Standalone initialize activity checking for presence
    this.uiService.init();

    // Wait half a second before starting the registration sequence in case this
    // tab needs to be closed by the Chrome extension.
    this.registrationPromise = setTimeout(() => {
      this.registrationPromise = null;
      // For now cache will always be enabled
      // Initialize indexedDB before proceeding with registration.
      this.LocalStoreSvc.initIndexedDb(true)
        .then(() => {
          this.LogSvc.debug('[AppComponent]: Initialized CircuitLabSvc from local storage');
          this.CircuitLabSvc.initFromLocalStorage();
          this.RegistrationSvc.setupConnections(this.onSetupConnections.bind(this), this.onSetupError.bind(this), this.serverName);
        });

    }, 500, false);
  }

  ngOnInit() {
    //https://github.com/angular/components/issues/20001
    this.overlayContainer.getContainerElement().setAttribute('role', 'region');

    this.PubSubSvc.subscribe('/telephony/availability', this.onTelephonyAvailability);
    this.PubSubSvc.subscribe('/telephony/available', this.onTelephonyAvailable);
    this.PubSubSvc.subscribe('/session/logout', this.onSessionLogout);
    this.PubSubSvc.subscribe('/session/newConnectionDetected', this.onNewConnectionDetected);
    this.PubSubSvc.subscribe('/session/suspended', this.onSessionSuspended);
    this.PubSubSvc.subscribe('/registration/state', this.onRegistrationState);
    this.PubSubSvc.subscribe('/application/reload', this.onApplicationReload);

    this.init();
  }

  ngOnDestroy() {
    this.PubSubSvc.unsubscribe('/telephony/availability', this.onTelephonyAvailability);
    this.PubSubSvc.unsubscribe('/telephony/available', this.onTelephonyAvailable);
    this.PubSubSvc.unsubscribe('/session/logout', this.onSessionLogout);
    this.PubSubSvc.unsubscribe('/session/newConnectionDetected', this.onNewConnectionDetected);
    this.PubSubSvc.unsubscribe('/session/suspended', this.onSessionSuspended);
    this.PubSubSvc.unsubscribe('/registration/state', this.onRegistrationState);
    this.PubSubSvc.unsubscribe('/application/reload', this.onApplicationReload);
  }
}
