<div class="container">
  <!-- Last Login -->
  <div class="section-wrapper">
    <h3 uLocalize="res_Activity"></h3>
    <p class="last-login">
      <span uLocalize="res_LastSuccessfulLogin"></span>:<span class="last-login-date">{{ formatLastLogin(rootScope.localUser?.lastLogin || '') }}</span>
    </p>
    <mat-divider></mat-divider>
  </div>

  <!-- Change password -->
  <div class="section-wrapper" *ngIf="rootScope.localUser?.isStandalone">
    <div class="pwa-wrapper">
      <h3 uLocalize="res_Password"></h3>
    </div>
    <div>
      <button mat-flat-button color="primary" (click)="changePassword()">
        <span>{{ rootScope.i18n.localize('res_ChangePassword') | uppercase }}</span>
      </button>
    </div>
  </div>
</div>
