import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[uSkipNav]'
})
export class USkipNavDirective {
  private targetElement: any = null;

  constructor(private hostElement: ElementRef) { }

  @HostListener('click', ['$event']) onClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    this.targetElement = document.querySelector('[skip-nav-target]') as HTMLElement;
    this.targetElement?.focus();
    this.hostElement.nativeElement.blur();
  }
}
