import { Component, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { ExchangeOnlineService } from '../../exchange-online.service';

declare let Circuit: any;

@Component({
  selector: 'app-exchange-extension',
  templateUrl: './exchange-extension.component.html',
  styleUrls: ['./exchange-extension.component.scss']
})
export class ExchangeExtensionComponent implements OnInit {
  private LogSvc: any;
  root: any;

  constructor(private commonBlService: CommonBLService, private exchangeOnlineService: ExchangeOnlineService) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.root = this.commonBlService.getRootScopeData();
  }

  ngOnInit(): void {
    this.LogSvc.info('[ExchangeExtensionComponent]: ngOnInit');
  }

  isExchangeConnected = () => this.exchangeOnlineService.getConnected();

  hasError = () => !!this.exchangeOnlineService.getLastConnectionError();

  toggleConnectionStatus = async () => {
    try {
      if (this.isExchangeConnected()) {
        await this.exchangeOnlineService.disconnect();
        return;
      }
      await this.exchangeOnlineService.connect();
    } catch (error: any) {
      this.LogSvc.error('[ExchangeExtensionComponent]: Could not toggle connection status: ', error);
    }
  };

  resetErrorMessage = () => {
    this.exchangeOnlineService.resetConnectionError();
    return this.exchangeOnlineService.getLastConnectionError();
  };

}
