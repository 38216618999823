<div class="bar-container" (click)="showCallView()" [class]="call?.state.css">

  <div class="call-info" *ngIf="phoneCalls.length > 2" >
    <!-- Mulptiple calls -->
    <p class="back-to-call-text first-call">
      <span class="back-to-call-name">
        {{root.i18n.localize('res_CallList', [phoneCalls.length])}}
      </span>
      <span class="call-state" uLocalize="res_BackToCall"></span>
    </p>
  </div>

  <div class="call-info" *ngIf="phoneCalls.length <= 2">
    <!-- Single call -->
    <p class="back-to-call-text first-call" [ngClass]="{'second-call': !!secondCall}">
      <ng-template [ngIf]="call?.participants?.length > 1 || call?.isDirectUpgradedToConf">
        <span class="back-to-call-name">
          <mat-icon class="material-icons-outlined md-12 forwarded-icon" >call_merge</mat-icon>
          {{getCallTitleUiStr(call)}}
        </span>
      </ng-template>
      <ng-template [ngIf]="call?.participants?.length <= 1 && !call?.isDirectUpgradedToConf">
        <span class="back-to-call-name">
          <mat-icon *ngIf="call?.forwarded && call?.state?.css !== 'incoming'" class="material-icons-outlined md-12 forwarded-icon">phone_forwarded</mat-icon>
          <mat-icon *ngIf="call?.state?.css !== 'incoming' && (call?.pickupNotification || call?.pickedUp)" class="material-icons-outlined md-15 icon-pickup-group-icon"></mat-icon>
          {{call?.peerUser.displayName || call?.peerUser.phoneNumber}}
        </span>
      </ng-template>
      <span *ngIf="(call?.state.ui) && !call?.redirectingUser" class="call-state">
        {{root.i18n.map[call?.state.ui]}}
      </span>
      <!-- Forwarded Call Or Pickup Group Info -->
      <span *ngIf="(!!call?.redirectingUser && call?.state?.css === 'incoming')" class='call-fwd'>
        <mat-icon *ngIf="call?.forwarded" class="material-icons-outlined md-12">phone_forwarded</mat-icon>
        <mat-icon *ngIf="call?.pickupNotification || call?.pickedUp" class="material-icons-outlined md-15 icon-pickup-group-icon"></mat-icon>
        <mat-card-subtitle class="forward-pickup-info mat-caption">
          {{ call?.pickupNotification ? root.i18n.localize(call.redirectingUser?.redirectionType?.ui, [getRedirectingUserUiStr(call.redirectingUser)]) : getRedirectingUserUiStr(call.redirectingUser)}}
        </mat-card-subtitle>
      </span>
      <span class="call-time"  [ngClass]="{'small': !!secondCall}" *ngIf="call?.state.css === 'active-call'" [uElapsedTime]="call.establishedTime"></span>
    </p>

    <!-- Sencond Call Info -->
    <p class="back-to-call-text second-call mat-caption"
      [ngClass]="{'second-call-hold' : secondCall.state.css === 'holding-call' || secondCall.isHolding(), 'second-call-incoming': secondCall.state.css === 'incoming'}"
      *ngIf="secondCall">
      <ng-template [ngIf]="secondCall?.participants?.length <= 1 && !secondCall?.isDirectUpgradedToConf">
        <span class="back-to-call-name" *ngIf="secondCall.state.css === 'holding-call' || secondCall?.isHolding()">{{secondCall?.peerUser.displayName || secondCall?.peerUser.phoneNumber}}</span>
        <span *ngIf="secondCall.state.ui && secondCall.state.css !== 'holding-call'"
        [ngClass]="{'call-state-warn': (secondCall.state.css === 'declined' || secondCall.state.css === 'failed' || secondCall.state.css === 'busy'),
        'call-state-accent' : secondCall.state.css === 'active-call-remote'}">{{root.i18n.map[secondCall.state.ui]}}</span>
      </ng-template>
      <ng-template [ngIf]="secondCall?.participants?.length > 1 || secondCall?.isDirectUpgradedToConf">
        <mat-icon class="material-icons-outlined md-12 forwarded-icon bottom-4" >call_merge</mat-icon>
        <span class="back-to-call-name">{{getCallTitleUiStr(secondCall)}}</span>
      </ng-template>
      <mat-icon class="material-icons-outlined md-12" *ngIf="secondCall.state.css === 'holding-call' || secondCall.isHolding()">pause</mat-icon>
    </p>
  </div>
  <!-- Multiple calls buttons -->
  <div *ngIf="phoneCalls.length > 2" class="voice-options">
    <button mat-flat-button color="accent" class="pull-remote-call" (click)="showCallView()">
      <div class="mat-body-2">{{root.i18n.map.res_Return}}</div>
    </button>
  </div>

  <!-- Single and two calls buttons -->
  <div *ngIf="phoneCalls.length <= 2" class="voice-options">
    <!-- Ignore call button -->
    <button mat-mini-fab color="ignore" *ngIf="canPickupCall(call?.callId)"
      class="back-to-call-btn"
      (click)="ignoreCall(call); $event.stopPropagation()"
      [attr.aria-label]="root.i18n.map.res_IgnoreCall">
      <mat-icon>close</mat-icon>
    </button>
    <!-- End call button -->
    <button mat-mini-fab *ngIf="!!call && call?.state.css === 'incoming' && isHangupCallAvailable(call?.callId)" class="back-to-call-btn" color="warn"
          [attr.aria-label]="call?.state?.css !=='incoming' ? root.i18n.map.res_AccessibilityHangup : root.i18n.map.res_AccessibilityDecline"
          (click)="endCall(call); $event.stopPropagation()">
      <mat-icon>call_end</mat-icon>
    </button>
    <!-- Answer call button -->
    <button mat-mini-fab *ngIf="canAnswerCall(call?.callId) && defaultAnswerDevice.value.id !== 'VM'" class="back-to-call-btn accept-call-btn" color="green" uAriaLabel="res_AccessibilityAnswerCall"
        (click)="answerCall(call.callId)">
      <mat-icon>call</mat-icon>
    </button>
    <!-- Return button -->
    <button mat-flat-button color="accent" class="pull-remote-call"  *ngIf="call?.state?.css !== 'incoming' && !call?.isRemote"
      (click)="showCallView()">
      <div class="mat-body-2">{{root.i18n.map.res_Return}}</div>
    </button>
    <!-- Pull call button -->
    <button mat-flat-button color="accent"
            class="pull-remote-call"
            uAriaLabel="res_AccessibilityPullCall"
            *ngIf="canPullRemoteCall(call?.callId)"
            (click)="pullRemoteCall(call.callId)"
            matTooltip="{{root.i18n.map.res_PullCallTooltip}}">
      <mat-icon class="md-20 icon-pull-icon"></mat-icon>
    </button>
  </div>
</div>
