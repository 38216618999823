<mat-tab-group  class="mat-tab-group" mat-align-tabs="center" animationDuration="0ms" (selectedTabChange)="onTabChangeHandler($event);">
  <mat-tab>
    <ng-template mat-tab-label class="custom-label">
      <h2 uLocalize="res_General"></h2>
    </ng-template>
    <mat-tab-content>
      <app-general-tab class="tab-container"></app-general-tab>
    </mat-tab-content>
  </mat-tab>
  <mat-tab [disabled]="this.rootScope.registrationState !== registrationState.Registered">
    <ng-template mat-tab-label class="custom-label">
      <h2 uLocalize="res_TelephonyTab"></h2>
    </ng-template>
    <mat-tab-content>
      <app-telephony-tab class="tab-container"></app-telephony-tab>
    </mat-tab-content>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label class="custom-label">
      <h2 uLocalize="res_Audio"></h2>
    </ng-template>
    <mat-tab-content>
        <app-audio-tab class="tab-container"></app-audio-tab>
    </mat-tab-content>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label class="custom-label">
      <h2 uLocalize="res_Account"></h2>
    </ng-template>
    <mat-tab-content>
        <app-account-tab class="tab-container"></app-account-tab>
    </mat-tab-content>
  </mat-tab>
  <mat-tab *ngIf="rootScope.showAlphaFeatures">
    <ng-template mat-tab-label class="custom-label">
      <h2 uLocalize="res_LabsTitle"></h2>
    </ng-template>
    <mat-tab-content>
        <app-labs-tab class="tab-container"></app-labs-tab>
    </mat-tab-content>
  </mat-tab>
</mat-tab-group>
