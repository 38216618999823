import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { Subscription } from 'rxjs';
declare let Circuit: any;

@Component({
  selector: 'app-tab-selector',
  templateUrl: './tab-selector.component.html',
  styleUrls: ['./tab-selector.component.scss']
})
export class TabSelectorComponent implements OnInit, OnDestroy {
  private LogSvc: any;
  private PubSubSvc: any;
  private PhoneCallLogSvc: any;
  private routerSub!: Subscription;

  phoneConv: any;
  root: any;
  i18n: any;

  @Input() tabs!: any[];

  constructor(private commonBlService: CommonBLService, private router: Router) {
    this.root = this.commonBlService.getRootScopeData();
    this.i18n = this.root.i18n;
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.subscribeToRouterEvents();
  }

  ngOnInit(): void {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this.PhoneCallLogSvc = Circuit.serviceInstances.phoneCallLogSvc;

    this.LogSvc.info('[SelectorComponent]: ngOnInit');
    this.PubSubSvc.subscribe('/conversation/update', this.onConversationUpdated);
    if (!this.phoneConv) {
      this.PhoneCallLogSvc.getTelephonyConversation()
        .then((phoneConv: any) => {
          this.phoneConv = phoneConv;
        });
    }
  }

  ngOnDestroy(): void {
    this.LogSvc.info('[SelectorComponent]: ngOnDestroy');
    this.PubSubSvc.unsubscribe('/conversation/update', this.onConversationUpdated);
    this.routerSub.unsubscribe();
  }

  subscribeToRouterEvents() {
    this.routerSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.toggleActive(event.urlAfterRedirects);
      }
    });
  }

  toggleActive = (selected: string) => {
    this.LogSvc.info('[SelectorComponent]: into toggleActive selected tab: ', selected);
    if (this.phoneConv?.call) {
      this.root.inMiniCallView = true;
    }
  };

  private onConversationUpdated = (conv: any) => {
    this.phoneConv = conv;
  };
}
