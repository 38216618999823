<div #elementRef
    [ngClass]="['avatar-single', size]"
    [class.avatar-with-badge]="user.isExchangeContact"
    [ngSwitch]="isUnknownUser() || user.isExchangeContact || isGroupCall"
    matTooltip="{{presence ? localizedPrecenceStates(presence) : ''}}"
    positionAtOrigin="true" matTooltipShowDelay="300">

  <!-- AVATARS -->

  <!-- Unknown user case -->
  <div *ngSwitchCase="true" class="avatar-image" [class.with-border]="withBorder">
    <mat-icon *ngIf="!user.isExchangeContact" class="material-icons-outlined unknown-icon">{{ isGroupCall ? 'groups' : 'person' }}</mat-icon>
    <mat-icon *ngIf="user.isExchangeContact" class="unknown-icon">phone</mat-icon>
  </div>
  <!-- Known user case -->
  <div *ngSwitchDefault [ngClass]="['avatar-image', color]" [class.with-border]="withBorder">
    <img *ngIf="user.hasAvatarPicture && !hasImageError"
    class="known-user"
    [src]="size === 'medium' || size === 'large' || size === 'xxlarge' ? user.avatarLarge : user.avatar"
    alt="avatar"
    (error)="onAvatarPictureError()">
    <span *ngIf="!user.hasAvatarPicture || hasImageError" class="known-user" [ngClass]="{ 'one-initial': hasOneInitial, 'two-initials': !hasOneInitial}">{{getInitials()}}</span>
  </div>

  <!-- OVERLAYS -->

  <!-- Exchange online contact badge -->
  <span *ngIf="user.isExchangeContact && user.isExchangeOnline" class="avatar-badge exchange online" alt="exchange-online"></span>
  <!-- Exchange on-premise contact badge -->
  <span *ngIf="user.isExchangeContact && user.isExchangeOnPremise" class="avatar-badge exchange onpremise" alt="exchange-onpremise"></span>
  <!-- Osbiz contact badge -->
  <span *ngIf="user.isOsbizContact" class="avatar-badge osbiz-contact" alt="osbiz-contact"></span>
  <!-- On Hold -->
  <span class="overlay" *ngIf="isOnHold !== undefined" [ngClass]="{'show': isOnHold }">
    <mat-icon *ngIf="isOnHold" class="hold-icon">pause</mat-icon>
  </span>
  <!-- Edit profile picture -->
  <span class="overlay" *ngIf="editProfilePic !== undefined" [ngClass]="{'show': editProfilePic }">
    <mat-icon *ngIf="editProfilePic" class="edit-profile">photo_camera</mat-icon>
  </span>
  <!-- Presence dot -->
  <span *ngIf="presence" class="dot" [class]="presence">
    <mat-icon
      [ngClass]="{'md-14': size === 'medium' || size === 'small2',
        'material-icons': presence !== 'OFFLINE' && presence !== 'INVISIBLE',
        'material-icons-outlined': presence === 'OFFLINE' || presence === 'INVISIBLE'}">
      {{getPresenceIcon()}}
    </mat-icon>
  </span>
</div>
