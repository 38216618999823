<nav mat-tab-nav-bar mat-align-tabs="center" class="selector-wrapper" [disablePagination]="true">
  <a mat-tab-link *ngFor='let tab of tabs' class="tab"
     [ngClass]="{'active' : rla.isActive, 'hide-selection': root.inFullCallView}"
     [active]="rla.isActive"
     [routerLink]="tab.route"
     routerLinkActive #rla="routerLinkActive"
     matRipple (click)="toggleActive(tab.name)">

    <span class="accessibility-element"
          role="status"
          aria-live="polite"
    >{{(tab.hasBadge && !!phoneConv?.userData.unreadItems)? root.i18n.localize('res_NewCallHistoryItems', [phoneConv?.userData.unreadItems]) : ''}}</span>

    <div *ngIf="tab.hasBadge && !!phoneConv?.userData.unreadItems"
         class="badge mat-caption"
         [ngClass]="{'badge-selected' : rla.isActive}">{{phoneConv?.userData.unreadItems}}</div>
    <mat-icon *ngIf="!!tab.icon" class="material-icons-outlined">{{tab.icon}}</mat-icon>
    <h2 class="mat-caption" [uLocalizeOnce]="tab.uLocalize"></h2>
  </a>
</nav>
