import { Directive, ElementRef, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import * as moment from 'moment';

declare let Circuit: any;
declare let $: any;

@Directive({
  selector: '[uElapsedTime]'
})
export class UElapsedTimeDirective implements OnInit, OnDestroy {
  @Input('uElapsedTime') establishedTime: any;

  constructor(private el: ElementRef) {
    this.label = this.el.nativeElement.getAttribute('label');
    this.LogSvc = Circuit.serviceInstances.logSvc;
  }

  private _startTime = 0;
  private _intervalId: any = null;
  private label: any;
  private LogSvc: any;

  private getElapsedTimeText(startTime: any, label: any) {
    // We recalculate the delta every time (as opposed to simply decrementing
    // it) to address the case where the computer is hibernated.
    const start = moment(startTime); // some random moment in time (in ms)
    const end = moment(Date.now()); // some random moment after start (in ms)
    const diff = end.diff(start);
    // execution
    const text = moment.utc(diff).format('HH:mm:ss');
    return label ? label + ' ' + text : text;
  }

  private setElementText = () => {
    const text = this.getElapsedTimeText(this._startTime, this.label);
    $(this.el.nativeElement).text(text);
  };

  private startTimer() {
    this.LogSvc.info('[UElapsedTime]: Starting elapsed time timer');
    if (this._intervalId) {
      // Cancel the existing interval
      clearInterval(this._intervalId);
      this._intervalId = null;
    }

    if (this._startTime === 0) {
      $(this.el.nativeElement).text('00:00');
      return;
    }

    this.setElementText();

    // Start 1 second timer to update the elapsed time.
    // We are not using $interval due to some issues
    // running in background.
    this._intervalId = setInterval(this.setElementText, 1000);
  }

  ngOnInit(): void {
    this.LogSvc.info('[UElapsedTime]: Initializing elapsed time directive');
  }

  ngOnDestroy(): void {
    this.LogSvc.info('[UElapsedTime]: Destroying elapsed time directive');
    this._intervalId && clearInterval(this._intervalId);
    this._intervalId = null;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.establishedTime?.currentValue > 0) {
      const value = changes.establishedTime.currentValue;
      this._startTime = Number.isInteger(value) ? value : 0;
      this.startTimer();
    }
  }
}
