import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { DialogService } from '../../sub-modules/dialog/dialog.service';
import { UtilsService } from '../../services/utils.service';

declare let Circuit: any;

@Component({
  selector: 'app-about-overlay',
  templateUrl: './about-overlay.component.html',
  styleUrls: ['./about-overlay.component.scss']
})
export class AboutOverlayComponent implements OnInit, OnDestroy {
  private documentMapEn: any = {
    tos: 'terms-of-service-production',
    dpa: 'dpa'
  };

  private documentMapDe: any = {
    tos: 'allgemeine-nutzungsbedingungen',
    dpa: 'datenschutzvereinbarung'
  };

  private LocalizeSvc: any;
  private ExtensionSvc: any;
  private LogSvc: any;
  private PubSubSvc: any;
  private overlayElements: any = [];

  rootScope: any;
  isExtensionRunning = false;
  isChromeAppConnected = false;
  productName = '';

  constructor(private commonBlService: CommonBLService, private dialogService: DialogService, private utilsService: UtilsService) {
    this.LocalizeSvc = Circuit.serviceInstances.localizeSvc;
    this.ExtensionSvc = Circuit.serviceInstances.extensionSvc;
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this.rootScope = this.commonBlService.getRootScopeData();
    this.productName = this.rootScope.localUser?.isStandalone ? 'res_ProductNameForOpenScape' : 'res_ProductName';
  }

  ngOnInit(): void {
    this.isExtensionRunning = this.rootScope.browser.chrome && this.ExtensionSvc.isExtensionRunning();

    this.PubSubSvc.subscribe('/show/banner', this.repositionOverlay);
    this.overlayElements = document.querySelectorAll('.settings-overlay')!;
    this.repositionOverlay(this.rootScope.showBanner);
  }

  ngOnDestroy(): void {
    this.PubSubSvc.unsubscribe('/show/banner', this.repositionOverlay);
  }

  private openNewView = (name: string, htmlData: any) => {
    const newWindow = window.open('', name);
    if (newWindow) {
      newWindow.document.write(htmlData);
      newWindow.document.close();
    }
  };

  getLegalLink = (documentName: string) => {
    const language = this.LocalizeSvc.getLanguageOnly() === 'de' ? 'de' : 'en';
    const domain = 'unify.com';
    const type = language === 'de' ? 'impressum' : 'legal-information';
    const product = 'unify-phone';
    const document = language === 'de' ? this.documentMapDe[documentName] : this.documentMapEn[documentName];

    return `https://${domain}/${language}/${type}/${product}/${document}`;
  };

  getEulaLink = () => {
    const prefix = this.LocalizeSvc.getLanguageOnly() === 'de' ? 'de' : 'en';
    const eulaUrl = '/legal/unifyphone/EULA_' + prefix + '.html';
    return eulaUrl;
  };

  getTpsiLink = () => {
    const tpsiUrl = '/legal/unifyphone/TPSI.html';
    return tpsiUrl;
  };

  showExtensionLink = (name: string, chromeExt: string) => {
    const prefix = this.LocalizeSvc.getLanguageOnly() === 'de' ? 'de' : 'en';
    if (chromeExt === 'extn') {
      this.ExtensionSvc.getFileFromExtension(name, prefix, (err: any, file: any) => {
        if (err || !file) {
          this.LogSvc.error('[AboutCtrl]: Failed to get EULA data. ', err || 'empty file');
          this.dialogService.error({message: 'res_EulaLinkWebError'});
        } else {
          this.openNewView(name + chromeExt, file.data);
        }
      });
    }
  };

  private repositionOverlay = (showBanner: boolean) => {
    this.utilsService.conditionallyToggleClassOnElements(this.overlayElements, showBanner, 'with-banner');
  };
}
