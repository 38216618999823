import { Component, OnInit, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { DialogService } from '../../sub-modules/dialog/dialog.service';
import { ContactsComponent } from '../contacts/contacts.component';

declare let Circuit: any;

@Component({
  selector: 'app-call-transfer',
  templateUrl: './call-transfer.component.html',
  styleUrls: ['./call-transfer.component.scss']
})
export class CallTransferComponent implements OnInit {
  @ViewChild(ContactsComponent) private contactsComponent!: ContactsComponent;
  private LogSvc: any;
  private PhoneCallSvc: any;

  value = '';
  waitingTransferResponse = false;
  isDialable = false;
  isValueSearchable = false;
  root: any;

  @Output('parentToggleTransfer') parentToggleTransfer: EventEmitter<any> = new EventEmitter();
  @Input() call: any;

  constructor(private commonBlService: CommonBLService, private dialogService: DialogService, private _snackBar: MatSnackBar) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PhoneCallSvc = Circuit.serviceInstances.phoneCallSvc;
  }

  ngOnInit(): void {
    this.root = this.commonBlService.getRootScopeData();
  }

  transferCall = (number: string): Promise<any> | null => {
    if (!number) {
      this.LogSvc.warn('[CallTransferComponent]: Cannot initiate a call without a number');
      return null;
    }

    this.LogSvc.buttonPressed('[CallTransferComponent]: Transfer calls');
    this.waitingTransferResponse = true;

    return this.PhoneCallSvc.transferCall(this.call.callId, number)
      .then(() => {
        this.openSnackBar(this.root.i18n.map.res_CallTransferred, '');
        this.value = '';
        this.goBack();
      })
      .catch((error: any) => {
        this.LogSvc.error('[CallTransferComponent]: transferCall() error', error);
        this.dialogService.handleCallError(error, null);
      })
      .finally(() => {
        this.waitingTransferResponse = false;
        //this.goBack();
      });
  };

  private openSnackBar = (message: string, action: string) => {
    this._snackBar.open(message, action, {
      duration: 3000
    });
  };

  goBack() {
    this.parentToggleTransfer.emit('Transfer');
  }

  setValue(value: any) {
    this.value = value;
    if (this.value.match(Circuit.Utils.PHONE_DIAL_PATTERN)) {
      this.isDialable = true;
    } else {
      this.isDialable = false;
    }
  }

  isSearchable(isSearchable: any) {
    this.isValueSearchable = isSearchable;
  }

  searchContact(event: any) {
    this.contactsComponent.handleKeydown(event);
  }
}
