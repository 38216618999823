/* eslint-disable no-useless-constructor */
/* eslint-disable @typescript-eslint/no-empty-function */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExchangeOnlineService } from 'projects/main-app/src/app/exchange-online.service';
import { ExchangeOnPremiseService } from 'projects/main-app/src/app/services/exchange-on-premise.service';
import { DialogService } from 'projects/main-app/src/app/sub-modules/dialog/dialog.service';

declare let Circuit: any;
declare global {
  interface Window { __clientVersion: any; }
}

window.__clientVersion = window.__clientVersion || {};

declare global {
  interface Window { __clientVersion: any; }
}

window.__clientVersion = window.__clientVersion || {};

class Deferred {
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
    this.promise.finally(() => {
      this.fulfilled = true;
    });
  }
  resolve: any;
  reject: any;
  promise: Promise<any>;
  fulfilled = false;
  notify = () => {
  };
}
class QPromise<T> extends Promise<T> {
  constructor(executor:any) {
    super(executor);
  }
  static defer = () => new Deferred();
  static when = (pr?: any) => Promise.resolve(pr);
}

@Injectable({
  providedIn: 'root'
})
export class CommonBLService {
  private initData:any = {};
  private injectedServices: any = {};

  constructor(private http: HttpClient,
              private dialogService: DialogService,
              private exchangeOnlineService: ExchangeOnlineService,
              private exchangeOnPremiseService: ExchangeOnPremiseService) {
    this.http = http;
    this.dialogService = dialogService;
    this.injectedServices.ExchangeOnlineSvc = exchangeOnlineService;
    this.injectedServices.ExchangeOnPremiseSvc = exchangeOnPremiseService;
  }

  private getHttpFn(http: HttpClient) {
    function wrapResult(httpReq: any): Promise<any> {
      return new Promise((resolve, reject) => {
        httpReq.then(function (data: any, status: any) {
          resolve({
            data: data,
            status: status || 200
          });
        })
        .catch(function (error: HttpErrorResponse) {
          reject({
            data: { value: error.message},
            status: error.status,
            statusText: error.statusText
          });
        });
      });
    }
    const fn = function (data?: any): Promise<any> {
      if (!data) {
        return Promise.reject('Invalid parameters');
      }
      switch (data.method) {
      case 'GET':return wrapResult(http.get(data.url).toPromise());
      case 'POST':{
        const config = {withCredentials: data.withCredentials };
        return wrapResult(http.post(data.url, data.params, config).toPromise());
      }
      case 'DELETE':return wrapResult(http.get(data.url).toPromise());
      default:return Promise.reject('HTTP protocol not supported');
      }
    };
    Object.getPrototypeOf(fn).get = (url:any, config:any) => wrapResult(http.get(url, config).toPromise());
    Object.getPrototypeOf(fn).post = (url:any, data:any, config:any) => wrapResult(http.post(url, data, config).toPromise());
    Object.getPrototypeOf(fn).delete = (url:any, config:any) => wrapResult(http.delete(url, config).toPromise());
    Object.getPrototypeOf(fn).ajax = (data:any) => {
      if (data) {
        if (data.dataType === 'GET') {
          const config = {withCredentials: data.xhrFields.withCredentials, crossDomain: data.crossDomain };
          return wrapResult(http.get(data.url, config).toPromise());
        }
        return Promise.reject('HTTP protocol not supported');
      }
      return Promise.reject('Invalid parameters');
    };

    return fn;
  }

  private getRootScopeFn() {
    return {
      clientVersion: window.__clientVersion,
      isRegistered: false,
      isChromeApp: true,
      isMobileApp: false,
      localUser: null,
      webRtcEnabled: true,
      browser: Circuit.Utils.getBrowserInfo(),
      // Angular interfaces
      $$phase: true,
      $apply: function (callback:any) {
        callback && callback();
      },
      $digest: function () {
      }
    };
  }

  getInstance(serviceName: string) {
    return Circuit.serviceInstances[serviceName];
  }

  getRootScopeData() {
    return this.initData.rootScope;
  }

  getPopupFn() {
    return {
      openCustomModal: this.dialogService.openCustomModal,
      openGenericModal: this.dialogService.openGenericModal,
      info: this.dialogService.info,
      closeInfo: this.dialogService.closeInfo,
      error: this.dialogService.error,
      confirm: this.dialogService.confirm,
      alert: this.dialogService.alert,
      handleCallError: this.dialogService.handleCallError,
      confirmDeletion: this.dialogService.confirmDeletion
    };
  }

  private getInjectedSvcInstances = (svcName: string) => this.injectedServices[svcName];

  private getInjectorFn() {
    return {
      get: this.getInjectedSvcInstances
    };
  }

  init(): Promise<void> {
    this.initData.promise = QPromise;
    this.initData.timeout = setTimeout.bind({});
    this.initData.timeout.cancel = clearTimeout.bind({});

    const customIntervalFn: any = setInterval.bind({});
    customIntervalFn.cancel = clearInterval.bind({});

    this.initData.interval = customIntervalFn;
    this.initData.http = this.getHttpFn(this.http);
    this.initData.rootScope = this.getRootScopeFn();
    this.initData.popupFn = this.getPopupFn();
    this.initData.injectorFn = this.getInjectorFn();

    Circuit.initBL(this.initData);
    this.exchangeOnlineService.init();
    this.exchangeOnPremiseService.init();
    return Promise.resolve();
  }
}
