/* eslint-disable no-unused-expressions */
import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, QueryList, Renderer2, SimpleChanges, ViewChildren } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';

declare let Circuit: any;

const MAX_VOLUME = 0.5;
const NUMBER_OF_DOTS = 8;
// we will use this to divide for the dots we have 0,5/8 = 0,05..
const VOLUME_PER_DOT = MAX_VOLUME / NUMBER_OF_DOTS;
// FF doesn't allow invoking getUserMedia on 2 different mics, so we need to first stop
// the first mic's stream and wait a little before invoking getUserMedia again
// https://bugzilla.mozilla.org/show_bug.cgi?id=1238038
const FIREFOX_GETUSERMEDIA_DELAY = 1000;
const START_STREAM_DELAY = 500;

@Component({
  selector: 'app-microphone-activity',
  templateUrl: './microphone-activity.component.html',
  styleUrls: ['./microphone-activity.component.scss']
})
export class MicrophoneActivityComponent implements OnInit, OnDestroy {
  private LogSvc: any;
  private DeviceHandlerSvc: any;

  rootScope: any;
  _audioMediaStream: any;
  _audioInput: any;
  _inputPoint: any;
  _analyser: any;
  _processorNode: any;
  _pendingStartStream: any = null;
  _audioCtx: any;
  dotsRefArray: any;
  dots = new Array(NUMBER_OF_DOTS); // NOSONAR

  @Input() deviceCategory!: any;
  @Input() deviceId: any | undefined;
  @ViewChildren('activityDot') activityDotRefs!: QueryList<ElementRef>;
  @HostListener('destroy', ['$event'])
  onEvent(event: any) {
    if (event.type === 'destroy') { this.ngOnDestroy(); }
  }

  constructor(private commonBlService: CommonBLService, private renderer: Renderer2) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.DeviceHandlerSvc = Circuit.serviceInstances.deviceHandlerSvc;
    this.rootScope = this.commonBlService.getRootScopeData();
  }

  ngOnInit(): void {
    this.LogSvc.info('[MicrophoneActivityComponent]: ngOnInit');
  }

  ngAfterViewInit() {
    this.dotsRefArray = this.activityDotRefs.toArray();
  }

  private initializeWorklet(): Promise<AudioWorkletNode> {
    return this._audioCtx.audioWorklet.addModule('/dist/main-app/assets/js/worklet-processor.js').then(() => {
      // Creating AudioWorkletNode sending context
      // and name of processor registered in worklet-processor.js
      this._processorNode = new AudioWorkletNode(this._audioCtx, 'worklet-processor');
      // Listing any message from AudioWorkletProcessor in its
      // process method here where you can know
      this._processorNode.port.onmessage = (event: any) => {
        if (event.data.volume) { this.volumeDots(event.data.volume); }
      };
      return Promise.resolve(this._processorNode);
    })
      .catch((err: any) => {
        this.LogSvc.error('[MicrophoneActivityComponent]: Error AudioWorklet Module ', err);
        return Promise.reject(this._processorNode);
      });
  }

  volumeDots = (volume: number) => {
    // we calculate based on maxVolume = 0,2
    // number of dots 8
    // 0,25/8 = 0,025
    const range = this.dotsRefArray.slice(0, Math.round(volume / VOLUME_PER_DOT));
    let i;
    for (i = 0; i < NUMBER_OF_DOTS; i++) {
      const dotEl = this.dotsRefArray[i]?.nativeElement;
      if (range.length > 0 && i <= range.length) {
        dotEl?.classList ? this.renderer.addClass(dotEl, 'active') : false;
      } else {
        dotEl?.classList ? this.renderer.removeClass(dotEl, 'active') : false;
      }
    }
  };

  stopStream = async () => {
    if (this._audioMediaStream) {
      try {
        this.LogSvc.debug('[MicrophoneActivityComponent]: Stopping old media stream');
        this._audioInput.disconnect();
        this._inputPoint.disconnect();
        this._processorNode.disconnect();

        await Circuit.WebRTCAdapter.stopMediaStream(this._audioMediaStream);
        this._audioMediaStream = null;
      } catch (error) {
        this.LogSvc.error('[MicrophoneActivityComponent]: Error stopping media stream. ', error);
      }
      return true;
    }
    return false;
  };

  getUserMedia = (constraints: any, id: string) => {
    this.DeviceHandlerSvc.getUserMedia(constraints,
      (stream: any) => {
        // check if no new stream was started in between
        if (id !== this.deviceId) {
          Circuit.WebRTCAdapter.stopMediaStream(stream);
          return;
        }
        this._audioMediaStream = stream;
        const audioContext = this.createAudioContext(stream);
        if (audioContext) {
          this.initializeWorklet()
          .then((/* node */) => {
            this._inputPoint.connect(this._processorNode); // connect our microphone to the AudioWorkletNode
            this._processorNode.connect(audioContext.destination); // connect our AudioWorkletNode to the output from audioContext
          });
        }
        this.LogSvc.info('[MicrophoneActivityComponent]: Media stream has been successfully started');
      },
      (err: string) => {
        this.LogSvc.error('[MicrophoneActivityComponent]: Failed to access user media: ', err);
      });
  };

  private createAudioContext(stream: MediaStream): AudioContext | undefined {
    // For more details about the Audio context you can find here:
    // https://riptutorial.com/web-audio
    this._audioCtx = this.DeviceHandlerSvc.getAudioContext();
    if (!this._audioCtx) {
      this.LogSvc.error('[MicrophoneActivityComponent]: Component cannot be used without AudioContext object.');
      this.ngOnDestroy();
      return undefined;
    }
    this.LogSvc.debug('[MicrophoneActivityComponent]: New MicrophoneActivityComponent AudioContext object');
    // Important before we connect audioInput (analyser, gain),
    // we need to set it from the stream
    this._audioInput = this._audioCtx.createMediaStreamSource(stream);
    this._analyser = this._audioCtx.createAnalyser();
    this._inputPoint = this._audioCtx.createGain();
    this._audioInput.connect(this._analyser);
    this._audioInput.connect(this._inputPoint);
    return this._audioCtx;
  }

  startStream = async (id: any) => {
    this.LogSvc.info('[MicrophoneActivityComponent]: Starting new media stream');

    // Stop previous stream if active
    const delay = await this.stopStream() && this.rootScope.browser.firefox;
    this.deviceId = id;

    const constraints = {
      audio: { sourceId: id },
      video: false
    };

    if (delay) {
      setTimeout(() => {
        this.getUserMedia(constraints, id);
      }, FIREFOX_GETUSERMEDIA_DELAY);
    } else {
      this.getUserMedia(constraints, id);
    }
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.deviceId) {
      this.LogSvc.debug('[MicrophoneActivityComponent]: New deviceId', changes.deviceId?.currentValue);
      if (this._pendingStartStream) {
        clearTimeout(this._pendingStartStream);
        this._pendingStartStream = null;
      }
      if (!changes.deviceId?.currentValue) {
        // We might get 2 consecutive events. Wait 500ms so we process only the second event
        this._pendingStartStream = setTimeout(() => {
          this._pendingStartStream = null;
          this.startStream(changes.deviceId?.currentValue);
        }, START_STREAM_DELAY);
      } else {
        this.startStream(changes.deviceId?.currentValue);
      }
    }
  }

  ngOnDestroy(): void {
    this.LogSvc.debug('[MicrophoneActivityComponent]: Destroy MicrophoneActivityComponent instance');
    this.stopStream();
    this._audioInput = null;
    this._inputPoint = null;
    this._processorNode = null;

    this.LogSvc.debug('[MicrophoneActivityComponent]: Destroying AudioContext instance');
    this._audioCtx?.close();
    this._audioCtx = null;
  }
}
