/* eslint-disable no-shadow */
import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { DialogService } from '../../sub-modules/dialog/dialog.service';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { UtilsService } from '../../services/utils.service';

declare let Circuit: any;

const MAX_FILE_SIZE = 15; // 15 MB
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE * 1024 * 1024;
const CUT_OUT_SIZES = {
  s: 80,
  b: 250
};


@Component({
  selector: 'app-edit-profile-picture',
  templateUrl: './edit-profile-picture.component.html',
  styleUrls: ['./edit-profile-picture.component.scss']
})
export class EditProfilePictureComponent implements OnInit {
  private LogSvc;
  private PictureEditSvc;
  private UserProfileSvc: any;
  private FileUploadSvc: any;
  initials: string | undefined;
  color: string | undefined;
  hasImageError = false;
  editProfilePic = false;
  currentImage = '';
  wasCleared = false;
  titleLabel = 'res_ChangeProfilePicture';
  showDropArea = false;
  imageSrc = '';
  rawImage = null;
  avatarImageFormat = '';
  warningMessage = '';
  imageChangedEvent: any = '';
  croppedImage: any = '';
  translateH = 0;
  translateV = 0;
  transform: ImageTransform = {};
  canvasRotation = 0;
  isLoading = false;
  private _photoBoothSelectedImages: any;

  mode = 'view';
  user: any;
  rootScope: any;

  @ViewChild('pictureInput') private pictureInput: any;
  @ViewChild('canvas') canvas!: ElementRef;
  @ViewChild('image') image!: ElementRef;
  @ViewChild('edit') private edit: any;

  constructor(private commonBlService: CommonBLService,
              private dialogService: DialogService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private mdDialogRef: MatDialogRef<EditProfilePictureComponent>,
              private utilsService: UtilsService) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PictureEditSvc = Circuit.serviceInstances.pictureEditSvc;
    this.UserProfileSvc = Circuit.serviceInstances.userProfileSvc;
    this.FileUploadSvc = Circuit.serviceInstances.fileUploadSvc;
    this.rootScope = this.commonBlService.getRootScopeData();
  }

  ngOnInit(): void {
    this.LogSvc.info('[EditProfilePictureComponent]: ngOnInit');
    this.user = this.rootScope.localUser;
    this.setAvatarInfo();
    if (this.user.hasAvatarPicture) {
      this.currentImage = this.user.avatarLarge;
    }
  }

  private setAvatarInfo = () => {
    this.color = this.user?.avatarColor || 'blue';
    if ((this.user?.hasAvatarPicture || this.user?.initials) && !this.hasImageError) {
      return;
    }
    if (this.user?.firstName && this.user?.lastName) {
      this.createInitials();
    }
  };

  // Function that validates of the uploaded image and shows error message popup
  private validateImageFile = (files: any) => {
    if (!files[0]) { return; }

    const filename = files[0].name;
    let errMessage;

    if (this.hasInvalidFileType(filename)) {
      errMessage = this.rootScope.i18n.localize('res_InvalidFileTypeMsg', [filename]);
    } else if (!files[0].type.includes('image')) {
      errMessage = filename + this.rootScope.i18n.map.res_FileIsNotImageMsg;
    } else if (!Circuit.Utils.isSupportedImage(files[0].type)) {
      errMessage = filename + this.rootScope.i18n.map.res_InvalidImageTypeMsg;
    } else if (files[0].size >= MAX_FILE_SIZE_BYTES) {
      errMessage = this.rootScope.i18n.localize('res_FailedImageUploadSize', [MAX_FILE_SIZE]);
    } else {
      this.onFileSelected(files);
    }

    this.showDropArea = false;
    errMessage && this.dialogService.open({ message: errMessage, title: 'res_ErrorTitle' })
    .then((resp: any) => {
      this.LogSvc.info('[EditProfilePictureComponent]: show dialog', resp);
    })
    .catch((err: any) => {
      this.LogSvc.error('[EditProfilePictureComponent]: show dialog error', err);
    });
  };

  private hasInvalidFileType = (filename: string) => {
    const extension = filename.substr((Math.max(0, filename.lastIndexOf('.')) || Infinity) + 1);
    return Circuit.Utils.invalidFileTypes.indexOf(extension) !== -1 ? extension : null;
  };

  private onFileSelected = (data: any) => {
    // Switch to edit mode
    this.mode = 'edit';
    this.setTitle();

    this.resetRotation();
    const file = data[0]; // The picture editor only cares about one file
    const reader = new FileReader();
    reader.readAsDataURL(file); // toBase64
    reader.onload = () => {
      this.imageSrc = reader.result as string; // base64 Image src
    };

    return this.PictureEditSvc.loadImageFromFile(file).then(this.setRawImage);
  };

  private setRawImage = (image: any) => {
    // We should check png format for saving transparent image
    this.avatarImageFormat = this.utilsService.getAvatarImageFormat(image.currentSrc || image.href);

    this.warningMessage = Math.min(image.width, image.height) <= CUT_OUT_SIZES.b ?
      this.rootScope.i18n.map.res_LowQualityPicture : '';

    this.rawImage = image;
  };

  @HostListener('drop', ['$event']) private drop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    const files = [...event.dataTransfer?.files];

    //check invalid file formats for image upload
    this.validateImageFile(files);
  }

  setTitle = () => {
    switch (this.mode) {
    case 'view':
      this.titleLabel = 'res_ChangeProfilePicture';
      break;
    case 'webcam':
      this.titleLabel = 'res_TakePicture';
      break;
    case 'edit':
      this.titleLabel = 'res_CropPicture';
      break;
    }
  };

  createInitials = () => {
    this.initials = (this.user.firstName[0] + this.user.lastName[0]).toUpperCase();
  };

  getInitials = () => {
    const initials = this.user?.initials || this.initials;
    return initials;
  };

  onAvatarPictureError = ($event: any) => {
    this.hasImageError = true;
    this.setAvatarInfo();
  };

  browsePicture = (event: any) => {
    this.LogSvc.buttonPressed('[EditProfilePictureComponent]: Browse picture');
    const entries = this.pictureInput.nativeElement.getAttribute('webktiEntries') || this.pictureInput.nativeElement.getAttribute('entries');
    let files = event.target.files;

    // Logic for getting the file object loaded
    if (entries && entries.length) {
      // No need to handle file tree entries
      return;
    } else if (!files.length) {
      const value = this.pictureInput.nativeElement.value;
      // If the files property is not available, the browser does not
      // support the File API and we add a pseudo File object with
      // the input value as name with path information removed:
      files = [{name: value.replace(/^.*\\/, '')}];
    }

    //check invalid file formats for image upload
    this.validateImageFile(files);
  };

  clearPicture = () => {
    this.LogSvc.buttonPressed('[EditProfilePictureComponent]: clearPicture');
    this.currentImage = '';
    this.wasCleared = true;
  };

  takePicture = () => {
    this.LogSvc.buttonPressed('[EditProfilePictureComponent]: Take new picture');
    this.mode = 'webcam';
    this.setTitle();
  };

  showBack = () => this.mode === 'webcam' || this.mode === 'edit';

  back = () => {
    this.mode = 'view';
    this.setTitle();
    this.warningMessage = '';
  };

  isSavePossible = () => {
    switch (this.mode) {
    case 'view':
      return !!this.wasCleared;
    case 'webcam':
      return !!this._photoBoothSelectedImages?.b;
    case 'edit':
      return true;
    default:
      return false;
    }
  };

  save = () => {
    let images, savePromise;
    this.warningMessage = '';
    this.isLoading = true;
    const doneLoading = () => {
      this.isLoading = false;
    };

    if (this.mode === 'edit') {
      images = this.getImages();
      savePromise = this.saveFn(images);
    } else if (this.mode === 'webcam') {
      images = this._photoBoothSelectedImages;
      savePromise = this.saveFn(images);
    } else if (this.mode === 'view' && this.wasCleared) {
      savePromise = this.clear();
    }

    if (savePromise) {
      savePromise.then(doneLoading, (err: any) => {
        if (err === 'deleteFailed') {
          this.warningMessage = this.rootScope.i18n.map.res_DeletePictureError;
        } else if (err === 'uploadFailed') {
          this.warningMessage = this.rootScope.i18n.map.res_UploadFilesFailed;
        } else {
          this.warningMessage = this.rootScope.i18n.map.res_ProfileUpdateFailed;
        }
        doneLoading();
      });
    } else {
      doneLoading();
    }
  };

  clear = () => this.saveProfile('', '').finally(() => {
    this.close(true);
  });

  private saveProfile(smallFileId: any, largeFileId: any) {
    return new Promise((resolve, reject) => {
      this.LogSvc.debug('[EditProfilePictureComponent]: Sending request to update the user');
      const userData = {
        smallImageUri: smallFileId,
        largeImageUri: largeFileId
      };

      this.UserProfileSvc.updateUserProfile(userData, (err: any, user: any) => {
        if (err || !user) {
          this.LogSvc.error('[EditProfilePictureComponent]: Failed to update user profile. ', err);
          reject(err);
          return;
        }
        this.LogSvc.debug('[EditProfilePictureComponent]: User profile was successfully updated');
        resolve(user);
      });

    });
  }

  saveFn(avatars: any) {
    return new Promise<void>((resolve, reject) => {
    // Upload the pictures and then save the avatar ids to profile
      this.FileUploadSvc.uploadAvatarsV2(avatars)
    .then((uploadedAvatars: any) => {
      this.LogSvc.debug('[EditProfilePictureComponent]: Uploaded avatars - ', uploadedAvatars);
      this.saveProfile(uploadedAvatars.s.fileId, uploadedAvatars.b.fileId)
        .then(() => {
          resolve();
          this.close(true);
        })
        .catch(function (err: any) {
          reject(err);
        });
    })
    .catch(() => {
      this.LogSvc.info('[EditProfilePictureComponent]: Upload avatars failed');
      reject('uploadFailed');
    });
    });
  }

  close = (value: boolean) => {
    this.LogSvc.info('[EditProfilePictureComponent] Dialog is being closed');
    this.mdDialogRef.close(value);
  };

  imageCropped = (event: ImageCroppedEvent) => {
    this.croppedImage = event.base64;
  };

  fileChangeEvent = (event: any) => {
    this.imageChangedEvent = event;
  };

  resetRotation = () => {
    //reset rotation
    this.transform = {};
    this.canvasRotation = 0;
  };

  rotate = () => {
    this.canvasRotation++;
    this.flipAfterRotate();
  };

  private flipAfterRotate = () => {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  };

  getImages = () => {
    // Draws the cut-out in the required output sizes
    const cropImage = this.image.nativeElement;
    const canvas = this.canvas.nativeElement;
    const context = canvas.getContext('2d', { willReadFrequently: true });
    const output:any = {};

    Object.entries(CUT_OUT_SIZES).forEach(([key, value]) => {
      canvas.width = value;
      canvas.height = value;
      const scaling = value / cropImage.width;

      context.scale(scaling, scaling);
      context.drawImage(this.image.nativeElement, 0, 0);
      output[key] = canvas.toDataURL('image/' + this.avatarImageFormat);
    });
    return output;
  };

  //Photobooth related
  getPhotoBoothImages = (images:any) => {
    this._photoBoothSelectedImages = images;
    this.isSavePossible();
  };


  onDragEventParent(event: any) {
    this.editProfilePic = event.editProfilePic;
    this.showDropArea = event.showDropArea;
  }
}
