import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';

declare let Circuit: any;

// eslint-disable-next-line no-use-before-define
const DeviceCategory: IDeviceCategoryIndex = {
  RINGING_OUTPUT: {
    soundType: Circuit.SoundType.INCOMING_CALL_RINGING,
    deviceList: 'ringingDevices'
  }
};

interface IDeviceCategory {
  deviceList: string;
  soundType: {
    category: string;
    mobile: boolean;
    playMode: string;
    repeat: boolean;
    soundFile: string;
    soundName: string;
    vibrate: boolean;
  };
}

interface IDeviceCategoryIndex {
  [index: string]: IDeviceCategory;
}

@Component({
  selector: 'app-ringtones',
  templateUrl: './ringtones.component.html',
  styleUrls: ['./ringtones.component.scss']
})
export class RingtonesComponent implements OnInit {
  private LogSvc: any;
  private SoundSvc: any;

  root: any;
  ringtoneList: any = [];
  selectedRingtone = '';
  category = 'RINGING_OUTPUT';

  constructor(private commonBlService: CommonBLService) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.SoundSvc = Circuit.serviceInstances.soundSvc;
    this.root = this.commonBlService.getRootScopeData();
  }

  ngOnInit(): void {
    this.ringtoneList = Circuit.Ringtones;
    this.selectedRingtone = this.SoundSvc.ringtone || this.ringtoneList[0];
    this.LogSvc.debug('[RingtonesComponent]: selectedRingtone', this.selectedRingtone);
  }

  onSelectRingtone = (ringtone: any) => {
    this.SoundSvc.ringtone = ringtone;
    this.selectedRingtone = this.SoundSvc.ringtone;
  };
}
