export class Tabs {
  callLog: any;
  dialpad: any;
  contacts: any;

  constructor() {
    // Set default values
    this.callLog = {
      name: 'callLog',
      icon: 'access_time',
      route: 'callhistory',
      uLocalize: 'res_CallLog',
      hasBadge: true
    };
    this.dialpad = {
      name: 'dialpad',
      icon: 'dialpad',
      route: 'dialpad',
      uLocalize: 'res_Keypad'
    };
    // this.contacts = {
    //     name: 'contacts',
    //     icon: 'contact_page',
    //     route: 'contacts',
    //     uLocalize: 'res_Contacts'
    // };
  }

  getTabsArray() {
    return [this.callLog, this.dialpad];
  }
}
