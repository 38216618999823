import { Component, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { UtilsService } from '../../services/utils.service';

declare let Circuit: any;

const helpFiles = {
  unifyphone: {
    osbiz: {
      userGuide: 'unifyphone/osbiz/unifyphone_osbiz_userguide'
    },
    osv4k: {
      userGuide: 'unifyphone/osv-4k/unifyphone_osv-4k_userguide'
    }
  },
  android: {
    osbiz: {
      userGuide: 'android/osbiz/android_osbiz_userguide'
    },
    osv4k: {
      userGuide: 'android/osv-4k/android_osv-4k_userguide'
    }
  },
  ios: {
    osbiz: {
      userGuide: 'ios/osbiz/ios_osbiz_userguide'
    },
    osv4k: {
      userGuide: 'ios/osv-4k/ios_osv-4k_userguide'
    }
  }
};

@Component({
  selector: 'app-help-overlay',
  templateUrl: './help-overlay.component.html',
  styleUrls: ['./help-overlay.component.scss']
})
export class HelpOverlayComponent implements OnInit {
  private PubSubSvc: any;
  private LocalizeSvc: any;
  private overlayElements: any = [];
  private language = 'en';

  rootScope: any;
  productName = '';

  constructor(private commonBlService: CommonBLService, private utilsService: UtilsService) {
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this.LocalizeSvc = Circuit.serviceInstances.localizeSvc;
    this.rootScope = this.commonBlService.getRootScopeData();
    this.productName = this.rootScope.localUser?.isStandalone ? 'res_ProductNameForOpenScape' : 'res_ProductName';
  }

  ngOnInit(): void {
    this.PubSubSvc.subscribe('/show/banner', this.repositionOverlay);
    this.overlayElements = document.querySelectorAll('.settings-overlay')!;
    this.repositionOverlay(this.rootScope.showBanner);
    this.language = this.LocalizeSvc.getLanguageOnly();
  }

  getHelpLink = (client: 'unifyphone' | 'android' | 'ios', topic: 'userGuide', type: 'pdf' | 'html') => {
    const pbx = this.rootScope.localUser?.isOsBizCTIEnabled ? 'osbiz' : 'osv4k';
    const path = helpFiles[client][pbx][topic];

    let url = '';

    if (this.language === 'ca') {
      url = `/help/${path}_es`;
    } else {
      url = `/help/${path}_${this.language}`;
    }

    let filetype = '';

    switch (type) {
    case 'pdf':
      filetype = '.pdf';
      break;
    case 'html':
      filetype = '/index.htm';
      break;
    }

    return url + filetype;
  };

  private repositionOverlay = (showBanner: boolean) => {
    this.utilsService.conditionallyToggleClassOnElements(this.overlayElements, showBanner, 'with-banner');
  };
}
