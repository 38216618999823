import { Component, EventEmitter, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { ExchangeOnlineService } from '../../exchange-online.service';

declare let RegistrationState: any;

@Component({
  selector: 'app-settings-tabs',
  templateUrl: './settings-tabs.component.html',
  styleUrls: ['./settings-tabs.component.scss']
})
export class SettingsTabsComponent implements OnInit {
  rootScope: any;
  registrationState: any = RegistrationState;
  tabChangedEvent: EventEmitter<boolean> = new EventEmitter();

  constructor(private commonBLService: CommonBLService, private exchangeOnlineService: ExchangeOnlineService) {
    this.rootScope = this.commonBLService.getRootScopeData();
  }

  ngOnInit(): void {
    this.resetExchangeError();
  }

  resetExchangeError = () => {
    // we need to reset the error message if any for exchange online service
    const hasExchangeError = !!this.exchangeOnlineService.getLastConnectionError();
    if (hasExchangeError) {
      this.exchangeOnlineService.resetConnectionError();
    }
  };

  onTabChangeHandler = (event: any) => {
    this.resetExchangeError();
  };

}
