import { Component, NgZone, OnDestroy, OnInit, Input, Output, ApplicationRef, HostListener, QueryList, ViewChildren } from '@angular/core';
import { CallLogTabs } from '../../shared/callLogTabs';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DialogService } from '../../sub-modules/dialog/dialog.service';
import { UtilsService } from '../../services/utils.service';
import { FocusKeyManager } from '@angular/cdk/a11y';
import { CallLogListItemComponent } from '../call-log-list-item/call-log-list-item.component';
import { CallActionService } from '../../services/call-action.service';


declare let Circuit: any;
const DISPLAY_PAGE_SIZE = 25;
const MIN_ITEMS_IN_FEED = 20;
@Component({
  selector: 'app-call-history',
  templateUrl: './call-history.component.html',
  styleUrls: ['./call-history.component.scss']
})
export class CallHistoryComponent implements OnInit, OnDestroy {
  @Input() dialable = true;

  @Output() callList!: Array<any>;
  private LogSvc: any;

  root: any;
  i18n: any;
  selectedTab: string;
  Tabs: any;
  tabs: any[];
  deleteInProgress = false;
  unreadAfter: any = null;
  phoneConv: any;
  loadInProgress = false;
  showBanner = false;
  atosSign: any = 'unify-phone-logo';

  private PhoneCallLogSvc;
  private PopupSvc;
  private lastReadTimestamp = 0;
  private hasMore = true;
  private pagePointer: any = null;
  private PubSubSvc: any;
  @ViewChildren(CallLogListItemComponent)
    items!: QueryList<CallLogListItemComponent>;
  private keyManager!: FocusKeyManager<CallLogListItemComponent>;

  @HostListener('keydown', ['$event'])
  manage(event: any) {
    this.keyManager.onKeydown(event);
  }

  constructor(private commonBlService: CommonBLService,
              private dialogService: DialogService,
              private utilsService: UtilsService,
              private ngZone: NgZone,
              private ref: ApplicationRef,
              private callActionService: CallActionService) {
    this.Tabs = new CallLogTabs(Circuit.Constants.JournalFilter);
    this.tabs = this.Tabs.getTabsArray();
    this.selectedTab = this.tabs[0].name;
    this.root = this.commonBlService.getRootScopeData();
    this.i18n = this.root.i18n;
    this.utilsService.createInterval();

    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PhoneCallLogSvc = Circuit.serviceInstances.phoneCallLogSvc;
    this.PopupSvc = Circuit.serviceInstances.popupSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this.callList = [];
  }

  private onItemsCacheCleared = () => {
    this.LogSvc.debug('[CallHistoryComponent]: Received /callHistory/items/cacheCleared event. Clear list.');
    this.resetCallHistory();
  };

  private onItemAdded = (item: any, applicableFilters: any) => {
    if (this.callList && (this.selectedTab === Circuit.Constants.JournalFilter.ALL || applicableFilters.includes(this.selectedTab))) {
      this.LogSvc.debug('[CallHistoryComponent]: Received /callHistory/item/added event. Add to list.');
      this.callList.unshift(item);
      if (item.includeInUnreadCount) {
        this.markItemsAsRead();
      }
    }
  };

  private clearUnreadIndicator = () => {
    this.LogSvc.debug('[CallHistoryComponent]: Window focus in phone calls view. Automatically mark items as read.');
    this.PhoneCallLogSvc.markCallHistoryAsRead()
      .catch(() => {
        this.LogSvc.error('[CallHistoryComponent]: clearUnreadIndicator error');
      });
    window.removeEventListener('focus', this.clearUnreadIndicator, false);
  };

  private markItemsAsRead = () => {
    if (this.selectedTab !== Circuit.Constants.JournalFilter.ALL) {
      return;
    }

    if (Circuit.Utils.appIsFocused()) {
      this.LogSvc.debug('[CallHistoryComponent]: Mark items as read.');
      this.PhoneCallLogSvc.markCallHistoryAsRead()
      .catch(() => {
        this.LogSvc.error('[CallHistoryComponent]: Couldn\'t mark items as read.');
      });
    } else {
      // Mark item as read when the Ansible tab becomes visible
      window.addEventListener('focus', this.clearUnreadIndicator, false);
    }
  };

  private getCallHistoryItems = () => {
    if (this.loadInProgress) {
      return;
    }

    const queryData = {
      journalFilter: this.selectedTab,
      numberOfResults: DISPLAY_PAGE_SIZE,
      pagePointer: this.pagePointer
    };

    this.loadInProgress = true;
    this.PhoneCallLogSvc.getCallHistoryItems(queryData)
    .then((data: any) => {
      this.ngZone.run(() => {
        if (queryData.journalFilter === this.selectedTab) {
          if (!queryData.pagePointer) {
            // This is the first query
            this.markItemsAsRead();
          }
          this.callList.push(...data.items);
          this.hasMore = data.hasMore;
          this.pagePointer = data.pagePointer || null;
        }
      });
    })
    .catch((error: any) => {
      if (queryData.journalFilter === this.selectedTab) {
        this.LogSvc.error('[CallHistoryComponent]: Error retrieving items or not items available. ', error);
        this.PopupSvc.handleApiError(error);
      }
    })
    .finally(() => {
      this.loadInProgress = false;
      if (queryData.journalFilter !== this.selectedTab) {
        // Re-trigger the query
        // this.getCallHistoryItems();
      }
      this.keyManager = !this.keyManager && new FocusKeyManager(this.items).withWrap() || this.keyManager;
    });
  };

  private populateList(isInit?: boolean) {
    if (!this.phoneConv) {
      this.PhoneCallLogSvc.getTelephonyConversation()
      .then((conv: any) => {
        this.phoneConv = conv;
        this.lastReadTimestamp = (this.phoneConv && this.phoneConv.userData &&
          this.phoneConv.userData.lastReadTimestamp) || 0;
        isInit && (this.unreadAfter = this.lastReadTimestamp);
        this.getCallHistoryItems();
      });
      return;
    }
    this.getCallHistoryItems();
  }

  onTabSelected = (changeEvent: MatTabChangeEvent) => {
    this.selectedTab = this.tabs[changeEvent.index].name;
    this.callList.length = 0;
    this.pagePointer = null;
    this.populateList();
  };

  private onItemDeleted = (item: any) => {
    this.LogSvc.debug('[CallHistoryComponent]: Received /callHistory/item/deleted event. anyFwdEnabled = ', item);

    if (!this.callList) {
      return;
    }
    const idx = this.callList.indexOf(item);
    if (idx === -1) {
      return;
    }

    this.LogSvc.debug('[CallHistoryComponent]: Received /callHistory/item/deleted event');
    this.callList.splice(idx, 1);
    if (this.callList.length <= MIN_ITEMS_IN_FEED) {
      // Reload the feed
      this.resetCallHistory();
    }
  };

  private resetCallHistory = () => {
    this.callList.length = 0;
    this.hasMore = true;
    this.pagePointer = null;
    this.getCallHistoryItems();
  };

  deleteAll = () => {
    this.LogSvc.info('[CallHistoryComponent]: Delete call log history. Filter: ', this.selectedTab);

    this.dialogService.confirmDeletion('res_DeleteCallsConfirm_' + this.selectedTab)
      .then(() => {
        this.deleteInProgress = true;
        this.LogSvc.debug('[CallHistoryComponent]: Deleting call log history with filter ', this.selectedTab);
        this.PhoneCallLogSvc.deleteAllCallLogs(this.selectedTab)
        .catch((err: any) => {
          this.LogSvc.error('[CallHistoryComponent]: Error deleting call log history: ', err);
        })
        .finally(() => {
          this.deleteInProgress = false;
        });
      })
      .catch(() => {
        // Handle reject to prevent 'Possibly unhandled rejection' error
      });
  };

  loadMore = () => {
    if (this.loadInProgress) {
      this.LogSvc.debug('[CallHistoryComponent]: Load more items called but loading is already in progress.');
      // Something is wrong with this OR we are already loading more items
      return;
    }
    if (!this.hasMore) {
      // We already have all the data
      this.LogSvc.debug('[CallHistoryComponent]: Load more items called but we already have all items.');
      this.loadInProgress = false;
      return;
    }

    this.LogSvc.debug('[CallHistoryComponent]: Load more call history items');
    this.getCallHistoryItems();
  };

  onScrollToBottom() {
    this.ngZone.run(() => {
      this.loadMore();
    });
  }

  private updateShowBanner = (showBanner: boolean) => {
    this.showBanner = showBanner;
  };

  ngOnInit(): void {
    this.PubSubSvc.subscribe('/callHistory/items/cacheCleared', this.onItemsCacheCleared);
    this.PubSubSvc.subscribe('/callHistory/item/added', this.onItemAdded);
    this.PubSubSvc.subscribe('/callHistory/item/deleted', this.onItemDeleted);
    this.PubSubSvc.subscribe('/show/banner', this.updateShowBanner);
    this.updateShowBanner(this.root.showBanner);

    this.LogSvc.info('[CallHistoryComponent]: ngOnInit');
    this.selectedTab = this.tabs[0].name;
    this.populateList(true);
  }

  ngOnDestroy(): void {
    this.LogSvc.info('[CallHistoryComponent]: ngOnDestroy');
    this.utilsService.destroyInterval();

    this.PubSubSvc.unsubscribe('/callHistory/items/cacheCleared', this.onItemsCacheCleared);
    this.PubSubSvc.unsubscribe('/callHistory/item/added', this.onItemAdded);
    this.PubSubSvc.unsubscribe('/callHistory/item/deleted', this.onItemDeleted);
    this.PubSubSvc.unsubscribe('/show/banner', this.updateShowBanner);
  }

  canDial = () => this.callActionService.canDial();
}
