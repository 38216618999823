<div class="avatar-container">
  <div [hidden]="videoError"
      [ngClass]="{'video-wrapper': true, 'preview': !!previewImage, 'active': countDown}">
    <time class="countdown-label" role="timer" aria-live="assertive">{{countDown}}</time>
    <img *ngIf="!!previewImage" [src]="previewImage" alt="" draggable="false">
    <video #video [hidden]="!!previewImage" u-stream-remove (canplay)="handleCanPlay()"></video>
    <canvas #canvas></canvas>
  </div>
  <div class="video-error" [hidden]="!videoError">
    <h4 uLocalize="res_CannotConnectCamera"></h4>
    <p uLocalize="res_CannotConnectCameraPleaseCheck"></p>
  </div>
</div>

<div class="action-buttons">
  <button mat-stroked-button
      class="take-picture-button action-button"
      [attr.aria-label]="root.i18n.map.res_TakePicture"
      [disabled]="videoError || countDown"
      [ngClass]="{'active': !videoError && !previewImage && !countDown}"
      (click)="takePicture()">
      <mat-icon class="camera-icon">photo_camera</mat-icon>
  </button>
</div>

<ul class="gallery"
    role="listbox"
    cdkListbox
    cdkListboxOrientation="horizontal"
    [(ngModel)]="selectedImage"
    (cdkListboxValueChange)="setSelectedImage($event.value[0])"
    [ngClass]="{'open': images.length > 0}"
    aria-live="polite"
    aria-relevant="additions removals"
    uAriaLabel="res_AccessibilityPicturesTaken"
    >
  <li role="option"
      *ngFor="let img of images; index as i"
      [attr.aria-label]="root.i18n.localize('res_AccessibilityCreatedProfilePicture', [(i + 1)])">
    <img class="gallery-image"
        [cdkOption]="img"
        [src]="img.b"
        [attr.alt]="root.i18n.localize('res_AccessibilityCreatedProfilePicture', [(i + 1)])"
        draggable="false">
    </li>
</ul>
