<div class="container">
    <!-- Overview -->
    <div class="overview">
        <h3 uLocalize="res_Overview"></h3>
        <h4 uLocalize="res_LabsAboutDetails"></h4>
    </div>
    <!-- Features -->
    <div class="features">
        <div class="features-container">
            <h3 uLocalize="res_LabsFeaturesTitle"></h3>
            <h4 uLocalize="res_LabsFeaturesDetails"></h4>

            <div *ngFor="let feature of supportedFeatureList">
                <div class="features-item" *ngIf="showCircuitLabsFeature(feature.featureName)">
                    <div class="item-header">
                        <p class="subtitle no-margin">
                            {{i18n.map[feature.titleRes]}} {{feature.typeRes ? i18n.map[feature.typeRes] : ''}}
                        </p>
                        <mat-slide-toggle
                        [(ngModel)]="selectedFeatures[feature.featureName].enabled"
                        color="primary"
                        (change)="setCircuitLabsFeature(feature, $event)" ngDefaultControl name="feature.featureName"
                        [aria-label]="i18n.map[feature.titleRes]"
                        ></mat-slide-toggle>
                    </div>
                <p class="subheading-2" [uLocalizeOnce]="feature.detailsRes"></p>
                </div>
            </div>
        </div>
    </div>
</div>
