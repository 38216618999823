import { Component, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { DialogService } from '../../sub-modules/dialog/dialog.service';
import { CallActionService } from '../../services/call-action.service';

declare let Circuit: any;

@Component({
  selector: 'app-voicemail',
  templateUrl: './voicemail.component.html',
  styleUrls: ['./voicemail.component.scss']
})
export class VoicemailComponent implements OnInit {
  private LogSvc: any;
  private PhoneCallSvc: any;

  root: any;
  vmNumber: any;

  constructor(private commonBlService: CommonBLService, private dialogService: DialogService, private callActionService: CallActionService) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PhoneCallSvc = Circuit.serviceInstances.phoneCallSvc;
  }

  ngOnInit(): void {
    this.root = this.commonBlService.getRootScopeData();
  }

  listenVoiceMails = () => {
    this.dial(this.root.localUser.vmNumber);
  };

  private dial = (number?: string, name?: string, userId?: string) => {
    if (!number) {
      this.LogSvc.warn('[VoicemailComponent]: Cannot initiate a call wthout a number');
      return;
    }
    this.LogSvc.buttonPressed('[VoicemailComponent]: Make voicemail call to: ', {
      number: number,
      userId: userId
    });

    this.PhoneCallSvc.checkDisclaimerAndDial(number, name, userId)
      .catch((err: any, warn: any) => {
        this.LogSvc.debug('[VoicemailComponent]: User has rejected the PopUp');
        this.dialogService.handleCallError(err, warn);
      });
  };

  canDial = () => this.callActionService.canDial();
}
