import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[uAllowDrag]'
})
export class UAllowDragDirective {
  @Output() onDragEvent = new EventEmitter();
  dragAreaConfig: any = {
    editProfilePic: false,
    showDropArea: false
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  /// Name:    u-allow-drag
  /// Purpose: Directive to allow drag & drop on the element done
  /// Usage:   <div u-allow-drag></div>
  ///////////////////////////////////////////////////////////////////////////////////////////////////

  @HostListener('dragover', ['$event']) private dragover(event: any) {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = 'copy';
    this.dragAreaConfig.editProfilePic = true;
    this.dragAreaConfig.showDropArea = true;
    this.onDragEvent.emit(this.dragAreaConfig);
  }

  @HostListener('dragleave', ['$event']) private dragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.dragAreaConfig.editProfilePic = false;
    this.dragAreaConfig.showDropArea = false;
    this.onDragEvent.emit(this.dragAreaConfig);
  }

}
