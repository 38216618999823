import { Component, Input, OnInit } from '@angular/core';

declare let Circuit: any;
declare const navigator: any;
declare global {
  interface Window {
    opera: any;
    opr: any;
    chrome: any;
    MSStream: any;
  }
}

@Component({
  selector: 'app-external-link-button',
  templateUrl: './external-link-button.component.html',
  styleUrls: ['./external-link-button.component.scss']
})
export class ExternalLinkButton implements OnInit {
  private LogSvc: any;
  @Input() text: any | '';
  @Input() uri: any | '';
  @Input() image: any | '';

  constructor() {
    this.LogSvc = Circuit.serviceInstances.logSvc;
  }

  ngOnInit(): void {
  }

  failCallback = (error: any) => {
    this.LogSvc.debug(`[${this.text} Button]: ` + error);
  };

  createHiddenIframe = (target: any, uri: string) => {
    const iframe = document.createElement('iframe');
    iframe.src = uri;
    iframe.id = 'hiddenIframe';
    iframe.style.display = 'none';
    target.appendChild(iframe);

    return iframe;
  };

  openUriUsingIFrame = () => {
    let iframe = (document.querySelector('#hiddenIframe') as HTMLIFrameElement);
    if (!iframe && !document.body) {
      this.failCallback('No iframe in document body');
      return;
    } else {
      iframe = this.createHiddenIframe(document.body, 'about:blank');
    }

    try {
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.location.href = this.uri;
      }
    } catch (e: any) {
      this.failCallback(e);
    }
  };

  checkBrowser = () => {
    const isOpera = !!window.opera || /OPR/.test(navigator.userAgent);
    const isChromiumEdge = /Edg\//.test(navigator.userAgent);
    const isFirefox = /Firefox/.test(navigator.userAgent);
    return {
      isChromiumEdge,
      isFirefox,
      isChrome: !!window.chrome && !isOpera && !isChromiumEdge && !isFirefox
    };
  };

  openAppByUri = () => {
    if (this.uri.length === 0) { return; }
    const browser = this.checkBrowser();
    if (browser.isFirefox || browser.isChrome || browser.isChromiumEdge) {
      this.openUriUsingIFrame();
    } else {
      //not supported
      this.failCallback('Unsupported Browser / OS');
    }
  };
}
