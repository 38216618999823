<mat-toolbar-row class="header-container">
  <div class="title-container mat-headline">
    <mat-icon class="settings-icon md-35">help</mat-icon>
    <h1 uLocalize="res_Help"></h1>
  </div>
  <button mat-button #closebtn align="end" mat-dialog-close class="close-btn">
    <span uAriaLabel="res_AccessibilityCloseModal" role="button"></span>
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar-row>
<mat-divider></mat-divider>

<div class="container">
  <div class="content-wrapper">
    <div class="info-container">
      <img class="unify-logo" alt="Unify Phone Logo" src="/dist/main-app/assets/img/unify-phone-logo.svg" id="brandLogo">
      <h2 class="product-name">{{rootScope.i18n.localize(productName)}}</h2>
    </div>

    <mat-divider class="line"></mat-divider>

    <section class="client-section">
      <h3 uLocalize="res_UserGuideTitleWeb"></h3>
      <p class="subtitle subheading-2 description" uLocalize="res_UserGuideDescriptionWeb"></p>
      <ul class="links-list">
        <li>
          <mat-icon class="html-icon" alt="HTML">language</mat-icon>
          <a class="subtitle subheading-2 stacked-link" [href]="getHelpLink('unifyphone', 'userGuide', 'html')" target="_blank" uLocalize="res_OpenHtml"></a>
        </li>
        <li>
          <img class="pdf-icon" alt="PDF" src="/dist/main-app/assets/img/pdf-icon.svg">
          <a class="subtitle subheading-2 stacked-link" [href]="getHelpLink('unifyphone', 'userGuide', 'pdf')" target="_blank" uLocalize="res_OpenPdf"></a>
        </li>
      </ul>
    </section>

    <section class="client-section">
      <h3 uLocalize="res_UserGuideTitleAndroid"></h3>
      <p class="subtitle subheading-2 description" uLocalize="res_UserGuideDescriptionAndroid"></p>
      <ul class="links-list">
        <li>
          <mat-icon class="html-icon" alt="HTML">language</mat-icon>
          <a class="subtitle subheading-2 stacked-link" [href]="getHelpLink('android', 'userGuide', 'html')" target="_blank" uLocalize="res_OpenHtml"></a>
        </li>
        <li>
          <img class="pdf-icon" alt="PDF" src="/dist/main-app/assets/img/pdf-icon.svg">
          <a class="subtitle subheading-2 stacked-link" [href]="getHelpLink('android', 'userGuide', 'pdf')" target="_blank" uLocalize="res_OpenPdf"></a>
        </li>
      </ul>
    </section>

     <section class="client-section">
      <h3 uLocalize="res_UserGuideTitleIos"></h3>
      <p class="subtitle subheading-2 description" uLocalize="res_UserGuideDescriptionIos"></p>
      <ul class="links-list">
        <li>
          <mat-icon class="html-icon" alt="HTML">language</mat-icon>
          <a class="subtitle subheading-2 stacked-link" [href]="getHelpLink('ios', 'userGuide', 'html')" target="_blank" uLocalize="res_OpenHtml"></a>
        </li>
        <li>
          <img class="pdf-icon" alt="PDF" src="/dist/main-app/assets/img/pdf-icon.svg">
          <a class="subtitle subheading-2 stacked-link" [href]="getHelpLink('ios', 'userGuide', 'pdf')" target="_blank" uLocalize="res_OpenPdf"></a>
        </li>
      </ul>
    </section>
  </div>
</div>
