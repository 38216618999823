<div class="container">
  <!-- Accessibility Settings -->
  <div class="section-wrapper">
    <div>
      <h3 class="headline" uLocalize="res_AccessibilitySettingTitle"></h3>
    </div>
    <p class="subtitle subheading-2" uLocalize="res_AccessibilitySettingDescription"></p>
    <div class="body-wrapper">
      <mat-checkbox color="primary" (change)="toggleAccessibilityStatus()" [checked]="root.accessibilityEnabled">
        <b>{{root.i18n.map.res_AccessibilitySettingDetails}}</b>
      </mat-checkbox>
      <p [innerHTML]="root.i18n.localize('res_AccessibilitySettingDetailsDescription')"></p>
    </div>
  </div>

  <!-- Language Selection -->
  <div class="section-wrapper">
    <mat-divider ></mat-divider>
    <div class="extension-wrapper">
      <h3 class="headline" uLocalize="res_Language"></h3>
    </div>
    <p class="subtitle subheading-2" uLocalize="res_LanguageSubtitle"></p>
    <mat-form-field appearance="outline" class="select">
      <mat-label class="accessibility-element" uLocalize="res_Language"></mat-label>
      <mat-select [(ngModel)]="selectedLanguage" name="language" (selectionChange)="onSelectChange($event)">
        <mat-option *ngFor="let language of languages" [value]="language.value">
          {{language.nativeName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- Microsoft Exchange settings -->
  <div>
    <mat-divider></mat-divider>
    <app-exchange-extension></app-exchange-extension>
  </div>

  <!-- Microsoft Exchange on-premise settings -->
  <div>
    <mat-divider></mat-divider>
    <app-exchange-onpremise-extension></app-exchange-onpremise-extension>
  </div>

  <!-- UnifyPhone Progressive Web App Installation Section-->
  <div *ngIf="canInstallPWA()">
    <mat-divider></mat-divider>
    <div class="pwa-wrapper">
      <h3 uLocalize="res_UnifyPhoneClientPWATitle"></h3>
    </div>
    <p uLocalize="res_UnifyPhoneClientPWADescription"></p>
    <button mat-flat-button color="primary" class="install-button" (click)="installPwa()">
      <span>{{ root.i18n.localize('res_Install') | uppercase }}</span>
    </button>
  </div>

  <!-- UnifyPhone Chrome-Edge Extension-->
  <div *ngIf="canInstallExtension()">
    <mat-divider></mat-divider>
    <div class="extension-wrapper">
      <h3 [uLocalizeOnce]="isEdge ? 'res_EdgeExtTitle' : 'res_ChromeExtTitle'"></h3>
    </div>
    <p uLocalizeOnce="res_ChromeExtDescription"></p>
    <button mat-flat-button color="primary" class="install-button" (click)="installExtension()"
            [attr.aria-label]="isEdge ? root.i18n.localize('res_AccessibilityInstallEdgeExt') : root.i18n.localize('res_AccessibilityInstallChromeExt')">
      <span>{{ root.i18n.localize('res_Install') | uppercase }}</span>
    </button>
  </div>

</div>
