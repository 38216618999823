<div class="app-call-views">
  <!-- Full call view -->
  <div class="call-wrapper" [class]="call?.state.css" [ngClass]="{'holding-call': call?.isHolding()}">
    <div class="call-view" [ngClass]="{'view-open': !!hasViewOpen}">
      <div *ngIf="!hasViewOpen" class="second-call-avatar-wrapper">
        <app-call-list [phoneCalls]="phoneCalls" [primaryCall]="call" *ngIf="this.phoneCalls.length > 1"></app-call-list>
        <app-avatar-single *ngIf="this.phoneCalls.length === 1"
                           [user]="call?.peerUser"
                           [isGroupCall]="call?.participants?.length > 1 || call?.isDirectUpgradedToConf"
                           [size]="this.phoneCalls.length > 1 ? 'xlarge' : 'xxlarge'"
                           [isOnHold]="call?.isHolding() || call?.state.css === 'holding-call'"></app-avatar-single>
      </div>
      <app-dtmf class="dtmf" [call]="call" [hidden]="!hasDtmfView" (parentToggleDtmf)="toggleView($event)"></app-dtmf>
      <app-new-call class="view-container" [call]="call" [hidden]="!hasNewCallView" (parentToggleNewCall)="toggleView($event)"></app-new-call>
      <app-call-transfer class="view-container" [call]="call" [hidden]="!hasTransferView" (parentToggleTransfer)="toggleView($event)"></app-call-transfer>
      <app-call-primary *ngIf="!(hasNewCallView || hasTransferView)" class="primary-call" [ngClass]="{'dtmf-open': !!hasDtmfView}"
                        [call]="call"
                        [hasViewOpen]="hasViewOpen"
                        [hasDtmfView]="hasDtmfView"
                        [hasNewCallView]="hasNewCallView"
                        [hasTransferView]="hasTransferView"
                        [phoneCalls]="phoneCalls"
                        (parentToggleView)="toggleView($event)">
      </app-call-primary>
    </div>
  </div>
</div>
