<div class="dialog-container">
    <div *ngIf="connectionInProgress" class="circle-button hold" uAriaLabel="res_AccessibilityLoading">
        <div class="loading-wrapper">
            <mat-spinner [diameter]="24"></mat-spinner>
        </div>
    </div>
    <div mat-dialog-title class="title-container">
        <h1 class="dialog-title headline" uLocalize="res_ExchangeConnectTitle"></h1>
    </div>
    <mat-dialog-content>
        <form [formGroup]="exchangeConnectFormGroup" class="exchange-connect-form">
            <section>
                <mat-form-field appearance="outline" class="fields-section">
                    <mat-label uLocalize="res_ExchangeUsername"></mat-label>
                    <input matInput placeholder="username@domain" formControlName="username" required>
                    <mat-error role="alert" *ngIf="hasError('username', 'required')">
                        <span uLocalize="res_UsernameRequired"></span>
                    </mat-error>
                </mat-form-field>
            </section>
            <section>
                <mat-form-field appearance="outline" class="fields-section">
                    <mat-label uLocalize="res_ExchangePassword"></mat-label>
                    <input matInput formControlName="password" type="password" required>
                    <mat-error role="alert" *ngIf="hasError('password', 'required')">
                        <span uLocalize="res_PasswordRequired"></span>
                    </mat-error>
                </mat-form-field>
            </section>
            <section class="exchange-url">
                <mat-form-field appearance="outline" class="fields-section">
                    <mat-label uLocalize="res_ExchangeWebServiceUrl"></mat-label>
                    <input #url matInput placeholder="https://" formControlName="exchangeURL" required>
                    <mat-error role="alert" *ngIf="hasError('exchangeURL', 'required')">
                        <span uLocalize="res_ExchangeWebServiceUrlRequired"></span>
                    </mat-error>
                </mat-form-field>
            </section>
        </form>
        <p uLocalize="res_ExchangeConnectContent"></p>
        <mat-error role="alert" class="error-status" *ngIf="hasConnectionError()">
            <mat-icon class="material-icons-outlined status-icon md-14">error_outline</mat-icon>
            <p>{{ this.root.i18n.localize("res_ExchangeConnError") }}</p>
        </mat-error>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <div>
        <button mat-button [mat-dialog-close]="false">
          <span uLocalize="res_Cancel"></span>
        </button>
      </div>
      <div>
        <button mat-button (click)="connect()" [disabled]="fieldsInvalid()">
          <span uLocalize="res_Connect"></span>
        </button>
      </div>
    </mat-dialog-actions>
  </div>
