<div class="telephony-wrapper">
  <!-- Call Forwarding OSBIZ -->
  <div *ngIf="!!callForwardingData?.Immediate?.cfwAvailable && !!root.localUser.isOsBizCTIEnabled">
    <div class="forwarding-wrapper">
      <h3 uLocalize="res_CallForwarding"></h3>
      <mat-slide-toggle class="start-align" color="primary" (ngModelChange)="toggleCallForwarding($event)"
        [disabled]="!isRegistered()"
        [(ngModel)]="callForwardingOptionsAvailable" [aria-label]="root.i18n.map.res_CallForwarding"></mat-slide-toggle>
    </div>
    <p uLocalize="res_CallForwardingDescriptionOsBiz"></p>
    <div [hidden]="!callForwardingOptionsAvailable">
      <p>
        <mat-radio-group [(ngModel)]="callForwardingType" (ngModelChange)="toggleForwardingType($event)"
          [disabled]="!isRegistered()">
          <mat-radio-button color="primary" value="Immediate" class="extention-number-chkbox">
            <b uLocalize="res_ExtensionNumber"></b>
          </mat-radio-button>
          <mat-radio-button color="primary" value="VM">
            <b uLocalize="res_BusyHandling_Voicemail"></b>
          </mat-radio-button>
        </mat-radio-group>
      </p>
      <p [hidden]="callForwardingType !== 'Immediate'">
        <span class="flex-input-error">
          <mat-form-field appearance="outline">
            <mat-label uLocalize="res_PhoneNumber"></mat-label>
            <input matInput [(ngModel)]="callForwardingNumber" (ngModelChange)="resetError()"
              (blur)="setCallForwardingNumber()">
          </mat-form-field>
          <mat-error class="error" role="alert" *ngIf="forwardingInvalidNumber">
            <span uLocalize="res_InvalidNumber"></span>
          </mat-error>
        </span>
      </p>
    </div>
    <mat-divider></mat-divider>
  </div>

  <!-- Call Forwarding ATC -->
  <div *ngIf="anyCallForwardingAvailable && !root.localUser.isOsBizCTIEnabled">
    <div class="forwarding-wrapper">
      <h3 uLocalize="res_CallForwarding"></h3>
    </div>
    <div *ngFor="let cfEntry of cfDataArray">
      <div class="forwarding-wrapper">
        <p class="subtitle" [uLocalizeOnce]="cfEntry.label"></p>
        <mat-slide-toggle class="start-align" color="primary" (ngModelChange)="toggleCallForwarding($event, cfEntry.cfwType)"
          [disabled]="!isTelephonyAvailable()"
          [(ngModel)]="cfEntry.cfwEnabled" [aria-label]="root.i18n.map[cfEntry.label]" labelPosition="before">
        </mat-slide-toggle>
      </div>
      <p [uLocalizeOnce]="cfEntry.description"></p>
      <span class="flex-input-error" #forwardingfield>
        <mat-form-field appearance="outline">
          <mat-label uLocalize="res_PhoneNumber"></mat-label>
          <input #forwarding matInput [(ngModel)]="callForwardingNumbersATC[cfEntry.cfwType]"
            (ngModelChange)="resetErrorATC(cfEntry.cfwType)" (blur)="setCallForwardingNumberATC(cfEntry.cfwType)"
            [disabled]="!cfEntry.cfwEnabled">
        </mat-form-field>
        <mat-error class="error" role="alert" *ngIf="forwardingError[cfEntry.cfwType]">
          <span uLocalize="res_InvalidNumber"></span>
        </mat-error>
      </span>
      <mat-divider></mat-divider>
    </div>
  </div>

  <!-- Alternative number -->
  <div *ngIf="!root.localUser.isOsBizCTIEnabled || root.localUser.pbxCallRoutingOptionsSupported">
    <div class="alternative-wrapper">
      <h3 uLocalize="res_AlternativeNumber"></h3>
      <mat-slide-toggle class="start-align" color="primary" [(ngModel)]="alternativeNumberAvailable"
        (ngModelChange)="setAltNumberAvailable($event)" [disabled]="!isTelephonyAvailable() || !isRegistered()"
        [aria-label]="root.i18n.map.res_AlternativeNumber" (mousedown)="onAltAvailableMouseDown()">
      </mat-slide-toggle>
    </div>
    <p [uLocalizeOnce]="root.localUser.isOsBizCTIEnabled ? 'res_AlternativeNumberDescriptionOSBiz' : 'res_AlternativeNumberDescription'"></p>
    <span class="flex-input-error" #altnumberfield>
      <mat-form-field appearance="outline">
        <mat-label uLocalize="res_PhoneNumber"></mat-label>
        <input #altnumber (keydown)="onKeydown($event)" matInput [matAutocomplete]="auto" [(ngModel)]="alternativeNumber"
          (ngModelChange)="onAltNumberChange($event)" (blur)="setAlternativeNumber()"
          [disabled]="!alternativeNumberAvailable || !isRegistered()" (focus)="altOnFocus()">
        <mat-autocomplete #auto="matAutocomplete">
          <div [hidden]="!alternativeNumberAvailable">
            <mat-select-trigger>
              <mat-option *ngFor="let option of filteredOptions" [value]="option" (mousedown)="onSwitchElementMouseDown()">
                <div class="option-container">
                  <div>{{option}}</div>
                  <button mat-icon-button *ngIf="canClearOption(option)" (click)="deleteAlternativeNumber($event, option)">
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>
              </mat-option>
            </mat-select-trigger>

          </div>
        </mat-autocomplete>
      </mat-form-field>
      <mat-error class="error" role="alert" *ngIf="alternativeInvalidNumber">
        <span uLocalize="res_InvalidNumber"></span>
      </mat-error>
    </span>
    <mat-divider></mat-divider>
  </div>

   <!-- Voicemail -->
   <div *ngIf="isVoiceMailAvailable">
    <div class="alternative-wrapper">
      <h3 uLocalize="res_Voicemail"></h3>
      <mat-slide-toggle class="start-align" color="primary" [(ngModel)]="voicemailEnabled"
        [disabled]="!isRegistered()"
        (ngModelChange)="toggleVoicemailEnabled($event)" [disabled]="!isTelephonyAvailable()"
        [aria-label]="root.i18n.map.res_Voicemail">
      </mat-slide-toggle>
    </div>
    <p uLocalizeOnce="res_VoicemailSettingDescription"></p>
    <span class="voicemail-timeout-label" uLocalize="res_VoicemailEnabledTimeout"></span>
    <mat-form-field appearance="outline" class="select">
      <mat-label class="accessibility-element" uLocalize="res_VoicemailEnabledTimeout"></mat-label>
      <mat-select [(ngModel)]="voicemailTimeout" name="voicemailTimeout" (selectionChange)="selectVoicemailTimeout($event)" [disabled]="!voicemailEnabled">
        <mat-option *ngFor="let vmTimeout of vmTimeouts" [value]="vmTimeout">
          {{root.i18n.localize('res_Duration_Seconds', [vmTimeout])}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-divider></mat-divider>
  </div>

  <!-- Call routing -->
  <div class="incoming-wrapper"
       *ngIf="!root.localUser.isOsBizCTIEnabled || root.localUser.pbxCallRoutingOptionsSupported"
       [ngClass]="{'invisible': callForwardingData?.Immediate?.cfwEnabled}">
    <h3 [uLocalizeOnce]="root.localUser.isOsBizCTIEnabled ? 'res_CallRouting' : 'res_IncomingCallRouting'"></h3>
    <div [ngSwitch]="ringDurationEnabled">
      <mat-radio-group [(ngModel)]="callRouting" (ngModelChange)="setRoutingOption($event)"
        [disabled]="!isRegistered()">
        <mat-radio-button *ngSwitchCase="true"
                          color="primary"
                          [value]="defaultRoutingOption.name"
                          [disabled]="defaultRoutingOption.disabled">
          <b [innerHTML]="root.i18n.localize(defaultRoutingOption.ui)"></b>
        </mat-radio-button>
        <div *ngSwitchCase="false">
          <mat-radio-button color="primary"
                            [value]="defaultRoutingOption.name"
                            [disabled]="defaultRoutingOption.disabled">
            <b [innerHTML]="root.i18n.localize(defaultRoutingOption.ui)"></b>
          </mat-radio-button>
          <p [ngClass]="{'disabled-text': defaultRoutingOption.disabled}" [innerHTML]="root.i18n.localize(defaultRoutingOption.desc)"></p>
        </div>
        <p *ngSwitchCase="true"
            uLocalizeOnce="res_DefaultRoutingDescriptionTimersPBXConfigurable">
        </p>
        <mat-form-field *ngSwitchCase="true" appearance="outline" class="select">
          <mat-label class="accessibility-element" uLocalize="res_VoicemailEnabledTimeout"></mat-label>
          <mat-select name="mainRingDuration" [(ngModel)]="mainRingDuration" (selectionChange)="selectMainRingDuration($event)"
                      [disabled]="callRouting !== 'DefaultRouting' || !alternativeNumberAvailable || !ringDurationConfigurable || !!callForwardingData.NoAnswer.cfwEnabled">
            <mat-option *ngFor="let duration of mainRingDurations" [value]="duration">
              {{root.i18n.localize('res_Duration_Seconds', [duration])}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div *ngFor="let option of routingOptions">
          <mat-radio-button color="primary" [value]="option.name" [disabled]="option.disabled">
            <b class="wrap-mat-radio-label" [innerHTML]="root.i18n.localize(option.ui)"></b>
          </mat-radio-button>
          <p [ngClass]="{'disabled-text': option.disabled}"
             [innerHTML]="!option.number ? root.i18n.localize(option.desc) : root.i18n.localize(option.desc, [option.number])"></p>
        </div>
      </mat-radio-group>
    </div>
    <mat-divider></mat-divider>
  </div>

  <!-- Busy handling -->
  <div class="incoming-wrapper"
       *ngIf="!root.localUser.isOsBizCTIEnabled"
       [ngClass]="{'invisible': callForwardingData?.Immediate?.cfwEnabled || (callRouting !== 'DefaultRouting')}">
    <h3 uLocalize="res_BusyHandling"></h3>
    <mat-radio-group [(ngModel)]="selectedBusyHandlingOption" (ngModelChange)="selectBusyHandlingOption($event)"
      [disabled]="!isRegistered()">
      <div *ngFor="let option of busyHandlingOptions">
        <mat-radio-button color="primary" [value]="option.name" [disabled]="option.disabled">
          <b class="wrap-mat-radio-label" [innerHTML]="root.i18n.localize(option.ui)"></b>
        </mat-radio-button>
        <p [ngClass]="{'disabled-text': option.disabled}" [innerHTML]="root.i18n.localize(option.desc)"></p>
      </div>
    </mat-radio-group>
    <mat-divider></mat-divider>
  </div>

  <!-- Call history -->
  <div class="call-history-wrapper">
    <h3 uLocalize="res_CallHistory"></h3>
    <h4 uLocalize="res_DownloadCallHistory"></h4>

    <button mat-flat-button color="primary" class="download-button" (click)="downloadCallHistory()">
      <mat-icon *ngIf="isDownloadingCallHistory" class="md-20 icon spinning-animation">sync</mat-icon>
      <mat-icon *ngIf="downloadCallHistoryFailed" class="md-20 icon">sync_problem</mat-icon>
      <span>{{ root.i18n.localize('res_Download') | uppercase }}</span>
    </button>

    <mat-hint *ngIf="isDownloadingCallHistory" role="status" class="mat-caption" uLocalize="res_DownloadingMessage"></mat-hint>
    <mat-error *ngIf="downloadCallHistoryFailed" role="alert" class="mat-caption" uLocalize="res_DownloadCallHistoryError"></mat-error>
  </div>
</div>
