<div class="call-log-list-item-container">
  <div class="list-item-call-info" [ngClass]="{'unread': !!unreadAfter && item.includeInUnreadCount && (item.creationTime > unreadAfter)}">
      <app-avatar-single [user]="item.peerUser" size="medium" class="avatar-container"></app-avatar-single>
      <div class="call-item-container" [ngClass]="{'missed': item.callLogType === 'MISSED'}">
        <div class="mat-subheading-2"
          [ngClass]="(item.callLogType !== 'MISSED') ? 'name-text' : 'name-text-missed'"
          matTooltip={{resolveNameOrNumber()}}
          [matTooltipShowDelay]="3000">
          {{ resolveNameOrNumber() }}
      </div>
        <mat-card-subtitle class="mat-caption" *ngIf="item.peerUser.displayName">
          {{ item.peerUser.phoneNumber }}
        </mat-card-subtitle>
      </div>
      <div class="call-item-info-container">
        <div>
          <span class="unread-indicator"></span>
          <span class="material-icons md-13"
            *ngIf="item.callLogType === 'INCOMING' ||
                  (item.callLogType === 'BUSY' && item.sentByMe === false) ||
                  (item.callLogType === 'DECLINED' && item.sentByMe === false) ||
                  item.callLogType === 'REDIRECTED'">phone_callback
          </span>
          <span *ngIf="item.callLogType === 'OUTGOING' ||
                (item.callLogType === 'BUSY' && item.sentByMe === true) ||
                (item.callLogType === 'DECLINED' && item.sentByMe === true) ||
                item.callLogType === 'FAILED' ||
                item.callLogType === 'NOT_ANSWERED'">
            <mat-icon class="material-icons md-10 icon-outgoing-icon"></mat-icon>
          </span>
          <span *ngIf="item.callLogType === 'MISSED'">
            <mat-icon color='warn' class="material-icons md-13">phone_callback</mat-icon>
          </span>
          <span class="material-icons-outlined md-13" *ngIf="item.callLogType === 'UNKNOWN'">help_outline</span>
        </div>
        <div class="call-log-type-container">
          <span class="call-log-type"
          [matTooltip]="!item.redirectingUser && !item.redirectedToUser
          ? root.i18n.localize(item.i18nCallLogTypeRes)
          : (!!item.redirectingUser ? root.i18n.localize(item.i18nCallLogTypeRes) + ' ' + root.i18n.localize('res_CallLogRedirectedFrom', [getRedirectingUserUiStr(item.redirectingUser)])
            : root.i18n.localize(item.i18nCallLogTypeRes) + ' ' + root.i18n.localize('res_CallLogRedirectedTo', [getRedirectingUserUiStr(item.redirectedToUser)]))"
            [matTooltipShowDelay]="1000">
            <mat-card-subtitle class="mat-caption">
              {{root.i18n.localize(item.i18nCallLogTypeRes)}}
            <span class="redirecting-info"
                *ngIf="!!item.redirectingUser"
                [innerHTML]="root.i18n.localize('res_CallLogRedirectedFrom', [getRedirectingUserUiStr(item.redirectingUser)])"></span>
            <span class="redirecting-info"
                *ngIf="!!item.redirectedToUser"
                [innerHTML]="root.i18n.localize('res_CallLogRedirectedTo', [getRedirectingUserUiStr(item.redirectedToUser)])"></span>
            </mat-card-subtitle>
          </span>
          <div>
            <app-call-summary-duration [item]="item"></app-call-summary-duration>
          </div>
        </div>
      </div>
      <div class="right-options">
        <div class="call-btn-container" matTooltip="{{ dialable && isUserDialable() ? '' : root.i18n.map.res_CannotDial }}">
          <button
            mat-stroked-button
            class="md-35"
            matTooltip="{{ dialable && isUserDialable() ? root.i18n.map.res_Call + ' ' + resolveNameOrNumber() : '' }}"
            [attr.aria-label]="root.i18n.map.res_Call + ' ' + resolveNameOrNumber()"
            (click)="dial(item.peerUser.phoneNumber, item.peerUser.displayName, '')"
            [disabled]="!dialable || !isUserDialable()">
            <mat-icon class="call-btn">call</mat-icon>
          </button>
        </div>
        <button
          mat-stroked-button
          class="md-35"
          matTooltip={{root.i18n.map.res_Delete}}
          uAriaLabel="res_Delete"
          (click)="deleteItem()">
          <mat-icon class="dlt-btn">delete_forever</mat-icon>
        </button>
        <div class="date-time-container">
          <app-item-date-format [item]="item" format="short"></app-item-date-format>
        </div>
      </div>
  </div>
</div>
<mat-divider> </mat-divider>
