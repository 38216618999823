<!-- Modal: Edit Log Level -->
<h1 mat-dialog-title uLocalize="res_LogManagement"></h1>
<mat-dialog-content>
  <div>
    <!-- Log Levels -->
    <fieldset>
      <legend class="accessibility-element" uLocalize="res_LogLevel"></legend>
      <h2 class="log-files-header-container" uLocalize="res_EditLogLevel"></h2>
      <hr>
      <mat-radio-group required class="radio-btn-wrapper" [(ngModel)]="selectedLogLevel" name="log-radios">
        <mat-radio-button *ngFor="let level of logLevels" [value]="level.name">
          {{level.name}}
        </mat-radio-button>
      </mat-radio-group>
    </fieldset>

    <fieldset class="log-files">
      <legend class="accessibility-element" [uLocalizeOnce]="fileList.length === 1 ? 'res_LogFile' : 'res_LogFiles'">
      </legend>
      <div class="log-files-header-container">
        <div [ngPlural]="fileList.length">
          <ng-template ngPluralCase="=1">
            1 <span uLocalize="res_LogFile"></span>
          </ng-template>
          <ng-template ngPluralCase="other">
            {{fileList.length}} <span uLocalize="res_LogFiles"></span>
          </ng-template>
        </div>
        <div>
          <button mat-button [disabled]="!fileList.length" (click)="downloadAll()">
            <mat-icon class="material-icons-outlined md-5">download</mat-icon>
            <span uLocalize="res_DownloadAll"></span>
          </button>
          <button mat-button (click)="removeLogFiles()">
            <mat-icon class="material-icons-outlined md-5">delete</mat-icon>
            <span uLocalize="res_DeleteAll"></span>
          </button>
        </div>
      </div>
      <hr class="solid">

      <div *ngIf="!!fileList.length">
        <a *ngFor="let file of fileList" [href]="sanitizeUrl(file.uri)" [download]="file.fileName" class="row">
          <div class="download-link">
            <mat-icon class="material-icons-outlined md-5">download</mat-icon>
            {{file.fileName}}
          </div>
          <div [textContent]="bytesToString(file.size)"></div>
          <div [textContent]="file.modifiedTime"></div>
        </a>
      </div>

      <div *ngIf="!!(fileList && !fileList.length)" uLocalize="res_NoLogFiles"></div>
      <div *ngIf="!fileList">
        <div class="icon"></div>
        <span uLocalize="res_RetrieveLogFiles"></span>
      </div>
    </fieldset>

  </div>
</mat-dialog-content>

<mat-dialog-actions align="end" *ngIf="data.action || data.alert" class="dialog-actions-container">
  <div *ngIf="data.alert">
    <button mat-button (click)="close(false)">
      <span [uLocalizeOnce]="data.okLabel"></span>
    </button>
  </div>
  <div *ngIf="data.action">
    <button mat-button *ngIf="data.noLabel" (click)="close(false)" [mat-dialog-close]="false">
      <span [uLocalizeOnce]="data.noLabel"></span>
    </button>
    <button mat-button *ngIf="data.yesLabel"
      [ngClass]="{'secondary':(data.type === 'error'), 'primary':(data.type !== 'error')}" (click)="saveLogLevel()"
      [mat-dialog-close]="true" cdkFocusInitial>
      <span [uLocalizeOnce]="data.yesLabel"></span>
    </button>
  </div>
</mat-dialog-actions>
