<div class="header-dtmf-container">
  <button mat-icon-button class="back-btn" uAriaLabel="res_Back" (click)="goBack()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <h2 class="mat-display-1 title" uLocalize="res_Dtmf"></h2>
</div>
<div class="dialpad">
    <div class="dial-input-wrapper">
        <input class="dial-dtmf-input mat-headline" #dtmfInput type="text" [(ngModel)]="value"
        (input)="change($event)" uAriaLabel="res_AccessibilityDtmfInput" appDtmfListenersDirective>
        <button mat-icon-button class="dial-input-button" uAriaLabel="res_AccessibilityShowPassword" [ngClass]="{'visible':value, 'invisible':!value}" (click)="toggleMaskedDigits()">
            <mat-icon>visibility</mat-icon>
        </button>
    </div>
    <div class="dialpad-wrapper">
        <div class="dialpad-row">
            <app-dialpad-button dialpadNumber="1" dialpadLetter="-" (btnClick)="type($event)"></app-dialpad-button>
            <app-dialpad-button dialpadNumber="2" dialpadLetter="abc" (btnClick)="type($event)"></app-dialpad-button>
            <app-dialpad-button dialpadNumber="3" dialpadLetter="def" (btnClick)="type($event)"></app-dialpad-button>
        </div>
        <div class="dialpad-row">
            <app-dialpad-button dialpadNumber="4" dialpadLetter="ghi" (btnClick)="type($event)"></app-dialpad-button>
            <app-dialpad-button dialpadNumber="5" dialpadLetter="jkl" (btnClick)="type($event)"></app-dialpad-button>
            <app-dialpad-button dialpadNumber="6" dialpadLetter="mno" (btnClick)="type($event)"></app-dialpad-button>
        </div>
        <div class="dialpad-row">
            <app-dialpad-button dialpadNumber="7" dialpadLetter="pqrs" (btnClick)="type($event)"></app-dialpad-button>
            <app-dialpad-button dialpadNumber="8" dialpadLetter="tuv" (btnClick)="type($event)"></app-dialpad-button>
            <app-dialpad-button dialpadNumber="9" dialpadLetter="wxyz" (btnClick)="type($event)"></app-dialpad-button>
        </div>
        <div class="dialpad-row">
            <app-dialpad-button dialpadNumber="*" (btnClick)="type($event)"></app-dialpad-button>
            <app-dialpad-button dialpadNumber="0" dialpadLetter="+" (btnClick)="type($event)" [hasLongPress]="true"></app-dialpad-button>
            <app-dialpad-button dialpadNumber="#" (btnClick)="type($event)"></app-dialpad-button>
        </div>
    </div>
  </div>
