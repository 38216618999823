<div class="notifications-snackbar-wrapper">
  <span class="message">{{ root.i18n.localize(data.message) }}</span>
  <div class="actions">
    <button mat-button (click)="snackBarRef.dismissWithAction()">
      {{ root.i18n.localize(data.action) }}
    </button>
    <button mat-button uAriaLabel="res_Close" (click)="snackBarRef.dismiss()">
      <mat-icon class="md-20">close</mat-icon>
    </button>
  </div>
</div>
