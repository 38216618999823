<mat-card-subtitle *ngIf="usersToDisplay.length > 0" class="instructions mat-caption"
    uLocalize="res_CallContactInstructions">
</mat-card-subtitle>

<div class="contacts-wrapper">
    <mat-selection-list class="contacts-list" role="list" [multiple]="false" #contactList
                        (selectionChange)="onContactSelected()"
                        (keyup.enter)="onContactSelected()">
        <mat-list-option *ngFor="let contact of usersToDisplay; first as isFirst"
                         role="listitem"
                         class="list-item"
                         [value]="contact"
                         [appScrollableItem]="contact"
                         #opt
                         (click)="onClick(contact)"
                         (contextmenu)="onRightClick($event, contact)">
            <app-contact [showPresence]="root.localUser?.isStandalone && !(contact.isExchangeContact || contact.isOsbizContact)" [contact]="contact" [searchTerm]="searchTerm" [isFirst]="isFirst && !contact.displayName" class="contact"></app-contact>
        </mat-list-option>
        <div class="spinner-wrapper" uAriaLabel="res_AccessibilityLoading">
            <mat-spinner class="spinner" *ngIf="showDialNumber && searchInProgress" [diameter]="30"></mat-spinner>
        </div>
        <p class="no-results" *ngIf="showDialNumber && noResults" uLocalize="res_NoMatchedRecords"></p>
    </mat-selection-list>
    <p *ngIf='!showDialNumber && searchInProgress' uLocalize="res_SearchInProgress"></p>
    <p *ngIf="!showDialNumber && noResults" uLocalize="res_NoMatchedRecords"></p>
</div>

<div class="menu-trigger"
     [style.left]="menuTopLeftPosition.x"
     [style.top]="menuTopLeftPosition.y"
     [matMenuTriggerFor]="deviceMenu"></div>

<mat-menu #deviceMenu="matMenu"
          yPosition="below"
          [overlapTrigger]="false"
          list-data-autotest="callDevice">
    <button mat-menu-item
            *ngFor="let device of callDevices"
            (click)="onDeviceSelect(device.id)">
        <mat-icon [class]="device.icon">{{ device.icon !== 'icon-deskphone-icon' ? device.icon : '' }}</mat-icon>
        <span [uLocalizeOnce]="device.text"></span>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="onCopySelect()">
        <mat-icon>content_copy</mat-icon>
        <span uLocalize="res_CopyNumber"></span>
    </button>
</mat-menu>
