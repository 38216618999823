import { Component, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { ExchangeOnPremiseService } from '../../services/exchange-on-premise.service';

declare let Circuit: any;

@Component({
  selector: 'app-exchange-onpremise-extension',
  templateUrl: './exchange-onpremise-extension.component.html',
  styleUrls: ['./exchange-onpremise-extension.component.scss']
})
export class ExchangeOnpremiseExtensionComponent implements OnInit {
  private LogSvc: any;
  root: any;

  constructor(private commonBlService: CommonBLService, private exchangeOnPremiseService: ExchangeOnPremiseService) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.root = this.commonBlService.getRootScopeData();
  }

  ngOnInit(): void {
    this.LogSvc.info('[ExchangeExtensionComponent]: ngOnInit');
  }

  isExchangeConnected = () => this.exchangeOnPremiseService.getConnected();

  hasError = () => !!this.exchangeOnPremiseService.getLastConnectionError();

  toggleConnectionStatus = async () => {
    try {
      if (this.isExchangeConnected()) {
        await this.exchangeOnPremiseService.disconnect();
        return;
      }
      await this.exchangeOnPremiseService.editConnectionSettings();
    } catch (error: any) {
      this.LogSvc.error('[ExchangeExtensionComponent]: Could not toggle connection status: ', error);
    }
  };

  resetErrorMessage = () => {
    this.exchangeOnPremiseService.resetConnectionError();
    return this.exchangeOnPremiseService.getLastConnectionError();
  };

}
