<mat-toolbar-row class="header-container">
  <div class="title-container mat-headline">
    <mat-icon class="settings-icon md-35">info</mat-icon>
    <h1 uLocalize="res_About"></h1>
  </div>
  <button mat-button #closebtn
    align="end"
    mat-dialog-close
    class="close-btn">
    <span uAriaLabel="res_AccessibilityCloseModal" role="button"></span>
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar-row>
<mat-divider></mat-divider>

<div class="container">
  <div class="content-wrapper">
    <div class="info-container">
      <img class="unify-logo" alt="Unify Phone Logo" src="/dist/main-app/assets/img/unify-phone-logo.svg" id="brandLogo">
      <div class="copyright-container">
        <h2 class="headline">{{rootScope.i18n.localize(productName)}}</h2>
        <div>
          <span class="subtitle subheading-2" uLocalize="res_Version"></span>
          <span class="subtitle subheading-2">{{' ' + rootScope.clientVersion}}</span>
        </div>
        <span class="subtitle subheading-2" uLocalize="res_UnifyLogoCopyright"></span>
      </div>
    </div>
    <mat-divider class="line"></mat-divider>
    <h2 class="headline" uLocalize="res_TermsOfService"></h2>
    <p class="subtitle subheading-2" uLocalize="res_TermsOfServiceInfo"></p>
    <ul class="links-list">
      <li>
        <a class="subtitle subheading-2 stacked-link" [href]="getLegalLink('tos')" target="_blank" uLocalize="res_TermsOfServiceProduction" ></a>
      </li>
      <li *ngIf="isExtensionRunning">
        <a class="subtitle subheading-2 stacked-link not-visited-style" href [routerLink]="[]" (click)="showExtensionLink('EULA', 'extn')" uLocalize="res_EulaLinkChromeExt"></a>
      </li>
      <li *ngIf="isExtensionRunning">
        <a class="subtitle subheading-2 stacked-link not-visited-style" href [routerLink]="[]" (click)="showExtensionLink('TPSI', 'extn')" uLocalize="res_ThirdpartyLinkWebChromeExt"></a>
      </li>
      <!-- Not supported -->
      <li *ngIf="isChromeAppConnected">
        <a class="subtitle subheading-2 stacked-link not-visited-style" href [routerLink]="[]" (click)="showExtensionLink('EULA', 'headset')" uLocalize="res_EulaLinkHeadsetIntegration"></a>
      </li>
      <li *ngIf="isChromeAppConnected">
        <a class="subtitle subheading-2 stacked-link not-visited-style" href [routerLink]="[]" (click)="showExtensionLink('TPSI', 'headset')" uLocalize="res_ThirdpartyLinkWebHeadsetIntegration"></a>
      </li>
    </ul>
    <mat-divider class="line"></mat-divider>
    <h2 class="headline" uLocalize="res_DataProtectionTitle" *ngIf="!rootScope.localUser?.hideLegalInformation"></h2>
    <p class="subtitle subheading-2 stacked-link" uLocalize="res_DataProtectionInfo"></p>
    <ul class="links-list">
      <li>
        <a class="subtitle subheading-2 stacked-link" [href]="getLegalLink('dpa')" target="_blank" uLocalize="res_DataProtectionAgreement" ></a>
      </li>
    </ul>
  </div>
</div>
