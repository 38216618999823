import { Injectable } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { DialogService } from '../sub-modules/dialog/dialog.service';

declare let Circuit: any;
declare let RegistrationState: any;

const REQUEST_TIMEOUT = 2000;

@Injectable({
  providedIn: 'root'
})
export class CallActionService {
  private LogSvc: any;
  private PhoneCallSvc: any;
  private PubSubSvc: any;
private CstaSvc: any;
  private electedCall: any;
private _getMessageWaitingTimer: any;

  answerDevices: any;
  callDevices: any;
  declineDevices: any;
  pushDevices: any;
  defaultAnswerDevice: any;
  defaultCallDevice: any;
  defaultDeclineDevice: any;
  defaultPushDevice: any;
  root: any;

  constructor(private dialogService: DialogService, private commonBlService: CommonBLService) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PhoneCallSvc = Circuit.serviceInstances.phoneCallSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this.CstaSvc = Circuit.serviceInstances.cstaSvc;
    this.root = this.commonBlService.getRootScopeData();

    this.callDevices = [];
    this.declineDevices = [];
    this.pushDevices = [];
    this.defaultAnswerDevice = {};
    this.defaultCallDevice = {};
    this.defaultDeclineDevice = {};
    this.defaultPushDevice = {};

    this.initDevices();
    this.PubSubSvc.subscribe('/telephony/callDevices/changed', this.onDeviceChangeEvent);
    this.PubSubSvc.subscribe('/telephony/available', this.onTelephonyAvailable);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.PubSubSvc.unsubscribe('/telephony/callDevices/changed', this.onDeviceChangeEvent);
    this.PubSubSvc.unsubscribe('/telephony/available', this.onTelephonyAvailable);
  }

  private onDeviceChangeEvent = () => {
    this.LogSvc.debug('[CallActionService]: Received /telephony/callDevices/changed');
    this.initDevices();
  };

  private getDeviceIcon(css: any) {
    let icon = '';
    switch (css) {
    case 'desk':
      icon = 'icon-deskphone-icon';
      break;
    case 'mobile':
      icon = 'send_to_mobile';
      break;
    case 'circuit':
      icon = 'phone';
      break;
    case 'vm':
      icon = 'voicemail';
      break;
    }
    return icon;
  }

  private createDevices(devices: any) {
    const newDevices: { text: any; icon: any; id: any; }[] = [];
    if (!devices.length) {
      return newDevices;
    }

    devices.forEach((device: any) => {
      if (!device) {
        return;
      }

      newDevices.push({
        text: device.ui,
        icon: this.getDeviceIcon(device.css),
        id: device.name
      });
    });
    return newDevices;
  }

  private initDevices() {
    const devices = this.PhoneCallSvc.getDevices();

    this.answerDevices = this.createDevices(devices.answerDevices);
    //DeclineDevices should have only Voicemail
    this.declineDevices = this.answerDevices.filter((device: any) => device.id === Circuit.Enums.Targets.VM.name);
    //AnswerDevices should not include voicemail
    this.answerDevices = this.answerDevices.filter((device: any) => device.id !== Circuit.Enums.Targets.VM.name);
    this.callDevices = this.createDevices(devices.callDevices);
    this.pushDevices = this.createDevices(devices.pushDevices);
    this.defaultAnswerDevice.value = {};
    this.defaultCallDevice.value = {};
    this.defaultDeclineDevice = {};
    this.defaultPushDevice.value = {};

    if (devices.defaultPushDevice) {
      this.defaultPushDevice.value = {
        ui: devices.defaultPushDevice.ui,
        css: devices.defaultPushDevice.css,
        text: 'res_PushCall',
        icon: this.getDeviceIcon(devices.defaultPushDevice.css),
        id: devices.defaultPushDevice.name,
        isDefault: true
      };
    }

    if (devices.defaultCallDevice) {
      this.defaultCallDevice.value = {
        ui: devices.defaultCallDevice.ui,
        text: 'res_Call',
        icon: this.getDeviceIcon(devices.defaultCallDevice.css),
        id: devices.defaultCallDevice.name,
        isDefault: true
      };
    }

    const defaultAnswerDevice = devices.defaultAnswerDevice || Circuit.Enums.Targets.WebRTC;
    this.defaultAnswerDevice.value = {
      icon: this.getDeviceIcon(defaultAnswerDevice.css),
      id: defaultAnswerDevice.name,
      isDefault: true
    };

    //Fill defaultDeclineDevice with voicemail options
    if (this.declineDevices.length === 1) {
      this.defaultDeclineDevice.value = {
        icon: this.declineDevices[0].icon,
        id: this.declineDevices[0].id,
        text: this.declineDevices[0].text,
        isDefault: true
      };
    }
  }

  toggleMute = (call: any) => {
    if (call) {
      this.LogSvc.info('[CallActionService]: Toggle mute');

      this.PhoneCallSvc.toggleMute(call.callId)
        .then(() => {
          this.LogSvc.debug('[CallActionService]: toggleMute() success', call.isMuted());
        })
        .catch((err: any) => {
          //possibly show the error in the dialog here
          //this.dialogService.handleCallError(err, null);
          this.LogSvc.error('[CallActionService]: toggleMute() error', err);
        });
    }
  };

  endCall = (call: any) => {
    this.LogSvc.info('[CallActionService]: endCall', call.callId);

    this.PhoneCallSvc.endCall(call.callId)
      .catch((err: any) => {
        //possibly show the error in the dialog here
        this.LogSvc.error('[CallActionService]: endCall() error', err);
      });
  };

  answerCall = (callId: string, deviceName?: any): void => {
    deviceName = deviceName || this.defaultAnswerDevice.value.id;
    this.LogSvc.buttonPressed('[CallActionService]: Answer call from: ' + deviceName);

    this.PhoneCallSvc.answerCall(callId, deviceName)
      .catch((err: any) => {
        this.LogSvc.error('[CallActionService]: answerCall() error', err);
        if (err === 'res_TerminateActiveCall') {
          // Ask the user to terminate the current call before answering the new one
          this.dialogService.confirm({ message: 'res_TerminateActiveCall', title: 'res_TerminateActiveCallTitle' })
            .result
            .then(() => {
              this.PhoneCallSvc.answerCall(callId, deviceName, true)
                .catch(this.dialogService.handleCallError);
            })
            .catch(() => {
              // Handle reject to prevent 'Possibly unhandled rejection' error
            });
        } else {
          this.dialogService.handleCallError(err, null);
        }
      });
  };

  pullRemoteCall = (callId: string) => {
    this.LogSvc.buttonPressed('Pull remote call');

    this.PhoneCallSvc.pullRemoteCall(callId)
      .catch((err: any) => {
        if (err === 'res_TerminateActiveCall') {
          this.dialogService.confirm({ message: 'res_TerminateActiveCall', title: 'res_TerminateActiveCallTitle' })
            .result
            .then(() => {
              this.PhoneCallSvc.pullRemoteCall(callId, true)
                .catch((error: any) => this.dialogService.handleCallError(error, null));
            })
            .catch(function () {
              // Handle reject to prevent 'Possibly unhandled rejection' error
            });
        } else {
          this.dialogService.handleCallError(err, null);
        }
      });
  };

  getCallTitleUiStr = (call: any) => {
    if (!call) {
      return '';
    }

    const res = call.participants.length <= 1 && !call.isDirectUpgradedToConf ? '' : 'res_MergedCall';
    return this.root.i18n.map[res];
  };

  getCallStateUiStr(call: any) {
    if (!call) {
      return '';
    }

    let res;
    if (call.checkCstaState([Circuit.Enums.CstaCallState.Conference, Circuit.Enums.CstaCallState.ConferenceHolding]) ||
      (call.isSTC && call.isDirectUpgradedToConf)) {
      res = '';
    } else {
      res = '';
    }
    if (call.participants.length <= 1 && !call.isDirectUpgradedToConf) {
      if (call.peerUser.displayName) {
        res += call.peerUser.displayName;
      } else {
        res += call.peerUser.phoneNumber || '';
      }
    } else {
      const associatedTelephonyUserID = this.root.localUser.associatedTelephonyUserID;
      const names: any = [];
      call.participants.forEach((p: any) => {
        if ((!p.hasTelephonyRole || call.isSTC) && p.userId !== associatedTelephonyUserID) {
          let name;
          if (p.displayName) {
            name = p.displayName + (p.phoneNumber ? ' (' + p.phoneNumber + ')' : '');
          } else {
            name = p.phoneNumber || '';
          }
          names.push(name);
        }
      });
      res += names.join(', ');
    }
    return res;
  }


  getNumOfAvailableCallDevices = () => this.PhoneCallSvc.getNumOfAvailableCallDevices();

  isTelephonyAvailable = () => this.PhoneCallSvc.isTelephonyAvailable();

  getRedirectingUserUiStr = (redirectingUser: any) => {
    if (!redirectingUser) {
      return '';
    }
    switch (redirectingUser.redirectionType) {
    case Circuit.Enums.RedirectionTypes.CallForward:
    case Circuit.Enums.RedirectionTypes.CallPickedUp:
    case Circuit.Enums.RedirectionTypes.Dss:
      return `${redirectingUser.displayName || redirectingUser.phoneNumber}`;
    case Circuit.Enums.RedirectionTypes.CallPickupNotification:
      return redirectingUser.displayName || redirectingUser.phoneNumber;
    default:
      return redirectingUser.phoneNumber;
    }
  };

  showCallStateUi = (call: any) => call?.state?.ui && !call?.checkState([Circuit.Enums.CallState.Terminated]);

  setElectedCall = (call:any) => {
    this.electedCall = call;
  };

  getElectedCall = () => this.electedCall;

  //TODO: create an observable and make components to subscribe to it.
  //Prerequisite is to define events that affect canDial() so recalculation can be triggered
  canDial = () => {
    const phoneCalls = this.PhoneCallSvc.getPhoneCalls();
    const isReconnecting = this.root.registrationState === RegistrationState.Reconnecting;
    const isTelephonyAvailable = this.PhoneCallSvc.isTelephonyAvailable();
    let canDial = false;

    if (!isReconnecting && isTelephonyAvailable) {
      if (phoneCalls.length === 0) {
        canDial = true;
      } else {
        const electedCall = this.getElectedCall();
        if (electedCall &&
            ((this.PhoneCallSvc.isLocalCallActive(electedCall.callId) && this.PhoneCallSvc.canInitiateConsultationLocalCall()) ||
            (this.PhoneCallSvc.isRemoteCallActive(electedCall.callId) && this.PhoneCallSvc.canInitiateConsultationRemoteCall()))) {
          canDial = true;
        }
      }
    }

    this.root.dialable = canDial;
    return canDial;
  };


  private onTelephonyAvailable = () => {
    this.LogSvc.debug('[CallActionService]: Received /telephony/available event.');
    if (!this.root.localUser.vmNumber) {
      return;
    }
    clearTimeout(this._getMessageWaitingTimer);

    // Add some time for ATC to reregister
    this._getMessageWaitingTimer = setTimeout(() => {
      this._getMessageWaitingTimer = null;
      this.CstaSvc.getMessageWaiting((error: any, res: any) => {
        if (error) {
          this.LogSvc.error('[CallActionService]: getMessageWaiting', error);
          return;
        }

        this.root.localUser.msgWaitingIndicator = res?.messageWaitingOn;
      });
    }, REQUEST_TIMEOUT);

  };
}
