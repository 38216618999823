import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';

declare let Circuit: any;
interface Page {
  id: number,
  version: string,
  versionId: number,
  categoryName?: string,
  title?: string,
  text?: string,
  bullets: any
  // image: string,
}

interface Item {
  versionId: number,
  pages: Page[],
  id: number,
  version: string,
}

const PRODUCT_NAME_REGEXP = /%PRODUCT_NAME%|Unify\sphone/gi;

@Component({
  selector: 'app-whats-new',
  templateUrl: './whats-new.component.html',
  styleUrls: ['./whats-new.component.scss']
})
export class WhatsNewComponent implements OnInit {
  private LogSvc: any;
  private LocalizeSvc: any;
  private UserProfileSvc: any;

  _root: any;
  i18n: any;
  showWhatsNew = false;
  pages: Array<Page> = [];
  currentPageId = 0;   // 0 is the first page
  _latestReadReleaseNoteVersionId = 0;

  constructor(private http: HttpClient, private commonBlService: CommonBLService) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.LocalizeSvc = Circuit.serviceInstances.localizeSvc;
    this.UserProfileSvc = Circuit.serviceInstances.userProfileSvc;
    this._root = this.commonBlService.getRootScopeData();
    this.i18n = this._root.i18n;
  }

  ngOnInit(): void {
    this.LogSvc.info('[WhatsNewComponent]: ngOnInit');
    this.getLatestReleaseReadVersion();
    this.checkReleaseNotes();
  }

  private getReleaseNotesFromServer = (cb: any, includeLastRead: boolean) => {
    // Initialize release notes data
    const releaseNotes: any[] = [];
    const FILE_URL = this.LocalizeSvc.getReleaseNotesFileUrl();
    const root = this._root;
    const logSvc = this.LogSvc;
    const userProfileSvc = this.UserProfileSvc;
    return this.http.get<any>(FILE_URL).toPromise()
  .then(async (res: any) => {
    if (this.checkIsArray(res)) {
      logSvc.debug('[WhatsNewComponent]: Retrieved release notes: ', res);

      // Get the latest
      let latestReadVersionId: any;
      if (includeLastRead) {
        logSvc.debug('[WhatsNewComponent]: Show all release notes again');
        latestReadVersionId = 0;
      } else {
        latestReadVersionId = userProfileSvc.getSetting(Circuit.Constants.UserSettingKey.RELEASE_NOTES_WEB_VERSION);
        latestReadVersionId = (latestReadVersionId && latestReadVersionId.numberValue) || 0;
        logSvc.debug('[WhatsNewComponent]: Last release notes version seen by the user. versionId = ', latestReadVersionId);
      }

      let i = 0;
      res.forEach((item: Item) => {
        if (!item || item.versionId <= latestReadVersionId) {
          return;
        }

        // New release notes that have not yet been seen (by this user)
        item.pages.forEach((page: any) => {
          if (!page?.categoryName || !page.title || !(page.text || page.bullets?.length > 0)) {
            return;
          }

          if (page.permission && !root.localUser.hasPermission(page.permission)) {
            // User does not have permission to see the page
            logSvc.debug('[WhatsNewComponent]: User does not have ' + page.permission + ' permission to see release note page: ', page.title);
            return;
          }

          page.id = i;
          page.version = item.version;
          page.versionId = item.versionId;
          page.categoryName = page.categoryName.replace(PRODUCT_NAME_REGEXP, Circuit.productName);
          page.title = page.title.replace(PRODUCT_NAME_REGEXP, Circuit.productName);
          page.text = page.text.replace(PRODUCT_NAME_REGEXP, Circuit.productName);
          releaseNotes.push(page);
          i++;
        });
      });
      this.showWhatsNew = true;
    }
    cb && cb(releaseNotes);
  })
    .catch(function () {
      logSvc.error('[WhatsNewComponent]: Failed to get release notes data');
      cb && cb();
    });
  };

  private checkReleaseNotes = () => {
    this.getReleaseNotesFromServer((notes: any) => {
      if (notes && notes.length > 0) {
        this.LogSvc.debug('[WhatsNewComponent]: Retrieved release notes from server. count = ', notes.length);
        this.pages.push(...notes);
      }
    }, true);
  };

  private getLatestReleaseReadVersion = () => {
    const latestReadVersionId = this.UserProfileSvc.getSetting(Circuit.Constants.UserSettingKey.RELEASE_NOTES_WEB_VERSION);
    this._latestReadReleaseNoteVersionId = (latestReadVersionId && latestReadVersionId.numberValue) || 0;
    this.LogSvc.debug('[WhatsNewComponent]: Last release notes version seen by the user. versionId = ', latestReadVersionId);
  };

  private saveReleaseNotes = () => {
    this.LogSvc.debug('[WhatsNewComponent]: Save new release notes version = ', this._latestReadReleaseNoteVersionId);
    this.UserProfileSvc.saveSetting(Circuit.Constants.UserSettingKey.RELEASE_NOTES_WEB_VERSION, this._latestReadReleaseNoteVersionId);
  };

  previousPage() {
    if (this.currentPageId === 0) {
      this.currentPageId = this.pages.length - 1;
    } else {
      this.currentPageId--;
    }
    this.updateLocalReadVersion(this.currentPageId);
  }

  nextPage() {
    if (this.currentPageId === this.pages.length - 1) {
      this.currentPageId = 0;
    } else {
      this.currentPageId++;
    }
    this.updateLocalReadVersion(this.currentPageId);
  }

  gotoPage(pageId: number) {
    this.currentPageId = pageId;
    this.updateLocalReadVersion(this.currentPageId);
  }

  updateLocalReadVersion = (newPageId: number) => {
    // Update local store if going to a unseen version
    const currentVersionId = this.pages[newPageId].versionId;
    if (currentVersionId > this._latestReadReleaseNoteVersionId) {
      this._latestReadReleaseNoteVersionId = currentVersionId;
      this.saveReleaseNotes();
    }
  };

  checkIsArray(parameter: any) {
    return Array.isArray(parameter) && parameter.length > 0;
  }
}
