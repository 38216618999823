<div class="second-call" [class]='secondCall?.state.css' [ngClass]="{'holding-call': secondCall?.isHolding()}">
  <!-- Avatar -->
  <app-avatar-single
    [user]="secondCall.peerUser"
    [isGroupCall]="secondCall?.participants?.length > 1 || secondCall?.isDirectUpgradedToConf"
    [isOnHold]="secondCall?.isHolding() || secondCall?.state.css === 'holding-call'"
    size="large"
    class="avatar">
  </app-avatar-single>

    <!-- Second-call Info -->
  <div class="second-call-info">
    <div class="mat-caption info-line" *ngIf="!!secondCall?.state.ui"
         [ngClass]="{'call-state-warn': (secondCall.state.css === 'declined' ||
                                        secondCall.state.css === 'failed' ||
                                        secondCall.state.css === 'busy'), 'call-state-accent' : secondCall.state.css === 'active-call-remote'}">
      {{showCallStateUi() ? root.i18n.map[secondCall.state.ui]: ''}}
      <span uLocalize="res_OnHold" *ngIf="secondCall?.isHolding() && secondCall?.state.ui !== 'res_OnHold'"></span>
    </div>
    <!-- Direct Call -->
    <ng-template [ngIf]="secondCall?.participants?.length <= 1 && !secondCall?.isDirectUpgradedToConf">
      <div class="mat-title other-person-number info-line">
        <mat-icon *ngIf="!!secondCall?.redirectingUser && secondCall?.forwarded"
                  class="material-icons md-15"
                  matTooltip="{{ root.i18n.localize(secondCall?.redirectingUser?.redirectionType?.ui, [getRedirectingUserUiStr(secondCall?.redirectingUser)])}}"
              >phone_forwarded</mat-icon>
        <mat-icon *ngIf="!!secondCall?.redirectingUser && (secondCall.pickupNotification || secondCall.pickedUp)"
                  class="material-icons md-15 icon-pickup-group-icon"
                  matTooltip="{{root.i18n.localize(secondCall?.redirectingUser?.redirectionType?.ui, [getRedirectingUserUiStr(secondCall?.redirectingUser)])}}"></mat-icon>
        {{secondCall.peerUser.phoneNumber}}
      </div>
      <div class="mat-caption info-line">{{secondCall.peerUser.displayName}}</div>
    </ng-template>
    <!-- Merged Call -->
    <ng-template [ngIf]="secondCall?.participants?.length > 1 || secondCall?.isDirectUpgradedToConf">
      <div class="mat-title other-person-number info-line">
        <mat-icon *ngIf="!!secondCall?.redirectingUser && secondCall?.forwarded"
              class="material-icons md-15"
              matTooltip="{{root.i18n.localize(secondCall?.redirectingUser?.redirectionType?.ui, [getRedirectingUserUiStr(secondCall?.redirectingUser)])}}"
              >phone_forwarded</mat-icon>
        <mat-icon *ngIf="secondCall?.pickedUp" class="material-icons md-15 icon-pickup-group-icon"
              matTooltip="{{root.i18n.localize(secondCall?.redirectingUser?.redirectionType?.ui, [getRedirectingUserUiStr(secondCall?.redirectingUser)])}}"></mat-icon>
        <mat-icon class="material-icons-outlined md-15">call_merge</mat-icon>
        {{getCallTitleUiStr(secondCall)}}
      </div>
      <div class="mat-caption call-participants" matTooltip="{{getCallStateUiStr(secondCall)}}">{{getCallStateUiStr(secondCall)}}</div>
    </ng-template>
  </div>

  <!-- Available actions -->
  <div class="available-actions">
    <!-- Swap call button -->
    <div class="center btn-wrapper" *ngIf="canSwapCall(secondCall.callId)" [ngSwitch]="hasAnyCallInTransientState()">
      <button mat-button *ngSwitchCase="false"
              class="circle-button small hold-swap swap"
              uAriaLabel="res_SwapCall"
              (click)="swapCall(secondCall)"
              data-autotest="swapCall">
        <mat-icon class="material-icons-outlined md-20">swap_calls</mat-icon>
      </button>
      <div *ngSwitchCase="true" class="circle-button small hold-swap" uAriaLabel="res_AccessibilityLoading">
        <div class="loading-wrapper">
          <mat-spinner [diameter]="20"></mat-spinner>
        </div>
      </div>
      <span class="mat-caption" uLocalize="res_SwapCall"></span>
    </div>

    <!-- Hold Call -->
    <div class="center btn-wrapper" *ngIf="canHoldCall(secondCall.callId)" [ngSwitch]="hasAnyCallInTransientState()">
      <button mat-button *ngSwitchCase="false"
              class="circle-button small hold"
              (click)="holdCall(secondCall)"
              uAriaLabel="res_HoldCall"
              data-autotest="holdCall">
        <mat-icon class="material-icons-outlined md-20">pause</mat-icon>
      </button>
      <div *ngSwitchCase="true" class="circle-button small hold" uAriaLabel="res_AccessibilityLoading">
        <div class="loading-wrapper">
          <mat-spinner [diameter]="20"></mat-spinner>
        </div>
      </div>
      <span class="mat-caption" uLocalize="res_HoldCall"></span>
    </div>

    <!-- Retrieve Hold Only  -->
    <div class="center btn-wrapper" *ngIf="canRetrieveCall(secondCall.callId)" [ngSwitch]="hasAnyCallInTransientState()">
      <button mat-button *ngSwitchCase="false"
              class="circle-button small hold-only retrieve active"
              uAriaLabel="res_RetrieveCall"
              (click)="retrieveCall(secondCall)"
              data-autotest="retrieveCall">
        <mat-icon class="material-icons-outlined md-20">pause</mat-icon>
      </button>
      <div *ngSwitchCase="true" class="circle-button small hold-only" uAriaLabel="res_AccessibilityLoading">
        <div class="loading-wrapper">
          <mat-spinner [diameter]="20"></mat-spinner>
        </div>
      </div>
      <span class="mat-caption" uLocalize="res_HoldCall"></span>
    </div>

    <!-- Pull Remote Second Call -->
    <div class="center btn-wrapper" *ngIf="canPullRemoteCall(secondCall.callId)">
      <button mat-fab color="green" class="btn-small"
              uAriaLabel="res_AccessibilityPullCall"
              (click)="pullRemoteCall(secondCall.callId)"
              matTooltip="{{root.i18n.map.res_PullCallTooltip}}"
              matTooltipPosition="above">
        <mat-icon class="md-20 icon-pull-icon"></mat-icon>
      </button>
    </div>
    <!-- Push Call -->
    <div *ngIf="canPushLocalCall(secondCall?.callId)" class="btn-wrapper">
      <button mat-fab color="green" class="btn-small"
              [attr.aria-label]="root.i18n.localize('res_AccessibilityPushCall', [root.i18n.map[defaultPushDevice.value.ui]])"
              (click)="pushLocalCall(secondCall.callId, defaultPushDevice.value.id)">
        <mat-icon class="md-20" [class]="defaultPushDevice.value.icon">{{defaultPushDevice.value.icon !== 'icon-deskphone-icon' ? defaultPushDevice.value.icon : ''}}</mat-icon>
      </button>
      <app-push-incoming-call *ngIf="pushDevices && pushDevices.length > 1"
                              [size]="'small'"
                              [action]="'pushLocalCall'"
                              [call]="secondCall"
                              [devices]="pushDevices"
                              [color]="'green'"
                              [title]="'res_PushCallDropdownLabel'"
                              [btnLabel]=""
                              [ariaLabel]="'res_PushCall'"
                              [tooltipText]="'res_PushCall'">
      </app-push-incoming-call>
    </div>

    <!-- Voicemail as separate button is only available when canAnswerCall is true and canHangupCall is false -->
    <!-- For cases where canHangupCall is true the hangup or decline button with drop down is used -->
    <div *ngIf="!canHangupCall(secondCall.callId) && canAnswerCall(secondCall.callId) && declineDevices.length > 0" class="btn-wrapper">
      <button mat-fab color="warn" class="btn-small"
              [attr.aria-label]="root.i18n.map.res_Voicemail"
              (click)="answerCall(secondCall.callId, defaultDeclineDevice.value.id)">
        <mat-icon class="md-20" [class]="defaultDeclineDevice.value.icon">{{defaultDeclineDevice.value.icon}}</mat-icon>
      </button>
      <span class="mat-button label-small voicemail-text" [uLocalizeOnce]="defaultDeclineDevice.value.text"></span>
    </div>

    <!-- hangup or decline button -->
    <div *ngIf="canHangupCall(secondCall.callId)" class="btn-wrapper">
      <button mat-fab color="warn"
              class="btn-small"
              [attr.aria-label]="secondCall?.state?.css !=='incoming' ? root.i18n.map.res_AccessibilityHangup : root.i18n.map.res_AccessibilityDecline"
              (click)="endCall(secondCall)">
        <mat-icon class="md-20">call_end</mat-icon>
      </button>
      <!-- We only want to display decline to voicemail when receiving a call -->
      <app-push-incoming-call *ngIf="canAnswerCall(secondCall?.callId) && declineDevices.length > 0"
                              [action]="'answerCall'"
                              [call]="secondCall"
                              [devices]="declineDevices"
                              [color]="'warn'"
                              [size]="'small'"
                              [title]="'res_SendTo'"
                              [btnLabel]=""
                              [ariaLabel]="'res_Decline'"
                              [tooltipText]="'res_Decline'">
        </app-push-incoming-call>
    </div>

    <!-- Incoming Calls (Voice) -->
    <div *ngIf="canAnswerCall(secondCall.callId)" class="btn-wrapper">
      <button mat-fab color="green"
              class="btn-small"
              uAriaLabel="res_AccessibilityAnswerCall"
              (click)="answerCall(secondCall.callId)"
              data-autotest="answerVoice">
        <mat-icon class="md-20" [class]="defaultAnswerDevice.value.icon">{{defaultAnswerDevice.value.icon !== 'icon-deskphone-icon' ? defaultAnswerDevice.value.icon : ''}}</mat-icon>
      </button>
      <app-push-incoming-call *ngIf="answerDevices.length > 0"
                              [action]="'answerCall'"
                              [call]="secondCall"
                              [devices]="answerDevices"
                              [color]="'green'"
                              [title]="'res_AnswerWith'"
                              [size]="'small'"
                              [btnLabel]=""
                              [ariaLabel]="'res_Answer'"
                              [tooltipText]="'res_Answer'">
        </app-push-incoming-call>
    </div>
  </div>
</div>
<mat-divider></mat-divider>
