<h1 mat-dialog-title>SDP parameters for audio (opus)</h1>
<mat-dialog-content>
    <div class="horizontal-wrapper">
        <label for="id_rtcDebugAudio_maxplaybackrate" class="label large">Max rendering sampling rate:</label>
        <input class="rtp-inputs" type="text" id="id_rtcDebugAudio_maxplaybackrate"
            [(ngModel)]="rtcDebug.audio.maxplaybackrate">
        <div class="unit">Hz (48000)</div>
    </div>
    <div class="horizontal-wrapper">
        <label for="id_rtcDebugAudio_sprop-maxcapturerate" class="label large">Max sender sampling rate:</label>
        <input class="rtp-inputs" type="text" id="id_rtcDebugAudio_sprop-maxcapturerate"
            [(ngModel)]="rtcDebug.audio['sprop-maxcapturerate']">
        <div class="unit">Hz (48000)</div>
    </div>
    <div class="horizontal-wrapper">
        <label for="id_rtcDebugAudio_maxptime" class="label large">Max packet duration:</label>
        <input class="rtp-inputs" type="text" id="id_rtcDebugAudio_maxptime" [(ngModel)]="rtcDebug.audio.maxptime">
        <div class="unit">ms (120)</div>
    </div>
    <div class="horizontal-wrapper">
        <label for="id_rtcDebugAudio_ptime" class="label large">Preferred packet duration:</label>
        <input class="rtp-inputs" type="text" id="id_rtcDebugAudio_ptime" [(ngModel)]="rtcDebug.audio.ptime">
        <div class="unit">ms (20)</div>
    </div>
    <div class="horizontal-wrapper">
        <label for="id_rtcDebugAudio_maxaveragebitrate" class="label large">Max average receive bitrate:</label>
        <input class="rtp-inputs" type="text" id="id_rtcDebugAudio_maxaveragebitrate"
            [(ngModel)]="rtcDebug.audio.maxaveragebitrate">
        <div class="unit"> bit/s</div>
    </div>

    <mat-checkbox color="primary" [(ngModel)]="rtcDebug.audio.stereo" binary="true">Prefer receiving stereo
    </mat-checkbox>

    <mat-checkbox color="primary" [(ngModel)]="rtcDebug.audio['sprop-stereo']" binary="true">Prefer sending stereo
    </mat-checkbox>

    <mat-checkbox color="primary" [(ngModel)]="rtcDebug.audio.cbr" binary="true">
        Use constant bitrate
    </mat-checkbox>

    <mat-checkbox color="primary" [(ngModel)]="rtcDebug.audio.useinbandfec" binary="true">Take advantage of the
        Opus in-band FEC</mat-checkbox>

    <mat-checkbox color="primary" [(ngModel)]="rtcDebug.audio.usedtx" binary="true">Use Discontinuous Transmission
        (DTX)</mat-checkbox>
</mat-dialog-content>
<mat-dialog-actions align="end" class="dialog-actions-container">
    <button mat-raised-button color="primary" (click)="confirm()">
        <span uLocalize="res_Save"></span>
    </button>
    <button mat-raised-button (click)="close()">
        <span uLocalize="res_Cancel"></span>
    </button>
</mat-dialog-actions>
