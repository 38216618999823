import { Component, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { UtilsService } from '../../../services/utils.service';

declare let Circuit: any;

const defaultCategories = [
  {
    altLabel: 'res_DefaultAudioOutput',
    categoryId: 'AUDIO_OUTPUT',
    label: 'res_DefaultDeviceLabel',
    noPermissionLabel: 'res_NoAudioOutput'
  },
  {
    altLabel: 'res_DefaultAudioOutput',
    categoryId: 'RINGING_OUTPUT',
    label: 'res_DefaultDeviceLabel',
    noPermissionLabel: 'res_NoAudioOutput'
  },
  {
    altLabel: 'res_DefaultAudioOutput',
    categoryId: 'RINGING_OUTPUT',
    label: 'res_DefaultDeviceLabel',
    noPermissionLabel: 'res_NoAudioOutput'
  },
  {
    altLabel: 'res_DefaultMicrophone',
    categoryId: 'AUDIO_INPUT',
    label: 'res_DefaultDeviceLabel',
    noPermissionLabel: 'res_NoMicrophonesFound'
  }
];

type Source = {
  altLabel: string;
  categoryId: string;
  groupId: string;
  id: string;
  kind: string;
  label: string;
} |
{
    categoryId: string;
    groupId: string;
    id: string;
    kind: string;
    label: string;
}

interface Category {
  checkPermissions: any;
  defaultSrc: {
    altLabel: string;
    categoryId: string;
    label: string;
    noPermissionLabel: string;
  };
  id: string;
  initialDeviceId?: string | null;
  name: string;
  selectedDevice?: {
    categoryId: string;
    groupId: string;
    id: string;
    kind: string;
    label: string;
  } | null;
  setSelectedDevice(arg0: any): any;
  sources: Source[]
}

@Component({
  selector: 'app-audio-tab',
  templateUrl: './audio-tab.component.html',
  styleUrls: ['./audio-tab.component.scss']
})
export class AudioTabComponent implements OnInit {
  private LogSvc: any;
  private PubSubSvc: any;
  private LocalStoreSvc: any;
  private DeviceSettingsSvc: any;
  private _webHIDSupported: boolean;

  rootScope: any;
  selectedSources: any = [];
  deviceCategories: Category[] = [];
  // Avoid having the selected device change during the load process,
  // so show it only when it's finished (when this.devicesReady is true)
  devicesReady = false;
  destroying = false;
  audioAGCEnabled = false;
  audioECEnabled = false;
  // Imports
  Constants = Circuit.Constants;
  Utils = Circuit.Utils;

  constructor(private commonBlService: CommonBLService, private utilsService: UtilsService) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this.DeviceSettingsSvc = Circuit.serviceInstances.deviceSettingsSvc;
    this.LocalStoreSvc = Circuit.serviceInstances.localStoreSvc;
    this.rootScope = this.commonBlService.getRootScopeData();
    this._webHIDSupported = this.utilsService.isWebHIDSupported();
  }

  ngOnInit(): void {
    this.LogSvc.info('[AudioTabComponent]: ngOnInit');
    this.initialize();
    // In Firefox & Chrome the MediaDevices.enumerateDevices API will return the devices without a label
    // if the user has not granted persistent permissions for Circuit. In order to address that
    // restriction we will update the devices list as soon as we get confirmation that the
    // getUserMedia API has been successly invoked, which indicates that the app has been granted
    // permission.
    if (this.rootScope.browser.firefox || this.rootScope.browser.chrome) {
      this.PubSubSvc.subscribe('/getUserMedia/success', this.onGetUserMediaSuccess);
    }

    this.PubSubSvc.subscribe('/media/device/added', this.onMediaDeviceChanged);
    this.PubSubSvc.subscribe('/media/device/removed', this.onMediaDeviceChanged);
    this.PubSubSvc.subscribe('/media/device/updated', this.onMediaDeviceSelectionUpdated);
    this.PubSubSvc.subscribe('/testCall/ended', this.onTestCallEnded);

    this.audioAGCEnabled = Circuit.RtcSessionController.enableAudioAGC;
    this.audioECEnabled = Circuit.RtcSessionController.enableAudioEC;
  }

  initialize = (): void => {
    this.devicesReady = false;
    this.deviceCategories = [];
    this.selectMediaDevices();
  };

  onGetUserMediaSuccess = () => {
    if (!this.canMakeTestCall()) {
      this.LogSvc.debug('[AudioTabComponent]: Received /getUserMedia/success event');
      this.selectMediaDevices();
    }
  };

  onMediaDeviceChanged = () => {
    this.LogSvc.debug('[AudioTabComponent]: Received /media/device/added or /media/device/removed event');
    this.initialize();
  };

  onMediaDeviceSelectionUpdated = () => {
    this.LogSvc.debug('[AudioTabComponent]: Received /media/device/updated event');
    this.initialize();
  };

  onTestCallEnded = () => {
    this.LogSvc.debug('[AudioTabComponent]: Received /testCall/ended event');
    this.selectMediaDevices();
  };

  onSelectChange(category: Category, source: Source) {
    category.setSelectedDevice(source);

    if (category.selectedDevice) {
      this.selectedSources[category.id] = category.selectedDevice;
      // This will trigger a /media/device/updated event
      this.DeviceSettingsSvc.setDevices([category.selectedDevice]);
    }
  }

  selectMediaDevices = () => {
    this.setMediaSources(() => {
      this.deviceCategories.forEach((category: Category) => {
        // we add manually for our select field the selected source
        // eslint-disable-next-line no-nested-ternary
        this.selectedSources[category.id] = category.selectedDevice ? category.selectedDevice : category.sources.length > 0 ?
          category.sources.find((a:any) => a.label === 'res_DefaultDeviceLabel') : defaultCategories.find((a:any) => a.categoryId === category.id);
      });
    });
  };

  setDeviceCategories = (fetchedDeviceCategories: Category[]) => {
    if (fetchedDeviceCategories !== null && fetchedDeviceCategories.length > 0) {
      this.deviceCategories = fetchedDeviceCategories.filter(category => category.id !== this.Constants.AudioVideoDeviceCategories.VIDEO_INPUT);
    }
  };

  setMediaSources = (cb: any) => {
    this.DeviceSettingsSvc.fetchDevices().then((finalCategories: Category[]) => {
      // Make sure we are still in the Diagnostics tab
      if (!this.isOpen()) {
        return;
      }
      this.setDeviceCategories(finalCategories);
      this.devicesReady = true;
      cb && cb();
    }, null, (initialCategories: Category[]) => {
      // We should display the initialCategories only once
      this.setDeviceCategories(initialCategories);
    });
  };

  findDeviceCategory(categoryId: string) {
    return this.deviceCategories.find((category: Category) => category.id === categoryId);
  }

  isOpen = () => !this.destroying;

  isWebHIDSupported = () => this._webHIDSupported;

  isFirefox = () => this.rootScope.browser.firefox;

  supportsHeadsetIntegrations = () => !this.isFirefox() || this.isWebHIDSupported();

  canMakeTestCall = () => {
    const categoryId = this.Constants.AudioVideoDeviceCategories.AUDIO_INPUT;
    const category = this.findDeviceCategory(categoryId);
    return !!(category && category.sources.length > 0);
  };

  toggleAudioAGC(event: any) {
    const audioAGC = event.checked;
    this.audioAGCEnabled = event.checked;
    Circuit.RtcSessionController.enableAudioAGC = audioAGC;
    this.LocalStoreSvc.setObjectSync(this.LocalStoreSvc.keys.AUDIOAGC, audioAGC);
  }

  toggleAudioEC(event: any) {
    const audioEC = event.checked;
    this.audioECEnabled = audioEC;
    Circuit.RtcSessionController.enableAudioEC = audioEC;
    this.LocalStoreSvc.setObjectSync(this.LocalStoreSvc.keys.AUDIOEC, audioEC);
  }

  ngOnDestroy() {
    this.LogSvc.info('[AudioTabComponent]: ngOnDestroy');
    this.destroying = true;

    if (this.rootScope.browser.firefox || this.rootScope.browser.chrome) {
      this.PubSubSvc.unsubscribe('/getUserMedia/success', this.onGetUserMediaSuccess);
    }

    this.PubSubSvc.unsubscribe('/media/device/added', this.onMediaDeviceChanged);
    this.PubSubSvc.unsubscribe('/media/device/removed', this.onMediaDeviceChanged);
    this.PubSubSvc.unsubscribe('/media/device/updated', this.onMediaDeviceSelectionUpdated);
    this.PubSubSvc.unsubscribe('/testCall/ended', this.onTestCallEnded);
  }
}
