<div class="dialog-container" uPreventDrag>
  <div mat-dialog-title class="title-container">
    <h1 class="dialog-title headline">{{rootScope.i18n.map[titleLabel]}}</h1>
  </div>
  <mat-dialog-content class="central-pane">
    <div [ngSwitch]="mode">
      <div *ngSwitchCase="'view'">
        <div class="avatar-container">
          <input #pictureInput hidden type="file" accept="image/gif, image/jpeg, image/bmp, image/png" (change)="browsePicture($event)" (click)="pictureInput.value=''"/>
          <div class="image-wrapper" uAllowDrag (onDragEvent)="onDragEventParent($event)">
            <button *ngIf="!!currentImage"
                    mat-fab
                    color="primary"
                    class="att-remove close-btn"
                    [attr.aria-label]="rootScope.i18n.map.res_AccessibilityDeleteProfilePicture"
                    (click)="clearPicture()">
              <mat-icon class="close-icon material-icons-outlined md-15">close</mat-icon>
            </button>
            <div tabindex="0"
              role="button"
              class="avatar-single"
              [attr.aria-label]="rootScope.i18n.map.res_ClickDragDropAddProfile"
              (click)="pictureInput.click()"
              (keyup.enter)="pictureInput.click()"
              (mouseenter) ="editProfilePic = true"
              (mouseleave) ="editProfilePic = false"
              (focus) ="editProfilePic = true"
              (focusout) ="editProfilePic = false">
              <div [ngClass]="['avatar-image', color]">
                <img *ngIf="user.hasAvatarPicture && !hasImageError"
                      class="known-user uploaded-image"
                      [src]="currentImage"
                      alt="avatar"
                      (error)="onAvatarPictureError($event)">
                <span *ngIf="!user.hasAvatarPicture || hasImageError" class="known-user initials">{{getInitials()}}</span>
              </div>
              <!-- overlay -->
              <div class="overlay" *ngIf="editProfilePic !== undefined" [ngClass]="{'show': editProfilePic }">
                <div *ngIf="editProfilePic" [hidden]="showDropArea" class="edit-profile" uLocalize="res_ClickDragDropAddProfile"></div>
                <div *ngIf="editProfilePic" [hidden]="!showDropArea" class="edit-profile" uLocalize="res_DropFile"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="action-buttons">
          <button mat-stroked-button
              class="take-picture-button action-button"
              [attr.aria-label]="rootScope.i18n.map.res_TakePicture"
              (click)="takePicture()" autofocus>
              <mat-icon class="camera-icon">photo_camera</mat-icon>
          </button>
        </div>
        <div class="gallery"></div>
      </div>
      <div *ngSwitchCase="'edit'">
        <div *ngIf="warningMessage" class="warning-message">{{warningMessage}}</div>
        <div class="editor">
          <div class="canvas-container cropper-container">
            <canvas #canvas hidden></canvas>
            <image-cropper uAllowDrag (onDragEvent)="onDragEventParent($event)"
                          class="editor"
                          *ngIf="imageSrc"
                          [alignImage]="'center'"
                          [imageChangedEvent]="imageChangedEvent"
                          imageAltText=""
                          [imageBase64]="imageSrc"
                          [maintainAspectRatio]="true"
                          [aspectRatio]="1 / 1"
                          [canvasRotation]="canvasRotation"
                          format="png"
                          (imageCropped)="imageCropped($event)">
            </image-cropper>
            <img #image [src]="croppedImage" alt="" hidden>
          </div>
        </div>
        <div class="action-buttons">
          <button mat-button (click)="rotate()">
            <mat-icon class="material-icons-outlined md-25">rotate_right</mat-icon>
            <span uLocalize="res_RotatePicture"></span>
          </button>
        </div>
        <div class="gallery"></div>
      </div>
      <div *ngSwitchCase="'webcam'">
        <div *ngIf="warningMessage" class="warning-message" [textContent]="warningMessage"></div>
        <app-photo-booth
              class="photo-booth"
              (images)="getPhotoBoothImages($event)">
        </app-photo-booth>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="multiple-actions-container">
    <div>
      <button mat-button (click)="back()" *ngIf="showBack()" class="back-btn">
        <mat-icon class="material-icons-outlined md-15">arrow_back_ios</mat-icon>
        <span uLocalize="res_Back"></span>
      </button>
    </div>
    <div class="right-actions">
      <div class="loading-wrapper" *ngIf="isLoading">
        <mat-spinner [diameter]="24"></mat-spinner>
      </div>
      <button mat-button (click)="save()" *ngIf="isSavePossible()">
          <span uLocalize="res_Save"></span>
      </button>
      <button mat-button (click)="close(false)">
        <span uLocalize="res_Cancel"></span>
      </button>
    </div>
  </mat-dialog-actions>
</div>
