import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dialpad-button',
  templateUrl: './dialpad-button.component.html',
  styleUrls: ['./dialpad-button.component.scss']
})
export class DialpadButtonComponent implements OnInit {
  @Input() dialpadNumber!: string;
  @Input() dialpadLetter!: string;
  @Input() hasLongPress = false;
  @Output() btnClick = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClick(value: any) {
    this.btnClick.emit(value);
  }

}
