import { Component, Inject, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

declare let Circuit: any;
const UPDATE_INTERVAL = 5010; // slightly over RTP collection timer

@Component({
  selector: 'app-call-quality',
  templateUrl: './call-quality.component.html',
  styleUrls: ['./call-quality.component.scss']
})
export class CallQualityComponent implements OnInit {
  private LogSvc: any;
  private CallControlSvc: any;
  private PubSubSvc: any;
  private _callQualityInfo:any;

  private _updateInterval: any;
  root: any;
  callQualityVideoValues: any;
  callQualityAudioValues: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private mdDialogRef: MatDialogRef<CallQualityComponent>,
  private commonBlService: CommonBLService) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.CallControlSvc = Circuit.serviceInstances.callControlSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this._updateInterval = null;
  }

  ngOnInit(): void {
    this.root = this.commonBlService.getRootScopeData();
    this._callQualityInfo = {
      VIDEO_FRAME_WIDTH_SENT: {name: 'res_Resolution', value: null, lowQuality: false},
      VIDEO_FRAME_RATE_SENT: {name: 'res_FrameRate', value: null, lowQuality: false, unit: 'fps'},
      VIDEO_BW_SEND: {name: 'res_BandwidthAvailable', value: null, lowQuality: false, unit: 'kbps'},
      VIDEO_TRX_BITRATE: {name: 'res_BandwidthUsed', value: null, lowQuality: false, unit: 'kbps'},
      VIDEO_PL_SEND: {name: 'res_PacketsLostSend', value: null, lowQuality: false, unit: '%'},
      VIDEO_PL_RECV: {name: 'res_PacketsLostRecv', value: null, lowQuality: false, unit: '%'},
      VIDEO_RTT: {name: 'res_RoundTripTime', value: null, lowQuality: false, unit: 'ms'},
      AUDIO_JITTER: {name: 'res_QosMetrics_Ji', value: null, lowQuality: false, unit: 'ms'},
      AUDIO_PL_SEND: {name: 'res_PacketsLostSend', value: null, lowQuality: false, unit: '%'},
      AUDIO_PL_RECV: {name: 'res_PacketsLostRecv', value: null, lowQuality: false, unit: '%'},
      AUDIO_RTT: {name: 'res_RoundTripTime', value: null, lowQuality: false, unit: 'ms'}
    };

    this.LogSvc.debug('New AngularJS Component: CallQualityComponent');
    this.callQualityVideoValues = [];
    this.callQualityAudioValues = [];

    Object.keys(this._callQualityInfo).forEach(key => {
      if (key.startsWith('VIDEO_')) {
        this.callQualityVideoValues.push(this._callQualityInfo[key]);
      } else {
        this.callQualityAudioValues.push(this._callQualityInfo[key]);
      }
    });

    this.onPopoverShown();
    this.PubSubSvc.subscribe('/call/network/quality', this.onCallNetworkQuality);
    this.PubSubSvc.subscribe('/call/ended', this.onCallEnded);
  }

  ngOnDestroy(): void {
    this.onPopoverHide();
    this.PubSubSvc.unsubscribe('/call/network/quality', this.onCallNetworkQuality);
    this.PubSubSvc.unsubscribe('/call/ended', this.onCallEnded);
  }

  onCallEnded = (call: any) => {
    if (call.callId === this.data.call?.callId) {
      this.mdDialogRef.close(false);
    }
  };

  checkLowQuality(obj: any) {
    // Quality level values are 0 (poor), 1 (low), 2 (medium), and 3 (high)
    return !!(obj && obj.qualityLevel && obj.qualityLevel.value < 3);
  }

  getQualityValue(obj: any, unit: string) {
    if (!obj || obj.value === undefined) {
      return null;
    }
    let value = obj.value;
    if (unit === '%') {
      // Value is in decimal format (e.g.: 0.33), convert it into an integer
      value = Math.round(value * 100);
    } else if (unit === 'kbps') {
      // Convert from bit/s to kbps
      value = Math.floor(value / 1000);
    }
    return value + ' ' + unit;
  }

  getResolutionValue(width: any, height: any) {
    if (!width || !width.value) {
      return null;
    }
    return width.value + ' x ' + height.value;
  }

  setStat = (networkQualityInfo: any, statName: any) => {
    const mediaInfo = statName.startsWith('VIDEO_') ? networkQualityInfo.video : networkQualityInfo.audio;
    const propertyName = statName.slice(6); // Remove VIDEO_/AUDIO_
    const obj = mediaInfo[propertyName];

    if (propertyName === 'FRAME_WIDTH_SENT') {
      this._callQualityInfo[statName].value = this.getResolutionValue(obj, mediaInfo.FRAME_HEIGHT_SENT);
    } else {
      this._callQualityInfo[statName].value = this.getQualityValue(obj, this._callQualityInfo[statName].unit);
    }
    this._callQualityInfo[statName].lowQuality = this.checkLowQuality(obj);
  };

  getCallQualityInfo = () => {
    this.LogSvc.debug('[CallQualityComponent]: Get call quality info');
    const networkQualityInfo = this.CallControlSvc.getNetworkQualityInfo(this.data.call?.callId);
    if (networkQualityInfo) {
      this.LogSvc.debug('[CallQualityComponent]: Set call quality info data');
      const setFn = this.setStat.bind(null, networkQualityInfo);
      Object.keys(this._callQualityInfo).forEach(setFn);
    }
  };

  cancelInterval = () => {
    if (this._updateInterval) {
      clearInterval(this._updateInterval);
      this._updateInterval = null;
    }
  };

  restartMonitoring = () => {
    this.getCallQualityInfo();
    this.cancelInterval();
    this._updateInterval = setInterval(this.getCallQualityInfo, UPDATE_INTERVAL);
  };

  onCallNetworkQuality = () => {
    if (this._updateInterval) {
      // New stats are available. Sync UI timer with BL.
      this.restartMonitoring();
    }
  };

  onPopoverShown = () => {
    this.LogSvc.debug('[CallQualityComponent]: Call quality popover has been opened');
    this.restartMonitoring();
  };

  onPopoverHide = () => {
    this.LogSvc.debug('[CallQualityComponent]: Call quality popover has been hidden');
    this.cancelInterval();
  };

  showValues = (src: string) => {
    let countValues = 0;
    let array = [];
    if (src === 'video') {
      array = this.callQualityVideoValues;
    } else {
      array = this.callQualityAudioValues;
    }
    if (array) {
      array.forEach((element: any) => {
        if (element.value) {
          countValues++;
        }
      });
      if (countValues === 0) {
        return false;
      }
      return true;
    }
    return false;
  };
}
