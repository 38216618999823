import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  @Input() contact: any;
  @Input() searchTerm = '';
  @Input() isFirst = false;
  @Input() showPresence = false;
}
