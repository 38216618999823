import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { CallActionService } from '../../services/call-action.service';
import { DialogService } from '../../sub-modules/dialog/dialog.service';

declare let Circuit: any;

@Component({
  selector: 'app-call-views',
  templateUrl: './call-views.component.html',
  styleUrls: ['./call-views.component.scss']
})
export class CallViewsComponent implements OnInit, OnDestroy {

  /*
    * Enum for Call Views
  */
  View = Object.freeze({
    TRANSFER: 'Transfer',
    NEWCALL: 'NewCall',
    DTMF: 'Dtmf'
  });

  private LogSvc: any;
  private PhoneCallSvc: any;
  private PubSubSvc: any;

  hasDtmfView: boolean;
  hasNewCallView: boolean;
  hasTransferView: boolean;
  hasViewOpen: boolean;
  root: any;
  call: any;
  phoneCalls: any;

  constructor(private commonBlService: CommonBLService, private callAction: CallActionService, private dialogService: DialogService, private _snackBar: MatSnackBar) {
    this.hasDtmfView = false;
    this.hasNewCallView = false;
    this.hasTransferView = false;
    this.hasViewOpen = false;
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this.root = this.commonBlService.getRootScopeData();
    this.PhoneCallSvc = Circuit.serviceInstances.phoneCallSvc;
  }

  ngOnDestroy(): void {
    this.LogSvc.info('[CallViewsComponent]: ngOnDestroy');
    this.PubSubSvc.unsubscribe('/conversation/update', this.onConversationUpdate);
    this.PubSubSvc.unsubscribe('/call/ended', this.onEndCall);
    this.PubSubSvc.unsubscribe('/call/state', this.onCallState);
  }

  ngOnInit(): void {
    this.LogSvc.info('[CallViewsComponent]: ngOnInit');
    this.PubSubSvc.subscribe('/conversation/update', this.onConversationUpdate);
    this.PubSubSvc.subscribe('/call/ended', this.onEndCall);
    this.PubSubSvc.subscribe('/call/state', this.onCallState);
    this.electPrimaryCall();
  }

  // private bindSvcFunctions = () => {
  //   [
  //     'canAnswerOnDevice',
  //     'canInitiateConsultation',
  //     'canPickupCall',
  //     'canDialFromDevice',
  //     'isCallBusy',
  //     'stopRingingTone'
  //   ].forEach((name) => {
  //       (this as any)[name] = this.PhoneCallSvc[name];
  //   });
  // }

  private onConversationUpdate = (conversation: any) => {
    this.LogSvc.debug('[CallViewsComponent]: Received /conversation/update event');
    if (conversation.call) {
      this.LogSvc.debug(`[CallViewsComponent]: electPrimaryCall for call state: ${conversation.call?.state?.css}, peer: ${conversation.call?.atcCallInfo?.peerDn}`);
      this.electPrimaryCall();
    }
  };

  private onEndCall = (call: any) => {
    if (call && !call.isHandoverInProgress) {
      this.LogSvc.debug('[CallViewsComponent]: Received /call/ended event');
      const transferFailedCause = call.transferCallFailedCause;
      if (transferFailedCause) {
        this.dialogService.handleCallError(transferFailedCause.ui);
      }
      this.phoneCalls = this.PhoneCallSvc.getPhoneCalls();
      if (this.call && this.call.callId === call.callId) {
        this.LogSvc.debug(`[CallViewsComponent]: electPrimaryCall for call state: ${call.state.css}, peer: ${call.atcCallInfo?.peerDn}`);
        this.electPrimaryCall();
      }
    }
  };

  private onCallState = (call: any) => {
    this.LogSvc.debug('[CallViewsComponent]: Received /call/state event with state:', call.state.css);

    this.phoneCalls = this.PhoneCallSvc.getPhoneCalls();
    // For OsBiz where calls can be active only on one client, we need to show properly active and remote-active calls
    if (this.root.localUser.isOsBizCTIEnabled && call.checkState([Circuit.Enums.CallState.Active, Circuit.Enums.CallState.ActiveRemote]) && !call.isHolding()) {
      this.call = call;
      return;
    }

    this.LogSvc.debug(`[CallViewsComponent]: electPrimaryCall for call state: ${call.state.css}, peer: ${call.atcCallInfo?.peerDn}`);
    this.electPrimaryCall();
  };

  private electPrimaryCall = () => {
    this.phoneCalls = this.PhoneCallSvc.getPhoneCalls();
    this.LogSvc.debug(`[CallViewsComponent]: electPrimaryCall phone calls items: ${this.phoneCalls.length}`);

    if (this.phoneCalls.length === 1) {
      this.call = this.phoneCalls[0];
    } else if (this.phoneCalls.length > 1) {
      // Define call categories for electing primary call
      let activeLocalCall = null;
      let holdLocalCall = null;
      let incomingCall = null;
      let activeRemoteCall = null;
      let holdRemoteCall = null;
      let outgoingCall = null;
      let declinedCall = null;
      let holdActiveCall = null;

      for (let i = 0; i < this.phoneCalls.length; i++) {
        const call = this.phoneCalls[i];
        if (call.checkState([Circuit.Enums.CallState.Active]) && !call.isHolding()) {
          activeLocalCall = call;
          this.LogSvc.debug(`[CallViewsComponent]: Primary active call assigned for peer:  ${call.atcCallInfo?.peerDn}`);
          break;
        } else if (call.checkState([Circuit.Enums.CallState.Active])) {
          holdActiveCall = call;
        } else if (call.checkState([Circuit.Enums.CallState.Initiated, Circuit.Enums.CallState.Delivered, Circuit.Enums.CallState.Busy])) {
          outgoingCall = call;
        } else if (call.checkState([Circuit.Enums.CallState.Holding, Circuit.Enums.CallState.Held, Circuit.Enums.CallState.HoldOnHold]) && !call.isRemote) {
          holdLocalCall = call;
        } else if (call.checkState([Circuit.Enums.CallState.Ringing, Circuit.Enums.CallState.Answering])) {
          incomingCall = call;
        } else if (call.checkState([Circuit.Enums.CallState.ActiveRemote]) && !call.isHolding()) {
          activeRemoteCall = call;
        } else if (call.checkState([Circuit.Enums.CallState.ActiveRemote])) {
          holdRemoteCall = call;
        } else if (call.checkState([Circuit.Enums.CallState.Declined, Circuit.Enums.CallState.Terminated, Circuit.Enums.CallState.Failed, Circuit.Enums.CallState.NotAnswered])) {
          declinedCall = call;
        } else {
          this.LogSvc.debug(`[CallViewsComponent]: Primary call was not updated for peer:  ${call.atcCallInfo?.peerDn}`);
        }
      }

      // Define priority of call types for electing primary call
      if (activeLocalCall) {
        this.call = activeLocalCall;
      } else if (holdActiveCall) {
        this.call = holdActiveCall;
      } else if (outgoingCall) {
        this.call = outgoingCall;
      } else if (declinedCall) {
        this.call = declinedCall;
      } else if (holdLocalCall) {
        this.call = holdLocalCall;
      } else if (incomingCall) {
        this.call = incomingCall;
      } else if (activeRemoteCall) {
        this.call = activeRemoteCall;
      } else if (holdRemoteCall) {
        this.call = holdRemoteCall;
      } else {
        this.LogSvc.warn('[CallViewsComponent]: Primary call was not elected.');
      }
      this.LogSvc.info(`[CallViewsComponent]: Primary call assigned for peer:  ${this.call.atcCallInfo?.peerDn}`);
    }

    this.callAction.setElectedCall(this.call);
  };

  private initiateConsultation = () => {
    if (this.call) {
      this.LogSvc.buttonPressed('[CallViewsComponent]: Initiate consultation');
      this.PhoneCallSvc.initiateConsultation(this.call.callId);
    }
  };

  private cancelConsultation = () => {
    if (this.call) {
      this.LogSvc.buttonPressed('[CallViewsComponent]: Cancel consultation');
      this.PhoneCallSvc.cancelConsultation(this.call.callId);
    }
  };

  toggleView = (view: any) => {
    this.hasViewOpen = !this.hasViewOpen;
    switch (view) {
    case this.View.DTMF:
      this.hasDtmfView = !this.hasDtmfView;
      break;
    case this.View.NEWCALL:
      if (!this.hasNewCallView) {
        //Initiate Consultation
        this.initiateConsultation();
      } else {
        //Cancel Consultation
        this.cancelConsultation();
      }
      this.hasNewCallView = !this.hasNewCallView;
      break;
    case this.View.TRANSFER:
      this.hasTransferView = !this.hasTransferView;
      break;
    }
  };

  get callView() { return this.View; }

}
