import { DOCUMENT } from '@angular/common';
import { Directive, Inject, OnDestroy, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';

declare let Circuit: any;

@Directive({
  selector: '[uFocusVisible]'
})
export class UFocusVisibleDirective implements OnInit, OnDestroy {
  private LogSvc: any;
  private LocalStoreSvc;
  private PubSubSvc;
  private rootScope;
  private LoginSvc;

  constructor(@Inject(DOCUMENT) private document: Document, private commonBLService: CommonBLService) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this.LocalStoreSvc = Circuit.serviceInstances.localStoreSvc;
    this.LoginSvc = Circuit.serviceInstances.loginSvc;
    this.rootScope = this.commonBLService.getRootScopeData();
  }

  private onAccessibilityStatus = (accessibilityEnabled: boolean) => {
    this.LogSvc.info('[UFocusVisibleDirective]: Received /accessibility/status event with status : ', accessibilityEnabled);
    accessibilityEnabled ? this.document.body.classList.add('focus-visible') : this.document.body.classList.remove('focus-visible');
  };

  ngOnInit(): void {
    // Initialization
    this.PubSubSvc.subscribe('/accessibility/status', this.onAccessibilityStatus);
    //loginSvc is only defined in login app
    this.LoginSvc ?
      this.rootScope.accessibilityEnabled = this.LoginSvc.getItem(this.LoginSvc.LocalStoreKey.ACCESSIBILITY_ENABLED) === 'true' :
      this.rootScope.accessibilityEnabled = !!this.LocalStoreSvc.getObjectSync(this.LocalStoreSvc.keys.ACCESSIBILITY_ENABLED);
    this.onAccessibilityStatus(this.rootScope.accessibilityEnabled);
  }

  ngOnDestroy(): void {
    this.PubSubSvc.unsubscribe('/accessibility/status', this.onAccessibilityStatus);
  }

}
