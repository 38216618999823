import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

const THRESHOLD = 400;

@Directive({
  selector: '[appDialPress]'
})
export class DialPressDirective {
  private _timeout: any;
  private _isLong = false;

  @Output() shortPress = new EventEmitter();
  @Output() longPress = new EventEmitter();

  @HostListener('click', ['$event']) onClick() {
    if (this._isLong) {
      this.longPress.emit();
      this._isLong = false;
      return;
    }
    this.shortPress.emit();
  }

  @HostListener('mousedown') onMouseDown() {
    this.setPressDownFunction();
  }

  @HostListener('touchstart') onTouchStart() {
    this.setPressDownFunction();
  }

  setPressDownFunction = () => {
    if (this._timeout) {
      clearTimeout(this._timeout);
    }
    this._timeout = setTimeout(() => {
      this._timeout = null;
    }, THRESHOLD);
  };

  @HostListener('mouseup') onMouseUp() {
    this.clearTimeoutFunction();
  }

  @HostListener('touchend') onTouchEnd() {
    this.clearTimeoutFunction();
  }

  clearTimeoutFunction = () => {
    if (this._timeout) {
      clearTimeout(this._timeout);
      this._timeout = null;
    } else {
      this._isLong = true;
    }
  };
}
