import { Component, ElementRef, Input, OnInit } from '@angular/core';

declare let Circuit: any;

@Component({
  selector: 'call-audio-stream',
  template: '<audio autoplay></audio>',
  styleUrls: ['./call-audio-stream.component.scss']
})
export class CallAudioStreamComponent implements OnInit {
  private _audioElm: any;
  private _remoteAudioStream: any;
  private _isMuted: any;
  private CallControlSvc: any;
  private LogSvc: any;
  private PubSubSvc: any;

  constructor(private elRef: ElementRef) {
    this.CallControlSvc = Circuit.serviceInstances.callControlSvc;
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
  }

  private setRemoteAudioStream = () => {
      var activeCall = this.CallControlSvc.getActiveCall();
      this._audioElm.srcObject = null;
      this._audioElm.src = '';
      if (activeCall && activeCall.remoteAudioStream && (activeCall.isEstablished() || activeCall.isPranswer)) {
          this._remoteAudioStream = activeCall.remoteAudioStream;
          this.updateStream();
          this._isMuted = activeCall.remoteAudioDisabled;
          this.LogSvc.debug('[call-audio-stream]: Set Remote Audio stream to ', (activeCall.remoteAudioStream && activeCall.remoteAudioStream.id) || '<null>');
      } else {
          this._remoteAudioStream = null;
          this._isMuted = false;
          this.LogSvc.debug('[call-audio-stream]: Cleared Remote Audio stream');
      }
      this._audioElm.muted = !!this._isMuted;
  }

  private updateStream() {
    if (this._remoteAudioStream) {
        // Set stream only after sinkId is attached to audio element
        setTimeout(() => {
            Circuit.WebRTCAdapter.attachSinkIdToAudioElement(this._audioElm, Circuit.RtcSessionController.playbackDevices, (err: any) => {
                if (err) {
                  this.LogSvc.warn('[call-audio-stream]: Failed to attach sinkId. ', err);
                  this.LogSvc.debug('[call-audio-stream]: Publish /call/devices/audio/outputFailed event');
                  this.PubSubSvc.publish('/call/devices/audio/outputFailed');
                }
                this._audioElm.srcObject = this._remoteAudioStream;
                this.LogSvc.debug('[call-audio-stream]: Assigned new audio stream = ', this._remoteAudioStream?.id);
            });
        }, 0);
    }
  }

  private onCallIncoming = (call: any) => {
      this.LogSvc.debug('[call-audio-stream]: Received /call/incoming event. callId =', call.callId);
      this.setRemoteAudioStream();
  }

  private onCallState = (call: any) => {
      this.LogSvc.debug('[call-audio-stream]: Received /call/state event. callId =', call.callId);
      this.setRemoteAudioStream();
  }

  private onCallEnded = (call: any) => {
      this.LogSvc.debug('[call-audio-stream]: Received /call/ended event. callId =', call.callId);
      this.setRemoteAudioStream();
  }

  private onCallSdpConnected = (call: any) => {
      this.LogSvc.debug('[call-audio-stream]: Received /call/sdpConnected event. callId =', call.callId);
      this.setRemoteAudioStream();
  }

  private onCallRemoteStreamUpdated = () => {
      this.LogSvc.debug('[call-audio-stream]: Received /call/remoteStreamUpdated event');
      this.setRemoteAudioStream();
  }

  ngOnInit(): void {
    this._audioElm = this.elRef.nativeElement.querySelector('audio');

    this.PubSubSvc.subscribe('/call/incoming', this.onCallIncoming);
    this.PubSubSvc.subscribe('/call/state', this.onCallState);
    this.PubSubSvc.subscribe('/call/ended', this.onCallEnded);
    this.PubSubSvc.subscribe('/call/sdpConnected', this.onCallSdpConnected);
    this.PubSubSvc.subscribe('/call/remoteStreamUpdated', this.onCallRemoteStreamUpdated);
  }

  ngOnDestroy(): void {
    this.PubSubSvc.unsubscribe('/call/incoming', this.onCallIncoming);
    this.PubSubSvc.unsubscribe('/call/state', this.onCallState);
    this.PubSubSvc.unsubscribe('/call/ended', this.onCallEnded);
    this.PubSubSvc.unsubscribe('/call/sdpConnected', this.onCallSdpConnected);
    this.PubSubSvc.unsubscribe('/call/remoteStreamUpdated', this.onCallRemoteStreamUpdated);
  }

}
