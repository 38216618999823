<div class="dialog-container">
  <h1 mat-dialog-title>ICE candidates collection</h1>
  <mat-dialog-content>
      <div class="horizontal-wrapper">
          <mat-label for="id_rtcDebugIceCandidatesTimeout">Timeout (miliseconds):</mat-label>
          <input matInput class="ice-inputs" id="id_rtcDebugIceCandidatesTimeout"
              [(ngModel)]="currentSettings.candidatesTimeout">
      </div>
      <div class="horizontal-wrapper">
          <mat-label for="id_rtcDebugIceTrickleIceTimeout">Trickle-ICE Timeout (miliseconds):</mat-label>
          <input matInput class="ice-inputs" id="id_rtcDebugIceTrickleIceTimeout"
              [(ngModel)]="currentSettings.trickleIceTimeout">
      </div>
      <div class="turn-section">
          <div class="turn-section-label">Additional TURN server(s):</div>
          <textarea matInput id="id_rtcDebugIceTurn" [(ngModel)]="currentSettings.customTurnServers"
              placeholder="turn:turn01.server.net:3481?transport=udp&#10;turn:turn01.server.net:443?transport=tcp&#10;turns:turn01.server.net:443?transport=tcp"
              class="servers" rows="5"></textarea>
      </div>

      <mat-checkbox color="primary" [(ngModel)]="currentSettings.disableTrickleIce">
          Disable Trickle-ICE
      </mat-checkbox>
      <mat-checkbox color="primary" [(ngModel)]="currentSettings.allowOnlyRelayCandidates">
          Allow only relay candidates
      </mat-checkbox>
      <mat-checkbox color="primary" [(ngModel)]="currentSettings.allowAllIceCandidatesScreenShare">
          <div>Allow all candidates for group screen share (default is only TURN over TCP)</div>
      </mat-checkbox>

  </mat-dialog-content>
  <mat-dialog-actions align="end" class="dialog-actions-container">
      <button mat-raised-button color="primary" (click)="confirm()">
          <span uLocalize="res_Save"></span>
      </button>
      <button mat-raised-button (click)="close()">
          <span uLocalize="res_Cancel"></span>
      </button>
  </mat-dialog-actions>
</div>
