<div class="app-call-views">
  <!-- Full call view -->
  <div class="call-wrapper" [class]="call?.state.css">
    <div class="call-view" [ngClass]="{'view-open': !!hasViewOpen}">
      <div *ngIf="!hasViewOpen" class="second-call-avatar-wrapper">
        <app-second-call *ngIf="secondCall && !hasDtmfView" [secondCall]="secondCall"></app-second-call>
        <app-avatar-single *ngIf="!secondCall" [user]="call?.peerUser"
                           [isGroupCall]="call?.participants?.length > 1 || call?.isDirectUpgradedToConf"
                           [size]="'xxlarge'"
                           [isOnHold]="call?.isHolding() || call?.state.css === 'holding-call'"></app-avatar-single>
      </div>

      <div *ngIf="!hasViewOpen" class="other-person-details">
        <div class="call-state">
          <span class="mat-body-2" [ngClass]="{'call-state-warn': (call?.state?.css === 'declined' || call?.state?.css === 'failed' || call?.state?.css === 'busy'),
                      'call-state-accent' : call?.state?.css === 'active-call-remote'}">
            {{ call?.state?.ui ? root.i18n.map[call.state.ui]: '' }}
          </span>
          <div class="loader" *ngIf="call?.state.css === 'outgoing'">
            <div class="ringing-animation medium light-dark-background">
              <div class="one"></div>
              <div class="two"></div>
              <div class="three"></div>
            </div>
          </div>
          <span *ngIf="call?.state?.ui &&
                      (call?.state.css==='answering' ||
                      call?.state.css==='waiting' ||
                      call?.state.css==='holding-call') &&
                      call.establishedTime"> &nbsp; - &nbsp; </span>
          <span class="mat-body-2 elapsed-time" [uElapsedTime]="call.establishedTime"
                *ngIf="call?.state.css==='answering' ||
                      call?.state.css==='waiting' ||
                      call?.state.css==='active-call' ||
                      call?.state.css==='holding-call'">
          </span>
        </div>
        <div *ngIf="call?.forwarded && (call?.state.css === 'incoming' || call?.isRemote)" class='call-forwarded'>
          <mat-icon class="material-icons-outlined md-15">phone_forwarded</mat-icon>
          <span class="mat-caption fwd-name">
            {{root.i18n.map.res_ForwardedFrom}} {{call?.redirectingUser.displayName || call?.redirectingUser.phoneNumber}}
          </span>
        </div>
        <div class="mat-display-1 other-person-number">
          <mat-icon *ngIf="call?.forwarded && !(call?.state.css === 'incoming' || call?.isRemote)" class="material-icons md-20"
                    matTooltip="{{ root.i18n.map.res_ForwardedFrom + ' ' + (call?.redirectingUser.displayName || call?.redirectingUser.phoneNumber) }}"
                    >phone_forwarded</mat-icon>
          <span *ngIf="call?.participants?.length <= 1 && !call?.isDirectUpgradedToConf">
            {{call?.peerUser.phoneNumber}}
          </span>
          <div *ngIf="call?.participants?.length > 1 || call?.isDirectUpgradedToConf" class="call-title">
            <mat-icon class="material-icons-outlined md-25">call_merge</mat-icon>
            <span>{{ getCallTitleUiStr(call) }}</span>
          </div>
        </div>
        <div class="mat-body-2 call-participants">
          <span matTooltip="{{getCallStateUiStr(call)}}">{{getCallStateUiStr(call)}}</span>
        </div>
      </div>

      <div *ngIf="!hasViewOpen" class="secondary-call-buttons-wrapper">
        <!-- Call Actions Start ------------------------------------------------------->
        <div class="secondary-call-buttons" *ngIf="!hasViewOpen && !call?.isRemote && call?.state.css !== 'incoming'">
          <div class="first-row">
            <div class="center">
              <button mat-button class="circle-button" [ngClass]="{'active': call?.isMuted()}"
                [attr.aria-label]="call?.isMuted() ? root.i18n.map.res_AccessibilityUnmute : root.i18n.map.res_AccessibilityMute"
                (click)="toggleMute(call)">
                <mat-icon class="material-icons md-26">mic_off</mat-icon>
              </button>
              <span class="mat-caption" uLocalize="res_Mute"></span>
            </div>
            <div class="center">
              <button mat-button [disabled]="call?.state?.css !== 'active-call'"
                class="circle-button option-grey"
                uAriaLabel="res_Keypad"
                (click)="toggleView(callView.DTMF)"
                [ngClass]="{'active': hasDtmfView}">
                <mat-icon class="material-icons-outlined md-26">dialpad</mat-icon>
              </button>
              <span class="mat-caption" [ngClass]="{'disabled': call?.state?.css !== 'active-call'}" uLocalize="res_Keypad"></span>
            </div>
            <!-- Hold Call -->
            <div class="center" *ngIf="canHoldCall(call?.callId)" [ngSwitch]="hasAnyCallInTransientState()">
              <button mat-button [disabled]="call?.state?.css !== 'active-call'" *ngSwitchCase="false"
                class="circle-button hold option-grey" (click)="holdCall(call)" uAriaLabel="res_HoldCall"
                data-autotest="holdCall">
                <mat-icon class="material-icons-outlined md-26">pause</mat-icon>
              </button>
              <div *ngSwitchCase="true" class="circle-button hold" uAriaLabel="res_AccessibilityLoading">
                <div class="loading-wrapper">
                  <mat-spinner [diameter]="24"></mat-spinner>
                </div>
              </div>
              <span class="mat-caption" [ngClass]="{'disabled': call?.state?.css !== 'active-call'}" uLocalize="res_HoldCall"></span>
            </div>
            <!-- Retrieve Hold Only  -->
            <div class="center" *ngIf="canRetrieveCall(call?.callId)" [ngSwitch]="hasAnyCallInTransientState()">
              <button mat-button *ngSwitchCase="false" class="circle-button hold-only retrieve active"
                uAriaLabel="res_RetrieveCall"
                (click)="retrieveCall(call)" data-autotest="retrieveCall">
                <mat-icon class="material-icons-outlined md-26">pause</mat-icon>
              </button>
              <div *ngSwitchCase="true" class="circle-button hold-only" uAriaLabel="res_AccessibilityLoading">
                <div class="loading-wrapper">
                  <mat-spinner [diameter]="24"></mat-spinner>
                </div>
              </div>
              <span class="mat-caption" uLocalize="res_HoldCall"></span>
            </div>
          </div>
          <div class="second-row">
            <!-- Call Transfer  -->
            <div [ngSwitch]="hasMultipleLocalCalls(true)" *ngIf="consultationAvailable">
              <div class="center" *ngSwitchCase="false">
                <button mat-button
                        class="circle-button transfer-btn"
                        (click)="toggleView(callView.TRANSFER)"
                        [disabled]="!canTransferCall(call.callId)"
                        uAriaLabel="res_Transfer">
                  <mat-icon class="material-icons-outlined md-26 icon-transfer-icon"></mat-icon>
                </button>
                <span class="mat-caption" [ngClass]="{'disabled': !canTransferCall(call.callId)}" uLocalize="res_Transfer"></span>
              </div>
              <div class="center" *ngSwitchCase="true">
                <button mat-button
                        class="circle-button transfer-btn"
                        (click)="completeTransfer()"
                        [disabled]="!canConsultTransfer(call.callId)"
                        uAriaLabel="res_Transfer"
                        matTooltip="{{root.i18n.map.res_CallControlToolTipCompleteTransfer}}"
                        [matTooltipShowDelay]="1000">
                  <mat-icon class="material-icons-outlined md-26 icon-transfer-icon"></mat-icon>
                </button>
                <span class="mat-caption" [ngClass]="{'disabled': !canConsultTransfer(call.callId)}" uLocalize="res_Transfer"></span>
              </div>
            </div>
            <!-- Call Holding hold-swap -->
            <div class="center" *ngIf="canSwapCall()" [ngSwitch]="hasAnyCallInTransientState()">
              <button mat-button *ngSwitchCase="false"
                      class="circle-button hold-swap swap"
                      uLocalize="res_SwapCall"
                      (click)="swapCall(call)"
                      data-autotest="swapCall">
                <mat-icon class="material-icons-outlined md-26">swap_calls</mat-icon>
              </button>
              <div *ngSwitchCase="true" class="circle-button hold-swap" uAriaLabel="res_AccessibilityLoading">
                <div class="loading-wrapper">
                  <mat-spinner [diameter]="24"></mat-spinner>
                </div>
              </div>
              <span class="mat-caption" uLocalize="res_SwapCall"></span>
            </div>
            <!-- New call -->
            <div class="center" *ngIf="consultationAvailable && canInitiateConsultationLocalCall()">
              <button mat-button
                      class="circle-button consultation"
                      (click)="toggleView(callView.NEWCALL)"
                      [disabled]="!canInitiateConsultationLocalCall()"
                      data-autotest="makeConsultation">
                <mat-icon class="md-26">add_ic_call</mat-icon>
              </button>
              <span class="mat-caption" [ngClass]="{'disabled': !canInitiateConsultationLocalCall()}" uLocalize="res_NewCall"></span>
            </div>
            <!-- Merge -->
            <div class="center">
              <button mat-button
                    *ngIf="mergeAvailable"
                    class="circle-button merge"
                    [disabled]="!canMergeCall(call?.callId)"
                    (click)="mergeCall(call)"
                    uAriaLabel="res_CallControlToolTipMerge">
                <mat-icon class="material-icons-outlined md-26">call_merge</mat-icon>
              </button>
              <span class="mat-caption" [ngClass]="{'disabled': !canMergeCall(call?.callId)}" uLocalize="res_MergeCall"></span>
            </div>
          </div>
        </div>
      </div>

      <app-dtmf class="dtmf" [call]="call" [hidden]="!hasDtmfView" (parentToggleDtmf)="toggleView($event)"></app-dtmf>
      <app-new-call class="view-container" [hidden]="!hasNewCallView" (parentToggleNewCall)="toggleView($event)"></app-new-call>
      <app-call-transfer class="view-container" [call]="call" [hidden]="!hasTransferView" (parentToggleTransfer)="toggleView($event)"></app-call-transfer>

      <!-- Call Actions End --------------------------------------------------------->
      <div class="primary-call-buttons" *ngIf="!hasTransferView && !hasNewCallView" [ngClass]="{'remote': call?.isRemote}">

        <!-- hangup or decline button -->
        <div matTooltip="{{ !!call && canHangupCall(call?.callId) ? '' : root.i18n.map.res_OSBizCannotEndCall}}" class="btn-wrapper">
          <button mat-fab color="warn"
                  class="end-call-btn"
                  [disabled]="!canHangupCall(call?.callId)"
                  *ngIf="!!call && isHangupCallAvailable(call?.callId)"
                  matTooltip="{{canHangupCall(call?.callId) ? '' : root.i18n.map.res_OSBizCannotEndCall}}"
                  [attr.aria-label]="call?.state?.css !=='incoming' ? root.i18n.map.res_AccessibilityHangup : root.i18n.map.res_AccessibilityDecline"
                  (click)="endCall(call)">
            <mat-icon>call_end</mat-icon>
          </button>
          <!-- We only want to display decline to voicemail when recieving a call -->
          <app-push-incoming-call *ngIf="canAnswerCall(call?.callId) && declineDevices.length > 0"
                                  class="left"
                                  [call]="call"
                                  [devices]="declineDevices"
                                  [color]="'warn'"
                                  [title]="'res_SendTo'"
                                  [btnLabel]="'res_Decline'"></app-push-incoming-call>
        </div>
        <!-- Pull Remote Call -->
        <button mat-raised-button color="accent" class="pull-remote-call" uAriaLabel="res_AccessibilityPullCall"
          *ngIf="canPullRemoteCall(call?.callId)" (click)="pullRemoteCall(call?.callId)"
          matTooltip="{{root.i18n.map.res_PullCallTooltip}}" matTooltipPosition="above">
          <div class="mat-body-2 pull-call-btn-text" uLocalize="res_PullCall"></div>
        </button>

        <!-- Push Call -->
        <div *ngIf="canPushLocalCall(call?.callId)" class="btn-wrapper">
          <button mat-fab color="green" class="end-call-btn"
                  [attr.aria-label]="root.i18n.localize('res_AccessibilityPushCall', [root.i18n.map[defaultPushDevice.value.ui]])"
                  (click)="pushLocalCall(call.callId, defaultPushDevice.value.id)">
            <mat-icon [class]="defaultPushDevice.value.icon">{{defaultPushDevice.value.icon !== 'icon-deskphone-icon' ? defaultPushDevice.value.icon : ''}}</mat-icon>
          </button>
        </div>

        <!-- Incoming Calls (Voice) -->
        <div *ngIf="canAnswerCall(call?.callId) && defaultAnswerDevice.value.id !== 'VM'" class="btn-wrapper">
          <button mat-fab color="green"
                  class="end-call-btn"
                  uAriaLabel="res_AccessibilityAnswerCall"
                  (click)="answerCall(call?.callId)"
                  data-autotest="answerVoice">
            <mat-icon>call</mat-icon>
          </button>
          <app-push-incoming-call *ngIf="answerDevices.length > 0"
                                  class="right"
                                  [call]="call"
                                  [devices]="answerDevices"
                                  [color]="'green'"
                                  [title]="'res_AnswerWith'"
                                  [btnLabel]="'res_Answer'"></app-push-incoming-call>
        </div>
      </div>
    </div>
  </div>
</div>
