<div class="extension-wrapper">
    <img class="extension-image" src="/dist/main-app/assets/img/microsoft-icon.svg" alt="exchange" />
    <h3 class="extension-title headline" uLocalize="res_MicrosoftExchangeOnPremisesTitle"></h3>
</div>
<p uLocalize="res_MicrosoftExchangeOnPremisesContent"></p>
<div class="subheading-2 status-container">
  <button mat-flat-button color="primary" class="button" (click)="toggleConnectionStatus()" *ngIf="!isExchangeConnected()">
    <span>{{this.root.i18n.localize("res_Connect")}}</span>
  </button>
  <button mat-button class="button disconnect-btn" (click)="toggleConnectionStatus()" *ngIf="isExchangeConnected()">
    <span>{{this.root.i18n.localize("res_Disconnect")}}</span>
  </button>

</div>
<mat-error class="error-status" role="alert" *ngIf="hasError()">
    <mat-icon class="material-icons-outlined status-icon md-14">error_outline</mat-icon>
    <span>{{ this.root.i18n.localize("res_ExchangeConnError") }}</span>
    <button mat-button class="clear-btn" (click)="resetErrorMessage()" uAriaLabel="res_Clear">
      <mat-icon class="md-14">clear</mat-icon>
    </button>
</mat-error>
