import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import * as moment from 'moment';

declare let Circuit: any;

const ONE_MIN = 60000;
const TEN_MIN = 600000;

enum CreationDate {
  LESS_1_MINUTE = 0,
  LESS_10_MINUTES = 1,
  SAME_DAY = 2,
  PREVIOUS_DAY = 3,
  LESS_7_DAYS = 4,
  SAME_YEAR = 5,
  OLDER_YEAR = 6
}

@Component({
  selector: 'app-item-date-format',
  templateUrl: './item-date-format.component.html',
  styleUrls: ['./item-date-format.component.scss']
})
export class ItemDateFormatComponent implements OnInit, OnDestroy {
  private LogSvc: any;
  private LocalizeSvc: any;
  private PubSubSvc: any;

  i18n: any;
  formattedDate: any;
  result: any;
  formattedLocale: string = 'en';
  localeData: any;

  @Input() item: any;
  @Input() format: any = 'short';

  constructor(private commonBlService: CommonBLService) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.LocalizeSvc = Circuit.serviceInstances.localizeSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this.i18n = this.commonBlService.getRootScopeData().i18n;
  }

  ngOnInit(): void {
    // we set the date format once in the beginning
    this.refreshDate();
    this.PubSubSvc.subscribe('/interval/update', this.refreshDate);
    this.PubSubSvc.subscribe('/language/update', this.refreshDate);
  }

  refreshDate = () => {
    if (!this.item || !this.item.creationTime) {
      return this.ngOnDestroy();
    }

    const locale = this.LocalizeSvc.getLocale();
    const firstElement = locale.slice(0, locale.indexOf('_')).toLowerCase();
    this.formattedLocale = firstElement === 'zh' ? 'zh-cn' : firstElement;
    // Get locale data
    this.localeData = moment.localeData(this.formattedLocale);
    this.buildDateTime(this.format, this.item.creationTime);
  };

  getCreationDate = (dateTime: any) => {
    const today = new Date();
    const createdDate = new Date(dateTime);
    const createdDateStr = createdDate.toDateString();

    if (today.toDateString() === createdDateStr) {
      // Same day
      return CreationDate.SAME_DAY;
    }

    const todayDate = today.getDate();
    const yesterday = new Date();
    yesterday.setDate(todayDate - 1);

    if (yesterday.toDateString() === createdDateStr) {
      // Previous day
      return CreationDate.PREVIOUS_DAY;
    }

    const weekAgo = new Date();
    weekAgo.setDate(todayDate - 6);

    if ((weekAgo.toDateString() === createdDateStr) || (weekAgo < createdDate)) {
      // Age older than previous day, <= 6 days
      return CreationDate.LESS_7_DAYS;
    }

    if (today.getFullYear() > createdDate.getFullYear()) {
      // Previous years
      return CreationDate.OLDER_YEAR;
    }

    // Same year, but older than 1 week
    return CreationDate.SAME_YEAR;
  };

  longFormat = (creationDate: number) => {
    switch (creationDate) {
    case CreationDate.SAME_DAY:
      return this.i18n.map.res_DateTimeShortTimeNg;
    case CreationDate.PREVIOUS_DAY:
      return this.i18n.map.res_YesterdayNg + ' ' + this.i18n.map.res_DateTimeShortTimeNg;
    case CreationDate.LESS_7_DAYS:
      return this.i18n.map.res_DateTimeLongDayNg + ' ' + this.i18n.map.res_DateTimeShortTimeNg;
    case CreationDate.SAME_YEAR:
      return this.i18n.map.res_DateTimeMediumDateTimeNg + ' ' + this.i18n.map.res_DateTimeShortTimeNg;
    case CreationDate.OLDER_YEAR:
      return this.i18n.map.res_DateTimeLongDateTimeNg;
    }
    return '';
  };

  shortFormat = (creationDate: number) => {
    switch (creationDate) {
    case CreationDate.SAME_DAY:
      return this.i18n.map.res_DateTimeShortTimeNg;
    case CreationDate.PREVIOUS_DAY:
      return this.i18n.map.res_YesterdayNg + ' ' + this.i18n.map.res_DateTimeShortTimeNg;
    case CreationDate.LESS_7_DAYS:
      return this.i18n.map.res_DateTimeWeekDayNg + ' ' + this.i18n.map.res_DateTimeShortTimeNg;
    case CreationDate.SAME_YEAR:
      return this.i18n.map.res_DateTimeShortDayNg + ' ' + this.i18n.map.res_DateTimeShortTimeNg;
    case CreationDate.OLDER_YEAR:
      return this.i18n.map.res_DateTimeLongDateNg;
    }
    return '';
  };

  listDividerFormat = (creationDate: number) => {
    switch (creationDate) {
    case CreationDate.SAME_DAY:
      return this.i18n.map.res_TodayNg;
    case CreationDate.PREVIOUS_DAY:
      return this.i18n.map.res_YesterdayNg;
    case CreationDate.LESS_7_DAYS:
      return this.i18n.map.res_DateTimeWeekDayNg;
    case CreationDate.SAME_YEAR:
      return this.i18n.map.res_DateTimeShortDayNg;
    case CreationDate.OLDER_YEAR:
      return this.i18n.map.res_DateTimeLongDateNg;
    }
    return '';
  };

  scrollbarFormat = (creationDate: number) => {
    if (creationDate === CreationDate.SAME_DAY) {
      return this.i18n.map.res_TodayNg;
    }
    return this.listDividerFormat(creationDate);
  };

  setMonthByLocale = (value: number) => {
    switch (value) {
    case 0:
      return this.i18n.map.res_DateTimeMonthJanuaryShort;
    case 1:
      return this.i18n.map.res_DateTimeMonthFebruaryShort;
    case 2:
      return this.i18n.map.res_DateTimeMonthMarchShort;
    case 3:
      return this.i18n.map.res_DateTimeMonthAprilShort;
    case 4:
      return this.i18n.map.res_DateTimeMonthMayShort;
    case 5:
      return this.i18n.map.res_DateTimeMonthJuneShort;
    case 6:
      return this.i18n.map.res_DateTimeMonthJulyShort;
    case 7:
      return this.i18n.map.res_DateTimeMonthAugustShort;
    case 8:
      return this.i18n.map.res_DateTimeMonthSeptemberShort;
    case 9:
      return this.i18n.map.res_DateTimeMonthOctoberShort;
    case 10:
      return this.i18n.map.res_DateTimeMonthNovemberShort;
    case 11:
      return this.i18n.map.res_DateTimeMonthDecemberShort;
    }
  };

  setWeekdayByLocale = (value: number) => {
    switch (value) {
    case 0:
      return this.i18n.map.res_DateTimeWeekDaySunday;
    case 1:
      return this.i18n.map.res_DateTimeWeekDayMonday;
    case 2:
      return this.i18n.map.res_DateTimeWeekDayTuesday;
    case 3:
      return this.i18n.map.res_DateTimeWeekDayWednesday;
    case 4:
      return this.i18n.map.res_DateTimeWeekDayThursday;
    case 5:
      return this.i18n.map.res_DateTimeWeekDayFriday;
    case 6:
      return this.i18n.map.res_DateTimeWeekDaySaturday;
    }
  };

  buildDateTime = (format: any, dateTime: any) => {
    const result = {
      timeStr: '',
      withinTenMin: false,
      withinOneWeek: false
    };

    if (!dateTime) {
      return result;
    }

    const creationDate = this.getCreationDate(dateTime);
    result.withinTenMin = creationDate <= CreationDate.LESS_10_MINUTES;
    result.withinOneWeek = creationDate <= CreationDate.LESS_7_DAYS;

    switch (format) {
    case 'time':
      result.timeStr = this.i18n.map.res_DateTimeShortTimeNg;
      break;
    case 'long':
      result.timeStr = this.longFormat(creationDate);
      break;
    case 'divider':
      result.timeStr = this.listDividerFormat(creationDate);
      break;
    case 'scrollbar':
      result.timeStr = this.scrollbarFormat(creationDate);
      break;
    default:
      result.timeStr = this.shortFormat(creationDate);
      break;
    }
    // we need to get the instance of setMonthByLocale & setWeekdayByLocale
    // to use it inside moment.updateLocale
    const setMonthByLocale = this.setMonthByLocale;
    const setWeekdayByLocale = this.setWeekdayByLocale;
    // Update locale with new values,
    // https://momentjs.com/docs/#/customization/month-abbreviations/
    moment.updateLocale(this.formattedLocale, {
      monthsShort: function (momentToFormat, format: any) {
        if (/MMM/.test(format)) {
          return setMonthByLocale(momentToFormat.month());
        }
        return '';
      },
      weekdays: function (momentToFormat, format: any) {
        if (/dddd/.test(format)) {
          return setWeekdayByLocale(momentToFormat.day());
        }
        return '';
      }
    });
    const date = moment(+dateTime).locale(this.formattedLocale);
    // we format data depending the result.timeStr = eg '[Now]' or 'hh:mm A' ..
    this.formattedDate = date.format(result.timeStr);
    return result;
  };

  ngOnDestroy(): void {
    this.PubSubSvc.unsubscribe('/interval/update', this.refreshDate);
    this.PubSubSvc.unsubscribe('/language/update', this.refreshDate);
  }
}
