import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

declare let Circuit: any;

@Component({
  selector: 'app-rtp-debug-audio',
  templateUrl: './rtp-debug-audio.component.html',
  styleUrls: ['./rtp-debug-audio.component.scss']
})
export class RtpDebugAudioComponent implements OnInit {
  private CallControlSvc: any;
  private LogSvc: any;
  private RtcSessionController: any;
  rtcDebug: any;

  constructor(private mdDialogRef: MatDialogRef<RtpDebugAudioComponent>) {
    this.RtcSessionController = Circuit.RtcSessionController;
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.CallControlSvc = Circuit.serviceInstances.callControlSvc;
  }

  ngOnInit(): void {
    this.rtcDebug = {
      audio: this.RtcSessionController.sdpParameters.audio
    };
  }

  close() {
    this.mdDialogRef.close();
  }

  confirm() {
    this.LogSvc.debug('[RtpDebugAudioComponent]: Setting RTC audio debug data to ', this.rtcDebug);
    this.RtcSessionController.sdpParameters.audio = this.rtcDebug.audio;
    let call = this.CallControlSvc.getActiveCall();
    if (call) {
      this.CallControlSvc.renegotiateMedia(call.callId);
    }

    this.close();
  }

}
