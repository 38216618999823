<div class="integration-item">
    <div class="item-header">
      <h4 class="subtitle no-margin" uLocalize="res_ExtensionTitle_JABRA"></h4>
      <mat-slide-toggle
      [(ngModel)]="jabra"
      color="primary"
      (change)="toggleJabra()" ngDefaultControl name="jabra"
      [aria-label]="i18n.map.res_ExtensionTitle_JABRA"></mat-slide-toggle>
    </div>
    <p class="subheading-2" uLocalize="res_ExtensionDescription_JABRA"></p>
    <div class="subheading-2 status-container" *ngIf="isJabraEnabled() && jabra && jabraState !== 1">
        <p class="status">
          <span class="integration-indicator" [ngClass]="jabraState === 2 ? 'green' : 'red'"></span>
          <span class="mat-card-subtitle" role="status" aria-atomic="true"> {{ deviceText }}</span>
        </p>
        <button mat-raised-button class="cta-pair" *ngIf="!_activeDevice" color="primary" (click)="triggerWebhidConsent()">
            <span class="subheading-1 uppercase" uLocalize="res_PairDevice"></span>
        </button>
    </div>
    <mat-error class="error-status" role="alert" *ngIf="jabra && jabraState === 1">
      <mat-icon class="material-icons-outlined status-icon md-14">error_outline</mat-icon> {{ deviceText }}
    </mat-error>
  </div>
