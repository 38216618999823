import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ULocalizeDirective } from './directives/u-localize.directive';
import { ULocalizeOnceComponent } from './components/u-localize-once.component';
import { CommonBLService } from './services/common-bl.service';
import { UPlaceholderDirective } from './directives/u-placeholder.directive';
import { UAriaLabelDirective } from './directives/u-aria-label.directive';
import { UAriaLabelOnceComponent } from './components/u-aria-label-once.component';
import { MatDialogModule } from '@angular/material/dialog';
import { USetLangAttributeDirective } from './directives/u-set-lang-attribute.directive';
import { UFocusVisibleDirective } from './directives/u-focus-visible.directive';

@NgModule({
  declarations: [
    ULocalizeDirective,
    ULocalizeOnceComponent,
    UPlaceholderDirective,
    UAriaLabelDirective,
    UAriaLabelOnceComponent,
    USetLangAttributeDirective,
    UFocusVisibleDirective
  ],
  imports: [
    CommonModule,
    MatDialogModule
  ],
  exports: [
    ULocalizeDirective,
    ULocalizeOnceComponent,
    UPlaceholderDirective,
    UAriaLabelDirective,
    UAriaLabelOnceComponent,
    USetLangAttributeDirective,
    UFocusVisibleDirective
  ],
  providers: [CommonBLService,
    // eslint-disable-next-line arrow-body-style
    { provide: APP_INITIALIZER, useFactory: (commonBLService: CommonBLService) => {
      return () => commonBLService.init();
    }, deps: [CommonBLService], multi: true}]
})
export class CommonNgModule { }
