import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { DialogService } from '../../../sub-modules/dialog/dialog.service';
import * as moment from 'moment';

declare let Circuit: any;
declare let RegistrationState: any;

const CALL_HISTORY_FILENAME = 'UnifyPhone_CallHistory';
const DOWNLOAD_CALL_HISTORY_ENDPOINT = 'fileApi/downloadCallHistory';
const SAVE_TIMEOUT = 1000;

@Component({
  selector: 'app-telephony-tab',
  templateUrl: './telephony-tab.component.html',
  styleUrls: ['./telephony-tab.component.scss']
})
export class TelephonyTabComponent implements OnInit, OnDestroy {
  root: any;
  filteredOptions: string[] = [];
  callForwardingNumber = '';
  callForwardingNumbersATC: any = {};
  alternativeNumberAvailable = false;
  callForwardingOptionsAvailable = false;
  callForwardingType = '';
  alternativeNumber = '';
  callRouting = '';
  routingOptions: any[] = [];
  callForwardingData: any = {};
  forwardingInvalidNumber = false;
  forwardingError: any = {};
  alternativeInvalidNumber = false;
  isDownloadingCallHistory = false;
  downloadCallHistoryFailed = false;
  anyCallForwardingAvailable = false;
  cfDataArray: any[] = [];
  busyHandlingOptions: any[] = [];
  selectedBusyHandlingOption: any;
  ringDurationEnabled = false;
  ringDurationConfigurable = false;
  mainRingDuration: any;
  defaultRoutingOption: any;
  mainRingDurations: number[] = [5, 10, 15, 20, 25];
  voicemailEnabled = false;
  voicemailTimeout = 20;
  vmTimeouts: number[] = [5, 10, 15, 20, 25, 30];
  isVoiceMailAvailable = false;

  private LogSvc: any;
  private HttpSvc: any;
  private TelephonySettingsSvc: any;
  private PubSubSvc: any;
  private PhoneCallSvc: any;
  private PhoneNumberFormatter: any;
  private VoicemailSvc: any;
  private UserProfileSvc: any;
  private Utils: any;
  private RoutingOptions: any;
  private AtcRegistrationState: any;
  private BusyHandlingOptions: any;

  private alternativeNumberOptions: string[] = [];
  private switchElementClicked = false;
  private altAvailableElementClicked = false;
  private settings: any[] = [];
  private _saveCallForwardingTimer: any;
  private _saveBusyHandlingOptionTimer: any;
  private _setRoutingOptionTimer: any;

  @ViewChild('altnumber') altnumber: any;
  @ViewChild('forwarding') forwarding: any;
  @ViewChild('forwardingfield') forwardingfield: any;
  @ViewChild('altnumberfield') altnumberfield: any;

  constructor(private commonBlService: CommonBLService, private dialogService: DialogService) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.HttpSvc = Circuit.serviceInstances.httpSvc;
    this.TelephonySettingsSvc = Circuit.serviceInstances.telephonySettingsSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this.PhoneCallSvc = Circuit.serviceInstances.phoneCallSvc;
    this.PhoneNumberFormatter = Circuit.PhoneNumberFormatter;
    this.VoicemailSvc = Circuit.serviceInstances.voicemailSvc;
    this.UserProfileSvc = Circuit.serviceInstances.userProfileSvc;
    this.Utils = Circuit.Utils;
    this.RoutingOptions = Circuit.RoutingOptions;
    this.AtcRegistrationState = Circuit.Enums.AtcRegistrationState;
    this.BusyHandlingOptions = Circuit.BusyHandlingOptions;
  }

  ngOnInit(): void {
    this.LogSvc.info('[TelephonyTabComponent]: ngOnInit');
    this.root = this.commonBlService.getRootScopeData();
    this.defaultRoutingOption = this.TelephonySettingsSvc.getDefaultRoutingOption();

    this.getUserNumbers();
    this.getCallForwarding();
    this.initTelephonyData();
    this.updateSettings(this.UserProfileSvc.getSettings());

    this.PubSubSvc.subscribe('/atc/routingForwardingEvent', this.onFwdChangeEvent);
    this.PubSubSvc.subscribe('/atcRegistration/state', this.onAtcRegistrationStateUpdate);
    this.PubSubSvc.subscribe('/atc/routingForwardingEvent', this.onAtcRoutingForwardingEvent);
    this.PubSubSvc.subscribe('/localUser/update', this.onLocalUserUpdate);
    this.PubSubSvc.subscribe('/user/settings/update', this.onUserSettingsUpdate);
  }

  ngOnDestroy(): void {
    this.LogSvc.info('[TelephonyTabComponent]: ngOnDestroy');
    this.PubSubSvc.unsubscribe('/atc/routingForwardingEvent', this.onFwdChangeEvent);
    this.PubSubSvc.unsubscribe('/atcRegistration/state', this.onAtcRegistrationStateUpdate);
    this.PubSubSvc.unsubscribe('/atc/routingForwardingEvent', this.onAtcRoutingForwardingEvent);
    this.PubSubSvc.unsubscribe('/localUser/update', this.onLocalUserUpdate);
    this.PubSubSvc.unsubscribe('/user/settings/update', this.onUserSettingsUpdate);
  }

  private validateInput = (input: string): boolean => {
    const regexp = new RegExp(/^(\+ ?[1-9]|\( ?[1-9]|\*{1,2} ?\d|\#{1,2} ?\d|\d)( |[\d\(\)\(#)-\/])*$/),
      validate = regexp.test(input);
    return validate;
  };

  private getAlternativeNumberSettings = () => {
    this.alternativeNumberAvailable = this.TelephonySettingsSvc.isReroutingEnabled();
    this.alternativeNumber = this.root.localUser.reroutingPhoneNumber;
  };

  private onFwdChangeEvent = (value: boolean) => {
    this.LogSvc.debug('[TelephonyTabComponent]: Received /atc/routingForwardingEvent');
    this.setRoutingOptions();
    this.initAtcRoutingForwardingSettings();

    if (this.callForwardingType === Circuit.Enums.CallForwardingTypes.Immediate.name && this.callForwardingNumber === '') {
      this.toggleCallForwardingNotShownInUI();
    } else {
      this.toggleCallForwarding(value);
    }
  };

  private onLocalUserUpdate = () => {
    this.LogSvc.debug('[TelephonyTabComponent]: Received /localUser/update');
    this.alternativeNumberOptions = this.root.localUser?.previousAlternativeNumbers;
    this.filteredOptions = [...this.alternativeNumberOptions];
  };

  private onUserSettingsUpdate = (settings: any) => {
    this.LogSvc.debug('[TelephonyTabComponent]: Received /user/settings/update event.');
    this.updateSettings(settings);
    this.setBusyHandlingOptions();
  };

  private onAtcRoutingForwardingEvent = () => {
    this.LogSvc.debug('[TelephonyTabComponent]: Received /atc/routingForwardingEvent event');
    this.setRoutingOptions();
    this.initAtcRoutingForwardingSettings();
  };

  private onAtcRegistrationStateUpdate = (state: any) => {
    this.LogSvc.debug('[TelephonyTabComponent]: Received /atcRegistration/state event.');
    if (state === this.AtcRegistrationState.Registered) {
      this.initTelephonyData();
    }
  };

  private initTelephonyData = () => {
    this.initAtcRoutingForwardingSettings();
    this.getAlternativeNumberSettings();
    this.setRoutingOptions();
    this.setBusyHandlingOptions();
    this.isVoiceMailAvailable = !this.root.localUser.isOsBizCTIEnabled && this.TelephonySettingsSvc.getVoiceMailNumber();
  };

  private updateSettings = (settings: any) => {
    this.settings = settings;
    this.voicemailEnabled = this.getUserSetting(Circuit.Constants.UserSettingKey.VOICEMAIL_ENABLED, false);
    this.voicemailTimeout = this.getUserSetting(Circuit.Constants.UserSettingKey.VOICEMAIL_TIMEOUT, 20);
  };

  private getUserSetting = (name: string, defaultValue: any) => {
    const setting = this.settings?.find((obj: any) => obj.key === name);

    if (setting) {
      if (setting.booleanValue !== undefined) {
        return setting.booleanValue;
      } else if (setting.numberValue !== undefined) {
        return setting.numberValue;
      } else if (setting.stringValue !== undefined) {
        return setting.stringValue;
      }
    }

    return defaultValue;
  };

  private getUserNumbers = () => {
    this.alternativeNumberOptions = this.root.localUser?.previousAlternativeNumbers;
    this.filteredOptions = [...this.alternativeNumberOptions];
  };

  private getCallForwarding = () => {
    this.callForwardingData = this.TelephonySettingsSvc.getCallForwardingData();
    this.cfDataArray = Object.values(this.callForwardingData).filter((el: any) => el.cfwAvailable && el.cfwType !== 'VM');
    this.initCallForwardingNumbersATC();
    this.callForwardingOptionsAvailable = this.TelephonySettingsSvc.isAnyCallForwardingEnabled();
    this.anyCallForwardingAvailable = this.TelephonySettingsSvc.isAnyCallForwardingAvailable();

    if (this.callForwardingData.Immediate && this.callForwardingData.Immediate.cfwEnabled) {
      this.callForwardingType = Circuit.Enums.CallForwardingTypes.Immediate.name;
      this.callForwardingNumber = this.TelephonySettingsSvc.getCallForwardingNumber();
    } else if (this.callForwardingData.VM && this.callForwardingData.VM.cfwEnabled) {
      this.callForwardingType = Circuit.Enums.CallForwardingTypes.VM.name;
    }
  };

  private initCallForwardingNumbersATC = () => {
    this.cfDataArray.forEach((el: any) => {
      this.callForwardingNumbersATC[el.cfwType] = el.cfwEnabled ? el.cfwNumber : '';
    });
  };

  private setRoutingOptions() {
    this.routingOptions = this.TelephonySettingsSvc.getRoutingOptions()
      .filter((option: any) => option !== this.RoutingOptions.DefaultRouting);
    this.defaultRoutingOption.disabled = false;
  }

  private showError = (error: string) => {
    const message = { message: error, title: 'res_ErrorTitle' };
    this.dialogService.open(message).then((resp: any) => {
      this.LogSvc.error('[TelephonyTabComponent]: show dialog', resp);
    })
      .catch((err: any) => {
        this.LogSvc.error('[TelephonyTabComponent]: show dialog error', err);
      });
  };

  private initAtcRoutingForwardingSettings = () => {
    this.callRouting = this.TelephonySettingsSvc.getSelectedRoutingOption();
    this.ringDurationEnabled = this.TelephonySettingsSvc.getRingDurationEnabled();
    this.ringDurationConfigurable = this.TelephonySettingsSvc.getRingDurationConfigurable();
    const routingTimers = this.TelephonySettingsSvc.getRoutingTimers();
    this.mainRingDuration = routingTimers.mainRingDuration;
    this.anyCallForwardingAvailable = this.TelephonySettingsSvc.isAnyCallForwardingAvailable();
    this.callForwardingData = this.TelephonySettingsSvc.getCallForwardingData();
    this.cfDataArray = Object.values(this.callForwardingData).filter((el: any) => el.cfwAvailable && el.cfwType !== 'VM');
    this.initCallForwardingNumbersATC();
  };

  private saveCallForwardingSettings = (cfData: any) => {
    clearTimeout(this._saveCallForwardingTimer);

    if (!cfData.cfwAvailable ||
      (cfData.cfwEnabled && !cfData.cfwNumber) ||
      (cfData.cfwNumber && !this.Utils.isDialableNumber(cfData.cfwNumber))) {
      // Call forwarding not available or the number is not ready yet to be set
      return;
    }

    this._saveCallForwardingTimer = setTimeout(() => {
      this._saveCallForwardingTimer = null;
      const cfEnabled = this.root.localUser.isOsBizCTIEnabled ? this.TelephonySettingsSvc.isCallForwardingEnabledOsBiz() : cfData.cfwEnabled;
      this.TelephonySettingsSvc.setCallForwarding(cfEnabled, cfData.cfwType, cfData.cfwNumber)
        .catch((error: any) => {
          this.LogSvc.error('[TelephonyTabComponent]: saveCallForwardingSettings error', error);
          this.showError(error);
          this.initAtcRoutingForwardingSettings();
        });
    }, SAVE_TIMEOUT);
  };

  private setBusyHandlingOptions = () => {
    this.selectedBusyHandlingOption = this.TelephonySettingsSvc.getSelectedBusyHandlingOption();
    this.busyHandlingOptions = this.TelephonySettingsSvc.getBusyHandlingOptions();
  };

  private saveBusyHandlingSetting = () => {
    // Wait a little before saving the settings in case the user is scrolling
    // through the options using the arrow keys.
    clearTimeout(this._saveBusyHandlingOptionTimer);
    this._saveBusyHandlingOptionTimer = setTimeout(() => {
      this._saveBusyHandlingOptionTimer = null;
      this.TelephonySettingsSvc.saveBusyHandlingOption(this.selectedBusyHandlingOption);
    }, SAVE_TIMEOUT);
  };

  selectMainRingDuration = (mainRingDuration: any) => {
    this.TelephonySettingsSvc.setRoutingTimers({ mainRingDuration: mainRingDuration.value });
    this.mainRingDuration = mainRingDuration.value;
  };

  selectBusyHandlingOption = (option: any) => {
    this.selectedBusyHandlingOption = option;
    this.saveBusyHandlingSetting();
  };

  onAltNumberChange = (number: any) => {
    this.alternativeInvalidNumber = false;
    this.filteredOptions = [...this.alternativeNumberOptions.filter(option => option.includes(number))];
  };

  resetError = () => {
    this.forwardingInvalidNumber = false;
  };

  resetErrorATC = (cfType: string) => {
    this.forwardingError[cfType] = false;
  };

  toggleCallForwardingNotShownInUI = () => {
    this.TelephonySettingsSvc.setCallForwarding(false, this.callForwardingType, '')
      .catch((error: any) => {
        this.LogSvc.error('[TelephonyTabComponent]: toggleCallForwarding error', error);
        this.showError(error);
      });
  };

  toggleCallForwarding = (forwarding: any, cfType?: string) => {
    this.callForwardingOptionsAvailable = forwarding;

    // Call forwarding OSBiz
    if (!forwarding && this.callForwardingType) {
      return this.TelephonySettingsSvc.setCallForwarding(false, this.callForwardingType, '')
        .then(() => {
          this.callForwardingType = '';
          this.callForwardingNumber = '';
          this.resetError();
        })
        .catch((error: any) => {
          this.LogSvc.error('[TelephonyTabComponent]: toggleCallForwarding error', error);
          this.showError(error);
        });
    }

    // Call forwarding ATC
    if (!this.root.localUser.isOsBizCTIEnabled && cfType) {
      const callForwarding = this.callForwardingData[cfType];

      if (forwarding) {
        this.callForwardingNumbersATC[cfType] = callForwarding.cfwNumber;
        this.forwarding.nativeElement.disabled = false;
        this.forwarding.nativeElement.value = callForwarding.cfwNumber;
        this.forwarding.nativeElement.focus();
        this.forwardingfield.nativeElement.querySelector('mat-form-field').classList.add('mat-focused');
      } else {
        callForwarding.cfwEnabled = false;
        this.saveCallForwardingSettings(callForwarding);
        this.callForwardingNumbersATC[cfType] = '';
        this.resetErrorATC(cfType);
      }
    }

    return null;
  };

  toggleForwardingType(forwarding: any) {
    this.callForwardingType = forwarding;
    this.callForwardingOptionsAvailable = true;

    if (forwarding === Circuit.Enums.CallForwardingTypes.VM.name) {
      this.callForwardingNumber = '';
      this.TelephonySettingsSvc.setCallForwarding(true, Circuit.Enums.CallForwardingTypes.VM.name, '')
        .catch((error: any) => {
          this.LogSvc.error('[TelephonyTabComponent]: toggleForwardingType error', error);
          this.showError(error);
        });
    } else if (forwarding === Circuit.Enums.CallForwardingTypes.Immediate.name && this.callForwardingNumber === '') {
      this.TelephonySettingsSvc.setCallForwarding(false, Circuit.Enums.CallForwardingTypes.VM.name, '')
        .catch((error: any) => {
          this.LogSvc.error('[TelephonyTabComponent]: toggleForwardingType error', error);
          this.showError(error);
        });
    }
  }

  setCallForwardingNumber = () => {
    if (this.callForwardingNumber === '') {
      this.toggleCallForwarding(false);
    } else if (this.callForwardingNumber !== '' && this.callForwardingType === Circuit.Enums.CallForwardingTypes.Immediate.name) {
      if (!this.validateInput(this.callForwardingNumber)) {
        this.forwardingInvalidNumber = true;
        return;
      }
      this.TelephonySettingsSvc.setCallForwarding(true, this.callForwardingType, this.callForwardingNumber)
        .catch((error: any) => {
          this.LogSvc.error('[TelephonyTabComponent]: setCallForwardingNumber error', error);
          this.showError(error);
        });
    }
  };

  setCallForwardingNumberATC = (cfType: string) => {
    this.forwardingfield.nativeElement.querySelector('mat-form-field').classList.remove('mat-focused');
    if (this.callForwardingNumbersATC[cfType] === '') {
      this.toggleCallForwarding(false, cfType);
      this.resetErrorATC(cfType);
    } else {
      if (!this.validateInput(this.callForwardingNumbersATC[cfType])) {
        this.forwardingError[cfType] = true;
        return;
      }
      const callForwarding = this.callForwardingData[cfType];
      callForwarding.cfwNumber = this.callForwardingNumbersATC[cfType];
      this.saveCallForwardingSettings(callForwarding);
    }
  };

  altOnFocus() {
    setTimeout(() => {
      const end = this.altnumber.nativeElement.selectionEnd;
      this.altnumber.nativeElement.setSelectionRange(end, end);
    }, 0);
  }

  setAltNumberAvailable = (altAvailable: any) => {
    if (!altAvailable) {
      this.LogSvc.info('[TelephonyTabComponent]: disabling alternative number');
      this.alternativeNumber = '';
      this.alternativeInvalidNumber = false;
      this.TelephonySettingsSvc.setReroutingPhoneNumber()
        .catch((error: any) => {
          this.LogSvc.error('[TelephonyTabComponent]: setAltNumberAvailable error', error);
          this.showError(error);
        });

      this.setRoutingOptions();
      this.setBusyHandlingOptions();
    } else {
      this.altAvailableElementClicked = false;
      this.alternativeNumber = '+';
      this.altnumber.nativeElement.disabled = false;
      this.altnumber.nativeElement.value = '+';
      this.altnumber.nativeElement.focus();
      this.altnumberfield.nativeElement.querySelector('mat-form-field').classList.add('mat-focused');
    }
  };

  setAlternativeNumber = () => {
    this.altnumberfield.nativeElement.querySelector('mat-form-field').classList.remove('mat-focused');
    if (!this.switchElementClicked
      && !this.altAvailableElementClicked
      && (!this.altnumber.nativeElement.value || this.altnumber.nativeElement.value === '+')) {
      this.alternativeNumber = '';
      this.altnumber.nativeElement.value = '';
      this.alternativeNumberAvailable = false;
      return;
    }

    if (this.switchElementClicked) {
      this.switchElementClicked = false;
    }

    if (this.altAvailableElementClicked) {
      this.altAvailableElementClicked = false;
    }

    //ingnore cases of + and '' and do not validate them
    if (this.alternativeNumber === '+' || !this.alternativeNumber) {
      return;
    }
    if (!this.validateInput(this.alternativeNumber)) {
      this.alternativeInvalidNumber = true;
    } else {
      this.LogSvc.info('[TelephonyTabComponent]: setAlternativeNumber');
      this.TelephonySettingsSvc.setReroutingPhoneNumber(this.alternativeNumber, true)
        .catch((error: any) => {
          this.LogSvc.error('[TelephonyTabComponent]: setAlternativeNumber error', error);
          this.showError(error);
        });

      this.setRoutingOptions();
      this.setBusyHandlingOptions();
    }
  };

  canClearOption = (option: string) => option?.replace(/ +/g, '') !== this.alternativeNumber?.replace(/ +/g, '');

  deleteAlternativeNumber = (event: any, number: string) => {
    event.preventDefault();
    event.stopPropagation();
    this.UserProfileSvc.removeAlternativeNumber(number, (err: any) => {
      if (err) {
        this.LogSvc.error('[TelephonyTabComponent]: deleteAlternativeNumber error', err);
        return this.showError(err);
      }
      return false;
    });
  };

  toggleVoicemailEnabled = (voicemailEnabled: any) => {
    this.VoicemailSvc.setVoicemailEnabled(voicemailEnabled)
      .then((val: boolean) => {
        this.voicemailEnabled = val;
        if (!this.voicemailEnabled && (this.TelephonySettingsSvc.getSelectedBusyHandlingOption() === this.BusyHandlingOptions.SendToVM.name)) {
          // Revert back Busy handling to Default.
          this.selectedBusyHandlingOption = this.BusyHandlingOptions.DefaultRouting.name;
          this.TelephonySettingsSvc.saveBusyHandlingOption(this.selectedBusyHandlingOption);
        }
      })
      .catch((err: any) => {
        this.LogSvc.error('[TelephonyTabComponent]: Failed to toggle voicemailEnabled. ', err);
      });
    this.setBusyHandlingOptions();
  };

  // eslint-disable-next-line arrow-body-style
  selectVoicemailTimeout = (voicemailTimeout: any) => {
    return this.VoicemailSvc.setVoicemailTimeout(voicemailTimeout.value)
      .then((val: number) => {
        this.voicemailTimeout = val;
      });
  };

  setRoutingOption = (option: any) => {
    clearTimeout(this._setRoutingOptionTimer);
    this._setRoutingOptionTimer = setTimeout(() => {
      this._setRoutingOptionTimer = null;
      this.TelephonySettingsSvc.updateRoutingOption(option)
        .catch((error: any) => {
          this.LogSvc.error('[TelephonyTabComponent]: setRoutingOption error', error);
          error && error.startsWith('res_') && this.showError(error);
          this.initAtcRoutingForwardingSettings();
        });
    }, SAVE_TIMEOUT);
  };

  onKeydown = (event: any) => {
    // Ignore keydown for several cases in order to prevent removing '+' prefix for phone number
    // ignore backspace, delete and '+' when '+' is the available text for alternative number
    if ((event.code === 'Backspace' || event.code === 'Delete' || event.key === '+') && this.alternativeNumber === '+') {
      event.preventDefault();
      return;
    }
    // ignore delete or any of '+', arrow left, arrow right key action when cursor is at the start of input, on the left of '+'
    if ((event.code === 'Delete' || (event.key !== '+' && event.code !== 'ArrowLeft' && event.code !== 'ArrowRight')) && (event.target.selectionStart === 0)) {
      event.preventDefault();
      return;
    }
    // ignore backspace key action when cursor is on the right of '+' and there some numbers on the right side of cursor
    if (event.code === 'Backspace' && event.target.selectionStart === 1) {
      event.preventDefault();
    }
  };

  onSwitchElementMouseDown = () => {
    this.switchElementClicked = true;
  };

  onAltAvailableMouseDown = () => {
    this.altAvailableElementClicked = true;
  };

  isRegistered = () => this.root.registrationState === RegistrationState.Registered;

  isTelephonyAvailable = () => this.PhoneCallSvc.isTelephonyAvailable();

  downloadCallHistory = () => {
    if (this.isDownloadingCallHistory) { return; }

    this.downloadCallHistoryFailed = false;
    this.isDownloadingCallHistory = true;

    const phoneNumber = this.root.localUser?.callerId ?
      '_' + this.root.localUser?.callerId.replace(/^\+/, '').replace(/\s+/g, '') :
      '';

    const file = {
      uri: Circuit.__domain + DOWNLOAD_CALL_HISTORY_ENDPOINT,
      mimeType: 'application/zip',
      fileName: `${CALL_HISTORY_FILENAME}${phoneNumber}_${moment().format('DDMMYYYYHHmmss')}`
    };

    this.HttpSvc.downloadFile(file, (error: any) => {
      this.isDownloadingCallHistory = false;

      if (error) {
        this.downloadCallHistoryFailed = true;
        this.LogSvc.error('[TelephonyTabComponent]: Failed to download call history.', error);
      }
    });
  };
}
