import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';

declare let Circuit: any;

@Component({
  selector: '[uAriaLabelOnce]',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ''
})
export class UAriaLabelOnceComponent implements OnInit {

  @Input() uAriaLabelOnce! : string;
  @Input() localizeParams? : string | string[];

  constructor(private el: ElementRef, commonBlService: CommonBLService) {
    this.rootScope = commonBlService.getRootScopeData();
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
  }

  private params: string[] = [];
  private PubSubSvc;
  private rootScope: any;
  private localizeValue: any;

  private updateText = () => {
    const text = this.params.length ? this.rootScope.localize(this.localizeValue, this.params) : this.rootScope.i18n.map[this.localizeValue];
    this.el.nativeElement.setAttribute('aria-label', text);
  };

  ngOnInit(): void {
    // Initialization
    this.localizeValue = this.uAriaLabelOnce;
    this.PubSubSvc.subscribe('/language/update', this.updateText);

    if (this.localizeParams) {
      if (typeof this.localizeParams === 'string') {
        this.params = this.localizeParams.split('|');
      } else {
        this.params = this.localizeParams;
      }
    }
    this.updateText();
  }

  ngOnDestroy(): void {
    this.PubSubSvc.unsubscribe('/language/update', this.updateText);
  }
}
