<input class="dial-input mat-headline" #input type="text" [(ngModel)]="value"
    uPlaceholder="res_SearchNameNumber"
    [attr.aria-label]="ariaLabel"
    (keydown.enter)="selectionChange($event)"
    (keydown.arrowup)="selectionChange($event)"
    (keydown.arrowdown)="selectionChange($event)"
    (ngModelChange)="checkInputValue()">
<button mat-mini-fab color="grey" class="dial-input-button" uAriaLabel="res_Search" *ngIf="!value"
        (click)="input.focus()">
    <mat-icon>search</mat-icon>
</button>
<button mat-mini-fab color="grey" class="dial-input-button clear" uAriaLabel="res_Clear" *ngIf="value"
    (click)="value=''">
    <mat-icon>close</mat-icon>
</button>
