import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { UtilsService } from '../../services/utils.service';
import { AppModule } from '../../app.module';

declare let Circuit: any;
@Component({
  selector: 'app-settings-overlay',
  templateUrl: './settings-overlay.component.html',
  styleUrls: ['./settings-overlay.component.scss']
})

export class SettingsOverlayComponent implements OnInit, OnDestroy {
  private rootScope: any;
  private PubSubSvc: any;
  private overlayElements!: any;

  constructor(private commonBLService: CommonBLService, private utilsService: UtilsService) {
    this.rootScope = this.commonBLService.getRootScopeData();
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
  }

  ngOnInit(): void {
    this.PubSubSvc.subscribe('/show/banner', this.repositionOverlay);
    this.overlayElements = document.querySelectorAll('.settings-overlay')!;
    this.repositionOverlay(this.rootScope.showBanner);
  }

  ngOnDestroy() {
    this.PubSubSvc.unsubscribe('/show/banner', this.repositionOverlay);
  }

  private repositionOverlay = (showBanner: boolean) => {
    this.utilsService.conditionallyToggleClassOnElements(this.overlayElements, showBanner, 'with-banner');
  };
}
