export class CallLogTabs {
  all: any;
  missed: any;

  constructor(journalFilter: any) {
    // Set default values
    this.all = {
      name: journalFilter.ALL,
      uLocalize: 'res_All',
      uA11yLocalize: 'res_AccessibilityCallHistoryAll'
    };
    this.missed = {
      name: journalFilter.MISSED,
      uLocalize: 'res_CallLogFilter_MISSED',
      uA11yLocalize: 'res_AccessibilityCallHistoryMissed'
    };
  }

  getTabsArray() {
    return [this.all, this.missed];
  }
}
