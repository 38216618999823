import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { CallActionService } from '../../services/call-action.service';
import { DialogService } from '../../sub-modules/dialog/dialog.service';

declare let Circuit: any;


@Component({
  selector: 'app-second-call',
  templateUrl: './second-call.component.html',
  styleUrls: ['./second-call.component.scss']
})
export class SecondCallComponent implements OnInit, OnDestroy {

  private LogSvc: any;
  private PhoneCallSvc: any;
  root: any;
  answerDevices: any;
  declineDevices: any;
  defaultAnswerDevice: any;
  defaultDeclineDevice: any;
  defaultPushDevice: any;
  pushDevices: any;

  @Input() secondCall!: any;

  constructor(private commonBlService: CommonBLService, private callActionService: CallActionService, private dialogService: DialogService) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PhoneCallSvc = Circuit.serviceInstances.phoneCallSvc;
    this.root = this.commonBlService.getRootScopeData();
  }

  ngOnDestroy(): void {
    this.LogSvc.info('[SecondCallComponent]: ngOnDestroy');
  }

  ngOnInit(): void {
    this.LogSvc.info('[SecondCallComponent]: ngOnInit');
    this.answerDevices = this.callActionService.answerDevices;
    this.declineDevices = this.callActionService.declineDevices;
    this.defaultAnswerDevice = this.callActionService.defaultAnswerDevice;
    this.defaultDeclineDevice = this.callActionService.defaultDeclineDevice;
    this.defaultPushDevice = this.callActionService.defaultPushDevice;
    this.pushDevices =  this.callActionService.pushDevices;
  }

  canHangupCall = (callId: any) => this.PhoneCallSvc.canHangupCall(callId);

  canAnswerCall = (callId: any) => this.PhoneCallSvc.canAnswerCall(callId);

  canSwapCall = (callId?: any) => this.PhoneCallSvc.canSwapCall(callId);

  hasAnyCallInTransientState = () => this.PhoneCallSvc.hasAnyCallInTransientState();

  canPullRemoteCall = (callId: any) => this.PhoneCallSvc.canPullRemoteCall(callId);

  canHoldCall = (callId: any) => this.PhoneCallSvc.canHoldCall(callId);

  canRetrieveCall = (callId: any) => this.PhoneCallSvc.canRetrieveCall(callId);

  canPushLocalCall = (callId: any) => this.PhoneCallSvc.canPushLocalCall(callId);

  getRedirectingUserUiStr = (redirectingUser: any) => this.callActionService.getRedirectingUserUiStr(redirectingUser);
  getCallStateUiStr = (call: any) => this.callActionService.getCallStateUiStr(call);
  getCallTitleUiStr = (call: any) => this.callActionService.getCallTitleUiStr(call);
  showCallStateUi = () => this.callActionService.showCallStateUi(this.secondCall);

  endCall = (call: any) => {
    this.LogSvc.info('[SecondCallComponent]: endCall');
    this.callActionService.endCall(call);
  }

  answerCall = (callId: string, deviceName?: any): void => {
    this.LogSvc.buttonPressed('[SecondCallComponent]: Answer call from: ' + deviceName);
    this.callActionService.answerCall(callId, deviceName);
  }

  swapCall = async (call: any) => {
    this.LogSvc.buttonPressed('[SecondCallComponent]: Swap calls');
    try {
      await this.PhoneCallSvc.swapCall(call.callId);
    } catch (err: any) {
      //possibly show the error in the dialog here
      this.dialogService.handleCallError(err, null);
      this.LogSvc.error('[SecondCallComponent]: swapCall() error: ', err);
    }
  }

  pullRemoteCall = (callId: string): void => {
    this.LogSvc.buttonPressed('[SecondCallComponent]: Pull remote call');
    this.callActionService.pullRemoteCall(callId);
  }

  pushLocalCall = (callId: any, deviceName: any) => {
    this.LogSvc.buttonPressed('[SecondCallComponent]: Push local call to ', deviceName);
    this.PhoneCallSvc.pushLocalCall(callId, deviceName)
      .catch((err: any) => {
        //possibly show the error in the dialog here
        //this.dialogService.handleCallError(err, null);
        this.LogSvc.error('[SecondCallComponent]: pushLocalCall() error: ', err);
      });
  }

  holdCall = (call: any) => {
    this.LogSvc.buttonPressed('[SecondCallComponent]: Hold call');
    this.PhoneCallSvc.holdCall(call.callId)
      .catch((err: any) => {
        this.LogSvc.error('[SecondCallComponent]: holdCall() error: ', err);
      });
  }

  retrieveCall = (call: any) => {
    this.LogSvc.buttonPressed('[SecondCallComponent]: Retrieve call');
    this.PhoneCallSvc.retrieveCall(call.callId)
      .catch((err: any) => {
        this.LogSvc.error('[SecondCallComponent]: retrieveCall() error: ', err);
      });
  }

}
