<div class="container">
  <!-- AUDIO OUTPUT, RINGING OUTPUT, MICROPHONE (AUDIO_INPUT) -->
  <div *ngFor="let category of deviceCategories; let i = index" class="items">
    <div class="audio-header" [ngClass]="{'no-mgt': i === 0}">
      <h3 class="title" [uLocalizeOnce]="category.name"></h3>
      <app-test-audio-device [deviceCategory]="category.id"  *ngIf="category.id !== 'AUDIO_INPUT'"></app-test-audio-device>
      <app-microphone-activity [deviceCategory]="category.id" [deviceId]="selectedSources[category.id]?.id"  *ngIf="category.id === 'AUDIO_INPUT'"></app-microphone-activity>
    </div>
    <mat-form-field appearance="outline" class="select" *ngIf="devicesReady">
      <mat-label class="accessibility-element" [uLocalizeOnce]="category.name"></mat-label>
      <mat-select
        [(ngModel)]="selectedSources[category.id]"
        name="category.id"
        data-autotest="dropdown[category.id]"
        [disabled]="category.sources.length === 0"
      >
        <mat-select-trigger>
          <span *ngIf="category.sources.length === 0">
            {{rootScope.i18n.localize(category.defaultSrc.noPermissionLabel)}}
          </span>
          <span *ngIf="category.sources.length > 0">
            {{rootScope.i18n.localize(selectedSources[category.id].altLabel || selectedSources[category.id].label)}}
          </span>
        </mat-select-trigger>
      <ng-container *ngIf="category.sources.length === 0">
        <mat-option
            [value]="selectedSources[category.id]"
            >
            {{rootScope.i18n.localize(category.defaultSrc.altLabel || category.defaultSrc.label)}}
          </mat-option>
      </ng-container>
      <ng-container *ngIf="category.sources.length > 0">
        <mat-option
          *ngFor="let source of category.sources"
          [value]="source"
          (click)="onSelectChange(category, source)"
          >
          {{rootScope.i18n.localize(source.label)}}
        </mat-option>
      </ng-container>
      </mat-select>
    </mat-form-field>
    <mat-divider class="divider"></mat-divider>
  </div>
  <!-- Ringtones -->
  <app-ringtones></app-ringtones>

  <!-- AGC -->
  <div class="headset-integrations" *ngIf="rootScope.webRtcEnabled && (rootScope.browser.chrome || rootScope.browser.firefox)">
    <h3 class="mat-headline" uLocalize="res_AudioAGC"></h3>
    <p uLocalize="res_AudioAGCDescription"></p>
    <mat-checkbox ngDefaultControl class="audio-checkbox" color="primary" [(ngModel)]="audioAGCEnabled" (change)="toggleAudioAGC($event)">
      <span uLocalize="res_AudioAGCEnable"></span>
    </mat-checkbox>
    <mat-divider class="divider"></mat-divider>
  </div>

  <!-- EC -->
  <div class="headset-integrations" *ngIf="rootScope.webRtcEnabled && (rootScope.browser.chrome || rootScope.browser.firefox)">
    <h3 class="mat-headline" uLocalize="res_AudioEC"></h3>
    <p uLocalize="res_AudioECDescription"></p>
    <mat-checkbox ngDefaultControl class="audio-checkbox" color="primary" [(ngModel)]="audioECEnabled" (change)="toggleAudioEC($event)">
      <span uLocalize="res_AudioECEnable"></span>
    </mat-checkbox>
    <mat-divider class="divider"></mat-divider>
  </div>

  <!-- Headset Integrations -->
  <div class="headset-integrations" *ngIf="supportsHeadsetIntegrations()">
    <h3 class="title" uLocalize="res_HeadsetAppName"></h3>
    <app-plantronics-integration *ngIf="!isFirefox()"></app-plantronics-integration>
    <app-jabra-integration *ngIf="isWebHIDSupported()"></app-jabra-integration>
  </div>
</div>
