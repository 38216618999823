import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';

declare let Circuit: any;

@Component({
  selector: 'app-call-list',
  templateUrl: './call-list.component.html',
  styleUrls: ['./call-list.component.scss']
})
export class CallListComponent implements OnInit, OnDestroy, OnChanges {

  private LogSvc: any;

  @Input() phoneCalls: any;
  @Input() primaryCall: any;

  filteredCalls: any;

  constructor(private commonBlService: CommonBLService) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
  }

  ngOnInit(): void {
    this.LogSvc.info('[CallListComponent]: ngOnInit');
    this.filterPhoneCalls();
  }

  ngOnDestroy(): void {
    this.LogSvc.info('[CallListComponent]: ngOnDestroy');
  }

  ngOnChanges(changes: SimpleChanges) {
    this.filterPhoneCalls();
  }

  private filterPhoneCalls = () => {
    if (this.primaryCall && this.phoneCalls.length > 0) {
      this.filteredCalls = this.phoneCalls.filter((call: any) => call.callId !== this.primaryCall.callId);
    } else {
      this.filteredCalls = this.phoneCalls;
    }
  };
}
