import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { DialogService } from '../../sub-modules/dialog/dialog.service';
import { UtilsService } from '../../services/utils.service';
import { ExternalLinkButton } from '../external-link-button/external-link-button.component';
import { NotificationsSnackbarComponent } from '../notifications-snackbar/notifications-snackbar.component';
import { AgentUIState } from '../../shared/constants';

declare let Circuit: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  private LogSvc;
  private PubSubSvc;
  private TelephonySettingsSvc: any;
  private CircuitLabSvc: any;
  private Utils: any;
  private PhoneCallLogSvc;
  private UserProfileSvc;
  private NotificationSvc;
  private PhoneCallSvc;
  private CstaSvc: any;

  rootScope: any;
  displayName = '';
  phoneNumber = '';
  cfEntry: any = {};
  cfwEnabled = false; // if user has set call forwarding
  showDebugMenu: boolean;
  popUpLogEnabled = false;
  showLogFileManagmentModal = false;
  i18nPrecenceStateRes: any;
  telephonyAvailable: boolean;
  huntGroupList: any;
  globalAgentStateReady = false;
  uIAgentState: any;
  isSetAgentStatePerGroupSupported = false;
  settingsOptions: any = {
    action: true,
    title: 'res_Settings',
    icon: 'settings',
    noLabel: 'res_Cancel',
    yesLabel: 'res_Yes',
    isOpen: false,
    settingsView: true
  };
  callFwdOptions: any = {
    action: true,
    icon: 'callForwarding',
    noLabel: 'res_Cancel',
    yesLabel: 'res_Disable',
    maxWidth: '28rem',
    maxHeight: '17.3rem',
    isOpen: false
  };
  editLogLevelOptions: any = {
    action: true,
    title: 'res_LogManagement',
    icon: 'editLogLevel',
    noLabel: 'res_Cancel',
    yesLabel: 'res_Yes',
    isOpen: false,
    settingsView: true
  };
  aboutOptions: any = {
    action: true,
    title: 'res_About',
    icon: 'about',
    noLabel: 'res_Cancel',
    yesLabel: 'res_Yes',
    isOpen: false,
    aboutView: true
  };
  helpOptions: any = {
    action: true,
    title: 'res_Help',
    icon: 'help',
    noLabel: 'res_Cancel',
    yesLabel: 'res_Yes',
    isOpen: false,
    helpView: true
  };
  agentOptions: any = {
    action: true,
    icon: 'agentStatusIndication',
    isOpen: false
  };
  whatsNewOptions: any = {
    action: true,
    title: 'res_WhatsNew',
    icon: 'whatsNew',
    noLabel: 'res_Cancel',
    yesLabel: 'res_Yes',
    isOpen: false
  };
  editProfilePictureOptions: any = {
    action: true,
    title: 'res_ChangeProfilePicture',
    icon: 'editProfilePicture',
    isOpen: false
  };

  phoneConv: any;

  notificationPermissionSet = false;
  showNotificationHeader = false;
  isNotificationBlocked = false;
  editProfilePic = false;

  @ViewChild('unifyOfficeChild')
  private unifyOfficeChild!: ExternalLinkButton;

  constructor(private commonBlService: CommonBLService, private dialogService: DialogService, private utilsService: UtilsService, private _snackBar: MatSnackBar) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this.TelephonySettingsSvc = Circuit.serviceInstances.telephonySettingsSvc;
    this.PhoneCallLogSvc = Circuit.serviceInstances.phoneCallLogSvc;
    this.UserProfileSvc = Circuit.serviceInstances.userProfileSvc;
    this.CircuitLabSvc = Circuit.serviceInstances.circuitLabSvc;
    this.NotificationSvc = Circuit.serviceInstances.notificationSvc;
    this.PhoneCallSvc = Circuit.serviceInstances.phoneCallSvc;
    this.CstaSvc = Circuit.serviceInstances.cstaSvc;
    this.showDebugMenu = false;
    this.telephonyAvailable = this.PhoneCallSvc.isTelephonyAvailable();
    this.rootScope = this.commonBlService.getRootScopeData();
  }

  ngOnInit(): void {
    this.LogSvc.info('[HeaderComponent]: ngOnInit');
    this.PubSubSvc.subscribe('/atc/routingForwardingEvent', this.onForwardingEvent);
    this.PubSubSvc.subscribe('/telephony/available', this.onTelephonyAvailable);
    this.PubSubSvc.subscribe('/agent/state/ready', this.onAgentStateReady);
    this.Utils = Circuit.Utils;
    if (!this.rootScope.localUser) {
      this.PubSubSvc.subscribeOnce('/localUser/init', this.onLocalUserInit);
      this.PubSubSvc.subscribe('/localUser/update', this.onLocalUserUpdate);
    }
    this.telephonyAvailable = this.PhoneCallSvc.isTelephonyAvailable();

    this.agentOptions.huntGroupList = this.CstaSvc.getHuntGroupList() || [];
    this.agentOptions.globalAgentStateReady = this.CstaSvc.getGlobalAgentState();
    this.agentOptions.isSetAgentStatePerGroupSupported = this.CstaSvc.isSetAgentStatePerGroupSupported();
    this.setUIAgentState();
  }

  ngOnDestroy(): void {
    this.LogSvc.info('[HeaderComponent]: ngOnDestroy');
    this.PubSubSvc.unsubscribeOnce('/localUser/init', this.onLocalUserInit);
    this.PubSubSvc.unsubscribe('/atc/routingForwardingEvent', this.onForwardingEvent);
    this.PubSubSvc.unsubscribe('/localUser/update', this.onLocalUserUpdate);
    this.PubSubSvc.unsubscribe('/telephony/available', this.onTelephonyAvailable);
    this.PubSubSvc.unsubscribe('/agent/state/ready', this.onAgentStateReady);
  }

  private onTelephonyAvailable = () => {
    this.telephonyAvailable = this.PhoneCallSvc.isTelephonyAvailable();
    const phoneNumbers = this.PhoneCallSvc.getMyPhoneNumbers();
    this.phoneNumber = phoneNumbers.public || phoneNumbers.private || '';

    this.PhoneCallLogSvc.getTelephonyConversation()
      .then((conv: any) => {
        this.phoneConv = conv;
      })
      .catch((err: any) => {
        this.LogSvc.error('[HeaderComponent]: Error retrieving phone calls conversation: ', err);
      });
  };

  private onLocalUserInit = (localUser: any) => {
    this.LogSvc.debug('[HeaderComponent]: Received /localUser/init event. user = ', localUser.userId);
    this.displayName = localUser.displayName;

    this.notificationPermissionSet = this.NotificationSvc.hasPermissionBeenSet();
    this.isNotificationBlocked = this.NotificationSvc.isPermissionDenied();

    if (!this.notificationPermissionSet) {
      this.LogSvc.debug('[HeaderComponent]: Notifications permision has not been set');
      this.openSnackBar('res_EnableNotificationsSnackbarContent', 'res_TurnOn');
    }
  };

  private onLocalUserUpdate = (localUser: any) => {
    this.LogSvc.debug('[HeaderComponent]: Received /localUser/update event. user = ', localUser.userId);
    this.i18nPrecenceStateRes = this.localizedPrecenceStates(this.rootScope.localUser.userPresenceState.state);
  };

  private openSnackBar = (message: string, action: string) => {

    const snackBarRef = this._snackBar.openFromComponent(NotificationsSnackbarComponent, {
      data: {
        message: message,
        action: action
      },
      horizontalPosition: 'left',
      panelClass: 'notifications-snackbar'
    });

    snackBarRef.onAction().subscribe(() => {
      this.enableNotifications();
    });
  };

  private onAgentStateReady = (agentStateReady: boolean) => {
    this.LogSvc.debug('[HeaderComponent]: Received /agent/state/ready event');
    this.huntGroupList = this.CstaSvc.getHuntGroupList() || [];
    this.globalAgentStateReady = agentStateReady;
    this.isSetAgentStatePerGroupSupported = this.CstaSvc.isSetAgentStatePerGroupSupported();
    this.setUIAgentState();
  };

  private setUIAgentState() {
    switch (this.CstaSvc.getUIAgentState()) {
    case 'available':
      this.uIAgentState = AgentUIState.AVAILABLE;
      break;
    case 'partiallyUnavailable':
      this.uIAgentState = AgentUIState.PARTIAL;
      break;
    case 'unavailable':
      this.uIAgentState = AgentUIState.UNAVAILABLE;
      break;
    }

    this.agentOptions.globalAgentStateReady = this.globalAgentStateReady;
    this.agentOptions.isSetAgentStatePerGroupSupported = this.isSetAgentStatePerGroupSupported;
    this.agentOptions.huntGroupList = this.huntGroupList;
    this.agentOptions.uIAgentState = this.uIAgentState;
  }

  enableNotifications(): void {
    this.NotificationSvc.requestPermission((res: any) => {
      this.notificationPermissionSet = res;
      this.showNotificationHeader = !res;
      this.isNotificationBlocked = this.NotificationSvc.isPermissionDenied();
    });
  }

  toggleShowDebugMenu(e: any): void {
    // Ctrl-Shift-click for Windows. Cmd-Alt-click for Mac.
    if ((e.ctrlKey && e.shiftKey) || (e.metaKey && e.altKey)) {
      this.showDebugMenu = !this.showDebugMenu;
    }
  }

  hideDebugMenu(): void {
    this.showDebugMenu = false;
  }

  enablePopUpLog(): void {
    this.LogSvc.enablePopup();
    this.popUpLogEnabled = true;
  }

  disablePopUpLog(): void {
    this.LogSvc.disablePopup();
    this.popUpLogEnabled = false;
  }

  showPopUpLogWindow(): void {
    this.LogSvc.show();
  }

  toggleLabFeatures = () => {
    this.rootScope.showAlphaFeatures = this.rootScope.enableAlphaFeatures && !this.rootScope.showAlphaFeatures;

    if (!this.rootScope.showAlphaFeatures) {
      this.CircuitLabSvc.disableAlphaFeatures();
    } else {
      // Automatically show Closed Beta features as well
      this.rootScope.showBetaFeatures = true;
    }
  };

  private localizedPrecenceStates(state: any): string {
    let statusText = '';
    switch (state) {
    case Circuit.Constants.PresenceState.AVAILABLE:{
      statusText = this.rootScope.i18n.map.res_Available;
      break;
    }
    case Circuit.Constants.PresenceState.BUSY:{
      statusText = this.rootScope.i18n.map.res_Busy;
      break;
    }
    case Circuit.Constants.PresenceState.BUSY_IN_A_CALL:{
      statusText = this.rootScope.i18n.map.res_BusyCall;
      break;
    }
    case Circuit.Constants.PresenceState.BUSY_IN_AN_EXTERNAL_CALL:{
      statusText = this.rootScope.i18n.map.res_BusyCall_Teams;
      break;
    }
    case Circuit.Constants.PresenceState.AWAY:{
      statusText = this.rootScope.i18n.map.res_Away;
      break;
    }
    case Circuit.Constants.PresenceState.DND:{
      statusText = this.rootScope.i18n.map.res_DoNotDisturb;
      break;
    }
    case Circuit.Constants.PresenceState.INVISIBLE:{
      statusText = this.rootScope.i18n.map.res_Invisible;
      break;
    }
    case Circuit.Constants.PresenceState.OFFLINE:{
      statusText = this.rootScope.i18n.map.res_Offline;
      break;
    }
    }
    return statusText;
  }

  onForwardingEvent = (anyFwdEnabled: boolean) => {
    this.LogSvc.debug('[HeaderComponent]: Received /atc/routingForwardingEvent event. anyFwdEnabled = ', anyFwdEnabled);
    this.cfwEnabled = anyFwdEnabled;
    if (this.cfwEnabled) { this.getCallForwardingMessage(); }
  };

  getCallForwardingMessage = () => {
    const cfEntry = this.TelephonySettingsSvc.getEnabledCallForwarding();

    if (cfEntry) {
      this.callFwdOptions = {
        ...this.callFwdOptions,
        introMessage: `${cfEntry.message || ''}`,
        messageParams: [`${cfEntry.cfwNumber || ''}`]
      };
    }

    return cfEntry ? this.callFwdOptions : false;
  };

  openOverlay = (options: any) => {
    if (options.isOpen) {
      return;
    }
    options.isOpen = true;

    if (options.settingsView || options.aboutView || options.helpView) {
      this.dialogService.closeActiveDialog();
      if (this.rootScope.inFullCallView === true) {
        this.rootScope.inFullCallView = false;
      }
      if (this.phoneConv?.call && !this.rootScope.inMiniCallView) {
        this.rootScope.inMiniCallView = true;
      }
    }

    this.openDialog(options)
      .then(() => {
        this.LogSvc.debug('[HeaderComponent]: User has accepted the Dialog');
        options.isOpen = false;
      })
      .catch(() => {
        this.LogSvc.debug('[HeaderComponent]: User has rejected the Dialog');
        options.isOpen = false;
        this.dialogService.handleCallError(null, null);
      });

  };

  private openDialog(options: any) {
    switch (options.icon) {
    case 'settings':
      return this.dialogService.openSettingsView(options);
    case 'about':
      return this.dialogService.openAboutView(options);
    case 'help':
      return this.dialogService.openHelpView(options);
    case 'callForwarding':
      return this.dialogService.openCallFwdView(options);
    case 'editLogLevel':
      return this.dialogService.openEditLogLevelComponent(options);
    case 'agentStatusIndication':
      return this.dialogService.openAgentStatusIndicationComponent(options);
    case 'whatsNew':
      return this.dialogService.openWhatsNewComponent(options);
    case 'editProfilePicture':
      return this.dialogService.openEditProfilePicture(options);
    default:
      return this.dialogService.open(options);
    }
  }

  emailLogs = () => {
    const body = this.rootScope.localize('res_EmailLogsTextPartner', [
      this.rootScope.localUser.emailAddress || '',
      this.rootScope.localUser.tenantId || '',
      this.rootScope.localUser.domainName || '',   // tenantName
      this.rootScope.localUser.displayName || ''
    ]);

    const supportEmailAddress = this.rootScope.localUser.supportEmailAddress || '';
    const link = 'mailto:' + supportEmailAddress + '?body=' + this.Utils.enhanceEncodeURI(body);

    this.utilsService.openNewWindow({
      newWindowUrl: link,
      emailWindow: true
    });

    this.utilsService.downloadAllLogFiles();
  };

  /////// Presence functions ---- Possibly create new component
  setPresence(state: any) {
    const currentState = this.rootScope.localUser.userPresenceState.state;
    this.LogSvc.debug(`[HeaderComponent] SetPresence. current state: ${currentState}, new state: ${state}`);
    //There is an active call so set to busy
    if (state === Circuit.Constants.PresenceState.AVAILABLE &&
      ((this.phoneConv?.call?.state.css === 'active-call') ||
        (this.phoneConv?.call?.state.css === 'active-call-remote'))) {
      state = !this.rootScope.localUser.isStandalone ? Circuit.Constants.PresenceState.BUSY_IN_A_CALL : state;
      this.LogSvc.debug(`[HeaderComponent] User has active call, set presence ${state}.`);
    }
    this.UserProfileSvc.setUnifiedPresenceV2({state});
  }

  clickUnifyOfficeChild = () => this.unifyOfficeChild.openAppByUri();

  setRtcDebugIceParams = () => {
    this.dialogService.openICESettingsComponent({});
  };

  setRtcDebugAudioParams = () => {
    this.dialogService.openRtpDebugAudioSettingsComponent({});
  };

  logout = () => {
    this.utilsService.setPreventUnloadPrompt(true);
    this.utilsService.redirectToPath('logout', 250);
  };
}
