import { Component, NgZone, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { JabraControlService } from '../../services/jabra-control.service';

declare let Circuit: any;

const JABRA_STATE = {
  NO_DEVICE_FOUND: 0,
  JABRA_CONNECTION_ERROR: 1,
  CONNECTED_DEVICE: 2
};

@Component({
  selector: 'app-jabra-integration',
  templateUrl: './jabra-integration.component.html',
  styleUrls: ['./jabra-integration.component.scss']
})
export class JabraIntegration implements OnInit {
  private LogSvc: any;
  private PubSubSvc: any;

  i18n: any;
  jabra = false;
  jabraState: number;
  _activeDevice: any = [];
  deviceText: string;
  connecting: boolean;
  installing: boolean;

  constructor(private commonBlService: CommonBLService, private jabraControlService: JabraControlService, private ngZone: NgZone) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this.jabraState = JABRA_STATE.NO_DEVICE_FOUND;
    this.deviceText = '';
    this.connecting = false;
    this.installing = false;
  }

  ngOnInit(): void {
    this.i18n = this.commonBlService.getRootScopeData().i18n;
    this.PubSubSvc.subscribe('/headset/jabra/deviceListUpdated', this.getJabraDevice);
    this.PubSubSvc.subscribe('/language/update', this.onLanguageUpdate);
    this.init();
  }

  init() {
    if (!this.isJabraEnabled()) {
      return;
    }
    this.jabra = true;

    if (this.hasErrorConnection()) {
      this.jabraState = JABRA_STATE.JABRA_CONNECTION_ERROR;
      this.getDeviceText();
      return;
    }
    this.getJabraDevice();
  }

  onLanguageUpdate = () => {
    this.getDeviceText();
  };

  connect = () => {
    this.connecting = true;
    this.jabra = true;

    return this.jabraControlService.connect()
    .then(() => {
      this.LogSvc.debug('[JabraIntegration]: Successfully connected to Jabra service');
      return this.getDeviceText();
    })
    .catch((err: any) => {
      this.LogSvc.warn('[JabraIntegration]: Failed to connect to Jabra service. ', err);
      this.jabraState = JABRA_STATE.JABRA_CONNECTION_ERROR;
      return this.getDeviceText();
    });
  };

  getDeviceText = () => {
    this.connecting = false;
    // status 0 (no value) no device found
    // status 1 (error): failed to connect
    // status 2 (success): device found
    switch (this.jabraState) {
    case JABRA_STATE.NO_DEVICE_FOUND:{
      this.deviceText = this.i18n.localize('res_HeadsetNotConnectedOrPaired');
      break;
    }
    case JABRA_STATE.JABRA_CONNECTION_ERROR:{
      this.deviceText = this.i18n.localize('res_HeadsetConnectFailed');
      break;
    }
    case JABRA_STATE.CONNECTED_DEVICE:{
      this.deviceText = this._activeDevice?.name ?
        this.i18n.localize('res_ConnectedTo', [this._activeDevice.name]) : this.i18n.map.res_Connected;
      break;
    }
    }
  };

  getJabraDevice = () => {
    this.ngZone.run(() => {
      const activeDevice = this.jabraControlService.getActiveDevice();
      if (!activeDevice) {
        this.LogSvc.info('[JabraIntegration]: No active device');
        this.jabraState = JABRA_STATE.NO_DEVICE_FOUND;
      } else {
        this.LogSvc.info('[JabraIntegration]: Active device = ', activeDevice.name);
        this.jabraState = JABRA_STATE.CONNECTED_DEVICE;
      }
      this._activeDevice = activeDevice;
      return this.getDeviceText();
    });
  };

  triggerWebhidConsent = async () => {
    await this.jabraControlService.triggerWebhidConsent();
  };

  toggleJabra = () => {
    this.jabraState = JABRA_STATE.NO_DEVICE_FOUND;
    if (!this.isJabraEnabled()) {
      this.connect();
    } else {
      if (this.hasErrorConnection()) {
        this.jabraState = JABRA_STATE.JABRA_CONNECTION_ERROR;
        return this.getDeviceText();
      }
      this.disconnect();
    }
  };

  isJabraEnabled = () => this.jabraControlService.isIntegrationEnabled();

  hasErrorConnection = () => this.isJabraEnabled() && this.jabraControlService.getHasConnectionError();

  disconnect = () => {
    this.jabra = false;
    this.jabraControlService.disconnect();
  };

  ngOnDestroy() {
    this.LogSvc.info('[JabraIntegration]: ngOnDestroy');
    this.PubSubSvc.unsubscribe('/headset/jabra/deviceListUpdated', this.getJabraDevice);
    this.PubSubSvc.unsubscribe('/language/update', this.onLanguageUpdate);
  }
}
