<div class="dialog-container">
  <button mat-button align="end"
    *ngIf="(!data.action || !data.noLabel)"
    uAriaLabel="res_AccessibilityCloseModal"
    class="close-btn"
    (click)="close(false)">
    <mat-icon>close</mat-icon>
  </button>

  <div *ngIf="data.title" mat-dialog-title>
    <div *ngIf="data.type === 'error'" class="error-icon"></div>
    <h1 class="dialog-title" [innerHTML]="i18n.localize(data.title, data.titleParams)"></h1>
  </div>
  <mat-dialog-content>
    <p *ngIf="data.introMessage" [uLocalizeOnce]="data.introMessage"></p>
    <p *ngIf="!!data.messageClickAction" (click)="messageClick($event)" [innerHTML]="i18n.localize(data.message, data.messageParams)"></p>
    <p *ngIf="!data.messageClickAction" [innerHTML]="i18n.localize(data.message, data.messageParams)"></p>
  </mat-dialog-content>
  <mat-dialog-actions align="end" *ngIf="data.action || data.alert">
    <div *ngIf="data.alert">
      <button mat-button (click)="close(false)">
        <span [uLocalizeOnce]="data.okLabel"></span>
      </button>
    </div>
    <div *ngIf="data.action">
      <button mat-button
              *ngIf="data.yesLabel"
              [ngClass]="{'secondary':(data.type === 'error'), 'primary':(data.type !== 'error')}"
              (click)="confirm()"
              [mat-dialog-close]="true"
              cdkFocusInitial>
        <span [uLocalizeOnce]="data.yesLabel"></span>
      </button>
      <button mat-button *ngIf="data.noLabel" (click)="close(false)" [mat-dialog-close]="false" >
        <span [uLocalizeOnce]="data.noLabel"></span>
      </button>
    </div>
  </mat-dialog-actions>
</div>
