import { FocusableOption } from '@angular/cdk/a11y';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { DialogService } from '../../sub-modules/dialog/dialog.service';

declare let Circuit: any;

const REMOVED_USER = 'res_RemovedUser';    // This is set to phoneNumber and display when obfuscating a call entry with CLI command (GDPR)

@Component({
  selector: 'app-call-log-list-item',
  templateUrl: './call-log-list-item.component.html',
  styleUrls: ['./call-log-list-item.component.scss']
})
export class CallLogListItemComponent implements OnInit, FocusableOption {
  private LogSvc: any;

  isChecked = false;

  @Input() item!: any;
  @Input() unreadAfter!: any;
  @Input() dialable = true;
  disabled!: boolean;

  PhoneCallSvc: any;
  PhoneCallLogSvc: any;
  root: any;

  constructor(private commonBlService: CommonBLService, private dialogService: DialogService, private element: ElementRef) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PhoneCallSvc = Circuit.serviceInstances.phoneCallSvc;
    this.root = this.commonBlService.getRootScopeData();
  }

  ngOnInit(): void {
    const serviceInstances = Circuit.serviceInstances;
    this.LogSvc = serviceInstances.logSvc;
    this.PhoneCallSvc = serviceInstances.phoneCallSvc;
    this.PhoneCallLogSvc = serviceInstances.phoneCallLogSvc;
    this.root = this.commonBlService.getRootScopeData();
  }

  getLabel(): string {
    return this.item;
  }

  focus(): void {
    this.element.nativeElement.focus();
  }

  dial(number: string, name: string, userId: string) {
    if (!number) {
      return;
    }

    this.LogSvc.buttonPressed('[CallLogListItemComponent]: Dial number from call log:', number);
    this.PhoneCallSvc.checkDisclaimerAndDial(number, name, userId)
      .catch((err: any, warn: any) => {
        this.LogSvc.debug('[CallLogListItemComponent]: User has rejected the PopUp');
        this.dialogService.handleCallError(err, warn);
      });
  }

  deleteItem() {
    const itemId = this.item.itemId;
    this.LogSvc.info('[CallLogListItemComponent]: Delete call log item. itemId: ', itemId);

    this.dialogService.confirmDeletion('res_DeleteCallJournalConfirmationText')
     .then(() => {
       this.LogSvc.debug('[CallLogListItemComponent]: Deleting item with itemId ', itemId);
       this.PhoneCallLogSvc.deleteCallLog(itemId)
        .catch((err: any) => {
          this.LogSvc.error('[CallLogListItemComponent]: Error during deleting call log: ', err);
        });
     })
     .catch(() => {
       // Handle reject to prevent 'Possibly unhandled rejection' error
     });
  }

  getRedirectingUserUiStr = function (redirectingUser: any) {
    if (!redirectingUser) {
      return '';
    }
    // if (redirectingUser.displayName) {
    //   return redirectingUser.displayName + (redirectingUser.phoneNumber ? ' (' + redirectingUser.phoneNumber + ')' : '');
    // }
    return redirectingUser.phoneNumber;
  };

  resolveNameOrNumber() {
    if (this.item.peerUser.isDialable === false) {
      return this.root.i18n.map.res_NumberNotAvailable;
    }

    if (this.item.peerUser.displayName) {
      return this.item.peerUser.displayName === REMOVED_USER ? this.root.i18n.map.res_RemovedUser : this.item.peerUser.displayName;
    }
    return this.item.peerUser.phoneNumber === REMOVED_USER ? this.root.i18n.map.res_RemovedUser : this.item.peerUser.phoneNumber;
  }

  isUserDialable() {
    return !(this.item.peerUser.isDialable === false || this.item.peerUser.displayName === REMOVED_USER || this.item.peerUser.phoneNumber === REMOVED_USER);
  }
}
