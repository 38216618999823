import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';

declare let Circuit: any;
declare let $: any;

@Component({
  selector: '[uLocalizeOnce]',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ''
})
export class ULocalizeOnceComponent implements OnInit {

  @Input() uLocalizeOnce! : string;
  @Input() localizeParams? : string | string[];

  constructor(private el: ElementRef,
    commonBlService: CommonBLService) {

    this.rootScope = commonBlService.getRootScopeData();
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
  }

  private params: string[] = [];
  private LogSvc;
  private PubSubSvc;
  private rootScope: any;
  private localizeValue: any;

  private updateText = () => {
    const text = this.params.length ? this.rootScope.localize(this.localizeValue, this.params) : this.rootScope.i18n.map[this.localizeValue];
    $(this.el.nativeElement).text(text);
  };

  ngOnInit(): void {
    this.localizeValue = this.uLocalizeOnce;
    // First make sure that the element does not contain inner HTML
    // eslint-disable-next-line newline-per-chained-call
    if ($(this.el.nativeElement).html().trim()) {
      this.LogSvc.error('[u-localize]: This directive cannot be used for elements with inner HTML - ', this.localizeValue);
      return;
    }

    // Initialization
    this.PubSubSvc.subscribe('/language/update', this.updateText);

    if (this.localizeParams) {
      if (typeof this.localizeParams === 'string') {
        this.params = this.localizeParams.split('|');
      } else {
        this.params = this.localizeParams;
      }
    }
    this.updateText();
  }

  ngOnDestroy(): void {
    this.PubSubSvc.unsubscribe('/language/update', this.updateText);
  }
}
