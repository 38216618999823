<!-- First default contact "Dial:" -->
<div class="contact-wrapper" *ngIf="isFirst">
  <app-avatar-single [user]="contact" size="medium" class="avatar"></app-avatar-single>
  <div class="name-number-wrapper">
    <div class="contact-name"><span uLocalize="res_Dial"></span><span>: </span></div>
    <mat-card-subtitle class="phone-number default-number">
      <span [innerHTML]="contact?.phoneNumber | scopedHighlighter:searchTerm:false"></span>
    </mat-card-subtitle>
  </div>
</div>

<!-- Other contacts -->
<div class="contact-wrapper" *ngIf="!isFirst">
  <app-avatar-single [user]="contact" size="medium" class="avatar" *ngIf="contact?.displayName && contact?.first" [presence]="showPresence && contact?.userPresenceState?.state"></app-avatar-single>
  <div class="name-number-wrapper">
    <div class="contact-name" *ngIf="contact?.displayName && contact?.first" [innerHTML]="contact.displayName | scopedHighlighter:searchTerm:false"></div>
    <mat-card-subtitle class="phone-number" [ngClass]="{'default-number': contact?.first}">
      <span [innerHTML]="contact?.phoneNumber | scopedHighlighter:searchTerm:false"></span> <span *ngIf="contact?.numberType"> - {{ contact.numberType }}</span>
    </mat-card-subtitle>
  </div>
</div>

<mat-divider *ngIf="contact.last"></mat-divider>
