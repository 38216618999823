import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { CallActionService } from '../../services/call-action.service';
import { DialogService } from '../../sub-modules/dialog/dialog.service';
declare let Circuit: any;

const CHECK_DTMF_STATUS_DELAY = 100; // In delivered state, it might take a few milliseconds for the DTMF status to change

@Component({
  selector: 'app-call-primary',
  templateUrl: './call-primary.component.html',
  styleUrls: ['./call-primary.component.scss']
})
export class CallPrimaryComponent implements OnInit, OnDestroy {

  /*
    * Enum for Call Views
  */
  View = Object.freeze({
    TRANSFER: 'Transfer',
    NEWCALL: 'NewCall',
    DTMF: 'Dtmf'
  });

  @Input() call: any;
  @Input() hasDtmfView!: boolean;
  @Input() hasNewCallView!: boolean;
  @Input() hasTransferView!: boolean;
  @Input() hasViewOpen!: boolean;
  @Input() phoneCalls: any;

  @Output('parentToggleView') parentToggleView: EventEmitter<any> = new EventEmitter();

  private LogSvc: any;
  private PhoneCallSvc: any;
  private PubSubSvc: any;
  private _checkDtmfTimeout: any;

  answerDevices: any;
  consultationAvailable: any;
  declineDevices: any;
  defaultAnswerDevice: any;
  defaultPushDevice: any;
  dtmfDisabled = true;
  mergeAvailable: any;
  pushDevices: any;
  root: any;

  constructor(private commonBlService: CommonBLService,
    private callAction: CallActionService,
    private dialogService: DialogService,
    private _snackBar: MatSnackBar) {
    this.root = this.commonBlService.getRootScopeData();
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PhoneCallSvc = Circuit.serviceInstances.phoneCallSvc;
    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
  }

  ngOnInit(): void {
    this.initDevices();
    this.initAvailableFeatures();
    this.checkDtmfStatus();

    this.PubSubSvc.subscribe('/call/ended', this.checkDtmfStatus);
    this.PubSubSvc.subscribe('/call/state', this.checkDtmfStatus);
    this.PubSubSvc.subscribe('/call/rtp/iceConnectionState', this.checkDtmfStatus);
    this.PubSubSvc.subscribe('/consultation/status/changed', this.checkDtmfStatus);
  }

  ngOnDestroy(): void {
    this._checkDtmfTimeout && clearTimeout(this._checkDtmfTimeout);

    this.PubSubSvc.unsubscribe('/call/ended', this.checkDtmfStatus);
    this.PubSubSvc.unsubscribe('/call/state', this.checkDtmfStatus);
    this.PubSubSvc.unsubscribe('/call/rtp/iceConnectionState', this.checkDtmfStatus);
    this.PubSubSvc.unsubscribe('/consultation/status/changed', this.checkDtmfStatus);
  }

  private checkDtmfStatus = () => {
    if (this._checkDtmfTimeout) {
      clearTimeout(this._checkDtmfTimeout);
      this._checkDtmfTimeout = null;
    }

    // Check if DTMF is available
    this._checkDtmfTimeout = setTimeout(() => {
      this._checkDtmfTimeout = null;

      if (this.call?.consultation || !this.call?.isPresent() || (!this.call?.isEstablished() && !this.call?.checkState(Circuit.Enums.CstaCallState.Delivered))) {
        // Scenarios where there are no calls or we shouldn't enter dtmfMode based on the call state (e.g.: incoming call)
        this.dtmfDisabled = true;
      } else {
        // Client has an active call
        this.dtmfDisabled = !this.call.isDtmfAllowed;
      }
    }, CHECK_DTMF_STATUS_DELAY, false);
  };

  private initAvailableFeatures = () => {
    const featureList = this.PhoneCallSvc.getAvailableFeatureList();

    this.mergeAvailable = featureList.includes(Circuit.Constants.TcAvailableFeatures.MERGE);
    this.consultationAvailable = featureList.includes(Circuit.Constants.TcAvailableFeatures.CONSULTATION);
  };

  private initDevices = () => {
    this.answerDevices = this.callAction.answerDevices;
    this.declineDevices = this.callAction.declineDevices;
    this.defaultPushDevice = this.callAction.defaultPushDevice;
    this.defaultAnswerDevice = this.callAction.defaultAnswerDevice;
    this.pushDevices = this.callAction.pushDevices;
  };

  private openSnackBar = (message: string, action: string) => {
    this._snackBar.open(message, action, {
      duration: 3000
    });
  };

  //PhoneCallSvc functions
  canAnswerCall = (callId: any) => this.PhoneCallSvc.canAnswerCall(callId);
  canConsultTransfer = (callId: any) => this.PhoneCallSvc.canConsultTransfer(callId);
  canHangupCall = (callId: any) => this.PhoneCallSvc.canHangupCall(callId);
  canHoldCall = (callId: any) => this.PhoneCallSvc.canHoldCall(callId);
  canInitiateConsultationLocalCall = () => this.PhoneCallSvc.canInitiateConsultationLocalCall();
  canInitiateConsultationRemoteCall = () => this.PhoneCallSvc.canInitiateConsultationRemoteCall();
  canInitiatePhoneCall = () => this.PhoneCallSvc.canInitiatePhoneCall();
  canMergeCall = (callId: any) => this.PhoneCallSvc.canMergeCall(callId);
  canPickupCall = (callId: any) => this.PhoneCallSvc.canPickupCall(callId);
  canPullRemoteCall = (callId: any) => this.PhoneCallSvc.canPullRemoteCall(callId);
  canPushLocalCall = (callId: any) => this.PhoneCallSvc.canPushLocalCall(callId);
  canRetrieveCall = (callId: any) => this.PhoneCallSvc.canRetrieveCall(callId);
  canTransferCall = (callId: any) => this.PhoneCallSvc.canTransferCall(callId);
  hasAnyCallInTransientState = () => this.PhoneCallSvc.hasAnyCallInTransientState();
  hasMultipleLocalCalls = (includeDelivered: boolean) => this.PhoneCallSvc.hasMultipleLocalCalls(includeDelivered);
  hasMultipleRemoteCalls = () => this.PhoneCallSvc.hasMultipleRemoteCalls();
  isHangupCallAvailable = (callId: any) => this.PhoneCallSvc.isHangupCallAvailable(callId);
  isLocalCallActive = (callId: any) => this.PhoneCallSvc.isLocalCallActive(callId);
  isRemoteCallActive = (callId: any) => this.PhoneCallSvc.isRemoteCallActive(callId);

  //Call action functions
  getRedirectingUserUiStr = (redirectingUser: any) => this.callAction.getRedirectingUserUiStr(redirectingUser);
  getCallStateUiStr = (call: any) => this.callAction.getCallStateUiStr(call);
  getCallTitleUiStr = (call: any) => this.callAction.getCallTitleUiStr(call);
  showCallStateUi = () => this.callAction.showCallStateUi(this.call);
  toggleMute = (call: any) => this.callAction.toggleMute(call);

  answerCall = (callId: string, deviceName?: any): void => {
    this.LogSvc.buttonPressed('[CallPrimaryComponent]: Answer call from: ' + deviceName);
    this.callAction.answerCall(callId, deviceName);
  };

  completeTransfer = () => {
    this.LogSvc.buttonPressed('[CallPrimaryComponent]: Complete transfer');
    return this.PhoneCallSvc.transferCall(this.call.callId)
      .then(() => {
        this.openSnackBar(this.root.i18n.map.res_CallTransferred, '');
      })
      .catch((error: any) => {
        this.LogSvc.error('[CallPrimaryComponent]: completeTransfer() error', error);
        this.dialogService.handleCallError(error, null);
      });
  };

  endCall = (call: any) => {
    this.LogSvc.info('[CallPrimaryComponent]: endCall');
    this.PhoneCallSvc.endCall(call.callId)
      .then(() => {
        //NGTC-1886: we need to unset inFullCallView when there is no call.
        //TODO:check if this could be done in the parent element
        if (this.phoneCalls.length === 0) {
          this.root.inFullCallView = null;
        }
      })
      .catch((err: any) => {
        //possibly show the error in the dialog here
        this.LogSvc.error('[CallPrimaryComponent]: endCall() error', err);
      });
  };

  holdCall = (call: any) => {
    this.LogSvc.buttonPressed('[CallPrimaryComponent]: Hold call');
    this.PhoneCallSvc.holdCall(call.callId)
      .catch((err: any) => {
        //possibly show the error in the dialog here
        //this.dialogService.handleCallError(err, null);
        this.LogSvc.error('[CallPrimaryComponent]: holdCall() error: ', err);
      });
  };

  ignoreCall = (call: any) => {
    this.LogSvc.buttonPressed('Ignore call');
    this.PhoneCallSvc.ignoreCall(call.callId)
    .catch((err: any) => {
      //possibly show the error in the dialog here
      //this.dialogService.handleCallError(err, null);
      this.LogSvc.error('[CallPrimaryComponent]: ignoreCall() error: ', err);
    });
  };

  mergeCall = (call: any) => {
    this.LogSvc.buttonPressed('[CallPrimaryComponent]: Merge calls');
    this.PhoneCallSvc.mergeCall(call.callId)
      .catch((err: any) => {
        //possibly show the error in the dialog here
        //this.dialogService.handleCallError(err, null);
        this.LogSvc.error('[CallPrimaryComponent]: mergeCall() error: ', err);
      });
  };

  pullRemoteCall = (callId: string): void => {
    this.LogSvc.buttonPressed('[CallPrimaryComponent]: Pull remote call');
    this.callAction.pullRemoteCall(callId);
  };

  pushLocalCall = (callId: any, deviceName: any) => {
    this.LogSvc.buttonPressed('[CallPrimaryComponent]: Push local call to ', deviceName);
    this.PhoneCallSvc.pushLocalCall(callId, deviceName)
      .catch((err: any) => {
        this.LogSvc.error('[CallPrimaryComponent]: pushLocalCall() error: ', err);
        this.dialogService.handleCallError(err);
      });
  };

  retrieveCall = (call: any) => {
    this.LogSvc.buttonPressed('[CallPrimaryComponent]: Retrieve call');
    this.PhoneCallSvc.retrieveCall(call.callId)
      .catch((err: any) => {
        //possibly show the error in the dialog here
        //this.dialogService.handleCallError(err, null);
        this.LogSvc.error('[CallPrimaryComponent]: retrieveCall() error: ', err);
      });
  };

  toggleView = (view: any) => {
    this.LogSvc.buttonPressed('[CallPrimaryComponent]: toggleView ', view);
    this.parentToggleView.emit(view);
  };

  get callView() { return this.View; }

  openDialog() {
    const callQualityOptions = {
      action: true,
      title: 'res_CallMetrics',
      icon: 'callQuality',
      isOpen: false,
      call: this.call
    };
    return this.dialogService.openCallQualityView(callQualityOptions);
  }
}
