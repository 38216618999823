import { Component, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { UtilsService } from '../../../services/utils.service';

declare let Circuit: any;
@Component({
  selector: 'app-account-tab',
  templateUrl: './account-tab.component.html',
  styleUrls: ['./account-tab.component.scss']
})
export class AccountTabComponent implements OnInit {
  private LogSvc: any;
  rootScope: any;

  constructor(private commonBlService: CommonBLService, private utilsService: UtilsService) {
    this.LogSvc = Circuit.serviceInstances.logSvc;
  }

  ngOnInit(): void {
    this.LogSvc.info('[AccountTabComponent]: ngOnInit');
    this.rootScope = this.commonBlService.getRootScopeData();
  }

  changePassword() {
    const url = '/web/changePassword';
    this.redirectToUrl(url);
  }

  private redirectToUrl(url: string) {
    this.utilsService.setPreventUnloadPrompt(true);
    window.location.href = url;
  }

  formatLastLogin(date: string) {
    if (!date) {
      return '-';
    }

    const locale = this.rootScope.localUser?.locale || 'en-US';

    const formattedDate = new Date(date).toLocaleDateString(locale, { day: 'numeric', year: 'numeric', month: 'short' });
    const formattedTime = new Date(date).toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit', hour12: false });

    return `${formattedDate} ${formattedTime}`;
  }
}
