import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';

declare let Circuit: any;

@Component({
  selector: 'app-manual-message',
  templateUrl: './manual-message.component.html',
  styleUrls: ['./manual-message.component.scss']
})
export class ManualMessageComponent implements OnInit, OnDestroy {
  root: any;
  i18n: any;
  microphoneDisabled = false;
  showMessage = true;
  infoMessage = '';
  iconMessage = '';

  private PubSubSvc: any;
  private LogSvc: any;

  constructor(private commonBlService: CommonBLService, private ngZone: NgZone) {
    this.root = this.commonBlService.getRootScopeData();
    this.i18n = this.root.i18n;

    this.PubSubSvc = Circuit.serviceInstances.pubSubSvc;
    this.LogSvc = Circuit.serviceInstances.logSvc;
  }

  ngOnInit(): void {
    this.LogSvc.info('[ManualMessageComponent]: ngOnInit');
    this.PubSubSvc.subscribe('/infoMessage/show', this.onInfoMessageShow);
    this.PubSubSvc.subscribe('/infoMessage/close', this.onInfoMessageClose);
  }

  ngOnDestroy(): void {
    this.LogSvc.info('[ManualMessageComponent]: ngOnDestroy');
    this.PubSubSvc.unsubscribe('/infoMessage/show', this.onInfoMessageShow);
    this.PubSubSvc.unsubscribe('/infoMessage/close', this.onInfoMessageClose);
  }

  onInfoMessageShow = (data: any) => {
    this.LogSvc.info('[ManualMessageComponent]: received message: /infoMessage/show');

    //case of missing microphone- show message
    if (data.message === 'res_AccessToAudioInputDeviceFailed') {
      this.ngZone.run(() => {
        this.microphoneDisabled = true;
        this.showMessage = true;
        this.infoMessage = data.message;
        this.iconMessage = 'mic_off';
      });
    }
  };

  onInfoMessageClose = (data: any) => {
    this.LogSvc.info('[ManualMessageComponent]: received message: /infoMessage/close');

    //case of missing microphone- hide message
    if (data === 'res_AccessToAudioInputDeviceFailed') {
      this.ngZone.run(() => {
        this.microphoneDisabled = false;
        this.iconMessage = '';
      });
    }
  };

  closeMessage = () => {
    this.showMessage = false;
  };
}
