<div class="integration-item">
    <div class="item-header">
      <h4 class="subtitle no-margin" uLocalize="res_PlantronicsHub"></h4>
      <mat-slide-toggle
      [(ngModel)]="plantronics"
      color="primary"
      (change)="togglePlantronics()" ngDefaultControl name="plantronics"
      [aria-label]="i18n.map.res_PlantronicsHub"></mat-slide-toggle>
    </div>
    <p class="subheading-2" uLocalize="res_PlantronicsHubDescription"></p>
    <p class="subheading-2 status" *ngIf="isPlantronicsEnabled() && plantronicsState !== 1">
      <span class="integration-indicator" [ngClass]="plantronicsState === 2 ? 'green' : 'red'"></span>
      <span class="mat-card-subtitle"> {{ deviceText }}</span>
    </p>
    <mat-error class="error-status" role="alert" *ngIf="!isPlantronicsEnabled() && plantronicsState === 1">
      <mat-icon class="material-icons-outlined status-icon md-14">error_outline</mat-icon> {{ deviceText }}
    </mat-error>
    <div class="notice" *ngIf="!plantronics">
      <mat-icon class="material-icons-outlined status-icon md-14"  *ngIf="plantronicsState !== 1">error_outline</mat-icon>
      <span class="subheading-2" [innerHTML]='i18n.localize("res_PlantronicsHubMustBeInstalled", plantronicsLink)'></span>
    </div>
  </div>
