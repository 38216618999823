import { Routes } from '@angular/router';
import { CallHistoryComponent } from './components/call-history/call-history.component';
import { DialpadComponent } from './components/dialpad/dialpad.component';
import { TabContainerComponent } from './components/tab-container/tab-container.component';

type PathMatch = 'full' | 'prefix' | undefined;

export const routes: Routes = [
  { path: '', redirectTo: '/dialpad', pathMatch: 'full' as PathMatch },
  { path: '', component: TabContainerComponent, children: [
    {
      path: 'callhistory',
      component: CallHistoryComponent,
      title: 'res_CallLog'
    },
    {
      path: 'dialpad',
      component: DialpadComponent,
      title: 'res_Keypad'
    }
  ]},
  { path: 'dialer', pathMatch: 'full', redirectTo: '/dialpad' }

];
