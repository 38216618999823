<div class="dialog-container">
    <mat-dialog-content>
      <p *ngIf="data.introMessage" [innerHTML]="i18n.localize(data.introMessage, data.messageParams)"></p>
    </mat-dialog-content>
    <mat-dialog-actions align="end" *ngIf="data.action || data.alert">
      <div *ngIf="data.alert">
        <button mat-button (click)="close(false)">
          <span [uLocalizeOnce]="data.okLabel"></span>
        </button>
      </div>
      <div *ngIf="data.action">
        <button mat-button *ngIf="data.noLabel" (click)="close(false)" [mat-dialog-close]="false" class="callFwd-btn">
          <span [uLocalizeOnce]="data.noLabel"></span>
        </button>
        <button mat-button
                *ngIf="data.yesLabel"
                [ngClass]="{'secondary':(data.type === 'error'), 'primary':(data.type !== 'error')}"
                (click)="confirm()"
                [mat-dialog-close]="true"
                cdkFocusInitial
                class="callFwd-btn">
                <span [uLocalizeOnce]="data.yesLabel"></span>
        </button>
      </div>
    </mat-dialog-actions>
  </div>
