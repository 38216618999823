import { Component, Input, OnInit } from '@angular/core';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { CallActionService } from '../../services/call-action.service';

declare let Circuit: any;

@Component({
  selector: 'app-push-incoming-call',
  templateUrl: './push-incoming-call.component.html',
  styleUrls: ['./push-incoming-call.component.scss']
})
export class PushIncomingCallComponent implements OnInit {

  /*
  * Enum for Call Actions
  */
  Action = Object.freeze({
    ANSWER_CALL: 'answerCall',
    PUSH_LOCAL_CALL: 'pushLocalCall'
  });

  @Input() ariaLabel: any;
  @Input() tooltipText: any;
  @Input() call:any;
  @Input() devices: any;
  @Input() color: any;
  @Input() title: any;
  @Input() btnLabel: any;
  @Input() action: any;
  @Input() size: any = '';
  private LogSvc: any;
  private PhoneCallSvc: any;
  root: any;

  constructor(private commonBlService: CommonBLService, private callActionService: CallActionService) {
    this.root = this.commonBlService.getRootScopeData();
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.PhoneCallSvc = Circuit.serviceInstances.phoneCallSvc;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////
  // Internal functions
  ///////////////////////////////////////////////////////////////////////////////////////////////


  ///////////////////////////////////////////////////////////////////////////////////////////////
  // PubSubSvc listeners
  // (You MUST unsubscribe to these events on the ngOnDestroy function below)
  ///////////////////////////////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////////////////////////////
  // Public functions
  ///////////////////////////////////////////////////////////////////////////////////////////////
  get getAction() { return this.Action; }

  splitButtonAction = (id: any, callId: any) => {
    this.LogSvc.info('[PushIncomingCallComponent]: splitButtonAction with action: ', this.action);
    switch (this.action) {
    case this.Action.PUSH_LOCAL_CALL:
      this.PhoneCallSvc.pushLocalCall(callId, id)
          .catch((err: any) => {
            //possibly show the error in the dialog here
            //this.dialogService.handleCallError(err, null);
            this.LogSvc.error('[PushIncomingCallComponent]: pushLocalCall() error: ', err);
          });
      break;
    case this.Action.ANSWER_CALL:
      this.callActionService.answerCall(callId, id);
      break;
    default:
      this.callActionService.answerCall(callId, id);
      break;
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////
  // Initializations
  // Keep this at the end of the controller.
  ///////////////////////////////////////////////////////////////////////////////////////////////
  ngOnInit(): void {
    this.LogSvc.info('[PushIncomingCallComponent]: ngOnInit');
  }
}
