import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonBLService } from 'common-ng/services/common-bl.service';
import { ExchangeOnPremiseService } from '../../services/exchange-on-premise.service';

declare let Circuit: any;

@Component({
  selector: 'app-exchange-connect',
  templateUrl: './exchange-connect.component.html',
  styleUrls: ['./exchange-connect.component.scss']
})
export class ExchangeConnectComponent implements OnInit {
  exchangeConnectFormGroup: FormGroup;
  root: any;
  versions: any = [{code: '2016', name: 'Exchange 2016'}, {code: '2019', name: 'Exchange 2019'}];
  connectingError = '';
  connectionInProgress = false;

  private LogSvc: any;
  private UserProfileSvc: any;
  private _exchangeConfigData: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private mdDialogRef: MatDialogRef<ExchangeConnectComponent>,
  private commonBlService: CommonBLService,
  private exchangeOnPremiseService: ExchangeOnPremiseService) {
    this.exchangeConnectFormGroup = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      exchangeURL: new FormControl('', [Validators.required])
    });
  }

  private async initSettings() {
    const { username, server} = await this.exchangeOnPremiseService.getConnectionSettings();
    const initValues = {
      username: username || '',
      password: '',
      exchangeURL: server || ''
    };

    this.exchangeConnectFormGroup.setValue(initValues);
  }

  hasError(controlName: string, errorName: string) {
    return this.exchangeConnectFormGroup.controls[controlName].hasError(errorName);
  }

  hasConnectionError = () => !!this.exchangeOnPremiseService.getLastConnectionError();

  fieldsInvalid() {
    if (this.exchangeConnectFormGroup.controls.username.hasError('required') ||
    this.exchangeConnectFormGroup.controls.password.hasError('required') ||
    this.exchangeConnectFormGroup.controls.exchangeURL.hasError('required')) {
      return true;
    }
    return false;
  }

  async connect() {
    this.connectionInProgress = true;
    const data = {
      username: this.exchangeConnectFormGroup.value.username,
      password: this.exchangeConnectFormGroup.value.password,
      server: this.exchangeConnectFormGroup.value.exchangeURL
    };
    await this.exchangeOnPremiseService.connect(data);
    this.connectionInProgress = false;
    if (this.exchangeOnPremiseService.getConnected()) {
      this.close(true);
    }
  }

  close(value?: boolean) {
    this.mdDialogRef.close(value);
  }

  ngOnInit(): void {
    this.LogSvc = Circuit.serviceInstances.logSvc;
    this.UserProfileSvc = Circuit.serviceInstances.userProfileSvc;
    this.LogSvc.info('[ExchangeConnect]: ngOnInit');
    this.root = this.commonBlService.getRootScopeData();
    this.initSettings();
  }
}
